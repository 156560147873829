import { useState, useEffect, useRef } from 'react';
import { collection, query, where, orderBy, limit, startAfter, getDocs, updateDoc, doc, addDoc, serverTimestamp, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import { Comment } from './Comment';
import { Loader2, MessageSquare } from 'lucide-react';
import { containsBlockedWords } from '../utils/content/filter';
import toast from 'react-hot-toast';

interface CommentsProps {
  postId: string;
  authorId: string;
  isDarkMode?: boolean;
}

const translations = {
  'pt-BR': {
    writeComment: 'Escreva um comentário...',
    posting: 'Postando...',
    post: 'Postar',
    signInToComment: 'Faça login para comentar',
    noComments: 'Nenhum comentário ainda',
    beFirst: 'Seja o primeiro a comentar!',
    showComments: (count: number) => 
      count === 1 
        ? 'Mostrar 1 comentário'
        : `Mostrar ${count} comentários`,
    showMore: 'Mostrar Mais',
    loadingMore: 'Carregando mais...',
    errors: {
      linksNotAllowed: 'Links não são permitidos em comentários',
      commentTooLong: 'Comentário deve ter no máximo {max} caracteres',
      pasteNotAllowed: 'Não é permitido colar texto em comentários',
      spamLimit: 'Você foi bloqueado por spam. Tente novamente em {hours} horas',
      uploadFailed: 'Falha ao enviar comentário',
      noLinks: 'Links não são permitidos em comentários',
      blockedWord: 'Sua mensagem contém uma palavra bloqueada: {word}'
    },
    success: {
      commentAdded: 'Comentário adicionado',
      commentAddedWithEmoji: 'Comentário adicionado 💭'
    }
  },
  'en': {
    writeComment: 'Write a comment...',
    posting: 'Posting...',
    post: 'Post',
    signInToComment: 'Please sign in to comment',
    noComments: 'No comments yet',
    beFirst: 'Be the first to comment!',
    showComments: (count: number) => 
      count === 1 
        ? 'Show 1 comment'
        : `Show ${count} comments`,
    showMore: 'Show More',
    loadingMore: 'Loading more...',
    errors: {
      linksNotAllowed: 'Links are not allowed in comments',
      commentTooLong: 'Comment must be {max} characters or less',
      pasteNotAllowed: 'Pasting text is not allowed in comments',
      spamLimit: 'You have been blocked for spam. Try again in {hours} hours',
      uploadFailed: 'Failed to upload comment',
      noLinks: 'Links are not allowed in comments',
      blockedWord: 'Your message contains a blocked word: {word}'
    },
    success: {
      commentAdded: 'Comment added',
      commentAddedWithEmoji: 'Comment added 💭'
    }
  }
};

const MAX_COMMENT_LENGTH = 120;
const INITIAL_COMMENTS_COUNT = 5;
const COMMENTS_PER_PAGE = 10;
const SPAM_THRESHOLD = 5;
const SPAM_TIMEFRAME = 5 * 60 * 1000;
const SPAM_BLOCK_DURATION = 48 * 60 * 60 * 1000;

interface CooldownData {
  timestamp: number;
  count?: number;
}

function getStoredCooldown(key: string): CooldownData | null {
  try {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  } catch {
    return null;
  }
}

function setStoredCooldown(key: string, data: CooldownData) {
  localStorage.setItem(key, JSON.stringify(data));
}

export function Comments({ postId, authorId, isDarkMode = false }: CommentsProps) {
  const [comments, setComments] = useState<any[]>([]);
  const [newComment, setNewComment] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [commentCount, setCommentCount] = useState(0);
  const [lastVisible, setLastVisible] = useState<any>(null);
  const [hasMore, setHasMore] = useState(true);
  const [recentComments, setRecentComments] = useState<{ timestamp: number }[]>([]);
  const [isBlocked, setIsBlocked] = useState(false);
  const [blockEndTime, setBlockEndTime] = useState<number | null>(null);
  const { user } = useAuth();
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string, params?: Record<string, string>) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }

    // Handle function translations (for Portuguese pluralization)
    if (typeof value === 'function' && params?.count !== undefined) {
      return value(parseInt(params.count));
    }

    let text = value as string;
    if (params) {
      Object.entries(params).forEach(([param, replacement]) => {
        text = text.replace(`{${param}}`, replacement);
      });
    }
    
    return text;
  };

  useEffect(() => {
    if (!postId) return;

    const fetchCommentCount = async () => {
      try {
        const commentsRef = collection(db, 'posts', postId, 'comments');
        const snapshot = await getDocs(commentsRef);
        setCommentCount(snapshot.size);
      } catch (error) {
        console.error('Error fetching comment count:', error);
      }
    };

    fetchCommentCount();
  }, [postId]);

  useEffect(() => {
    if (!showComments) return;

    const fetchInitialComments = async () => {
      try {
        setLoading(true);
        const commentsRef = collection(db, 'posts', postId, 'comments');
        const q = query(
          commentsRef,
          orderBy('timestamp', 'desc'),
          limit(INITIAL_COMMENTS_COUNT)
        );

        const snapshot = await getDocs(q);
        const commentData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate() || new Date(),
        }));
        
        setComments(commentData);
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
        setHasMore(snapshot.docs.length === INITIAL_COMMENTS_COUNT);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching comments:', error);
        setLoading(false);
      }
    };

    fetchInitialComments();
  }, [postId, showComments]);

  const loadMore = async () => {
    if (!lastVisible || loadingMore || !hasMore) return;

    try {
      setLoadingMore(true);
      const commentsRef = collection(db, 'posts', postId, 'comments');
      const q = query(
        commentsRef,
        orderBy('timestamp', 'desc'),
        startAfter(lastVisible),
        limit(COMMENTS_PER_PAGE)
      );

      const snapshot = await getDocs(q);
      const newComments = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate() || new Date(),
      }));

      setComments(prev => [...prev, ...newComments]);
      setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      setHasMore(snapshot.docs.length === COMMENTS_PER_PAGE);
    } catch (error) {
      console.error('Error loading more comments:', error);
      toast.error(t('errors.loadMoreFailed'));
    } finally {
      setLoadingMore(false);
    }
  };

  const checkForSpam = () => {
    const now = Date.now();
    const recentValid = recentComments.filter(
      comment => now - comment.timestamp < SPAM_TIMEFRAME
    );
    const updatedComments = [...recentValid, { timestamp: now }];
    setRecentComments(updatedComments);
    
    if (updatedComments.length >= SPAM_THRESHOLD) {
      const blockEndTime = now + SPAM_BLOCK_DURATION;
      localStorage.setItem(`comment_block_${user?.uid}`, blockEndTime.toString());
      setIsBlocked(true);
      setBlockEndTime(blockEndTime);
      return true;
    }
    return false;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user || !newComment.trim() || submitting) return;

    if (isBlocked) {
      const remainingTime = Math.ceil((blockEndTime! - Date.now()) / (1000 * 60 * 60));
      toast.error(t('errors.spamLimit', { hours: remainingTime.toString() }));
      return;
    }

    const commentContent = newComment.trim();

    // Check for blocked words
    const { isBlocked: hasBlockedWord, blockedWord } = containsBlockedWords(commentContent);
    if (hasBlockedWord) {
      toast.error(t('errors.blockedWord', { word: blockedWord || '' }));
      return;
    }

    // Check for URLs in content
    const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)|([a-zA-Z0-9-]+\.(com|net|org|br|io|dev|app|me|co|uk|us|ca|eu))/gi;
    if (urlRegex.test(commentContent)) {
      toast.error(t('errors.noLinks'));
      return;
    }

    if (commentContent.length > MAX_COMMENT_LENGTH) {
      toast.error(t('errors.commentTooLong', { max: MAX_COMMENT_LENGTH.toString() }));
      return;
    }

    // Check for spam
    if (checkForSpam()) {
      toast.error(t('errors.spamLimit', { hours: '48' }));
      return;
    }

    setNewComment('');

    const optimisticComment = {
      id: `temp-${Date.now()}`,
      content: commentContent,
      authorId: user.uid,
      authorName: user.displayName || '',
      authorUsername: user.displayName?.startsWith('@') 
        ? user.displayName.slice(1) 
        : user.displayName || '',
      authorPhotoURL: user.photoURL || '',
      timestamp: new Date(),
      postId,
    };

    if (!showComments) {
      setShowComments(true);
    }

    setComments(prev => [optimisticComment, ...prev]);
    setCommentCount(prev => prev + 1);

    try {
      setSubmitting(true);

      const commentRef = collection(db, 'posts', postId, 'comments');
      const docRef = await addDoc(commentRef, {
        content: commentContent,
        authorId: user.uid,
        authorName: user.displayName || '',
        authorUsername: user.displayName?.startsWith('@') 
          ? user.displayName.slice(1) 
          : user.displayName || '',
        authorPhotoURL: user.photoURL,
        timestamp: serverTimestamp(),
      });

      if (user.uid !== authorId) {
        // Create comment notification
        await addDoc(collection(db, 'notifications'), {
          type: 'post_comment',
          postId,
          commentId: docRef.id,
          recipientId: authorId,
          senderId: user.uid,
          senderName: user.displayName || '',
          senderUsername: user.displayName?.startsWith('@') 
            ? user.displayName.slice(1) 
            : user.displayName || '',
          senderPhotoURL: user.photoURL,
          content: commentContent,
          timestamp: serverTimestamp(),
          read: false
        });

        toast.success(t('success.commentAddedWithEmoji'), {
          icon: '💭',
          style: {
            background: '#000000',
            color: '#ffffff',
            fontWeight: 'bold'
          }
        });
      } else {
        toast.success(t('success.commentAdded'), {
          style: {
            background: '#000000',
            color: '#ffffff',
            fontWeight: 'bold'
          }
        });
      }
    } catch (error) {
      console.error('Error adding comment:', error);
      toast.error(t('errors.uploadFailed'));
      setComments(prev => prev.filter(comment => comment.id !== optimisticComment.id));
      setCommentCount(prev => prev - 1);
      setNewComment(commentContent);
    } finally {
      setSubmitting(false);
    }
  };

  const handleCommentDelete = (commentId: string) => {
    setComments(prevComments => prevComments.filter(comment => comment.id !== commentId));
    setCommentCount(prev => prev - 1);
  };

  const handleLoadComments = () => {
    setLoading(true);
    setShowComments(true);
  };

  // Prevent paste event
  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault();
    toast.error(t('errors.pasteNotAllowed'));
  };

  return (
    <div className={`border-t ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
      {user ? (
        <form onSubmit={handleSubmit} className={`p-4 border-b ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
          <div className="flex space-x-3">
            <img
              src={user.photoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${user.uid}`}
              alt={user.displayName || 'User'}
              className="h-10 w-10 rounded-full"
            />
            <div className="flex-1">
              <textarea
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                onPaste={handlePaste}
                placeholder={isBlocked ? t('errors.spamLimit', { hours: Math.ceil((blockEndTime! - Date.now()) / (1000 * 60 * 60)).toString() }) : t('writeComment')}
                className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none ${
                  newComment.length > MAX_COMMENT_LENGTH 
                    ? 'border-red-500' 
                    : isDarkMode
                      ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400'
                      : 'border-gray-300 placeholder-gray-500'
                }`}
                rows={1}
                maxLength={MAX_COMMENT_LENGTH}
                disabled={isBlocked}
              />
              <div className="mt-2 flex justify-between items-center">
                <span className={`text-sm ${
                  newComment.length > MAX_COMMENT_LENGTH 
                    ? 'text-red-500' 
                    : isDarkMode
                      ? 'text-gray-400' 
                      : 'text-gray-500'
                }`}>
                  {newComment.length}/{MAX_COMMENT_LENGTH}
                </span>
                <button
                  type="submit"
                  disabled={!newComment.trim() || submitting || newComment.length > MAX_COMMENT_LENGTH || isBlocked}
                  className={`px-4 py-2 rounded-full font-medium disabled:opacity-50 disabled:cursor-not-allowed ${
                    isDarkMode 
                      ? 'bg-blue-600 text-white hover:bg-blue-700' 
                      : 'bg-blue-500 text-white hover:bg-blue-600'
                  }`}
                >
                  {submitting ? t('posting') : t('post')}
                </button>
              </div>
              {isBlocked && blockEndTime && (
                <p className={`mt-2 text-sm ${isDarkMode ? 'text-red-400' : 'text-red-500'}`}>
                  {t('errors.spamLimit', { hours: Math.ceil((blockEndTime - Date.now()) / (1000 * 60 * 60)).toString() })}
                </p>
              )}
            </div>
          </div>
        </form>
      ) : (
        <div className={`p-4 text-center border-b ${
          isDarkMode 
            ? 'border-gray-700 text-gray-400' 
            : 'border-gray-200 text-gray-500'
        }`}>
          {t('signInToComment')}
        </div>
      )}

      {!showComments && commentCount > 0 && (
        <button
          onClick={handleLoadComments}
          className={`w-full p-4 hover:bg-opacity-80 transition-colors flex items-center justify-center space-x-2 ${
            isDarkMode 
              ? 'text-blue-400 hover:bg-gray-700' 
              : 'text-blue-500 hover:bg-gray-50'
          }`}
        >
          <MessageSquare className="h-5 w-5" />
          <span>{t('showComments', { count: commentCount.toString() })}</span>
        </button>
      )}

      {showComments && (
        <div className={`divide-y ${isDarkMode ? 'divide-gray-700' : 'divide-gray-200'}`}>
          {loading ? (
            <div className="flex justify-center p-4">
              <Loader2 className={`h-6 w-6 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
            </div>
          ) : comments.length > 0 ? (
            <>
              {comments.map((comment) => (
                <Comment
                  key={comment.id}
                  {...comment}
                  postId={postId}
                  onDelete={() => handleCommentDelete(comment.id)}
                  isDarkMode={isDarkMode}
                />
              ))}
              
              {hasMore && (
                <div className="p-4 flex justify-center">
                  <button
                    onClick={loadMore}
                    disabled={loadingMore}
                    className={`px-4 py-2 text-sm rounded-full disabled:opacity-50 flex items-center space-x-2 ${
                      isDarkMode 
                        ? 'text-blue-400 hover:bg-gray-700' 
                        : 'text-blue-500 hover:bg-blue-50'
                    }`}
                  >
                    {loadingMore ? (
                      <>
                        <Loader2 className="h-4 w-4 animate-spin" />
                        <span>{t('loadingMore')}</span>
                      </>
                    ) : (
                      <span>{t('showMore')}</span>
                    )}
                  </button>
                </div>
              )}
            </>
          ) : (
            <div className={`p-4 text-center ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
              <p>{t('noComments')}</p>
              <p className="text-sm mt-1">{t('beFirst')}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}