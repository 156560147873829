import { doc, updateDoc, getDoc, collection, query, where, getDocs, writeBatch } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { auth } from '../../lib/firebase';
import toast from 'react-hot-toast';

const ADMIN_EMAIL = 'afonsoalcantara@outlook.com';

export async function isAdmin(email: string | null): Promise<boolean> {
  return email?.toLowerCase() === ADMIN_EMAIL.toLowerCase();
}

export async function toggleDiamondStatus(userId: string, currentUserEmail: string | null): Promise<boolean> {
  if (!await isAdmin(currentUserEmail)) {
    throw new Error('Unauthorized: Admin access required');
  }

  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      throw new Error('User not found');
    }

    const currentDiamondStatus = userDoc.data().isDiamond || false;
    await updateDoc(userRef, {
      isDiamond: !currentDiamondStatus
    });

    toast.success(`Diamond status ${!currentDiamondStatus ? 'added' : 'removed'} successfully`);
    return !currentDiamondStatus;
  } catch (error) {
    console.error('Error toggling diamond status:', error);
    throw error;
  }
}

export async function searchUsers(searchQuery: string, currentUserEmail: string | null): Promise<any[]> {
  if (!await isAdmin(currentUserEmail)) {
    throw new Error('Unauthorized: Admin access required');
  }

  try {
    const usersRef = collection(db, 'users');
    const cleanQuery = searchQuery.toLowerCase().trim();
    
    // Search by username
    const usernameQuery = query(
      usersRef,
      where('username', '>=', cleanQuery),
      where('username', '<=', cleanQuery + '\uf8ff')
    );

    // Search by name
    const nameQuery = query(
      usersRef,
      where('name', '>=', cleanQuery),
      where('name', '<=', cleanQuery + '\uf8ff')
    );

    const [usernameSnapshot, nameSnapshot] = await Promise.all([
      getDocs(usernameQuery),
      getDocs(nameQuery)
    ]);

    // Combine and deduplicate results
    const results = new Map();
    [...usernameSnapshot.docs, ...nameSnapshot.docs].forEach(doc => {
      if (!results.has(doc.id)) {
        results.set(doc.id, {
          id: doc.id,
          ...doc.data()
        });
      }
    });

    return Array.from(results.values());
  } catch (error) {
    console.error('Error searching users:', error);
    throw new Error('Failed to search users');
  }
}

export async function blockUser(userId: string, currentUserEmail: string | null): Promise<void> {
  if (!await isAdmin(currentUserEmail)) {
    throw new Error('Unauthorized: Admin access required');
  }

  const batch = writeBatch(db);

  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      throw new Error('User not found');
    }

    // Update user document with blocked status and force logout flag
    batch.update(userRef, {
      blocked: true,
      blockedAt: new Date(),
      blockedBy: currentUserEmail,
      forceLogout: true
    });

    // Get all active sessions for this user
    const sessionsRef = collection(db, 'sessions');
    const sessionsQuery = query(sessionsRef, where('userId', '==', userId));
    const sessionsSnapshot = await getDocs(sessionsQuery);

    // Mark all sessions as terminated
    sessionsSnapshot.docs.forEach(doc => {
      batch.update(doc.ref, {
        active: false,
        terminatedAt: new Date(),
        terminatedBy: 'admin',
        terminationReason: 'blocked'
      });
    });

    // Commit all changes atomically
    await batch.commit();

    // Force sign out if the blocked user is currently signed in
    const currentUser = auth.currentUser;
    if (currentUser?.uid === userId) {
      await auth.signOut();
    }

    toast.success('User blocked successfully');
  } catch (error) {
    console.error('Error blocking user:', error);
    throw error;
  }
}

export async function unblockUser(userId: string, currentUserEmail: string | null): Promise<void> {
  if (!await isAdmin(currentUserEmail)) {
    throw new Error('Unauthorized: Admin access required');
  }

  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      throw new Error('User not found');
    }

    await updateDoc(userRef, {
      blocked: false,
      blockedAt: null,
      blockedBy: null,
      forceLogout: false
    });

    toast.success('User unblocked successfully');
  } catch (error) {
    console.error('Error unblocking user:', error);
    throw error;
  }
}

export async function isUserBlocked(userId: string): Promise<boolean> {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      return false;
    }

    const userData = userDoc.data();
    return userData.blocked || false;
  } catch (error) {
    console.error('Error checking user block status:', error);
    return false;
  }
}

export async function checkForceLogout(userId: string): Promise<boolean> {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      return false;
    }

    const userData = userDoc.data();
    return userData.forceLogout || false;
  } catch (error) {
    console.error('Error checking force logout status:', error);
    return false;
  }
}