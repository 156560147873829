import { useState, useEffect } from 'react';
import { collection, query, where, orderBy, limit, getDocs, onSnapshot, addDoc, serverTimestamp, updateDoc, doc, deleteDoc, increment, writeBatch, getDoc, startAfter } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import { formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Link } from 'react-router-dom';
import { MoreHorizontal, Loader2, Heart, Languages, MessageCircle, Send, Trash2, X, UserPlus, UserMinus } from 'lucide-react';
import { Post } from './Post';
import toast from 'react-hot-toast';

interface ChatMessage {
  id: string;
  userMessage: string;
  aiResponse: string;
  timestamp: Date;
  error?: boolean;
}

const translations = {
  'pt-BR': {
    loading: 'Carregando...',
    noNotifications: 'Nenhuma notificação ainda',
    whenInteract: 'Quando alguém interagir com seu conteúdo, você verá aqui',
    showMore: 'Mostrar Mais',
    loadingMore: 'Carregando mais...',
    notifications: {
      comment_reply: 'respondeu seu comentário',
      comment_like: 'curtiu seu comentário',
      post_like: 'curtiu sua publicação',
      post_comment: 'comentou em sua publicação',
      profile_match: 'deu match com você',
      story_like: 'curtiu seu story',
      new_follower: 'agora é seu aliado seguidor! 🤝',
      follower_removed: 'não é mais seu aliado seguidor'
    },
    delete: {
      button: 'Excluir notificação',
      confirm: 'Tem certeza que deseja excluir esta notificação?',
      success: 'Notificação excluída com sucesso',
      error: 'Falha ao excluir notificação'
    },
    reply: {
      button: 'Responder',
      placeholder: 'Escreva uma resposta...',
      success: 'Resposta enviada com sucesso',
      error: 'Falha ao enviar resposta',
      tooLong: 'Resposta deve ter no máximo 120 caracteres',
      noLinks: 'Links não são permitidos em respostas'
    }
  },
  'en': {
    loading: 'Loading...',
    noNotifications: 'No notifications yet',
    whenInteract: 'When someone interacts with your content, you\'ll see it here',
    showMore: 'Show More',
    loadingMore: 'Loading more...',
    notifications: {
      comment_reply: 'replied to your comment',
      comment_like: 'liked your comment',
      post_like: 'liked your post',
      post_comment: 'commented on your post',
      profile_match: 'matched with you',
      story_like: 'liked your story',
      new_follower: 'is now your ally follower! 🤝',
      follower_removed: 'is no longer your ally follower'
    },
    delete: {
      button: 'Delete notification',
      confirm: 'Are you sure you want to delete this notification?',
      success: 'Notification deleted successfully',
      error: 'Failed to delete notification'
    },
    reply: {
      button: 'Reply',
      placeholder: 'Write a reply...',
      success: 'Reply sent successfully',
      error: 'Failed to send reply',
      tooLong: 'Reply must be 120 characters or less',
      noLinks: 'Links are not allowed in replies'
    }
  }
};

interface NotificationListProps {
  language?: string;
  isDarkMode?: boolean;
}

export function NotificationList({ language = 'en', isDarkMode = false }: NotificationListProps) {
  const [notifications, setNotifications] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [selectedPost, setSelectedPost] = useState<any>(null);
  const [loadingPost, setLoadingPost] = useState(false);
  const [selectedNotificationId, setSelectedNotificationId] = useState<string | null>(null);
  const [replyContent, setReplyContent] = useState('');
  const [sendingReply, setSendingReply] = useState(false);
  const [showReplyInput, setShowReplyInput] = useState<string | null>(null);
  const [lastDoc, setLastDoc] = useState<any>(null);
  const [hasMore, setHasMore] = useState(true);
  const { user } = useAuth();
  const [error, setError] = useState<string | null>(null);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  useEffect(() => {
    if (!user) return;

    const loadInitialNotifications = async () => {
      try {
        setLoading(true);
        setError(null);

        const notificationsRef = collection(db, 'notifications');
        const q = query(
          notificationsRef,
          where('recipientId', '==', user.uid),
          orderBy('timestamp', 'desc'),
          limit(10)
        );

        const snapshot = await getDocs(q);
        const notificationsData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate() || new Date()
        }));

        setNotifications(notificationsData);
        setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
        setHasMore(snapshot.docs.length === 10);

        // Mark notifications as read
        const batch = writeBatch(db);
        snapshot.docs.forEach(doc => {
          if (!doc.data().read) {
            batch.update(doc.ref, { read: true });
          }
        });
        await batch.commit();

      } catch (error) {
        console.error('Error loading notifications:', error);
        setError('Failed to load notifications');
      } finally {
        setLoading(false);
      }
    };

    loadInitialNotifications();
  }, [user]);

  const loadMoreNotifications = async () => {
    if (!user || !lastDoc || loadingMore) return;

    try {
      setLoadingMore(true);
      const notificationsRef = collection(db, 'notifications');
      const q = query(
        notificationsRef,
        where('recipientId', '==', user.uid),
        orderBy('timestamp', 'desc'),
        startAfter(lastDoc),
        limit(10)
      );

      const snapshot = await getDocs(q);
      const newNotifications = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate() || new Date()
      }));

      setNotifications(prev => [...prev, ...newNotifications]);
      setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
      setHasMore(snapshot.docs.length === 10);

      // Mark new notifications as read
      const batch = writeBatch(db);
      snapshot.docs.forEach(doc => {
        if (!doc.data().read) {
          batch.update(doc.ref, { read: true });
        }
      });
      await batch.commit();

    } catch (error) {
      console.error('Error loading more notifications:', error);
      toast.error('Failed to load more notifications');
    } finally {
      setLoadingMore(false);
    }
  };

  const handleDeleteNotification = async (notificationId: string) => {
    const confirmDelete = window.confirm(t('delete.confirm'));
    if (!confirmDelete) return;

    try {
      await deleteDoc(doc(db, 'notifications', notificationId));
      setNotifications(prev => prev.filter(n => n.id !== notificationId));
      toast.success(t('delete.success'));
    } catch (error) {
      console.error('Error deleting notification:', error);
      toast.error(t('delete.error'));
    }
  };

  const handleSubmitReply = async (notification: any) => {
    if (!user || !replyContent.trim() || sendingReply) return;

    // Check for character limit
    if (replyContent.length > 120) {
      toast.error(t('reply.tooLong'));
      return;
    }

    // Check for links
    const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)|([a-zA-Z0-9-]+\.(com|net|org|br|io|dev|app|me|co|uk|us|ca|eu))/gi;
    if (urlRegex.test(replyContent)) {
      toast.error(t('reply.noLinks'));
      return;
    }

    try {
      setSendingReply(true);

      // Add reply to the post's comments
      const replyRef = await addDoc(collection(db, 'posts', notification.postId, 'comments', notification.commentId, 'replies'), {
        content: replyContent.trim(),
        authorId: user.uid,
        authorName: user.displayName || '',
        authorUsername: user.displayName?.replace('@', '') || '',
        authorPhotoURL: user.photoURL,
        timestamp: serverTimestamp(),
        likes: []
      });

      // Create notification for comment author
      await addDoc(collection(db, 'notifications'), {
        type: 'comment_reply',
        postId: notification.postId,
        commentId: notification.commentId,
        replyId: replyRef.id,
        recipientId: notification.senderId,
        senderId: user.uid,
        senderName: user.displayName || '',
        senderUsername: user.displayName?.replace('@', '') || '',
        senderPhotoURL: user.photoURL,
        content: replyContent.trim(),
        timestamp: serverTimestamp(),
        read: false
      });

      setReplyContent('');
      setShowReplyInput(null);
      toast.success(t('reply.success'));
    } catch (error) {
      console.error('Error adding reply:', error);
      toast.error(t('reply.error'));
    } finally {
      setSendingReply(false);
    }
  };

  const handleViewPost = async (notification: any) => {
    if (!notification.postId) {
      toast.error('Post not available');
      return;
    }

    try {
      setLoadingPost(true);
      const postRef = doc(db, 'posts', notification.postId);
      const postDoc = await getDoc(postRef);
      
      if (!postDoc.exists()) {
        // Post was deleted - clean up the notification
        await deleteDoc(doc(db, 'notifications', notification.id));
        setNotifications(prev => prev.filter(n => n.id !== notification.id));
        toast.error('This post has been deleted');
        return;
      }

      const postData = postDoc.data();
      if (!postData) {
        toast.error('Post not available');
        return;
      }

      setSelectedPost({
        id: postDoc.id,
        ...postData,
        timestamp: postData.timestamp?.toDate() || new Date(),
        action: t(`notifications.${notification.type}`)
      });
    } catch (error) {
      console.error('Error loading post:', error);
      toast.error('Failed to load post');
    } finally {
      setLoadingPost(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center py-4">
        <Loader2 className={`h-6 w-6 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
      </div>
    );
  }

  if (error) {
    return (
      <div className={`text-center py-8 ${isDarkMode ? 'text-red-400' : 'text-red-500'}`}>
        {error}
      </div>
    );
  }

  return (
    <div className={`divide-y ${isDarkMode ? 'divide-gray-700' : 'divide-gray-200'}`}>
      {notifications.length > 0 ? (
        <>
          {notifications.map((notification) => {
            const isCommentNotification = notification.type === 'post_comment' || notification.type === 'comment_reply';
            const isShowingReply = showReplyInput === notification.id;
            
            return (
              <div
                key={notification.id}
                className={`block p-4 ${
                  notification.read 
                    ? isDarkMode 
                      ? 'hover:bg-gray-700' 
                      : 'hover:bg-gray-50'
                    : isDarkMode 
                      ? 'bg-blue-900/20' 
                      : 'bg-blue-50'
                } transition-colors relative`}
              >
                <div className="flex items-start space-x-3">
                  <Link 
                    to={`/profile/${notification.senderUsername}`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <img
                      src={notification.senderPhotoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${notification.senderId}`}
                      alt={notification.senderName}
                      className="h-10 w-10 rounded-full"
                    />
                  </Link>
                  <div className="flex-1 min-w-0">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-2">
                        <Link 
                          to={`/profile/${notification.senderUsername}`}
                          onClick={(e) => e.stopPropagation()}
                          className={`font-medium hover:underline ${isDarkMode ? 'text-white' : 'text-gray-900'}`}
                        >
                          {notification.senderName}
                        </Link>
                        <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                          {formatDistanceToNow(notification.timestamp, { 
                            addSuffix: true,
                            locale: language === 'pt-BR' ? ptBR : undefined 
                          })}
                        </span>
                      </div>
                      <button
                        onClick={() => handleDeleteNotification(notification.id)}
                        className={`p-2 ${
                          isDarkMode 
                            ? 'hover:bg-red-900/30 text-red-400' 
                            : 'hover:bg-red-50 text-red-500'
                        } rounded-full transition-colors`}
                        title="Delete notification"
                      >
                        <Trash2 className="h-4 w-4" />
                      </button>
                    </div>
                    
                    <div className="flex items-center space-x-2">
                      {notification.type === 'new_follower' ? (
                        <UserPlus className="h-4 w-4 text-blue-500" />
                      ) : notification.type === 'follower_removed' ? (
                        <UserMinus className="h-4 w-4 text-red-500" />
                      ) : notification.type.includes('like') ? (
                        <Heart className="h-4 w-4 text-red-500 fill-current" />
                      ) : notification.type.includes('comment') ? (
                        <MessageCircle className="h-4 w-4 text-blue-500" />
                      ) : null}
                      <span className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                        {t(`notifications.${notification.type}`)}
                      </span>
                    </div>

                    {notification.content && (
                      <div className={`mt-4 p-4 rounded-lg ${
                        isDarkMode 
                          ? 'bg-gray-700/50' 
                          : 'bg-gray-100'
                      }`}>
                        <p className={`whitespace-pre-wrap break-words text-sm ${
                          isDarkMode ? 'text-gray-300' : 'text-gray-700'
                        }`}>
                          {notification.content}
                        </p>
                      </div>
                    )}

                    <div className="mt-4 flex items-center space-x-4">
                      {isCommentNotification && (
                        <button
                          onClick={() => setShowReplyInput(notification.id)}
                          className={`flex items-center space-x-2 px-3 py-1.5 text-sm ${
                            isDarkMode 
                              ? 'bg-gray-700 text-white hover:bg-gray-600' 
                              : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                          } rounded-full transition-colors`}
                        >
                          <MessageCircle className="h-4 w-4" />
                          <span>{t('reply.button')}</span>
                        </button>
                      )}

                      {notification.postId && (
                        <button
                          onClick={() => handleViewPost(notification)}
                          className={`flex items-center space-x-2 px-3 py-1.5 text-sm ${
                            isDarkMode 
                              ? 'bg-gray-700 text-white hover:bg-gray-600' 
                              : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                          } rounded-full transition-colors`}
                        >
                          <span>Poost</span>
                        </button>
                      )}
                    </div>

                    {isShowingReply && (
                      <form 
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmitReply(notification);
                        }} 
                        className="mt-4"
                      >
                        <div className="flex items-center space-x-2">
                          <input
                            type="text"
                            value={replyContent}
                            onChange={(e) => setReplyContent(e.target.value)}
                            placeholder={t('reply.placeholder')}
                            maxLength={120}
                            className={`flex-1 px-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                              isDarkMode 
                                ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                                : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                            }`}
                          />
                          <button
                            type="submit"
                            disabled={!replyContent.trim() || sendingReply}
                            className={`p-2 ${
                              isDarkMode 
                                ? 'bg-blue-600 text-white hover:bg-blue-700' 
                                : 'bg-blue-500 text-white hover:bg-blue-600'
                            } rounded-full disabled:opacity-50`}
                          >
                            {sendingReply ? (
                              <Loader2 className="h-5 w-5 animate-spin" />
                            ) : (
                              <Send className="h-5 w-5" />
                            )}
                          </button>
                        </div>
                        <div className={`text-xs mt-1 ${
                          replyContent.length > 120 
                            ? 'text-red-500' 
                            : isDarkMode 
                              ? 'text-gray-400' 
                              : 'text-gray-500'
                        }`}>
                          {replyContent.length}/120
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
          
          {hasMore && (
            <div className="p-4 flex justify-center">
              <button
                onClick={loadMoreNotifications}
                disabled={loadingMore}
                className={`px-6 py-2 rounded-full disabled:opacity-50 flex items-center space-x-2 ${
                  isDarkMode 
                    ? 'bg-gray-700 text-white hover:bg-gray-600' 
                    : 'bg-black text-white hover:bg-gray-800'
                }`}
              >
                {loadingMore ? (
                  <>
                    <Loader2 className="h-4 w-4 animate-spin" />
                    <span>{t('loadingMore')}</span>
                  </>
                ) : (
                  <span>{t('showMore')}</span>
                )}
              </button>
            </div>
          )}
        </>
      ) : (
        <div className="text-center py-8">
          <MessageCircle className={`h-12 w-12 mx-auto mb-3 ${isDarkMode ? 'text-gray-600' : 'text-gray-400'}`} />
          <p className={isDarkMode ? 'text-gray-300' : 'text-gray-500'}>
            {t('noNotifications')}
          </p>
          <p className={`text-sm mt-1 ${isDarkMode ? 'text-gray-400' : 'text-gray-400'}`}>
            {t('whenInteract')}
          </p>
        </div>
      )}

      {selectedPost && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
          onClick={() => {
            setSelectedPost(null);
            setSelectedNotificationId(null);
          }}
        >
          <div 
            className={`w-full max-w-2xl rounded-lg shadow-xl max-h-[90vh] overflow-y-auto ${
              isDarkMode ? 'bg-gray-800' : 'bg-white'
            }`}
            onClick={e => e.stopPropagation()}
          >
            <div className={`sticky top-0 z-10 p-4 border-b flex justify-between items-center ${
              isDarkMode ? 'border-gray-700 bg-gray-800' : 'border-gray-200 bg-white'
            }`}>
              <div className="flex items-center space-x-3">
                <h3 className={`text-lg font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                  Related Post
                </h3>
                {selectedPost.action && (
                  <span className={`text-sm px-3 py-1 ${
                    isDarkMode 
                      ? 'bg-gray-700 text-gray-300' 
                      : 'bg-gray-100 text-gray-700'
                  } rounded-full`}>
                    {selectedPost.action}
                  </span>
                )}
              </div>
              <button
                onClick={() => {
                  setSelectedPost(null);
                  setSelectedNotificationId(null);
                }}
                className={`p-2 ${
                  isDarkMode 
                    ? 'hover:bg-gray-700' 
                    : 'hover:bg-gray-100'
                } rounded-full`}
                title="Close"
              >
                <X className={`h-5 w-5 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`} />
              </button>
            </div>
            
            <div className="p-4">
              {loadingPost ? (
                <div className="flex justify-center py-8">
                  <Loader2 className={`h-8 w-8 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
                </div>
              ) : (
                <Post {...selectedPost} isDarkMode={isDarkMode} />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}