import { useRef, useState } from 'react';
import { Camera } from 'lucide-react';
import { ImageEditor } from './ImageEditor';
import { validateImageFile, ACCEPTED_IMAGE_TYPES } from '../utils/image/validation';
import { moderateImage } from '../utils/vision';
import toast from 'react-hot-toast';

interface ImageUploadProps {
  imagePreview: string | null;
  onImageSelect: (file: File) => void;
}

export function ImageUpload({ imagePreview, onImageSelect }: ImageUploadProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showEditor, setShowEditor] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [imageError, setImageError] = useState(false);
  const [moderating, setModerating] = useState(false);

  const handleImageSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const validation = validateImageFile(file);
    if (!validation.valid) {
      toast.error(validation.error);
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setSelectedImage(reader.result as string);
      setShowEditor(true);
      setImageError(false);
    };
    reader.onerror = () => {
      toast.error('Error reading image file');
      setImageError(true);
    };
    reader.readAsDataURL(file);
  };

  const handleCroppedImage = async (blob: Blob) => {
    try {
      setModerating(true);
      
      // Convert blob to base64 for Cloud Vision
      const base64Image = await new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(blob);
      });

      // Check image with Cloud Vision
      const isImageSafe = await moderateImage(base64Image);
      if (!isImageSafe) {
        toast.error('This image contains promotional content or inappropriate material. Please choose another image.');
        return;
      }

      const file = new File([blob], 'profile-photo.jpg', { type: 'image/jpeg' });
      onImageSelect(file);
      setShowEditor(false);
      setSelectedImage(null);
      setImageError(false);
    } catch (error) {
      console.error('Error processing cropped image:', error);
      toast.error('Failed to process image');
      setImageError(true);
    } finally {
      setModerating(false);
    }
  };

  return (
    <>
      <div className="flex justify-center mb-4">
        <div className="relative">
          <div
            onClick={() => fileInputRef.current?.click()}
            className="w-24 h-24 rounded-full bg-gray-200 flex items-center justify-center cursor-pointer overflow-hidden group"
          >
            {imagePreview && !imageError ? (
              <img
                src={imagePreview}
                alt="Profile preview"
                className="w-full h-full object-cover"
                onError={(e) => setImageError(true)}
              />
            ) : (
              <Camera className="h-8 w-8 text-gray-400 group-hover:text-gray-500" />
            )}
          </div>
          <input
            ref={fileInputRef}
            type="file"
            accept={ACCEPTED_IMAGE_TYPES.join(',')}
            onChange={handleImageSelect}
            className="hidden"
          />
        </div>
      </div>

      {showEditor && selectedImage && (
        <ImageEditor
          image={selectedImage}
          onSave={handleCroppedImage}
          onCancel={() => {
            setShowEditor(false);
            setSelectedImage(null);
          }}
          aspectRatio={1}
          isProcessing={moderating}
        />
      )}
    </>
  );
}