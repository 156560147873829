import { useState, useEffect, useCallback } from 'react';
import { ChevronUp, ChevronDown, ChevronLeft, ChevronRight, RotateCw } from 'lucide-react';

interface Props {
  isDarkMode?: boolean;
}

const GRID_SIZE = 15;
const CELL_SIZE = 30;

type Direction = 'up' | 'down' | 'left' | 'right';

interface Tank {
  x: number;
  y: number;
  direction: Direction;
  isPlayer?: boolean;
}

interface Bullet {
  x: number;
  y: number;
  direction: Direction;
  isPlayer: boolean;
}

interface Block {
  x: number;
  y: number;
  type: 'brick' | 'steel' | 'base';
}

const translations = {
  'pt-BR': {
    title: 'Battle City',
    score: 'Pontuação',
    gameOver: 'Fim de Jogo!',
    newGame: 'Novo Jogo',
    controls: {
      title: 'Controles:',
      move: 'Mover: Setas',
      shoot: 'Atirar: Espaço',
      tip: 'Proteja sua base e destrua os tanques inimigos!'
    }
  },
  'en': {
    title: 'Battle City',
    score: 'Score',
    gameOver: 'Game Over!',
    newGame: 'New Game',
    controls: {
      title: 'Controls:',
      move: 'Move: Arrows',
      shoot: 'Shoot: Space',
      tip: 'Protect your base and destroy enemy tanks!'
    }
  }
};

export function BattleCityGame({ isDarkMode = false }: Props) {
  const [player, setPlayer] = useState<Tank>({ x: 7, y: 13, direction: 'up', isPlayer: true });
  const [enemies, setEnemies] = useState<Tank[]>([]);
  const [bullets, setBullets] = useState<Bullet[]>([]);
  const [blocks, setBlocks] = useState<Block[]>([]);
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  const initializeGame = useCallback(() => {
    // Reset player
    setPlayer({ x: 7, y: 13, direction: 'up', isPlayer: true });

    // Create initial enemies
    setEnemies([
      { x: 2, y: 1, direction: 'down' },
      { x: 7, y: 1, direction: 'down' },
      { x: 12, y: 1, direction: 'down' }
    ]);

    // Create blocks
    const initialBlocks: Block[] = [];

    // Add base
    initialBlocks.push({ x: 7, y: 14, type: 'base' });

    // Add brick walls
    for (let i = 6; i <= 8; i++) {
      initialBlocks.push({ x: i, y: 13, type: 'brick' });
    }
    initialBlocks.push({ x: 6, y: 12, type: 'brick' });
    initialBlocks.push({ x: 8, y: 12, type: 'brick' });

    // Add steel walls
    for (let i = 0; i < GRID_SIZE; i += 2) {
      initialBlocks.push({ x: i, y: 7, type: 'steel' });
    }

    setBlocks(initialBlocks);
    setBullets([]);
    setScore(0);
    setGameOver(false);
  }, []);

  useEffect(() => {
    initializeGame();
  }, [initializeGame]);

  const movePlayer = (dx: number, dy: number, newDirection: Direction) => {
    if (gameOver) return;

    setPlayer(prev => {
      const newX = Math.max(0, Math.min(GRID_SIZE - 1, prev.x + dx));
      const newY = Math.max(0, Math.min(GRID_SIZE - 1, prev.y + dy));

      // Check collision with blocks
      if (blocks.some(block => block.x === newX && block.y === newY)) {
        return { ...prev, direction: newDirection };
      }

      return { ...prev, x: newX, y: newY, direction: newDirection };
    });
  };

  const shoot = (tank: Tank) => {
    const bullet: Bullet = {
      x: tank.x,
      y: tank.y,
      direction: tank.direction,
      isPlayer: !!tank.isPlayer
    };

    setBullets(prev => [...prev, bullet]);
  };

  useEffect(() => {
    const gameLoop = setInterval(() => {
      if (gameOver) return;

      // Move bullets
      setBullets(prev => {
        const newBullets = prev.map(bullet => {
          let newX = bullet.x;
          let newY = bullet.y;

          switch (bullet.direction) {
            case 'up': newY--; break;
            case 'down': newY++; break;
            case 'left': newX--; break;
            case 'right': newX++; break;
          }

          return { ...bullet, x: newX, y: newY };
        }).filter(bullet => 
          bullet.x >= 0 && bullet.x < GRID_SIZE && 
          bullet.y >= 0 && bullet.y < GRID_SIZE
        );

        return newBullets;
      });

      // Move enemies
      setEnemies(prev => {
        return prev.map(enemy => {
          if (Math.random() < 0.1) { // 10% chance to shoot
            shoot(enemy);
          }

          if (Math.random() < 0.2) { // 20% chance to change direction
            const directions: Direction[] = ['up', 'down', 'left', 'right'];
            enemy.direction = directions[Math.floor(Math.random() * directions.length)];
          }

          let newX = enemy.x;
          let newY = enemy.y;

          switch (enemy.direction) {
            case 'up': newY = Math.max(0, enemy.y - 1); break;
            case 'down': newY = Math.min(GRID_SIZE - 1, enemy.y + 1); break;
            case 'left': newX = Math.max(0, enemy.x - 1); break;
            case 'right': newX = Math.min(GRID_SIZE - 1, enemy.x + 1); break;
          }

          // Check collision with blocks
          if (blocks.some(block => block.x === newX && block.y === newY)) {
            return enemy;
          }

          return { ...enemy, x: newX, y: newY };
        });
      });

      // Check collisions
      bullets.forEach(bullet => {
        // Bullet hits block
        blocks.forEach(block => {
          if (block.x === bullet.x && block.y === bullet.y) {
            if (block.type === 'brick' || (block.type === 'base' && !bullet.isPlayer)) {
              setBlocks(prev => prev.filter(b => !(b.x === block.x && b.y === block.y)));
              setBullets(prev => prev.filter(b => b !== bullet));
              if (bullet.isPlayer) {
                setScore(prev => prev + 10);
              }
            } else if (block.type === 'base' && !bullet.isPlayer) {
              setGameOver(true);
            }
          }
        });

        // Bullet hits tank
        if (bullet.isPlayer) {
          enemies.forEach(enemy => {
            if (enemy.x === bullet.x && enemy.y === bullet.y) {
              setEnemies(prev => prev.filter(e => e !== enemy));
              setBullets(prev => prev.filter(b => b !== bullet));
              setScore(prev => prev + 100);
            }
          });
        } else {
          if (bullet.x === player.x && bullet.y === player.y) {
            setGameOver(true);
          }
        }
      });

      // Check if base is destroyed
      if (!blocks.some(block => block.type === 'base')) {
        setGameOver(true);
      }
    }, 200);

    return () => clearInterval(gameLoop);
  }, [gameOver, blocks, bullets, enemies, player]);

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowUp':
          movePlayer(0, -1, 'up');
          break;
        case 'ArrowDown':
          movePlayer(0, 1, 'down');
          break;
        case 'ArrowLeft':
          movePlayer(-1, 0, 'left');
          break;
        case 'ArrowRight':
          movePlayer(1, 0, 'right');
          break;
        case ' ':
          shoot(player);
          break;
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [player]);

  return (
    <div className="flex flex-col items-center justify-center p-4">
      <div className={`mb-4 text-lg font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        {gameOver ? t('gameOver') : `${t('score')}: ${score}`}
      </div>

      <div 
        className={`grid gap-0.5 p-2 rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'}`}
        style={{
          gridTemplateColumns: `repeat(${GRID_SIZE}, ${CELL_SIZE}px)`,
          gridTemplateRows: `repeat(${GRID_SIZE}, ${CELL_SIZE}px)`
        }}
      >
        {Array.from({ length: GRID_SIZE }).map((_, y) =>
          Array.from({ length: GRID_SIZE }).map((_, x) => {
            const block = blocks.find(b => b.x === x && b.y === y);
            const bullet = bullets.find(b => b.x === x && b.y === y);
            const enemy = enemies.find(e => e.x === x && e.y === y);
            const isPlayer = player.x === x && player.y === y;

            return (
              <div
                key={`${x}-${y}`}
                className={`relative flex items-center justify-center ${
                  block
                    ? block.type === 'brick'
                      ? isDarkMode ? 'bg-red-900' : 'bg-red-700'
                      : block.type === 'steel'
                      ? isDarkMode ? 'bg-gray-600' : 'bg-gray-500'
                      : isDarkMode ? 'bg-yellow-600' : 'bg-yellow-500'
                    : isDarkMode ? 'bg-gray-700' : 'bg-gray-200'
                }`}
              >
                {isPlayer && (
                  <div className={`text-xl transform ${
                    player.direction === 'up' ? 'rotate-0' :
                    player.direction === 'right' ? 'rotate-90' :
                    player.direction === 'down' ? 'rotate-180' :
                    'rotate-270'
                  }`}>
                    🛸
                  </div>
                )}
                {enemy && (
                  <div className={`text-xl transform ${
                    enemy.direction === 'up' ? 'rotate-0' :
                    enemy.direction === 'right' ? 'rotate-90' :
                    enemy.direction === 'down' ? 'rotate-180' :
                    'rotate-270'
                  }`}>
                    👾
                  </div>
                )}
                {bullet && (
                  <div className="text-xl">
                    💥
                  </div>
                )}
                {block?.type === 'base' && (
                  <div className="text-xl">
                    🏰
                  </div>
                )}
              </div>
            );
          })
        )}
      </div>

      {/* Mobile Controls */}
      <div className="mt-6 grid grid-cols-3 gap-4">
        <div />
        <button
          onClick={() => movePlayer(0, -1, 'up')}
          className={`p-3 rounded-full ${
            isDarkMode 
              ? 'bg-gray-700 text-white hover:bg-gray-600' 
              : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
          }`}
        >
          <ChevronUp className="h-6 w-6" />
        </button>
        <div />
        <button
          onClick={() => movePlayer(-1, 0, 'left')}
          className={`p-3 rounded-full ${
            isDarkMode 
              ? 'bg-gray-700 text-white hover:bg-gray-600' 
              : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
          }`}
        >
          <ChevronLeft className="h-6 w-6" />
        </button>
        <button
          onClick={() => shoot(player)}
          className={`p-3 rounded-full ${
            isDarkMode 
              ? 'bg-red-600 text-white hover:bg-red-700' 
              : 'bg-red-500 text-white hover:bg-red-600'
          }`}
        >
          💥
        </button>
        <button
          onClick={() => movePlayer(1, 0, 'right')}
          className={`p-3 rounded-full ${
            isDarkMode 
              ? 'bg-gray-700 text-white hover:bg-gray-600' 
              : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
          }`}
        >
          <ChevronRight className="h-6 w-6" />
        </button>
        <div />
        <button
          onClick={() => movePlayer(0, 1, 'down')}
          className={`p-3 rounded-full ${
            isDarkMode 
              ? 'bg-gray-700 text-white hover:bg-gray-600' 
              : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
          }`}
        >
          <ChevronDown className="h-6 w-6" />
        </button>
        <div />
      </div>

      {gameOver && (
        <button
          onClick={initializeGame}
          className={`mt-6 px-6 py-2 rounded-lg flex items-center space-x-2 ${
            isDarkMode 
              ? 'bg-blue-600 text-white hover:bg-blue-700' 
              : 'bg-blue-500 text-white hover:bg-blue-600'
          }`}
        >
          <RotateCw className="h-5 w-5" />
          <span>{t('newGame')}</span>
        </button>
      )}

      <div className={`mt-6 text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
        <p className="font-medium">{t('controls.title')}</p>
        <p>{t('controls.move')}</p>
        <p>{t('controls.shoot')}</p>
        <p className="mt-2 italic">{t('controls.tip')}</p>
      </div>
    </div>
  );
}