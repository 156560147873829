import { collection, query, orderBy, getDocs, where, limit } from 'firebase/firestore';
import { db } from '../../lib/firebase';

interface RankingWinner {
  userId: string;
  name: string;
  username: string;
  photoURL: string | null;
  likesCount: number;
  monthlyScore: number;
  date: Date;
  position: number;
  month: number;
  year: number;
}

export async function getDailyWinners(limitCount: number = 100): Promise<RankingWinner[]> {
  try {
    // Get current month and year
    const now = new Date();
    const currentMonth = now.getMonth() + 1;
    const currentYear = now.getFullYear();

    const winnersRef = collection(db, 'dailyWinners');
    const q = query(
      winnersRef,
      where('month', '==', currentMonth),
      where('year', '==', currentYear),
      orderBy('date', 'desc'),
      limit(limitCount)
    );

    const snapshot = await getDocs(q);
    
    return snapshot.docs.map((doc, index) => ({
      ...doc.data(),
      date: doc.data().date?.toDate() || new Date(),
      position: index + 1
    })) as RankingWinner[];
  } catch (error) {
    console.error('Error fetching daily winners:', error);
    throw error;
  }
}

export async function getMonthlyWinners(month?: number, year?: number): Promise<RankingWinner[]> {
  try {
    const now = new Date();
    const targetMonth = month || now.getMonth() + 1;
    const targetYear = year || now.getFullYear();

    const monthlyWinnersRef = collection(db, 'monthlyWinners');
    const q = query(
      monthlyWinnersRef,
      where('month', '==', targetMonth),
      where('year', '==', targetYear),
      limit(1)
    );

    const snapshot = await getDocs(q);
    if (snapshot.empty) {
      return [];
    }

    const monthlyData = snapshot.docs[0].data();
    return monthlyData.winners.map((winner: any, index: number) => ({
      ...winner,
      position: index + 1
    }));
  } catch (error) {
    console.error('Error fetching monthly winners:', error);
    throw error;
  }
}