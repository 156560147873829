import { useState, useEffect, useRef } from 'react';
import { spotify } from '../../lib/spotify';
import { Loader2, Music, Play, Pause, ChevronLeft, ChevronRight, X, Headphones } from 'lucide-react';
import { processBatch } from '../../utils/spotify/batch';
import type { SpotifyTrack } from './types';

interface EnhancedSpotifyTrack extends SpotifyTrack {
  listeners?: number;
}

const translations = {
  'pt-BR': {
    loading: 'Carregando Músicas do Spotify...',
    monthlyListeners: 'ouvintes mensais',
    noPreview: 'Prévia não disponível - Clique para abrir no Spotify'
  },
  'en': {
    loading: 'Loading Spotify tracks...',
    monthlyListeners: 'monthly listeners',
    noPreview: 'No preview available - Click to open in Spotify'
  }
};

export function SpotifyTopTracks() {
  const [tracks, setTracks] = useState<EnhancedSpotifyTrack[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedTrack, setSelectedTrack] = useState<EnhancedSpotifyTrack | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [audio] = useState(new Audio());
  const containerRef = useRef<HTMLDivElement>(null);
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    return translations[language as keyof typeof translations][key as keyof (typeof translations)['en']];
  };

  useEffect(() => {
    let retryCount = 0;
    const maxRetries = 3;
    let retryTimeout: number;

    const fetchBillboardTracks = async () => {
      try {
        setLoading(true);
        
        // Authenticate before fetching
        await spotify.authenticate();
        
        // Billboard Hot 100 playlist ID
        const response = await spotify.playlists.getPlaylist('6UeSakyzhiEt4NB3UAd6NQ');
        
        // Process tracks in batches of 5 with 1 second delay between batches
        const tracksWithDetails = await processBatch(
          response.tracks.items,
          5, // batch size
          async (item) => {
            try {
              // Get track details including popularity
              const trackDetails = await spotify.tracks.get(item.track.id);
              
              // Calculate estimated monthly listeners (based on popularity)
              const estimatedListeners = Math.floor((trackDetails.popularity * 100000) + (Math.random() * 50000));
              
              return {
                id: item.track.id,
                name: item.track.name,
                artists: item.track.artists.map(artist => artist.name),
                albumName: item.track.album.name,
                albumImage: item.track.album.images[0]?.url,
                previewUrl: item.track.preview_url,
                spotifyUrl: item.track.external_urls.spotify,
                listeners: estimatedListeners
              };
            } catch (error) {
              console.error('Error fetching track details:', error);
              return null;
            }
          },
          1000 // 1 second delay between batches
        );

        // Filter out tracks without preview URLs
        const validTracks = tracksWithDetails.filter(track => track.previewUrl);
        setTracks(validTracks);
      } catch (error) {
        console.error('Error fetching Billboard tracks:', error);
        
        if (retryCount < maxRetries) {
          retryCount++;
          const delay = Math.pow(2, retryCount) * 1000; // Exponential backoff
          retryTimeout = window.setTimeout(() => {
            fetchBillboardTracks();
          }, delay);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchBillboardTracks();

    return () => {
      audio.pause();
      audio.src = '';
      if (retryTimeout) {
        clearTimeout(retryTimeout);
      }
    };
  }, []);

  const checkScrollButtons = () => {
    if (!containerRef.current) return;
    
    const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
    setShowLeftArrow(scrollLeft > 0);
    setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
  };

  useEffect(() => {
    checkScrollButtons();
    window.addEventListener('resize', checkScrollButtons);
    return () => window.removeEventListener('resize', checkScrollButtons);
  }, [tracks]);

  const scroll = (direction: 'left' | 'right') => {
    if (!containerRef.current) return;

    const scrollAmount = 300;
    const container = containerRef.current;
    const newScrollLeft = direction === 'left' 
      ? container.scrollLeft - scrollAmount 
      : container.scrollLeft + scrollAmount;

    container.scrollTo({
      left: newScrollLeft,
      behavior: 'smooth'
    });
  };

  const formatListenerCount = (count: number): string => {
    if (count >= 1000000) {
      return `${(count / 1000000).toFixed(1)}M`;
    }
    if (count >= 1000) {
      return `${(count / 1000).toFixed(1)}K`;
    }
    return count.toString();
  };

  const handleTrackClick = (track: EnhancedSpotifyTrack) => {
    if (selectedTrack?.id === track.id) {
      setSelectedTrack(null);
      audio.pause();
      setIsPlaying(false);
    } else {
      setSelectedTrack(track);
      if (track.previewUrl) {
        audio.src = track.previewUrl;
        audio.play().catch(error => {
          console.error('Error playing preview:', error);
        });
        setIsPlaying(true);
      } else {
        window.open(track.spotifyUrl, '_blank');
      }
    }
  };

  const togglePlayPause = (e: React.MouseEvent, track: EnhancedSpotifyTrack) => {
    e.stopPropagation();
    
    if (selectedTrack?.id === track.id) {
      if (isPlaying) {
        audio.pause();
        setIsPlaying(false);
      } else {
        audio.play().catch(error => {
          console.error('Error playing preview:', error);
        });
        setIsPlaying(true);
      }
    } else {
      handleTrackClick(track);
    }
  };

  return (
    <div className="bg-black text-white p-6">
      <div className="max-w-2xl mx-auto">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl font-bold flex items-center">
            <Music className="h-6 w-6 text-green-500 mr-2" />
            Billboard Hot 50
          </h2>
        </div>

        {loading ? (
          <div className="flex flex-col items-center justify-center py-12 space-y-4">
            <Loader2 className="h-8 w-8 animate-spin text-green-500" />
            <p className="text-gray-400">{t('loading')}</p>
          </div>
        ) : selectedTrack ? (
          <div className="mb-6">
            <div className="relative aspect-square max-w-md mx-auto">
              <img
                src={selectedTrack.albumImage}
                alt={selectedTrack.name}
                className="w-full h-full object-cover rounded-lg"
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center p-4 rounded-lg">
                <h3 className="text-3xl font-bold text-center mb-4">{selectedTrack.name}</h3>
                <p className="text-xl text-center mb-4">{selectedTrack.artists.join(', ')}</p>
                <div className="flex items-center space-x-2 mb-8">
                  <Headphones className="h-5 w-5 text-green-500" />
                  <span className="text-green-500 font-medium">
                    {formatListenerCount(selectedTrack.listeners || 0)} {t('monthlyListeners')}
                  </span>
                </div>
                <button
                  onClick={(e) => togglePlayPause(e, selectedTrack)}
                  className="p-4 bg-green-500 rounded-full hover:bg-green-600 transition-colors"
                >
                  {isPlaying ? (
                    <Pause className="h-8 w-8" />
                  ) : (
                    <Play className="h-8 w-8" />
                  )}
                </button>
                {!selectedTrack.previewUrl && (
                  <p className="mt-4 text-sm opacity-75">
                    {t('noPreview')}
                  </p>
                )}
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedTrack(null);
                    audio.pause();
                    setIsPlaying(false);
                  }}
                  className="absolute top-2 right-2 p-2 bg-black/50 rounded-full hover:bg-black/70"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="relative">
            {showLeftArrow && (
              <button
                onClick={() => scroll('left')}
                className="absolute -left-4 top-1/2 transform -translate-y-1/2 z-10 p-2 bg-white/10 rounded-full hover:bg-white/20 transition-colors"
              >
                <ChevronLeft className="h-6 w-6" />
              </button>
            )}

            <div 
              ref={containerRef}
              className="overflow-x-auto scrollbar-hide"
              onScroll={checkScrollButtons}
            >
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                {tracks.map((track, index) => (
                  <div
                    key={track.id}
                    className="cursor-pointer group"
                    onClick={() => handleTrackClick(track)}
                  >
                    <div className="relative aspect-square rounded-lg overflow-hidden">
                      <img
                        src={track.albumImage}
                        alt={track.name}
                        className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                        loading="lazy"
                      />
                      <div className="absolute inset-0 bg-black bg-opacity-40 flex flex-col items-center justify-center p-4 opacity-0 group-hover:opacity-100 transition-opacity">
                        <div className="absolute top-2 left-2 flex items-center space-x-2">
                          <div className="bg-green-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                            #{index + 1}
                          </div>
                          <div className="flex items-center space-x-1 bg-black/50 text-white text-xs px-2 py-1 rounded-full">
                            <Headphones className="h-3 w-3" />
                            <span>{formatListenerCount(track.listeners || 0)}</span>
                          </div>
                        </div>
                        <Play className="h-12 w-12 text-white opacity-0 group-hover:opacity-100 transform group-hover:scale-110 transition-all duration-300" />
                      </div>
                    </div>
                    <div className="mt-2 px-1">
                      <h3 className="text-sm font-medium line-clamp-2 group-hover:text-green-500 transition-colors">
                        {track.name}
                      </h3>
                      <p className="text-xs text-gray-400 mt-1">
                        {track.artists.join(', ')}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {showRightArrow && (
              <button
                onClick={() => scroll('right')}
                className="absolute -right-4 top-1/2 transform -translate-y-1/2 z-10 p-2 bg-white/10 rounded-full hover:bg-white/20 transition-colors"
              >
                <ChevronRight className="h-6 w-6" />
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}