import { Link } from 'react-router-dom';
import { VerificationBadge } from '../VerificationBadge';
import { useState, useEffect, useRef } from 'react';
import { UserPosts } from '../UserPosts';
import { Heart, Trophy } from 'lucide-react';
import type { RankingItem } from '../../types/ranking';

interface RankingListProps {
  rankings: RankingItem[];
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    position: 'Posição',
    likes: 'curtidas',
    posts: 'Posts',
    viewPosts: 'Ver posts das últimas 24h'
  },
  'en': {
    position: 'Position',
    likes: 'likes',
    posts: 'Posts',
    viewPosts: 'View posts from last 24h'
  }
};

export function RankingList({ rankings, isDarkMode = false, language = 'en' }: RankingListProps) {
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [selectedUsername, setSelectedUsername] = useState<string | null>(null);
  const [animatedCounts, setAnimatedCounts] = useState<{ [key: string]: number }>({});
  const animationFrameRef = useRef<number>();

  const t = (key: string) => {
    return translations[language as keyof typeof translations][key as keyof (typeof translations)['en']];
  };

  useEffect(() => {
    // Initialize animated counts to 0
    const initialCounts = rankings.reduce((acc, ranking) => {
      acc[ranking.userId] = 0;
      return acc;
    }, {} as { [key: string]: number });
    setAnimatedCounts(initialCounts);

    // Start animation after a short delay
    const timeoutId = setTimeout(() => {
      let startTime: number;
      const duration = 2000; // 2 seconds animation

      const animate = (timestamp: number) => {
        if (!startTime) startTime = timestamp;
        const progress = Math.min((timestamp - startTime) / duration, 1);

        // Use easeOutExpo for smooth animation
        const easeOutExpo = 1 - Math.pow(2, -10 * progress);

        const newCounts = rankings.reduce((acc, ranking) => {
          acc[ranking.userId] = Math.round(easeOutExpo * ranking.likesCount);
          return acc;
        }, {} as { [key: string]: number });

        setAnimatedCounts(newCounts);

        if (progress < 1) {
          animationFrameRef.current = requestAnimationFrame(animate);
        }
      };

      animationFrameRef.current = requestAnimationFrame(animate);
    }, 500);

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      clearTimeout(timeoutId);
    };
  }, [rankings]);

  const getMedalColor = (position: number) => {
    switch (position) {
      case 1:
        return 'text-yellow-500';
      case 2:
        return 'text-gray-400';
      case 3:
        return 'text-amber-600';
      default:
        return isDarkMode ? 'text-gray-400' : 'text-gray-500';
    }
  };

  return (
    <div className={`divide-y ${isDarkMode ? 'divide-gray-700' : 'divide-gray-200'}`}>
      {rankings.map((ranking, index) => (
        <div
          key={`${ranking.userId}-${ranking.username}`}
          className={`flex items-center p-3 ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-50'} transition-colors`}
        >
          {/* Position/Trophy */}
          <div className="w-8 flex justify-center">
            {index + 1 <= 3 ? (
              <Trophy className={`${getMedalColor(index + 1)} animate-bounce h-4 w-4`} />
            ) : (
              <span className={`text-sm font-bold ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                #{index + 1}
              </span>
            )}
          </div>
          
          {/* User Photo */}
          <div className="relative">
            <Link to={`/profile/${ranking.username}`}>
              <img
                src={ranking.photoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${ranking.userId}`}
                alt={ranking.name}
                className="w-10 h-10 rounded-full object-cover border-2 border-white shadow-md"
              />
              {index < 3 && (
                <div className={`absolute -top-1 -right-1 w-5 h-5 rounded-full flex items-center justify-center ${
                  index === 0 ? 'bg-yellow-500' :
                  index === 1 ? 'bg-gray-400' :
                  'bg-amber-600'
                } text-white text-xs font-bold shadow-lg animate-pulse`}>
                  {index + 1}
                </div>
              )}
            </Link>
          </div>
          
          {/* User Info */}
          <div className="flex-1 min-w-0 ml-3">
            <Link to={`/profile/${ranking.username}`}>
              <div className="flex items-center space-x-1">
                <h3 className={`text-sm font-semibold truncate ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                  {ranking.name}
                </h3>
                <VerificationBadge alliesCount={ranking.alliesCount} />
              </div>
              <p className={`text-xs truncate ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                @{ranking.username}
              </p>
            </Link>
          </div>

          <div className="flex items-center space-x-3">
            {/* Posts Button */}
            <button
              onClick={() => {
                setSelectedUserId(ranking.userId);
                setSelectedUsername(ranking.username);
              }}
              className={`px-2 py-1 rounded-full text-xs font-medium transition-colors ${
                isDarkMode 
                  ? 'bg-gray-700 text-white hover:bg-gray-600' 
                  : 'bg-black text-white hover:bg-gray-800'
              }`}
              title={t('viewPosts')}
            >
              {t('posts')}
            </button>

            {/* Stats Group */}
            <div className="flex items-center space-x-2">
              {/* Likes Count */}
              <div className="flex items-center space-x-1 group">
                <Heart className="h-4 w-4 text-red-500 fill-current group-hover:scale-125 group-hover:rotate-12 transition-transform duration-300 ease-in-out animate-[pulse_2s_ease-in-out_infinite]" />
                <span className={`text-xs font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'} group-hover:scale-110 transition-transform duration-300 ease-in-out`}>
                  {animatedCounts[ranking.userId]?.toLocaleString() || '0'}
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* Posts Modal */}
      {selectedUserId && selectedUsername && (
        <UserPosts
          userId={selectedUserId}
          username={selectedUsername}
          onClose={() => {
            setSelectedUserId(null);
            setSelectedUsername(null);
          }}
          isDarkMode={isDarkMode}
        />
      )}

      <style>
        {`
          @keyframes count {
            0% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.2);
              color: #ef4444;
            }
            100% {
              transform: scale(1);
            }
          }
        `}
      </style>
    </div>
  );
}