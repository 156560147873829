import { useState, useEffect, useRef } from 'react';
import { RotateCw, Loader2 } from 'lucide-react';

interface Props {
  isDarkMode?: boolean;
}

const translations = {
  'pt-BR': {
    title: 'Jogo da Memória',
    score: 'Pontuação',
    highScore: 'Recorde',
    newGame: 'Novo Jogo',
    youWin: 'Você venceu!',
    congratulations: 'Parabéns! Você venceu em {moves} jogadas! 🎉',
    newHighScore: 'Novo Recorde! 🏆',
    loading: 'Carregando...',
    moves: 'Jogadas',
    controls: {
      title: 'Como jogar:',
      click: 'Clique nas cartas para revelar os emojis',
      match: 'Encontre todos os pares para vencer',
      tip: 'Dica: Tente memorizar a posição dos emojis!'
    }
  },
  'en': {
    title: 'Memory Game',
    score: 'Score',
    highScore: 'High Score',
    newGame: 'New Game',
    youWin: 'You win!',
    congratulations: 'Congratulations! You won in {moves} moves! 🎉',
    newHighScore: 'New High Score! 🏆',
    loading: 'Loading...',
    moves: 'Moves',
    controls: {
      title: 'How to play:',
      click: 'Click on cards to reveal emojis',
      match: 'Find all matching pairs to win',
      tip: 'Tip: Try to remember where each emoji is!'
    }
  }
};

const GRID_SIZE = 36; // Increased from 16 to 36 (6x6 grid)
const HIGH_SCORE_KEY = 'memory_high_score';

export function MemoryGame({ isDarkMode = false }: Props) {
  const [cards, setCards] = useState<Card[]>([]);
  const [flippedCards, setFlippedCards] = useState<number[]>([]);
  const [matchedPairs, setMatchedPairs] = useState<number>(0);
  const [moves, setMoves] = useState<number>(0);
  const [highScore, setHighScore] = useState<number>(Infinity);
  const [isChecking, setIsChecking] = useState<boolean>(false);
  const [gameStarted, setGameStarted] = useState<boolean>(false);
  const [gameWon, setGameWon] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [language, setLanguage] = useState('en');
  const [isNewHighScore, setIsNewHighScore] = useState(false);

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  // Load high score from localStorage
  useEffect(() => {
    const savedHighScore = localStorage.getItem(HIGH_SCORE_KEY);
    if (savedHighScore) {
      setHighScore(parseInt(savedHighScore));
    }
  }, []);

  const t = (key: string, params?: Record<string, string>) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    let text = value as string;
    if (params) {
      Object.entries(params).forEach(([param, replacement]) => {
        text = text.replace(`{${param}}`, replacement);
      });
    }
    
    return text;
  };

  const initializeGame = () => {
    // Get random emojis
    const gameEmojis = [...EMOJIS]
      .sort(() => Math.random() - 0.5)
      .slice(0, GRID_SIZE / 2);
    
    // Create pairs and shuffle
    const cardPairs = [...gameEmojis, ...gameEmojis]
      .sort(() => Math.random() - 0.5)
      .map((emoji, index) => ({
        id: index,
        emoji,
        isFlipped: false,
        isMatched: false
      }));

    setCards(cardPairs);
    setFlippedCards([]);
    setMatchedPairs(0);
    setMoves(0);
    setGameWon(false);
    setGameStarted(false);
    setIsNewHighScore(false);
    setLoading(false);
  };

  useEffect(() => {
    initializeGame();
  }, []);

  const handleCardClick = (cardId: number) => {
    if (
      isChecking || 
      flippedCards.length === 2 || 
      cards[cardId].isFlipped ||
      cards[cardId].isMatched
    ) {
      return;
    }

    if (!gameStarted) {
      setGameStarted(true);
    }

    const newFlippedCards = [...flippedCards, cardId];
    setFlippedCards(newFlippedCards);

    const newCards = [...cards];
    newCards[cardId].isFlipped = true;
    setCards(newCards);

    if (newFlippedCards.length === 2) {
      setIsChecking(true);
      setMoves(moves => moves + 1);
      
      const [firstCard, secondCard] = newFlippedCards;
      
      if (cards[firstCard].emoji === cards[secondCard].emoji) {
        // Match found
        setTimeout(() => {
          const newCards = [...cards];
          newCards[firstCard].isMatched = true;
          newCards[secondCard].isMatched = true;
          setCards(newCards);
          setFlippedCards([]);
          setMatchedPairs(matchedPairs + 1);
          setIsChecking(false);

          // Check if game is won
          if (matchedPairs + 1 === GRID_SIZE / 2) {
            setGameWon(true);
            // Check for new high score
            if (moves + 1 < highScore) {
              setHighScore(moves + 1);
              setIsNewHighScore(true);
              localStorage.setItem(HIGH_SCORE_KEY, (moves + 1).toString());
            }
          }
        }, 500);
      } else {
        // No match
        setTimeout(() => {
          const newCards = [...cards];
          newCards[firstCard].isFlipped = false;
          newCards[secondCard].isFlipped = false;
          setCards(newCards);
          setFlippedCards([]);
          setIsChecking(false);
        }, 1000);
      }
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center p-8">
        <Loader2 className={`h-8 w-8 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center p-4">
      <div className={`mb-4 text-lg font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        <div className="flex justify-between items-center gap-8 mb-2">
          <div>{t('moves')}: {moves}</div>
          <div>{t('highScore')}: {highScore === Infinity ? '-' : highScore}</div>
        </div>
        {gameWon && (
          <div className="text-center mt-4">
            <div>{t('congratulations', { moves: moves.toString() })}</div>
            {isNewHighScore && (
              <div className="text-yellow-500 mt-2">{t('newHighScore')}</div>
            )}
          </div>
        )}
      </div>

      <div className="grid grid-cols-6 gap-2 w-full max-w-md">
        {cards.map((card) => (
          <button
            key={card.id}
            onClick={() => handleCardClick(card.id)}
            disabled={card.isMatched || isChecking}
            className={`aspect-square rounded-lg text-2xl flex items-center justify-center transition-all duration-300 transform ${
              card.isFlipped || card.isMatched
                ? 'rotate-0'
                : 'rotate-180'
            } ${
              isDarkMode
                ? card.isMatched
                  ? 'bg-green-600'
                  : card.isFlipped
                    ? 'bg-blue-600'
                    : 'bg-gray-700 hover:bg-gray-600'
                : card.isMatched
                  ? 'bg-green-500'
                  : card.isFlipped
                    ? 'bg-blue-500'
                    : 'bg-gray-200 hover:bg-gray-300'
            }`}
          >
            <span className={`transition-opacity duration-300 ${
              card.isFlipped || card.isMatched ? 'opacity-100' : 'opacity-0'
            }`}>
              {card.emoji}
            </span>
          </button>
        ))}
      </div>

      <button
        onClick={initializeGame}
        className={`mt-6 px-4 py-2 rounded-lg flex items-center space-x-2 ${
          isDarkMode 
            ? 'bg-blue-600 text-white hover:bg-blue-700' 
            : 'bg-blue-500 text-white hover:bg-blue-600'
        }`}
      >
        <RotateCw className="h-5 w-5" />
        <span>{t('newGame')}</span>
      </button>

      <div className={`mt-4 text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
        <p className="font-medium">{t('controls.title')}</p>
        <p>{t('controls.click')}</p>
        <p>{t('controls.match')}</p>
        <p className="mt-2 italic">{t('controls.tip')}</p>
      </div>
    </div>
  );
}

// Emoji pairs for the game - Expanded to support 36 cards (18 pairs)
const EMOJIS = [
  '🐶', '🐱', '🐭', '🐹', '🐰', '🦊', '🐻', '🐼', '🐨', '🐯', 
  '🦁', '🐮', '🐷', '🐸', '🐵', '🦄', '🐔', '🐧', '🐦', '🦆', 
  '🦅', '🦉', '🦇', '🐺', '🦊', '🐗', '🐴', '🦓', '🦒', '🦘',
  '🦬', '🦣', '🦏', '🐘', '🦛', '🦍', '🦧', '🦃', '🦚', '🦜'
];

interface Card {
  id: number;
  emoji: string;
  isFlipped: boolean;
  isMatched: boolean;
}