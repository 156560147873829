// List of available radio API servers
const API_SERVERS = [
  'https://de1.api.radio-browser.info',
  'https://de2.api.radio-browser.info', 
  'https://nl.api.radio-browser.info'
];

// Cache for radio stations to avoid repeated requests
let cachedStations: any[] | null = null;
let lastCacheTime: number = 0;
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

// Function to try each server until one works
async function fetchWithFallback(path: string): Promise<any> {
  let lastError;

  for (const baseUrl of API_SERVERS) {
    try {
      const response = await fetch(`${baseUrl}/json/${path}`, {
        method: 'GET',
        headers: {
          'User-Agent': 'Poosting/1.0',
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.warn(`Failed to fetch from ${baseUrl}:`, error);
      lastError = error;
      continue; // Try next server
    }
  }

  // If we get here, all servers failed
  throw lastError || new Error('All radio servers failed');
}

export async function fetchRadioStations() {
  try {
    // Check if we have valid cache
    const now = Date.now();
    if (cachedStations && (now - lastCacheTime) < CACHE_DURATION) {
      return cachedStations;
    }

    // Add Beach Park radio station as first station
    const beachParkStation = {
      name: "Beach Park Radio",
      url: "https://8026.awmix.com/stream",
      favicon: "https://images.unsplash.com/photo-1507525428034-b723cf961d3e?w=128&h=128&fit=crop&auto=format",
      countrycode: "BR",
      language: "Portuguese",
      tags: "beach,summer,music",
      votes: 1000,
      clickcount: 1000,
      bitrate: 128,
      stationuuid: "beach-park-radio"
    };

    // Try to fetch stations from available servers
    const stations = await fetchWithFallback('stations/topclick/300');

    // Filter and process stations
    const processedStations = stations
      .filter((station: any) => 
        station.url_resolved && 
        station.name &&
        station.codec !== 'AAC+' && // Remove formats that consume more bandwidth
        station.bitrate && 
        station.bitrate <= 128 // Limit bitrate to save bandwidth
      )
      .map((station: any) => ({
        name: station.name,
        url: station.url_resolved,
        favicon: station.favicon || `https://api.dicebear.com/7.x/initials/svg?seed=${station.name}`,
        countrycode: station.countrycode || 'Unknown',
        language: station.language || 'Unknown',
        tags: station.tags || '',
        votes: station.votes || 0,
        clickcount: station.clickcount || 0,
        bitrate: station.bitrate || 0,
        stationuuid: station.stationuuid
      }))
      // Sort by votes and clickcount
      .sort((a: any, b: any) => (b.votes + b.clickcount) - (a.votes + a.clickcount))
      // Take top 100 stations
      .slice(0, 100);

    // Add Beach Park station at the beginning
    processedStations.unshift(beachParkStation);

    // Update cache
    cachedStations = processedStations;
    lastCacheTime = now;

    return processedStations;
  } catch (error) {
    console.error('Error fetching radio stations:', error);
    // Return cache even if expired in case of error
    if (cachedStations) {
      return cachedStations;
    }
    // Return Beach Park station as fallback
    return [beachParkStation];
  }
}