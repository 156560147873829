import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import { usePhoto } from '../contexts/PhotoContext';
import { useTheme } from '../contexts/ThemeContext';
import { Loader2, Users } from 'lucide-react';
import { MainMenu } from '../components/MainMenu';
import { ProfileHeader } from '../components/ProfileHeader';
import { AlliesList } from '../components/AlliesList';
import { ProfileVotes } from '../components/ProfileVotes';
import { ProfileMenu } from '../components/ProfileMenu';
import { VerificationBadge } from '../components/VerificationBadge';
import { hasBlockedOrBeenBlockedBy } from '../utils/blocking/users';
import toast from 'react-hot-toast';

interface UserProfile {
  uid: string;
  name: string;
  username: string;
  photoURL: string;
  bio?: string;
  likes?: string[];
  stars?: string[];
  allies?: string[];
  hidden?: boolean;
}

const translations = {
  'pt-BR': {
    title: 'Perfil',
    errors: {
      usernameNotProvided: 'Nome de usuário não fornecido',
      userNotFound: 'Usuário não encontrado',
      failedToLoad: 'Falha ao carregar perfil',
      contentBlocked: 'Conteúdo bloqueado',
      contentBlockedMessage: 'Você bloqueou o conteúdo deste usuário.'
    },
    buttons: {
      goHome: 'Ir para o Início',
      viewFollowers: 'Ver Aliados',
      alliesTitle: 'Aliados'
    }
  },
  'en': {
    title: 'Profile',
    errors: {
      usernameNotProvided: 'Username not provided',
      userNotFound: 'User not found',
      failedToLoad: 'Failed to load profile',
      contentBlocked: 'Content blocked',
      contentBlockedMessage: 'You have blocked this user\'s content.'
    },
    buttons: {
      goHome: 'Go Home',
      viewFollowers: 'View Allies',
      alliesTitle: 'Allies'
    }
  }
};

export function Profile() {
  const { username } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { userPhoto } = usePhoto();
  const { isDarkMode } = useTheme();
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isBlocked, setIsBlocked] = useState(false);
  const [showAlliesList, setShowAlliesList] = useState(false);
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  useEffect(() => {
    const fetchProfile = async () => {
      if (!username) {
        setError(t('errors.usernameNotProvided'));
        setLoading(false);
        return;
      }
      
      try {
        setLoading(true);
        setError(null);

        const usersRef = collection(db, 'users');
        const userQuery = query(
          usersRef,
          where('username', '==', username.toLowerCase())
        );

        const userSnapshot = await getDocs(userQuery);

        if (userSnapshot.empty) {
          setError(t('errors.userNotFound'));
          setLoading(false);
          return;
        }

        const userData = userSnapshot.docs[0].data() as UserProfile;
        userData.uid = userSnapshot.docs[0].id;

        // Check if either user has blocked the other
        if (user && hasBlockedOrBeenBlockedBy(user.uid, userData.uid)) {
          setIsBlocked(true);
          setLoading(false);
          return;
        }

        // Check if profile is hidden and viewer is not admin or profile owner
        const isAdmin = user?.email?.toLowerCase() === 'afonsoalcantara@outlook.com';
        if (userData.hidden && !isAdmin && user?.uid !== userData.uid) {
          setError(t('errors.userNotFound'));
          setLoading(false);
          return;
        }

        if (user && userData.uid === user.uid && userPhoto) {
          userData.photoURL = userPhoto;
        }

        setUserProfile(userData);
      } catch (error: any) {
        console.error('Error fetching profile:', error);
        setError(t('errors.failedToLoad'));
        toast.error(t('errors.failedToLoad'));
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [username, user, userPhoto, language]);

  if (loading) {
    return (
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
        <header className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b`}>
          <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
            <h1 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-black'}`}>
              {t('title')}
            </h1>
          </div>
          <MainMenu />
        </header>
        <div className="flex justify-center items-center p-8">
          <Loader2 className={`h-8 w-8 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
        </div>
      </div>
    );
  }

  if (error || !userProfile) {
    return (
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'} flex flex-col items-center justify-center p-4`}>
        <h2 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
          {error || t('errors.userNotFound')}
        </h2>
        <button
          onClick={() => navigate('/')}
          className={`px-4 py-2 rounded-full ${
            isDarkMode 
              ? 'bg-blue-600 text-white hover:bg-blue-700' 
              : 'bg-blue-500 text-white hover:bg-blue-600'
          }`}
        >
          {t('buttons.goHome')}
        </button>
      </div>
    );
  }

  if (isBlocked) {
    return (
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'} flex flex-col items-center justify-center p-4`}>
        <h2 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
          {t('errors.contentBlocked')}
        </h2>
        <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
          {t('errors.contentBlockedMessage')}
        </p>
        <button
          onClick={() => navigate('/')}
          className={`px-4 py-2 rounded-full ${
            isDarkMode 
              ? 'bg-blue-600 text-white hover:bg-blue-700' 
              : 'bg-blue-500 text-white hover:bg-blue-600'
          }`}
        >
          {t('buttons.goHome')}
        </button>
      </div>
    );
  }

  const isOwnProfile = user?.uid === userProfile.uid;

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <header className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b`}>
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <h1 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-black'}`}>
            {t('title')}
          </h1>
          <div className="flex items-center space-x-2">
            {!isOwnProfile && (
              <ProfileMenu
                profileId={userProfile.uid}
                currentUserId={user?.uid}
                onBlock={() => setIsBlocked(true)}
              />
            )}
          </div>
        </div>
        <MainMenu />
      </header>

      <main className={`max-w-2xl mx-auto ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-x min-h-screen`}>
        <ProfileHeader
          profile={userProfile}
          currentUserId={user?.uid}
          isOwnProfile={isOwnProfile}
          isDarkMode={isDarkMode}
          language={language}
        />

        {!isOwnProfile && (
          <div className="flex flex-col items-center mt-0">
            <ProfileVotes
              profileId={userProfile.uid}
              currentUserId={user?.uid}
              initialVotes={{
                likes: userProfile.likes || [],
                stars: userProfile.stars || []
              }}
              profileName={userProfile.name}
              profileUsername={userProfile.username}
            />
          </div>
        )}

        <div className="mt-2 px-4 mb-4 flex justify-center">
          <button
            onClick={() => setShowAlliesList(true)}
            className={`px-6 py-2 rounded-full font-medium ${
              isDarkMode 
                ? 'bg-gray-700 text-white hover:bg-gray-600' 
                : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
            } transition-colors inline-flex items-center`}
          >
            <Users className="h-5 w-5 mr-2" />
            {t('buttons.viewFollowers')} ({userProfile.allies?.length || 0})
          </button>
        </div>

        {showAlliesList && (
          <AlliesList 
            userId={userProfile.uid}
            allies={userProfile.allies || []}
            isDarkMode={isDarkMode}
            language={language}
          />
        )}
      </main>
    </div>
  );
}