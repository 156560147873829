import { useState, useEffect } from 'react';
import { RotateCw, Loader2 } from 'lucide-react';

interface Props {
  isDarkMode?: boolean;
}

type BetType = 'red' | 'black' | 'even' | 'odd' | 'low' | 'high' | number;

interface Bet {
  type: BetType;
  amount: number;
}

const translations = {
  'pt-BR': {
    chips: 'Fichas',
    bet: 'Aposta',
    spin: 'Girar!',
    reset: 'Reiniciar',
    red: 'Vermelho',
    black: 'Preto',
    even: 'Par',
    odd: 'Ímpar',
    low: '1-18',
    high: '19-36',
    spinning: 'Girando...',
    notEnoughChips: 'Fichas insuficientes!',
    betPlaced: 'Aposta feita em',
    betterLuck: 'Mais sorte na próxima!',
    youWon: 'Você ganhou {amount} fichas!'
  },
  'en': {
    chips: 'Chips',
    bet: 'Bet',
    spin: 'Spin!',
    reset: 'Reset',
    red: 'Red',
    black: 'Black',
    even: 'Even',
    odd: 'Odd',
    low: '1-18',
    high: '19-36',
    spinning: 'Spinning...',
    notEnoughChips: 'Not enough chips!',
    betPlaced: 'Bet placed on',
    betterLuck: 'Better luck next time!',
    youWon: 'You won {amount} chips!'
  }
};

const ROULETTE_NUMBERS = Array.from({ length: 37 }, (_, i) => i); // 0-36
const RED_NUMBERS = [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36];
const INITIAL_CHIPS = 1000;

export function RouletteGame({ isDarkMode = false }: Props) {
  const [chips, setChips] = useState(INITIAL_CHIPS);
  const [currentBet, setCurrentBet] = useState<Bet | null>(null);
  const [spinning, setSpinning] = useState(false);
  const [result, setResult] = useState<number | null>(null);
  const [betAmount, setBetAmount] = useState(10);
  const [message, setMessage] = useState('');
  const [wheelRotation, setWheelRotation] = useState(0);
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string, params?: Record<string, string>) => {
    const text = translations[language as keyof typeof translations][key as keyof (typeof translations)['en']];
    if (params) {
      return text.replace(/\{(\w+)\}/g, (_, key) => params[key] || '');
    }
    return text;
  };

  const isRed = (number: number) => RED_NUMBERS.includes(number);

  const placeBet = (type: BetType) => {
    if (spinning) return;
    if (betAmount > chips) {
      setMessage(t('notEnoughChips'));
      return;
    }
    setCurrentBet({ type, amount: betAmount });
    setMessage(`${t('betPlaced')} ${type}`);
  };

  const calculatePayout = (bet: Bet, result: number): number => {
    if (typeof bet.type === 'number') {
      return bet.type === result ? bet.amount * 35 : 0;
    }

    switch (bet.type) {
      case 'red':
        return isRed(result) ? bet.amount * 2 : 0;
      case 'black':
        return !isRed(result) && result !== 0 ? bet.amount * 2 : 0;
      case 'even':
        return result !== 0 && result % 2 === 0 ? bet.amount * 2 : 0;
      case 'odd':
        return result % 2 === 1 ? bet.amount * 2 : 0;
      case 'low':
        return result >= 1 && result <= 18 ? bet.amount * 2 : 0;
      case 'high':
        return result >= 19 && result <= 36 ? bet.amount * 2 : 0;
      default:
        return 0;
    }
  };

  const spin = async () => {
    if (!currentBet || spinning) return;

    setSpinning(true);
    setMessage(t('spinning'));
    setChips(prev => prev - currentBet.amount);

    const spins = 5;
    const randomDegrees = Math.random() * 360;
    const totalRotation = (spins * 360) + randomDegrees;
    setWheelRotation(totalRotation);

    await new Promise(resolve => setTimeout(resolve, 3000));
    const newResult = Math.floor(Math.random() * 37);
    setResult(newResult);

    const winnings = calculatePayout(currentBet, newResult);
    if (winnings > 0) {
      setMessage(t('youWon', { amount: winnings.toString() }));
      setChips(prev => prev + winnings);
    } else {
      setMessage(t('betterLuck'));
    }

    setSpinning(false);
    setCurrentBet(null);
  };

  const resetGame = () => {
    setChips(INITIAL_CHIPS);
    setCurrentBet(null);
    setResult(null);
    setMessage('');
    setSpinning(false);
    setWheelRotation(0);
  };

  return (
    <div className="flex flex-col items-center justify-center p-4">
      <div className={`mb-4 text-lg font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        {t('chips')}: {chips}
      </div>

      {/* Roulette Wheel */}
      <div 
        className={`relative w-64 h-64 rounded-full mb-8 ${
          isDarkMode ? 'bg-gray-700' : 'bg-gray-200'
        } flex items-center justify-center`}
        style={{
          transform: `rotate(${wheelRotation}deg)`,
          transition: spinning ? 'transform 3s cubic-bezier(0.25, 0.1, 0.25, 1)' : 'none'
        }}
      >
        {ROULETTE_NUMBERS.map((number, index) => {
          const angle = (index * 360) / ROULETTE_NUMBERS.length;
          return (
            <div
              key={number}
              className={`absolute w-8 h-8 flex items-center justify-center rounded-full ${
                number === 0 
                  ? 'bg-green-500' 
                  : isRed(number) 
                    ? 'bg-red-500' 
                    : 'bg-black'
              } text-white font-bold`}
              style={{
                transform: `rotate(${angle}deg) translateY(-120px) rotate(-${angle}deg)`,
              }}
            >
              {number}
            </div>
          );
        })}
        {result !== null && (
          <div className="text-4xl font-bold text-white">
            {result}
          </div>
        )}
      </div>

      {/* Bet Amount Selector */}
      <div className="mb-4 flex items-center space-x-2">
        <button
          onClick={() => setBetAmount(Math.max(10, betAmount - 10))}
          className={`px-3 py-1 rounded ${
            isDarkMode 
              ? 'bg-gray-700 text-white hover:bg-gray-600' 
              : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
          }`}
        >
          -
        </button>
        <span className={isDarkMode ? 'text-white' : 'text-gray-900'}>
          {t('bet')}: {betAmount}
        </span>
        <button
          onClick={() => setBetAmount(Math.min(chips, betAmount + 10))}
          className={`px-3 py-1 rounded ${
            isDarkMode 
              ? 'bg-gray-700 text-white hover:bg-gray-600' 
              : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
          }`}
        >
          +
        </button>
      </div>

      {/* Betting Options */}
      <div className="grid grid-cols-3 gap-2 mb-4">
        <button
          onClick={() => placeBet('red')}
          className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
          disabled={spinning}
        >
          {t('red')}
        </button>
        <button
          onClick={() => placeBet('black')}
          className="px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800"
          disabled={spinning}
        >
          {t('black')}
        </button>
        <button
          onClick={() => placeBet(0)}
          className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
          disabled={spinning}
        >
          0
        </button>
        <button
          onClick={() => placeBet('even')}
          className={`px-4 py-2 rounded-lg ${
            isDarkMode 
              ? 'bg-blue-600 text-white hover:bg-blue-700' 
              : 'bg-blue-500 text-white hover:bg-blue-600'
          }`}
          disabled={spinning}
        >
          {t('even')}
        </button>
        <button
          onClick={() => placeBet('odd')}
          className={`px-4 py-2 rounded-lg ${
            isDarkMode 
              ? 'bg-purple-600 text-white hover:bg-purple-700' 
              : 'bg-purple-500 text-white hover:bg-purple-600'
          }`}
          disabled={spinning}
        >
          {t('odd')}
        </button>
        <button
          onClick={() => placeBet('low')}
          className={`px-4 py-2 rounded-lg ${
            isDarkMode 
              ? 'bg-yellow-600 text-white hover:bg-yellow-700' 
              : 'bg-yellow-500 text-white hover:bg-yellow-600'
          }`}
          disabled={spinning}
        >
          {t('low')}
        </button>
        <button
          onClick={() => placeBet('high')}
          className={`px-4 py-2 rounded-lg ${
            isDarkMode 
              ? 'bg-orange-600 text-white hover:bg-orange-700' 
              : 'bg-orange-500 text-white hover:bg-orange-600'
          }`}
          disabled={spinning}
        >
          {t('high')}
        </button>
      </div>

      {/* Action Buttons */}
      <div className="flex space-x-4">
        <button
          onClick={spin}
          disabled={!currentBet || spinning}
          className={`px-6 py-2 rounded-lg flex items-center space-x-2 ${
            isDarkMode 
              ? 'bg-green-600 text-white hover:bg-green-700' 
              : 'bg-green-500 text-white hover:bg-green-600'
          } disabled:opacity-50`}
        >
          {spinning ? (
            <Loader2 className="h-5 w-5 animate-spin" />
          ) : (
            t('spin')
          )}
        </button>

        <button
          onClick={resetGame}
          className={`px-6 py-2 rounded-lg flex items-center space-x-2 ${
            isDarkMode 
              ? 'bg-blue-600 text-white hover:bg-blue-700' 
              : 'bg-blue-500 text-white hover:bg-blue-600'
          }`}
        >
          <RotateCw className="h-5 w-5" />
          <span>{t('reset')}</span>
        </button>
      </div>

      {message && (
        <div className={`mt-4 text-lg font-bold ${
          message.includes('won') 
            ? 'text-green-500' 
            : isDarkMode 
              ? 'text-white' 
              : 'text-gray-900'
        }`}>
          {message}
        </div>
      )}
    </div>
  );
}