import { useState, useEffect, useCallback } from 'react';
import { Search, Image, Loader2, Send, X } from 'lucide-react';
import { useAuth } from '../components/AuthProvider';
import { useTheme } from '../contexts/ThemeContext';
import { MainMenu } from '../components/MainMenu';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import toast from 'react-hot-toast';

const TENOR_API_KEY = 'AIzaSyBuJT7El4mSrV6GjcknUnKvHbjrsSXuyf8';
const TENOR_CLIENT_KEY = 'poosting_gif_search';
const POST_COOLDOWN = 60 * 60 * 1000; // 60 minutes in milliseconds
const GIFS_PER_PAGE = 30;
const MAX_SHOW_MORE_CLICKS = 3;

const translations = {
  'pt-BR': {
    title: 'Poosting GIFs',
    searchPlaceholder: 'Buscar GIFs...',
    writeMessage: 'Escreva uma mensagem...',
    posting: 'Postando...',
    post: 'Postar',
    noResults: 'Nenhum GIF encontrado',
    searchGifs: 'Buscar GIFs',
    enterSearch: 'Digite algo para buscar GIFs',
    waitMessage: 'Aguarde {time} antes de postar novamente',
    showMore: 'Exibir Mais',
    loadingMore: 'Carregando mais...',
    errors: {
      messageRequired: 'Por favor, escreva uma mensagem',
      gifRequired: 'Por favor, selecione um GIF',
      messageTooLong: 'Mensagem deve ter no máximo 120 caracteres',
      noLinks: 'Links não são permitidos na mensagem',
      postFailed: 'Falha ao criar post',
      searchFailed: 'Falha ao buscar GIFs',
      cooldown: 'Aguarde {time} antes de postar novamente'
    },
    success: {
      postCreated: 'Post criado com sucesso!'
    }
  },
  'en': {
    title: 'Poosting GIFs',
    searchPlaceholder: 'Search GIFs...',
    writeMessage: 'Write a message...',
    posting: 'Posting...',
    post: 'Post',
    noResults: 'No GIFs found',
    searchGifs: 'Search GIFs',
    enterSearch: 'Type something to search GIFs',
    waitMessage: 'Wait {time} before posting again',
    showMore: 'Show More',
    loadingMore: 'Loading more...',
    errors: {
      messageRequired: 'Please write a message',
      gifRequired: 'Please select a GIF',
      messageTooLong: 'Message must be 120 characters or less',
      noLinks: 'Links are not allowed in messages',
      postFailed: 'Failed to create post',
      searchFailed: 'Failed to search GIFs',
      cooldown: 'Please wait {time} before posting again'
    },
    success: {
      postCreated: 'Post created successfully!'
    }
  }
};

interface GifResult {
  id: string;
  title: string;
  media_formats: {
    gif: {
      url: string;
      duration: number;
      preview: string;
      dims: number[];
      size: number;
    };
  };
}

export function Gif() {
  const [searchQuery, setSearchQuery] = useState('');
  const [gifs, setGifs] = useState<GifResult[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [selectedGif, setSelectedGif] = useState<GifResult | null>(null);
  const [message, setMessage] = useState('');
  const [posting, setPosting] = useState(false);
  const [lastPostTime, setLastPostTime] = useState<Date | null>(null);
  const [cooldownRemaining, setCooldownRemaining] = useState(0);
  const [nextPosition, setNextPosition] = useState<string | null>(null);
  const [showMoreClickCount, setShowMoreClickCount] = useState(0);
  const { user } = useAuth();
  const { isDarkMode } = useTheme();
  const [language, setLanguage] = useState(() => {
    return navigator.language.startsWith('pt') ? 'pt-BR' : 'en';
  });

  // Memoize translation function
  const t = useCallback((key: string, params?: Record<string, string>) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    let text = value as string;
    if (params) {
      Object.entries(params).forEach(([param, replacement]) => {
        text = text.replace(`{${param}}`, replacement);
      });
    }
    
    return text;
  }, [language]);

  useEffect(() => {
    // Load last post time from localStorage
    const lastPost = localStorage.getItem('lastPostTime');
    if (lastPost) {
      setLastPostTime(new Date(lastPost));
    }
  }, []);

  useEffect(() => {
    if (!lastPostTime) return;

    const timer = setInterval(() => {
      const now = new Date().getTime();
      const lastPost = lastPostTime.getTime();
      const timeLeft = Math.max(0, POST_COOLDOWN - (now - lastPost));
      setCooldownRemaining(timeLeft);

      if (timeLeft === 0) {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [lastPostTime]);

  const formatCooldownTime = useCallback((ms: number): string => {
    const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((ms % (1000 * 60)) / 1000);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  }, []);

  // Function to check for links in text
  const containsLinks = useCallback((text: string): boolean => {
    const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)|([a-zA-Z0-9-]+\.(com|net|org|br|io|dev|app|me|co|uk|us|ca|eu))/gi;
    return urlRegex.test(text);
  }, []);

  // Handle message input with link validation
  const handleMessageChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newMessage = e.target.value;
    if (containsLinks(newMessage)) {
      toast.error(t('errors.noLinks'));
      return;
    }
    setMessage(newMessage);
  }, [containsLinks, t]);

  // Search GIFs effect
  useEffect(() => {
    if (!searchQuery) {
      setGifs([]);
      setNextPosition(null);
      setShowMoreClickCount(0);
      return;
    }

    const searchGifs = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `https://tenor.googleapis.com/v2/search?q=${encodeURIComponent(searchQuery)}&key=${TENOR_API_KEY}&client_key=${TENOR_CLIENT_KEY}&limit=${GIFS_PER_PAGE}`
        );
        
        if (!response.ok) {
          throw new Error('Failed to fetch GIFs');
        }
        
        const data = await response.json();
        setGifs(data.results || []);
        setNextPosition(data.next);
      } catch (error) {
        console.error('Error searching GIFs:', error);
        toast.error(t('errors.searchFailed'));
      } finally {
        setLoading(false);
      }
    };

    const timer = setTimeout(searchGifs, 500);
    return () => clearTimeout(timer);
  }, [searchQuery, t]);

  const loadMore = useCallback(async () => {
    if (!nextPosition || loadingMore || showMoreClickCount >= MAX_SHOW_MORE_CLICKS) return;

    try {
      setLoadingMore(true);
      const response = await fetch(
        `https://tenor.googleapis.com/v2/search?q=${encodeURIComponent(searchQuery)}&key=${TENOR_API_KEY}&client_key=${TENOR_CLIENT_KEY}&limit=${GIFS_PER_PAGE}&pos=${nextPosition}`
      );

      if (!response.ok) {
        throw new Error('Failed to fetch more GIFs');
      }

      const data = await response.json();
      setGifs(prev => [...prev, ...(data.results || [])]);
      setNextPosition(data.next);
      setShowMoreClickCount(prev => prev + 1);
    } catch (error) {
      console.error('Error loading more GIFs:', error);
      toast.error(t('errors.searchFailed'));
    } finally {
      setLoadingMore(false);
    }
  }, [nextPosition, loadingMore, showMoreClickCount, searchQuery, t]);

  const handleSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user || !selectedGif) return;

    if (cooldownRemaining > 0) {
      toast.error(t('errors.cooldown', { time: formatCooldownTime(cooldownRemaining) }));
      return;
    }

    if (!message.trim()) {
      toast.error(t('errors.messageRequired'));
      return;
    }

    if (!selectedGif) {
      toast.error(t('errors.gifRequired'));
      return;
    }

    if (message.length > 120) {
      toast.error(t('errors.messageTooLong'));
      return;
    }

    if (containsLinks(message)) {
      toast.error(t('errors.noLinks'));
      return;
    }

    try {
      setPosting(true);

      await addDoc(collection(db, 'posts'), {
        content: message.trim(),
        authorId: user.uid,
        authorName: user.displayName || '',
        authorUsername: user.displayName?.replace('@', ''),
        authorPhotoURL: user.photoURL,
        timestamp: serverTimestamp(),
        gifUrl: selectedGif.media_formats.gif.url,
        gifTitle: selectedGif.title,
        views: 0,
        comments: [],
        likes: [],
        stars: [],
        trash: []
      });

      // Update last post time
      const now = new Date();
      setLastPostTime(now);
      localStorage.setItem('lastPostTime', now.toISOString());

      setMessage('');
      setSelectedGif(null);
      setSearchQuery('');
      toast.success(t('success.postCreated'));
    } catch (error) {
      console.error('Error creating post:', error);
      toast.error(t('errors.postFailed'));
    } finally {
      setPosting(false);
    }
  }, [user, selectedGif, cooldownRemaining, message, containsLinks, formatCooldownTime, t]);

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <header className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b`}>
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <Image className={`h-6 w-6 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
            <h1 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-black'}`}>
              {t('title')}
            </h1>
          </div>
        </div>
        <MainMenu />
      </header>

      <main className={`max-w-2xl mx-auto ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-x min-h-screen`}>
        <div className="p-4">
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <div className="relative">
                <Search className={`absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-400'}`} />
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder={t('searchPlaceholder')}
                  className={`w-full pl-10 pr-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                    isDarkMode 
                      ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                      : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                  }`}
                />
              </div>
            </div>

            {selectedGif && (
              <div className="relative">
                <img
                  src={selectedGif.media_formats.gif.url}
                  alt={selectedGif.title}
                  className="w-full rounded-lg"
                />
                <button
                  type="button"
                  onClick={() => setSelectedGif(null)}
                  className="absolute top-2 right-2 p-1 bg-black/50 hover:bg-black/70 rounded-full text-white transition-colors"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>
            )}

            <div className="flex items-center space-x-2">
              <input
                type="text"
                value={message}
                onChange={handleMessageChange}
                placeholder={cooldownRemaining > 0 
                  ? t('waitMessage', { time: formatCooldownTime(cooldownRemaining) })
                  : t('writeMessage')
                }
                maxLength={120}
                className={`flex-1 px-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  isDarkMode 
                    ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                    : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                }`}
                disabled={cooldownRemaining > 0}
              />
              <button
                type="submit"
                disabled={!selectedGif || !message.trim() || posting || cooldownRemaining > 0}
                className={`px-6 py-2 ${
                  isDarkMode 
                    ? 'bg-blue-600 text-white hover:bg-blue-700' 
                    : 'bg-blue-500 text-white hover:bg-blue-600'
                } rounded-full disabled:opacity-50 flex items-center space-x-2`}
              >
                {posting ? (
                  <>
                    <Loader2 className="h-5 w-5 animate-spin" />
                    <span>{t('posting')}</span>
                  </>
                ) : (
                  <>
                    <Send className="h-5 w-5" />
                    <span>{t('post')}</span>
                  </>
                )}
              </button>
            </div>
            <p className={`text-xs ${
              message.length > 120 
                ? 'text-red-500' 
                : isDarkMode 
                  ? 'text-gray-400' 
                  : 'text-gray-500'
            }`}>
              {message.length}/120
            </p>
          </form>

          <div className="mt-8">
            {loading ? (
              <div className="flex justify-center py-8">
                <Loader2 className={`h-8 w-8 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
              </div>
            ) : gifs.length > 0 ? (
              <>
                <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                  {gifs.map((gif) => (
                    <button
                      key={gif.id}
                      onClick={() => setSelectedGif(gif)}
                      className={`relative aspect-square rounded-lg overflow-hidden ${
                        selectedGif?.id === gif.id 
                          ? 'ring-4 ring-blue-500' 
                          : 'hover:opacity-80'
                      } transition-all`}
                    >
                      <img
                        src={gif.media_formats.gif.url}
                        alt={gif.title}
                        className="w-full h-full object-cover"
                        loading="lazy"
                      />
                    </button>
                  ))}
                </div>

                {nextPosition && showMoreClickCount < MAX_SHOW_MORE_CLICKS && (
                  <div className="mt-6 flex justify-center">
                    <button
                      onClick={loadMore}
                      disabled={loadingMore}
                      className={`px-6 py-2 rounded-full disabled:opacity-50 flex items-center space-x-2 ${
                        isDarkMode 
                          ? 'bg-gray-700 text-white hover:bg-gray-600' 
                          : 'bg-black text-white hover:bg-gray-800'
                      }`}
                    >
                      {loadingMore ? (
                        <>
                          <Loader2 className="h-4 w-4 animate-spin" />
                          <span>{t('loadingMore')}</span>
                        </>
                      ) : (
                        <span>{t('showMore')}</span>
                      )}
                    </button>
                  </div>
                )}
              </>
            ) : searchQuery ? (
              <div className="text-center py-8">
                <p className={isDarkMode ? 'text-gray-400' : 'text-gray-500'}>
                  {t('noResults')}
                </p>
              </div>
            ) : (
              <div className="text-center py-8">
                <Image className={`h-12 w-12 mx-auto mb-4 ${isDarkMode ? 'text-gray-600' : 'text-gray-400'}`} />
                <p className={`text-lg font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                  {t('searchGifs')}
                </p>
                <p className={`mt-2 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                  {t('enterSearch')}
                </p>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}