import axios from 'axios';

const API_KEY = 'AIzaSyBuJT7El4mSrV6GjcknUnKvHbjrsSXuyf8';
const VISION_API_URL = 'https://vision.googleapis.com/v1/images:annotate';

// Authorized ad poster
const AUTHORIZED_AD_USER = {
  email: 'contact@poosting.com',
  username: 'poostingads'
};

interface SafeSearchAnnotation {
  adult: string;
  spoof: string;
  medical: string;
  violence: string;
  racy: string;
}

interface FaceAnnotation {
  joyLikelihood: string;
  sorrowLikelihood: string;
  angerLikelihood: string;
  surpriseLikelihood: string;
  underExposedLikelihood: string;
  blurredLikelihood: string;
  headwearLikelihood: string;
  detectionConfidence: number;
  landmarkingConfidence: number;
  rollAngle: number;
  panAngle: number;
  tiltAngle: number;
  youngAdultLikelihood: string;
  adultLikelihood: string;
  seniorLikelihood: string;
}

interface LabelAnnotation {
  description: string;
  score: number;
}

interface TextAnnotation {
  description: string;
  locale: string;
}

// Blocked payment and financial terms
const BLOCKED_PAYMENT_TERMS = [
  'pix',
  'pagamento',
  'payment',
  'transfer',
  'deposit',
  'bank',
  'account'
];

// Blocked content patterns
const BLOCKED_PATTERNS = {
  qrCode: /qr[\s-]?code|qr[\s-]?scanner/i,
  links: /(https?:\/\/[^\s]+)|(www\.[^\s]+)|([a-zA-Z0-9-]+\.(com|net|org|br|io|dev|app|me|co|uk|us|ca|eu))/i,
  mentions: /@[a-zA-Z0-9_]+/i,
  phoneNumbers: /(\+\d{1,3}[\s-]?)?\(?\d{2,3}\)?[\s-]?\d{4,5}[\s-]?\d{4}/g,
  emails: /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g
};

export async function moderateImage(imageUrl: string, userEmail?: string, username?: string): Promise<boolean> {
  try {
    // Check if user is authorized ad poster
    const isAdPoster = userEmail === AUTHORIZED_AD_USER.email && 
                      username?.toLowerCase() === AUTHORIZED_AD_USER.username;

    // If the image is a URL, fetch it first
    let base64Image: string;
    if (imageUrl.startsWith('http')) {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      base64Image = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result as string;
          resolve(base64String.split(',')[1]);
        };
        reader.readAsDataURL(blob);
      });
    } else if (imageUrl.startsWith('data:')) {
      base64Image = imageUrl.split(',')[1];
    } else {
      base64Image = imageUrl;
    }

    const response = await axios.post(
      `${VISION_API_URL}?key=${API_KEY}`,
      {
        requests: [
          {
            image: {
              content: base64Image
            },
            features: [
              {
                type: 'SAFE_SEARCH_DETECTION'
              },
              {
                type: 'FACE_DETECTION'
              },
              {
                type: 'LABEL_DETECTION',
                maxResults: 20
              },
              {
                type: 'TEXT_DETECTION'
              },
              {
                type: 'OBJECT_LOCALIZATION'
              }
            ]
          }
        ]
      }
    );

    const safeSearch = response.data.responses[0].safeSearchAnnotation as SafeSearchAnnotation;
    const faceAnnotations = response.data.responses[0].faceAnnotations as FaceAnnotation[];
    const labelAnnotations = response.data.responses[0].labelAnnotations as LabelAnnotation[];
    const textAnnotations = response.data.responses[0].textAnnotations as TextAnnotation[];
    const localizedObjects = response.data.responses[0].localizedObjectAnnotations || [];

    // If user is authorized ad poster, only check for adult content and violence
    if (isAdPoster) {
      return !(
        safeSearch.adult === 'VERY_LIKELY' ||
        safeSearch.violence === 'VERY_LIKELY'
      );
    }

    // Regular moderation checks for other users
    // 1. Block QR codes
    const hasQRCode = labelAnnotations?.some(label => 
      BLOCKED_PATTERNS.qrCode.test(label.description)
    ) || localizedObjects.some((obj: any) => 
      BLOCKED_PATTERNS.qrCode.test(obj.name)
    );

    if (hasQRCode) {
      console.log('QR code detected in image');
      return false;
    }

    // 2. Check for text content
    if (textAnnotations?.length > 0) {
      const detectedText = textAnnotations[0].description.toLowerCase();

      // Check for payment terms
      const hasPaymentTerms = BLOCKED_PAYMENT_TERMS.some(term => 
        detectedText.includes(term.toLowerCase())
      );

      // Check for phone numbers
      const hasPhoneNumbers = BLOCKED_PATTERNS.phoneNumbers.test(detectedText);

      // Check for email addresses
      const hasEmails = BLOCKED_PATTERNS.emails.test(detectedText);

      if (hasPaymentTerms || hasPhoneNumbers || hasEmails) {
        console.log('Blocked content detected in text:', {
          hasPaymentTerms,
          hasPhoneNumbers,
          hasEmails
        });
        return false;
      }
    }

    // 3. Check for art-related labels
    const artLabels = ['Art', 'Artwork', 'Illustration', 'Drawing', 'Painting', 'Sketch', 'Animation', 'Cartoon', 'Graphics', 'Digital art'];
    const isArtwork = labelAnnotations?.some(label => 
      artLabels.some(artLabel => 
        label.description.toLowerCase().includes(artLabel.toLowerCase())
      ) && label.score > 0.7
    );

    // If it's artwork, be very permissive
    if (isArtwork) {
      // For artwork, only block extreme adult content
      if (safeSearch.adult === 'VERY_LIKELY' && safeSearch.racy === 'VERY_LIKELY') {
        console.log('Extreme inappropriate content detected in artwork');
        return false;
      }
      return true;
    }

    // 4. For non-artwork photos, be more permissive with content checks
    if (
      safeSearch.adult === 'VERY_LIKELY' ||
      safeSearch.violence === 'VERY_LIKELY' ||
      (safeSearch.adult === 'LIKELY' && safeSearch.violence === 'LIKELY')
    ) {
      console.log('Inappropriate content detected');
      return false;
    }

    // 5. Check faces for potential minors
    if (faceAnnotations && faceAnnotations.length > 0) {
      for (const face of faceAnnotations) {
        if (
          face.youngAdultLikelihood === 'VERY_LIKELY' &&
          face.adultLikelihood === 'VERY_UNLIKELY'
        ) {
          console.log('Possible minor detected in image');
          return false;
        }
      }
    }

    return true;
  } catch (error) {
    console.error('Error moderating image:', error);
    // Return true on error to avoid blocking valid content
    return true;
  }
}