import { useState } from 'react';
import { X, Star, MapPin, Phone, Globe, Navigation, Clock, Loader2, Trash2, Edit } from 'lucide-react';
import { useAuth } from '../components/AuthProvider';
import { doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import toast from 'react-hot-toast';

interface PlaceDetailsProps {
  place: google.maps.places.PlaceResult;
  onClose: () => void;
}

export function PlaceDetails({ place, onClose }: PlaceDetailsProps) {
  const { user } = useAuth();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(place.name || '');
  const [editedDescription, setEditedDescription] = useState(place.formatted_address || '');
  const [isSaving, setIsSaving] = useState(false);
  
  // Check if user is admin
  const isAdmin = user?.email?.toLowerCase() === 'afonsoalcantara@outlook.com';

  const handleDelete = async () => {
    if (!isAdmin) return;

    const confirmDelete = window.confirm('Are you sure you want to delete this place?');
    if (!confirmDelete) return;

    try {
      setIsDeleting(true);
      
      // Delete the place document
      if (place.place_id) {
        await deleteDoc(doc(db, 'favoritePlaces', place.place_id));
      }
      
      toast.success('Place deleted successfully');
      onClose();
    } catch (error) {
      console.error('Error deleting place:', error);
      toast.error('Failed to delete place');
    } finally {
      setIsDeleting(false);
    }
  };

  const handleSave = async () => {
    if (!isAdmin || !place.place_id) return;

    try {
      setIsSaving(true);
      
      await updateDoc(doc(db, 'favoritePlaces', place.place_id), {
        name: editedName.trim(),
        description: editedDescription.trim(),
        lastModified: new Date(),
        lastModifiedBy: user.email
      });

      toast.success('Place updated successfully');
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating place:', error);
      toast.error('Failed to update place');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="sticky top-0 bg-white p-4 border-b flex justify-between items-center">
          {isEditing ? (
            <input
              type="text"
              value={editedName}
              onChange={(e) => setEditedName(e.target.value)}
              className="flex-1 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 mr-2"
              placeholder="Place name"
            />
          ) : (
            <h2 className="text-xl font-semibold">{place.name}</h2>
          )}
          <div className="flex items-center space-x-2">
            {isAdmin && (
              <>
                {isEditing ? (
                  <>
                    <button
                      onClick={handleSave}
                      disabled={isSaving}
                      className="p-2 text-blue-500 hover:bg-blue-50 rounded-full transition-colors"
                      title="Save changes"
                    >
                      {isSaving ? (
                        <Loader2 className="h-5 w-5 animate-spin" />
                      ) : (
                        <Edit className="h-5 w-5" />
                      )}
                    </button>
                    <button
                      onClick={() => setIsEditing(false)}
                      className="p-2 text-gray-500 hover:bg-gray-100 rounded-full"
                      title="Cancel editing"
                    >
                      <X className="h-5 w-5" />
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={() => setIsEditing(true)}
                      className="p-2 text-blue-500 hover:bg-blue-50 rounded-full transition-colors"
                      title="Edit place"
                    >
                      <Edit className="h-5 w-5" />
                    </button>
                    <button
                      onClick={handleDelete}
                      disabled={isDeleting}
                      className="p-2 text-red-500 hover:bg-red-50 rounded-full transition-colors"
                      title="Delete place"
                    >
                      {isDeleting ? (
                        <Loader2 className="h-5 w-5 animate-spin" />
                      ) : (
                        <Trash2 className="h-5 w-5" />
                      )}
                    </button>
                  </>
                )}
              </>
            )}
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>

        <div className="p-4">
          {place.photos && place.photos[0] && (
            <div className="mb-6">
              <img
                src={place.photos[0].getUrl()}
                alt={place.name}
                className="w-full h-64 object-cover rounded-lg"
              />
            </div>
          )}

          <div className="space-y-4">
            {place.rating && (
              <div className="flex items-center text-yellow-500">
                <Star className="h-5 w-5 fill-current mr-2" />
                <span className="text-lg">{place.rating}</span>
                <span className="text-gray-600 ml-2">
                  ({place.user_ratings_total} reviews)
                </span>
              </div>
            )}

            {isEditing ? (
              <div className="flex items-center text-gray-700">
                <MapPin className="h-5 w-5 mr-2 flex-shrink-0" />
                <textarea
                  value={editedDescription}
                  onChange={(e) => setEditedDescription(e.target.value)}
                  className="flex-1 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Place description"
                  rows={3}
                />
              </div>
            ) : (
              place.formatted_address && (
                <div className="flex items-center text-gray-700">
                  <MapPin className="h-5 w-5 mr-2" />
                  <span>{place.formatted_address}</span>
                </div>
              )
            )}

            {place.formatted_phone_number && (
              <div className="flex items-center text-gray-700">
                <Phone className="h-5 w-5 mr-2" />
                <a 
                  href={`tel:${place.formatted_phone_number}`}
                  className="hover:text-blue-500"
                >
                  {place.formatted_phone_number}
                </a>
              </div>
            )}

            {place.website && (
              <div className="flex items-center text-gray-700">
                <Globe className="h-5 w-5 mr-2" />
                <a
                  href={place.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  Visit Website
                </a>
              </div>
            )}

            {place.geometry?.location && (
              <div className="flex items-center text-gray-700">
                <Navigation className="h-5 w-5 mr-2" />
                <a
                  href={`https://www.google.com/maps/dir/?api=1&destination=${place.geometry.location.lat()},${place.geometry.location.lng()}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  Get Directions
                </a>
              </div>
            )}

            {place.opening_hours && (
              <div className="mt-6">
                <div className="flex items-center text-gray-700 mb-2">
                  <Clock className="h-5 w-5 mr-2" />
                  <span className="font-medium">Opening Hours</span>
                </div>
                <ul className="space-y-1 text-gray-600">
                  {place.opening_hours.weekday_text?.map((hours, index) => (
                    <li key={index} className="text-sm">{hours}</li>
                  ))}
                </ul>
              </div>
            )}

            {place.reviews && (
              <div className="mt-6">
                <h3 className="text-lg font-semibold mb-4">Reviews</h3>
                <div className="space-y-4">
                  {place.reviews.map((review, index) => (
                    <div key={index} className="border-b pb-4">
                      <div className="flex items-center mb-2">
                        <img
                          src={review.profile_photo_url}
                          alt={review.author_name}
                          className="w-8 h-8 rounded-full mr-2"
                        />
                        <div>
                          <p className="font-medium">{review.author_name}</p>
                          <div className="flex items-center">
                            <Star className="h-4 w-4 text-yellow-500 fill-current" />
                            <span className="text-sm text-gray-600 ml-1">
                              {review.rating} • {new Date(review.time * 1000).toLocaleDateString()}
                            </span>
                          </div>
                        </div>
                      </div>
                      <p className="text-gray-700 text-sm">{review.text}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}