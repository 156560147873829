import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';

const CHUNK_SIZE = 30; // Firestore IN clause limit

// Helper function to chunk array
function chunkArray<T>(array: T[], size: number): T[][] {
  const chunks: T[][] = [];
  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size));
  }
  return chunks;
}

export async function getAllyIds(userId: string): Promise<string[]> {
  try {
    const userDoc = await getDocs(
      query(collection(db, 'users'), where('uid', '==', userId))
    );
    
    if (!userDoc.empty) {
      const userData = userDoc.docs[0].data();
      // Return only confirmed allies
      return userData.allies || [];
    }
    
    return [];
  } catch (error) {
    console.error('Error fetching ally IDs:', error);
    return [];
  }
}

export async function getAllyUsers(userId: string): Promise<any[]> {
  try {
    // Get confirmed ally IDs first
    const allyIds = await getAllyIds(userId);
    
    if (allyIds.length === 0) {
      return [];
    }

    // Split ally IDs into chunks of 30 (Firestore IN clause limit)
    const chunks = chunkArray(allyIds, CHUNK_SIZE);
    const allAllies: any[] = [];

    // Query each chunk separately
    for (const chunk of chunks) {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('uid', 'in', chunk));
      const snapshot = await getDocs(q);
      
      const allies = snapshot.docs
        .map(doc => ({
          uid: doc.id,
          ...doc.data()
        }))
        .filter(user => user.allies?.includes(userId)); // Only include mutual allies

      allAllies.push(...allies);
    }

    return allAllies;
  } catch (error) {
    console.error('Error fetching ally users:', error);
    return [];
  }
}