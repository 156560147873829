import { useState, useEffect } from 'react';
import { RotateCw, Loader2 } from 'lucide-react';

interface Props {
  isDarkMode?: boolean;
}

const translations = {
  'pt-BR': {
    title: 'Jogo da Forca',
    newGame: 'Novo Jogo',
    youWin: 'Você venceu! 🎉',
    youLose: 'Você perdeu! A palavra era:',
    categories: {
      animals: ['GATO', 'CACHORRO', 'LEAO', 'TIGRE', 'MACACO', 'ZEBRA', 'GIRAFA', 'ELEFANTE', 'COBRA', 'PANDA', 'RATO', 'PATO', 'SAPO', 'ONCA', 'FOCA', 'ORCA', 'ANTA', 'ARARA', 'BURRO', 'CABRA', 'CORVO', 'GANSO', 'HIENA', 'IGUANA', 'JABUTI', 'LONTRA', 'MACACO', 'OVELHA', 'PAVAO', 'RAPOSA', 'SAGUI', 'TUCANO', 'VEADO', 'BALEIA', 'CAVALO', 'COELHO', 'DONINHA', 'ELEFANTE', 'FLAMINGO', 'GIRAFA', 'HAMSTER', 'JAGUAR', 'LAGARTO', 'MORCEGO', 'PEIXE', 'PORCO', 'QUATI', 'RINOCERONTE', 'TARTARUGA', 'URUBU', 'VACA', 'ABELHA', 'AGUIA', 'ALPACA', 'ANTILOPE', 'ARANHA', 'BARATA', 'BESOURO', 'BORBOLETA', 'CAMELO', 'CAPIVARA', 'CARACOL', 'CARANGUEJO', 'CASTOR', 'CENTOPEIA', 'CHIMPANZE', 'CIGARRA', 'COALA', 'CROCODILO', 'DRAGAO', 'ESQUILO', 'FALCAO', 'FORMIGA', 'GAIVOTA', 'GOLFINHO', 'GORILA', 'GUAXINIM', 'HIPOPOTAMO', 'JACARE', 'JAGUATIRICA', 'JIBOIA', 'LAGARTIXA', 'LEOPARDO', 'LHAMA', 'LOBO', 'MARITACA', 'MINHOCA', 'MOSCA', 'ORANGOTANGO', 'ORNITORRINCO', 'OSTRA', 'PAPAGAIO', 'PINGUIM', 'POLVO', 'PREGUICA', 'QUERO-QUERO', 'RAIA', 'SALAMANDRA', 'SERPENTE', 'SURICATE', 'TAMANDUÁ', 'TATU', 'TEXUGO', 'TIGRE', 'TUBARAO', 'URSO', 'VESPA', 'ZEBRA'],
      fruits: ['BANANA', 'MACA', 'UVA', 'PERA', 'MANGA', 'LIMAO', 'LARANJA', 'ABACAXI', 'MELANCIA', 'MORANGO', 'KIWI', 'AMORA', 'CEREJA', 'GOIABA', 'JABUTICABA', 'MARACUJA', 'PESSEGO', 'TANGERINA', 'ABACATE', 'ACEROLA', 'AMEIXA', 'CACAU', 'CAJU', 'CARAMBOLA', 'COCO', 'DAMASCO', 'FIGO', 'FRAMBOESA', 'GRAVIOLA', 'GROSELHA', 'JACA', 'JAMBO', 'LICHIA', 'MAMAO', 'MEXERICA', 'NECTARINA', 'PEQUI', 'PITANGA', 'PITAYA', 'ROMA', 'SERIGUELA', 'TAMARA', 'UMBU', 'ACAI', 'ARAÇA', 'BACURI', 'CAGAITA', 'CAMBUCI', 'CUPUACU', 'FEIJOA', 'FRUTA-PAO', 'GENIPAPO', 'GUARANA', 'INGA', 'JAMELAO', 'JATOBA', 'JERIVA', 'KINKAN', 'MANGABA', 'MARACUJA-DO-MATO', 'MURICI', 'NONI', 'PEQUI', 'PHYSALIS', 'PITOMBA', 'PUPUNHA', 'RAMBUTAO', 'SAPOTI', 'SIRIGUELA', 'TAMARINDO', 'UMBU', 'UVAIA'],
      colors: ['AZUL', 'VERDE', 'VERMELHO', 'AMARELO', 'ROXO', 'BRANCO', 'PRETO', 'ROSA', 'LARANJA', 'MARROM', 'CINZA', 'VIOLETA', 'TURQUESA', 'DOURADO', 'PRATA', 'BEGE', 'CORAL', 'CREME', 'INDIGO', 'LILAS', 'MAGENTA', 'OCRE', 'PURPURA', 'SALMAO', 'VERDE-LIMAO', 'VINHO', 'AMBAR', 'BERILO', 'BORGONHA', 'BRONZE', 'CARAMELO', 'CARMESIM', 'CEREJA', 'CIANO', 'COBALTO', 'COBRE', 'ESMERALDA', 'FERRUGEM', 'FUCHSIA', 'GRENA', 'JADE', 'JAMBO', 'MALVA', 'MARFIM', 'MOSTARDA', 'OLIVA', 'PEROLA', 'PESSEGO', 'SEPIA', 'TERRACOTA', 'TIJOLO', 'URUCUM', 'VERDE-AGUA', 'VERDE-MUSGO', 'VERMELHO-SANGUE'],
      countries: ['BRASIL', 'PORTUGAL', 'FRANCA', 'ITALIA', 'ESPANHA', 'CHINA', 'JAPAO', 'INDIA', 'RUSSIA', 'CANADA', 'MEXICO', 'ARGENTINA', 'CHILE', 'PERU', 'URUGUAI', 'PARAGUAI', 'BOLIVIA', 'COLOMBIA', 'VENEZUELA', 'EQUADOR', 'PANAMA', 'CUBA', 'JAMAICA', 'HAITI', 'ANGOLA', 'NIGERIA', 'EGITO', 'MARROCOS', 'TUNISIA', 'QUENIA', 'TANZANIA', 'UGANDA', 'ZAMBIA', 'ZIMBABUE', 'ALEMANHA', 'BELGICA', 'HOLANDA', 'SUECIA', 'NORUEGA', 'FINLANDIA', 'DINAMARCA', 'POLONIA', 'UCRANIA', 'ROMENIA', 'HUNGRIA', 'GRECIA', 'TURQUIA', 'ISRAEL', 'LIBANO', 'SIRIA', 'IRAQUE', 'IRA', 'ARABIA', 'QATAR', 'KUWAIT', 'NEPAL', 'TAILANDIA', 'VIETNA', 'MALASIA', 'INDONESIA', 'AUSTRALIA']
    },
    guessesLeft: 'Tentativas restantes:',
    loading: 'Carregando...'
  },
  'en': {
    title: 'Hangman',
    newGame: 'New Game',
    youWin: 'You win! 🎉',
    youLose: 'You lose! The word was:',
    categories: {
      animals: ['CAT', 'DOG', 'LION', 'TIGER', 'MONKEY', 'ZEBRA', 'GIRAFFE', 'ELEPHANT', 'SNAKE', 'PANDA', 'MOUSE', 'DUCK', 'FROG', 'JAGUAR', 'SEAL', 'ORCA', 'TAPIR', 'MACAW', 'DONKEY', 'GOAT', 'CROW', 'GOOSE', 'HYENA', 'IGUANA', 'TURTLE', 'OTTER', 'MONKEY', 'SHEEP', 'PEACOCK', 'FOX', 'MARMOSET', 'TOUCAN', 'DEER', 'WHALE', 'HORSE', 'RABBIT', 'WEASEL', 'ELEPHANT', 'FLAMINGO', 'GIRAFFE', 'HAMSTER', 'JAGUAR', 'LIZARD', 'BAT', 'FISH', 'PIG', 'COATI', 'RHINOCEROS', 'TORTOISE', 'VULTURE', 'COW', 'BEE', 'EAGLE', 'ALPACA', 'ANTELOPE', 'SPIDER', 'COCKROACH', 'BEETLE', 'BUTTERFLY', 'CAMEL', 'CAPYBARA', 'SNAIL', 'CRAB', 'BEAVER', 'CENTIPEDE', 'CHIMPANZEE', 'CICADA', 'KOALA', 'CROCODILE', 'DRAGON', 'SQUIRREL', 'FALCON', 'ANT', 'SEAGULL', 'DOLPHIN', 'GORILLA', 'RACCOON', 'HIPPOPOTAMUS', 'ALLIGATOR', 'OCELOT', 'BOA', 'GECKO', 'LEOPARD', 'LLAMA', 'WOLF', 'PARAKEET', 'WORM', 'FLY', 'ORANGUTAN', 'PLATYPUS', 'OYSTER', 'PARROT', 'PENGUIN', 'OCTOPUS', 'SLOTH', 'KILLDEER', 'STINGRAY', 'SALAMANDER', 'SERPENT', 'MEERKAT', 'ANTEATER', 'ARMADILLO', 'BADGER', 'TIGER', 'SHARK', 'BEAR', 'WASP', 'ZEBRA'],
      fruits: ['APPLE', 'PEAR', 'GRAPE', 'LIME', 'MANGO', 'LEMON', 'ORANGE', 'PINEAPPLE', 'WATERMELON', 'STRAWBERRY', 'KIWI', 'BLACKBERRY', 'CHERRY', 'GUAVA', 'JABUTICABA', 'PASSION', 'PEACH', 'TANGERINE', 'AVOCADO', 'ACEROLA', 'PLUM', 'COCOA', 'CASHEW', 'STARFRUIT', 'COCONUT', 'APRICOT', 'FIG', 'RASPBERRY', 'SOURSOP', 'CURRANT', 'JACKFRUIT', 'JAMBU', 'LYCHEE', 'PAPAYA', 'MANDARIN', 'NECTARINE', 'PEQUI', 'PITANGA', 'DRAGONFRUIT', 'POMEGRANATE', 'SERIGUELA', 'DATE', 'UMBU', 'ACAI', 'ARAZA', 'BACURI', 'CAGAITA', 'CAMBUCI', 'CUPUACU', 'FEIJOA', 'BREADFRUIT', 'GENIPAP', 'GUARANA', 'INGA', 'JAMBOLAN', 'JATOBA', 'JERIVA', 'KUMQUAT', 'MANGABA', 'MAYPOP', 'MURICI', 'NONI', 'PEQUI', 'GROUNDCHERRY', 'PITOMBA', 'PEACH-PALM', 'RAMBUTAN', 'SAPODILLA', 'SIRIGUELA', 'TAMARIND', 'UMBU', 'UVAIA'],
      colors: ['BLUE', 'GREEN', 'RED', 'YELLOW', 'PURPLE', 'WHITE', 'BLACK', 'PINK', 'ORANGE', 'BROWN', 'GRAY', 'VIOLET', 'TURQUOISE', 'GOLD', 'SILVER', 'BEIGE', 'CORAL', 'CREAM', 'INDIGO', 'LILAC', 'MAGENTA', 'OCHRE', 'PLUM', 'RUBY', 'SEPIA', 'TEAL', 'TAN', 'WINE', 'AMBER', 'AZURE', 'BURGUNDY', 'BRONZE', 'CARAMEL', 'CRIMSON', 'CHERRY', 'CYAN', 'COBALT', 'COPPER', 'EMERALD', 'RUST', 'FUCHSIA', 'GARNET', 'JADE', 'KHAKI', 'MAUVE', 'IVORY', 'MUSTARD', 'OLIVE', 'PEARL', 'PEACH', 'SALMON', 'SIENNA', 'SLATE', 'UMBER', 'VERMILION', 'VIRIDIAN', 'ZAFFRE'],
      countries: ['BRAZIL', 'PORTUGAL', 'FRANCE', 'ITALY', 'SPAIN', 'CHINA', 'JAPAN', 'INDIA', 'RUSSIA', 'CANADA', 'MEXICO', 'ARGENTINA', 'CHILE', 'PERU', 'URUGUAY', 'PARAGUAY', 'BOLIVIA', 'COLOMBIA', 'VENEZUELA', 'ECUADOR', 'PANAMA', 'CUBA', 'JAMAICA', 'HAITI', 'ANGOLA', 'NIGERIA', 'EGYPT', 'MOROCCO', 'TUNISIA', 'KENYA', 'TANZANIA', 'UGANDA', 'ZAMBIA', 'ZIMBABWE', 'GERMANY', 'BELGIUM', 'NETHERLANDS', 'SWEDEN', 'NORWAY', 'FINLAND', 'DENMARK', 'POLAND', 'UKRAINE', 'ROMANIA', 'HUNGARY', 'GREECE', 'TURKEY', 'ISRAEL', 'LEBANON', 'SYRIA', 'IRAQ', 'IRAN', 'ARABIA', 'QATAR', 'KUWAIT', 'NEPAL', 'THAILAND', 'VIETNAM', 'MALAYSIA', 'INDONESIA', 'AUSTRALIA']
    },
    guessesLeft: 'Guesses left:',
    loading: 'Loading...'
  }
};

const MAX_GUESSES = 6;

export function HangmanGame({ isDarkMode = false }: Props) {
  const [word, setWord] = useState('');
  const [guessedLetters, setGuessedLetters] = useState<Set<string>>(new Set());
  const [remainingGuesses, setRemainingGuesses] = useState(MAX_GUESSES);
  const [gameOver, setGameOver] = useState(false);
  const [won, setWon] = useState(false);
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value;
  };

  const initializeGame = () => {
    setLoading(true);
    const categories = t('categories') as Record<string, string[]>;
    const category = Object.keys(categories)[Math.floor(Math.random() * Object.keys(categories).length)];
    const words = categories[category];
    const newWord = words[Math.floor(Math.random() * words.length)];
    
    setWord(newWord);
    setGuessedLetters(new Set());
    setRemainingGuesses(MAX_GUESSES);
    setGameOver(false);
    setWon(false);
    setLoading(false);
  };

  useEffect(() => {
    initializeGame();
  }, [language]);

  const guessLetter = (letter: string) => {
    if (gameOver || guessedLetters.has(letter)) return;

    const newGuessedLetters = new Set(guessedLetters).add(letter);
    setGuessedLetters(newGuessedLetters);

    if (!word.includes(letter)) {
      const newRemainingGuesses = remainingGuesses - 1;
      setRemainingGuesses(newRemainingGuesses);

      if (newRemainingGuesses === 0) {
        setGameOver(true);
      }
    } else {
      // Check if won
      const won = word.split('').every(letter => newGuessedLetters.has(letter));
      if (won) {
        setWon(true);
        setGameOver(true);
      }
    }
  };

  const renderHangman = () => {
    const parts = [
      // Base
      <line key="base" x1="20" y1="140" x2="100" y2="140" stroke="currentColor" />,
      // Pole
      <line key="pole" x1="60" y1="20" x2="60" y2="140" stroke="currentColor" />,
      // Top
      <line key="top" x1="60" y1="20" x2="120" y2="20" stroke="currentColor" />,
      // Rope
      <line key="rope" x1="120" y1="20" x2="120" y2="40" stroke="currentColor" />,
      // Head
      <circle key="head" cx="120" cy="50" r="10" fill="none" stroke="currentColor" />,
      // Body
      <line key="body" x1="120" y1="60" x2="120" y2="90" stroke="currentColor" />,
      // Left arm
      <line key="leftArm" x1="120" y1="70" x2="100" y2="80" stroke="currentColor" />,
      // Right arm
      <line key="rightArm" x1="120" y1="70" x2="140" y2="80" stroke="currentColor" />,
      // Left leg
      <line key="leftLeg" x1="120" y1="90" x2="100" y2="110" stroke="currentColor" />,
      // Right leg
      <line key="rightLeg" x1="120" y1="90" x2="140" y2="110" stroke="currentColor" />
    ];

    const partsToShow = 10 - remainingGuesses;

    return (
      <svg width="160" height="160" className={isDarkMode ? 'text-white' : 'text-gray-900'}>
        {parts.slice(0, partsToShow)}
      </svg>
    );
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center p-8">
        <Loader2 className={`h-8 w-8 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center p-4">
      <div className={`mb-4 text-lg font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        {gameOver ? (
          won ? t('youWin') : `${t('youLose')} ${word}`
        ) : (
          `${t('guessesLeft')} ${remainingGuesses}`
        )}
      </div>

      {/* Hangman Drawing */}
      <div className="mb-8">
        {renderHangman()}
      </div>

      {/* Word Display */}
      <div className="flex space-x-2 mb-8">
        {word.split('').map((letter, index) => (
          <div
            key={index}
            className={`w-8 h-8 flex items-center justify-center border-b-2 ${
              isDarkMode ? 'border-gray-400' : 'border-gray-900'
            }`}
          >
            <span className={`text-xl font-bold ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              {guessedLetters.has(letter) ? letter : ''}
            </span>
          </div>
        ))}
      </div>

      {/* Letter Buttons */}
      <div className="grid grid-cols-7 gap-2 mb-8">
        {'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').map((letter) => (
          <button
            key={letter}
            onClick={() => guessLetter(letter)}
            disabled={guessedLetters.has(letter) || gameOver}
            className={`w-8 h-8 rounded-lg flex items-center justify-center font-bold ${
              guessedLetters.has(letter)
                ? isDarkMode
                  ? 'bg-gray-700 text-gray-500'
                  : 'bg-gray-200 text-gray-400'
                : isDarkMode
                  ? 'bg-blue-600 text-white hover:bg-blue-700'
                  : 'bg-blue-500 text-white hover:bg-blue-600'
            } disabled:cursor-not-allowed transition-colors`}
          >
            {letter}
          </button>
        ))}
      </div>

      {/* New Game Button */}
      <button
        onClick={initializeGame}
        className={`px-4 py-2 rounded-lg flex items-center space-x-2 ${
          isDarkMode 
            ? 'bg-green-600 text-white hover:bg-green-700' 
            : 'bg-green-500 text-white hover:bg-green-600'
        }`}
      >
        <RotateCw className="h-5 w-5" />
        <span>{t('newGame')}</span>
      </button>
    </div>
  );
}