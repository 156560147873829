import { useState, useEffect, useCallback } from 'react';
import { ChevronUp, ChevronDown, ChevronLeft, ChevronRight } from 'lucide-react';

interface Props {
  isDarkMode?: boolean;
}

const translations = {
  'pt-BR': {
    title: 'Jogo da Cobrinha',
    score: 'Pontuação',
    highScore: 'Recorde',
    gameOver: 'Fim de Jogo!',
    newGame: 'Novo Jogo',
    controls: {
      title: 'Controles:',
      move: 'Mover: Setas',
      tip: 'Dica: Colete os pontos e evite colidir com você mesmo!'
    }
  },
  'en': {
    title: 'Snake',
    score: 'Score',
    highScore: 'High Score',
    gameOver: 'Game Over!',
    newGame: 'New Game',
    controls: {
      title: 'Controls:',
      move: 'Move: Arrows',
      tip: 'Tip: Collect dots and avoid colliding with yourself!'
    }
  }
};

interface Position {
  x: number;
  y: number;
}

const GRID_SIZE = 20;
const CELL_SIZE = 20;
const INITIAL_SNAKE = [{ x: 10, y: 10 }];
const INITIAL_DIRECTION = { x: 1, y: 0 };
const GAME_SPEED = 150;

const HIGH_SCORE_KEY = 'snake_high_score';

export function SnakeGame({ isDarkMode = false }: Props) {
  const [snake, setSnake] = useState<Position[]>(INITIAL_SNAKE);
  const [food, setFood] = useState<Position>({ x: 15, y: 10 });
  const [direction, setDirection] = useState(INITIAL_DIRECTION);
  const [score, setScore] = useState(0);
  const [highScore, setHighScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  useEffect(() => {
    // Load high score from localStorage
    const savedHighScore = localStorage.getItem(HIGH_SCORE_KEY);
    if (savedHighScore) {
      setHighScore(parseInt(savedHighScore));
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  const generateFood = useCallback(() => {
    while (true) {
      const newFood = {
        x: Math.floor(Math.random() * GRID_SIZE),
        y: Math.floor(Math.random() * GRID_SIZE)
      };
      
      // Check if food spawns on snake
      if (!snake.some(segment => segment.x === newFood.x && segment.y === newFood.y)) {
        return newFood;
      }
    }
  }, [snake]);

  const resetGame = useCallback(() => {
    setSnake(INITIAL_SNAKE);
    setFood(generateFood());
    setDirection(INITIAL_DIRECTION);
    setScore(0);
    setGameOver(false);
  }, [generateFood]);

  const moveSnake = useCallback(() => {
    if (gameOver) return;

    setSnake(currentSnake => {
      const head = currentSnake[0];
      const newHead = {
        x: (head.x + direction.x + GRID_SIZE) % GRID_SIZE,
        y: (head.y + direction.y + GRID_SIZE) % GRID_SIZE
      };

      // Check collision with self
      if (currentSnake.some(segment => segment.x === newHead.x && segment.y === newHead.y)) {
        setGameOver(true);
        // Update high score if current score is higher
        if (score > highScore) {
          setHighScore(score);
          localStorage.setItem(HIGH_SCORE_KEY, score.toString());
        }
        return currentSnake;
      }

      const newSnake = [newHead, ...currentSnake];

      // Check if snake ate food
      if (newHead.x === food.x && newHead.y === food.y) {
        setScore(s => s + 1);
        setFood(generateFood());
      } else {
        newSnake.pop();
      }

      return newSnake;
    });
  }, [direction, food, gameOver, generateFood, score, highScore]);

  useEffect(() => {
    const gameLoop = setInterval(moveSnake, GAME_SPEED);
    return () => clearInterval(gameLoop);
  }, [moveSnake]);

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowUp':
          if (direction.y !== 1) setDirection({ x: 0, y: -1 });
          break;
        case 'ArrowDown':
          if (direction.y !== -1) setDirection({ x: 0, y: 1 });
          break;
        case 'ArrowLeft':
          if (direction.x !== 1) setDirection({ x: -1, y: 0 });
          break;
        case 'ArrowRight':
          if (direction.x !== -1) setDirection({ x: 1, y: 0 });
          break;
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [direction]);

  const handleDirectionClick = (newDirection: { x: number; y: number }) => {
    if (
      (newDirection.y === 1 && direction.y !== -1) ||
      (newDirection.y === -1 && direction.y !== 1) ||
      (newDirection.x === 1 && direction.x !== -1) ||
      (newDirection.x === -1 && direction.x !== 1)
    ) {
      setDirection(newDirection);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center p-4">
      <div className={`mb-4 text-lg font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        <div className="flex justify-between items-center gap-8">
          <div>{t('score')}: {score}</div>
          <div>{t('highScore')}: {highScore}</div>
        </div>
        {gameOver && <div className="text-center mt-2">{t('gameOver')}</div>}
      </div>
      
      <div 
        className={`relative border-2 ${isDarkMode ? 'border-gray-700' : 'border-gray-300'}`}
        style={{ 
          width: GRID_SIZE * CELL_SIZE, 
          height: GRID_SIZE * CELL_SIZE 
        }}
      >
        {/* Snake */}
        {snake.map((segment, index) => (
          <div
            key={index}
            className={`absolute ${isDarkMode ? 'bg-green-500' : 'bg-green-600'} rounded-sm`}
            style={{
              width: CELL_SIZE - 2,
              height: CELL_SIZE - 2,
              left: segment.x * CELL_SIZE,
              top: segment.y * CELL_SIZE,
              transition: 'all 0.1s'
            }}
          />
        ))}

        {/* Food */}
        <div
          className={`absolute ${isDarkMode ? 'bg-red-500' : 'bg-red-600'} rounded-full`}
          style={{
            width: CELL_SIZE - 2,
            height: CELL_SIZE - 2,
            left: food.x * CELL_SIZE,
            top: food.y * CELL_SIZE
          }}
        />

        {/* Game Over Overlay */}
        {gameOver && (
          <div 
            className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center"
            onClick={resetGame}
          >
            <div className="text-white text-center">
              <p className="text-xl font-bold mb-2">{t('gameOver')}</p>
              <p className="text-sm">Click to restart</p>
            </div>
          </div>
        )}
      </div>

      {/* Mobile Controls */}
      <div className="mt-4 grid grid-cols-3 gap-2">
        <div />
        <button
          onClick={() => handleDirectionClick({ x: 0, y: -1 })}
          className={`p-3 rounded-full ${
            isDarkMode 
              ? 'bg-gray-700 text-white hover:bg-gray-600' 
              : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
          }`}
        >
          <ChevronUp className="h-6 w-6" />
        </button>
        <div />
        <button
          onClick={() => handleDirectionClick({ x: -1, y: 0 })}
          className={`p-3 rounded-full ${
            isDarkMode 
              ? 'bg-gray-700 text-white hover:bg-gray-600' 
              : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
          }`}
        >
          <ChevronLeft className="h-6 w-6" />
        </button>
        <div />
        <button
          onClick={() => handleDirectionClick({ x: 1, y: 0 })}
          className={`p-3 rounded-full ${
            isDarkMode 
              ? 'bg-gray-700 text-white hover:bg-gray-600' 
              : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
          }`}
        >
          <ChevronRight className="h-6 w-6" />
        </button>
        <div />
        <button
          onClick={() => handleDirectionClick({ x: 0, y: 1 })}
          className={`p-3 rounded-full ${
            isDarkMode 
              ? 'bg-gray-700 text-white hover:bg-gray-600' 
              : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
          }`}
        >
          <ChevronDown className="h-6 w-6" />
        </button>
        <div />
      </div>

      <div className={`mt-4 text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
        <p className="font-medium">{t('controls.title')}</p>
        <p>{t('controls.move')}</p>
        <p className="mt-2 italic">{t('controls.tip')}</p>
      </div>
    </div>
  );
}