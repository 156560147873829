import { useState, useEffect, useCallback } from 'react';
import { ChevronLeft, ChevronRight, RotateCw } from 'lucide-react';

interface Props {
  isDarkMode?: boolean;
}

const ROAD_WIDTH = 300;
const ROAD_HEIGHT = 600;
const CAR_WIDTH = 40;
const CAR_HEIGHT = 60;
const ENEMY_COUNT = 3;

interface Car {
  x: number;
  y: number;
  speed: number;
  lane: number;
}

interface PowerUp {
  x: number;
  y: number;
  type: 'speed' | 'shield' | 'points';
}

const translations = {
  'pt-BR': {
    title: 'Road Fighter',
    score: 'Pontuação',
    distance: 'Distância',
    gameOver: 'Fim de Jogo!',
    newGame: 'Novo Jogo',
    highScore: 'Recorde',
    controls: {
      title: 'Controles:',
      move: 'Mover: Setas Esquerda/Direita',
      tip: 'Desvie dos outros carros e colete diamantes para pontuar!'
    },
    powerUps: {
      speed: '⚡ Velocidade',
      shield: '🛡️ Escudo',
      points: '💎 +100 Pontos'
    }
  },
  'en': {
    title: 'Road Fighter',
    score: 'Score',
    distance: 'Distance',
    gameOver: 'Game Over!',
    newGame: 'New Game',
    highScore: 'High Score',
    controls: {
      title: 'Controls:',
      move: 'Move: Left/Right Arrows',
      tip: 'Dodge other cars and collect diamonds to score!'
    },
    powerUps: {
      speed: '⚡ Speed',
      shield: '🛡️ Shield',
      points: '💎 +100 Points'
    }
  }
};

export function RoadFighterGame({ isDarkMode = false }: Props) {
  const [player, setPlayer] = useState<Car>({
    x: ROAD_WIDTH / 2 - CAR_WIDTH / 2,
    y: ROAD_HEIGHT - CAR_HEIGHT - 20,
    speed: 5,
    lane: 2
  });
  const [enemies, setEnemies] = useState<Car[]>([]);
  const [powerUps, setPowerUps] = useState<PowerUp[]>([]);
  const [score, setScore] = useState(0);
  const [distance, setDistance] = useState(0);
  const [highScore, setHighScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [hasShield, setHasShield] = useState(false);
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  const initializeGame = useCallback(() => {
    setPlayer({
      x: ROAD_WIDTH / 2 - CAR_WIDTH / 2,
      y: ROAD_HEIGHT - CAR_HEIGHT - 20,
      speed: 5,
      lane: 2
    });

    // Create initial enemies
    const initialEnemies = Array.from({ length: ENEMY_COUNT }, () => ({
      x: Math.random() * (ROAD_WIDTH - CAR_WIDTH),
      y: -CAR_HEIGHT - Math.random() * ROAD_HEIGHT,
      speed: 2 + Math.random() * 2,
      lane: Math.floor(Math.random() * 4)
    }));

    setEnemies(initialEnemies);
    setPowerUps([]);
    setScore(0);
    setDistance(0);
    setGameOver(false);
    setHasShield(false);
  }, []);

  useEffect(() => {
    initializeGame();
  }, [initializeGame]);

  useEffect(() => {
    if (gameOver) return;

    const gameLoop = setInterval(() => {
      // Move enemies
      setEnemies(prev => {
        return prev.map(enemy => {
          let newY = enemy.y + enemy.speed;
          if (newY > ROAD_HEIGHT) {
            newY = -CAR_HEIGHT;
            enemy.x = Math.random() * (ROAD_WIDTH - CAR_WIDTH);
            enemy.speed = 2 + Math.random() * 2;
            enemy.lane = Math.floor(Math.random() * 4);
          }
          return { ...enemy, y: newY };
        });
      });

      // Move power-ups
      setPowerUps(prev => {
        return prev.map(powerUp => {
          let newY = powerUp.y + 3;
          if (newY > ROAD_HEIGHT) {
            return null;
          }
          return { ...powerUp, y: newY };
        }).filter(Boolean) as PowerUp[];
      });

      // Spawn new power-up
      if (Math.random() < 0.01) {
        const types: ('speed' | 'shield' | 'points')[] = ['speed', 'shield', 'points'];
        const newPowerUp: PowerUp = {
          x: Math.random() * (ROAD_WIDTH - 20),
          y: -20,
          type: types[Math.floor(Math.random() * types.length)]
        };
        setPowerUps(prev => [...prev, newPowerUp]);
      }

      // Update distance only
      setDistance(prev => prev + 1);

      // Check collisions
      enemies.forEach(enemy => {
        if (
          !hasShield &&
          player.x < enemy.x + CAR_WIDTH &&
          player.x + CAR_WIDTH > enemy.x &&
          player.y < enemy.y + CAR_HEIGHT &&
          player.y + CAR_HEIGHT > enemy.y
        ) {
          setGameOver(true);
          if (score > highScore) {
            setHighScore(score);
          }
        }
      });

      // Check power-up collisions
      powerUps.forEach(powerUp => {
        if (
          player.x < powerUp.x + 20 &&
          player.x + CAR_WIDTH > powerUp.x &&
          player.y < powerUp.y + 20 &&
          player.y + CAR_HEIGHT > powerUp.y
        ) {
          switch (powerUp.type) {
            case 'speed':
              setPlayer(prev => ({ ...prev, speed: prev.speed + 1 }));
              break;
            case 'shield':
              setHasShield(true);
              setTimeout(() => setHasShield(false), 5000);
              break;
            case 'points':
              // Only increase score when collecting diamonds
              setScore(prev => prev + 100);
              break;
          }
          setPowerUps(prev => prev.filter(p => p !== powerUp));
        }
      });
    }, 1000 / 60);

    return () => clearInterval(gameLoop);
  }, [gameOver, enemies, player, powerUps, score, highScore, hasShield]);

  const moveCar = (direction: 'left' | 'right') => {
    if (gameOver) return;

    setPlayer(prev => {
      const newX = direction === 'left'
        ? Math.max(0, prev.x - prev.speed)
        : Math.min(ROAD_WIDTH - CAR_WIDTH, prev.x + prev.speed);
      
      return { ...prev, x: newX };
    });
  };

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowLeft':
          moveCar('left');
          break;
        case 'ArrowRight':
          moveCar('right');
          break;
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [gameOver]);

  return (
    <div className="flex flex-col items-center justify-center p-4">
      <div className={`mb-4 text-lg font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        {gameOver ? (
          <>
            <div>{t('gameOver')}</div>
            <div className="text-sm mt-2">
              {t('score')}: {score} | {t('highScore')}: {highScore}
            </div>
          </>
        ) : (
          <div>
            {t('score')}: {score} | {t('distance')}: {Math.floor(distance / 100)}m
          </div>
        )}
      </div>

      <div 
        className={`relative overflow-hidden rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-gray-200'}`}
        style={{ width: ROAD_WIDTH, height: ROAD_HEIGHT }}
      >
        {/* Road stripes */}
        <div className="absolute inset-0">
          <div className="absolute left-1/4 top-0 bottom-0 w-1 bg-white opacity-50" />
          <div className="absolute left-2/4 top-0 bottom-0 w-1 bg-white opacity-50" />
          <div className="absolute left-3/4 top-0 bottom-0 w-1 bg-white opacity-50" />
        </div>

        {/* Player car */}
        <div 
          className={`absolute ${hasShield ? 'animate-pulse' : ''}`}
          style={{
            left: player.x,
            top: player.y,
            width: CAR_WIDTH,
            height: CAR_HEIGHT,
            backgroundColor: hasShield ? '#60a5fa' : '#3b82f6',
            borderRadius: '5px',
            transition: 'left 0.1s'
          }}
        >
          🏎️
        </div>

        {/* Enemy cars */}
        {enemies.map((enemy, index) => (
          <div
            key={index}
            className="absolute"
            style={{
              left: enemy.x,
              top: enemy.y,
              width: CAR_WIDTH,
              height: CAR_HEIGHT,
              backgroundColor: '#ef4444',
              borderRadius: '5px'
            }}
          >
            🚗
          </div>
        ))}

        {/* Power-ups */}
        {powerUps.map((powerUp, index) => (
          <div
            key={index}
            className="absolute animate-bounce"
            style={{
              left: powerUp.x,
              top: powerUp.y,
              width: 20,
              height: 20,
              fontSize: '20px'
            }}
          >
            {powerUp.type === 'speed' ? '⚡' :
             powerUp.type === 'shield' ? '🛡️' :
             powerUp.type === 'points' ? '💎' : ''}
          </div>
        ))}
      </div>

      {/* Mobile Controls */}
      <div className="mt-6 flex justify-center space-x-20">
        <button
          onTouchStart={() => moveCar('left')}
          onMouseDown={() => moveCar('left')}
          className={`p-4 rounded-full ${
            isDarkMode 
              ? 'bg-gray-700 text-white hover:bg-gray-600' 
              : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
          }`}
        >
          <ChevronLeft className="h-6 w-6" />
        </button>

        {gameOver ? (
          <button
            onClick={initializeGame}
            className={`p-4 rounded-full ${
              isDarkMode 
                ? 'bg-blue-600 text-white hover:bg-blue-700' 
                : 'bg-blue-500 text-white hover:bg-blue-600'
            }`}
          >
            <RotateCw className="h-6 w-6" />
          </button>
        ) : (
          <button
            onTouchStart={() => moveCar('right')}
            onMouseDown={() => moveCar('right')}
            className={`p-4 rounded-full ${
              isDarkMode 
                ? 'bg-gray-700 text-white hover:bg-gray-600' 
                : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
            }`}
          >
            <ChevronRight className="h-6 w-6" />
          </button>
        )}
      </div>

      <div className={`mt-6 text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
        <p className="font-medium">{t('controls.title')}</p>
        <p>{t('controls.move')}</p>
        <p className="mt-2 italic">{t('controls.tip')}</p>
        <div className="mt-2">
          <p className="font-medium">{t('powerUps.speed')}</p>
          <p className="font-medium">{t('powerUps.shield')}</p>
          <p className="font-medium">{t('powerUps.points')}</p>
        </div>
      </div>
    </div>
  );
}