import { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents, useMap } from 'react-leaflet';
import { collection, addDoc, getDocs, query, where, serverTimestamp, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import { useTheme } from '../contexts/ThemeContext';
import { MainMenu } from '../components/MainMenu';
import { MapPin, Loader2, Plus, X, Edit, Trash2, Heart, Search } from 'lucide-react';
import toast from 'react-hot-toast';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix for default marker icons in react-leaflet
delete (L.Icon.Default.prototype as any)._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

// Add MapController component to handle map updates
function MapController({ places }: { places: FavoritePlace[] }) {
  const map = useMap();

  useEffect(() => {
    if (places.length > 0) {
      const bounds = L.latLngBounds(places.map(place => [place.latitude, place.longitude]));
      map.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [places, map]);

  return null;
}

const translations = {
  'pt-BR': {
    title: 'Lugares Favoritos',
    searchPlaceholder: 'Buscar lugares por título...',
    loading: 'Carregando...',
    addPlace: 'Adicionar Lugar',
    editPlace: 'Editar Lugar',
    updatePlace: 'Atualizar Lugar',
    titlePlaceholder: 'Título (máximo 30 caracteres)',
    descriptionPlaceholder: 'Descrição (máximo 120 caracteres)',
    characters: 'caracteres',
    clickToAdd: 'Clique em qualquer lugar no mapa para adicionar um novo lugar favorito',
    yourPlaces: 'Seus Lugares',
    allPlaces: 'Todos os Lugares Públicos',
    addedBy: 'Adicionado por',
    errors: {
      locationAccess: 'Não foi possível acessar sua localização',
      enterTitle: 'Por favor, insira um título',
      noLinks: 'Links não são permitidos no título',
      noLinksDesc: 'Links não são permitidos na descrição',
      addFailed: 'Falha ao adicionar lugar',
      updateFailed: 'Falha ao atualizar lugar',
      deleteFailed: 'Falha ao excluir lugar',
      noPermission: 'Você não tem permissão para excluir este lugar'
    },
    success: {
      placeAdded: 'Lugar adicionado com sucesso!',
      placeUpdated: 'Lugar atualizado com sucesso!',
      placeDeleted: 'Lugar excluído com sucesso!',
      placeDeletedAdmin: 'Lugar excluído pelo admin',
      likeRemoved: 'Like removido',
      placeLiked: 'Lugar curtido!'
    },
    confirmDelete: 'Tem certeza que deseja excluir este lugar?',
    adminDelete: 'Admin: Excluir lugar',
    deletePlace: 'Excluir lugar'
  },
  'en': {
    title: 'Favorite Places',
    searchPlaceholder: 'Search places by title...',
    loading: 'Loading...',
    addPlace: 'Add Place',
    editPlace: 'Edit Place',
    updatePlace: 'Update Place',
    titlePlaceholder: 'Title (max 30 characters)',
    descriptionPlaceholder: 'Description (max 120 characters)',
    characters: 'characters',
    clickToAdd: 'Click anywhere on the map to add a new favorite place',
    yourPlaces: 'Your Places',
    allPlaces: 'All Public Places',
    addedBy: 'Added by',
    errors: {
      locationAccess: 'Could not access your location',
      enterTitle: 'Please enter a title',
      noLinks: 'Links are not allowed in the title',
      noLinksDesc: 'Links are not allowed in the description',
      addFailed: 'Failed to add place',
      updateFailed: 'Failed to update place',
      deleteFailed: 'Failed to delete place',
      noPermission: 'You do not have permission to delete this place'
    },
    success: {
      placeAdded: 'Place added successfully!',
      placeUpdated: 'Place updated successfully!',
      placeDeleted: 'Place deleted successfully!',
      placeDeletedAdmin: 'Place deleted by admin',
      likeRemoved: 'Like removed',
      placeLiked: 'Place liked!'
    },
    confirmDelete: 'Are you sure you want to delete this place?',
    adminDelete: 'Admin: Delete place',
    deletePlace: 'Delete place'
  }
};

interface FavoritePlace {
  id: string;
  title: string;
  description: string;
  latitude: number;
  longitude: number;
  userId: string;
  userName: string;
  timestamp: Date;
  likes: string[];
}

function AddMarker({ onLocationSelect }: { onLocationSelect: (latlng: L.LatLng) => void }) {
  useMapEvents({
    click(e) {
      onLocationSelect(e.latlng);
    },
  });
  return null;
}

export function FavoritePlaces() {
  const [allPlaces, setAllPlaces] = useState<FavoritePlace[]>([]);
  const [userPlaces, setUserPlaces] = useState<FavoritePlace[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState<L.LatLng | null>(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [userLocation, setUserLocation] = useState<[number, number]>([0, 0]);
  const [editingPlace, setEditingPlace] = useState<FavoritePlace | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [language, setLanguage] = useState('en');
  const [showUserPlaces, setShowUserPlaces] = useState(false);
  const [filteredMapPlaces, setFilteredMapPlaces] = useState<FavoritePlace[]>([]);
  const { user } = useAuth();
  const { isDarkMode } = useTheme();

  // Check if user is admin
  const isAdmin = user?.email?.toLowerCase() === 'afonsoalcantara@outlook.com';

  useEffect(() => {
    try {
      const userLang = navigator.language;
      setLanguage(userLang.startsWith('pt') ? 'pt-BR' : 'en');
    } catch (error) {
      console.error('Language detection error:', error);
      setLanguage('en'); // Fallback to English
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  useEffect(() => {
    // Update filtered places whenever search query changes
    if (searchQuery) {
      const filtered = allPlaces.filter(place => 
        place.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        place.description.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredMapPlaces(filtered);
    } else {
      setFilteredMapPlaces(allPlaces);
    }
  }, [searchQuery, allPlaces]);

  useEffect(() => {
    if (!user) return;

    const fetchPlaces = async () => {
      try {
        setLoading(true);
        const placesRef = collection(db, 'favoritePlaces');
        
        // Get all places
        const allSnapshot = await getDocs(placesRef);
        const allPlacesData = allSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate() || new Date(),
          likes: doc.data().likes || []
        })) as FavoritePlace[];
        setAllPlaces(allPlacesData);
        setFilteredMapPlaces(allPlacesData);

        // Get user's places
        const userQuery = query(placesRef, where('userId', '==', user.uid));
        const userSnapshot = await getDocs(userQuery);
        const userPlacesData = userSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate() || new Date(),
          likes: doc.data().likes || []
        })) as FavoritePlace[];
        setUserPlaces(userPlacesData);
      } catch (error) {
        console.error('Error loading places:', error);
        toast.error(t('errors.loadFailed'));
      } finally {
        setLoading(false);
      }
    };

    fetchPlaces();
  }, [user, language]);

  useEffect(() => {
    // Get user's location
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setUserLocation([position.coords.latitude, position.coords.longitude]);
      },
      () => {
        setUserLocation([-23.5505, -46.6333]); // Default to São Paulo
        toast.error(t('errors.locationAccess'));
      }
    );
  }, [language]);

  const handleAddPlace = async () => {
    if (!user || !selectedLocation) return;
    if (!title.trim()) {
      toast.error(t('errors.enterTitle'));
      return;
    }

    // Check for links in title and description
    const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)|([a-zA-Z0-9-]+\.(com|net|org|br|io|dev|app|me|co|uk|us|ca|eu))/gi;
    if (urlRegex.test(title)) {
      toast.error(t('errors.noLinks'));
      return;
    }

    if (urlRegex.test(description)) {
      toast.error(t('errors.noLinksDesc'));
      return;
    }

    try {
      setSubmitting(true);
      const placeData = {
        title: title.trim(),
        description: description.trim(),
        latitude: selectedLocation.lat,
        longitude: selectedLocation.lng,
        userId: user.uid,
        userName: user.displayName || '',
        timestamp: serverTimestamp(),
        likes: []
      };

      const docRef = await addDoc(collection(db, 'favoritePlaces'), placeData);
      
      const newPlace = {
        id: docRef.id,
        ...placeData,
        timestamp: new Date(),
        likes: []
      } as FavoritePlace;

      setAllPlaces(prev => [...prev, newPlace]);
      setUserPlaces(prev => [...prev, newPlace]);
      setFilteredMapPlaces(prev => [...prev, newPlace]);
      
      setTitle('');
      setDescription('');
      setSelectedLocation(null);
      setEditingPlace(null);
      toast.success(t('success.placeAdded'));
    } catch (error) {
      console.error('Error adding place:', error);
      toast.error(t('errors.addFailed'));
    } finally {
      setSubmitting(false);
    }
  };

  const handleEditPlace = async () => {
    if (!user || !editingPlace) return;
    if (!title.trim()) {
      toast.error(t('errors.enterTitle'));
      return;
    }

    // Check for links in title and description
    const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)|([a-zA-Z0-9-]+\.(com|net|org|br|io|dev|app|me|co|uk|us|ca|eu))/gi;
    if (urlRegex.test(title)) {
      toast.error(t('errors.noLinks'));
      return;
    }

    if (urlRegex.test(description)) {
      toast.error(t('errors.noLinksDesc'));
      return;
    }

    try {
      setSubmitting(true);
      const placeRef = doc(db, 'favoritePlaces', editingPlace.id);
      
      const updatedData = {
        title: title.trim(),
        description: description.trim()
      };

      await updateDoc(placeRef, updatedData);

      const updatedPlace = {
        ...editingPlace,
        ...updatedData
      };

      setAllPlaces(prev => prev.map(place => 
        place.id === editingPlace.id ? updatedPlace : place
      ));
      setUserPlaces(prev => prev.map(place => 
        place.id === editingPlace.id ? updatedPlace : place
      ));
      setFilteredMapPlaces(prev => prev.map(place => 
        place.id === editingPlace.id ? updatedPlace : place
      ));

      setTitle('');
      setDescription('');
      setEditingPlace(null);
      toast.success(t('success.placeUpdated'));
    } catch (error) {
      console.error('Error updating place:', error);
      toast.error(t('errors.updateFailed'));
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeletePlace = async (placeId: string, placeUserId: string) => {
    if (!user) return;

    // Check if user is owner or admin
    const isOwner = user.uid === placeUserId;
    if (!isOwner && !isAdmin) {
      toast.error(t('errors.noPermission'));
      return;
    }

    const confirmDelete = window.confirm(t('confirmDelete'));
    if (!confirmDelete) return;

    try {
      setSubmitting(true);
      await deleteDoc(doc(db, 'favoritePlaces', placeId));

      setAllPlaces(prev => prev.filter(place => place.id !== placeId));
      setUserPlaces(prev => prev.filter(place => place.id !== placeId));
      setFilteredMapPlaces(prev => prev.filter(place => place.id !== placeId));
      
      toast.success(isAdmin && !isOwner ? t('success.placeDeletedAdmin') : t('success.placeDeleted'));
    } catch (error) {
      console.error('Error deleting place:', error);
      toast.error(t('errors.deleteFailed'));
    } finally {
      setSubmitting(false);
    }
  };

  const handleLike = async (place: FavoritePlace) => {
    if (!user) return;

    try {
      const placeRef = doc(db, 'favoritePlaces', place.id);
      const isLiked = place.likes.includes(user.uid);

      await updateDoc(placeRef, {
        likes: isLiked ? place.likes.filter(id => id !== user.uid) : [...place.likes, user.uid]
      });

      const updatedPlace = {
        ...place,
        likes: isLiked 
          ? place.likes.filter(id => id !== user.uid)
          : [...place.likes, user.uid]
      };

      setAllPlaces(prev => prev.map(p => 
        p.id === place.id ? updatedPlace : p
      ));
      setUserPlaces(prev => prev.map(p => 
        p.id === place.id ? updatedPlace : p
      ));
      setFilteredMapPlaces(prev => prev.map(p => 
        p.id === place.id ? updatedPlace : p
      ));

      toast.success(isLiked ? t('success.likeRemoved') : t('success.placeLiked'));
    } catch (error) {
      console.error('Error updating like:', error);
      toast.error('Failed to update like');
    }
  };

  const startEdit = (place: FavoritePlace) => {
    setEditingPlace(place);
    setTitle(place.title);
    setDescription(place.description);
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <header className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b`}>
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <MapPin className="h-6 w-6 text-blue-500" />
            <h1 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-black'}`}>
              {t('title')}
            </h1>
          </div>
          <button
            onClick={() => setShowUserPlaces(true)}
            className={`px-4 py-2 rounded-full ${
              isDarkMode 
                ? 'bg-gray-700 text-white hover:bg-gray-600' 
                : 'bg-black text-white hover:bg-gray-800'
            }`}
          >
            {t('yourPlaces')}
          </button>
        </div>
        <MainMenu />
      </header>

      <main className={`max-w-2xl mx-auto ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-x min-h-screen`}>
        {loading ? (
          <div className="flex justify-center items-center p-8">
            <Loader2 className={`h-8 w-8 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
          </div>
        ) : (
          <div className="p-4">
            <div className="relative mb-4">
              <Search className={`absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-400'}`} />
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder={t('searchPlaceholder')}
                className={`w-full pl-10 pr-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  isDarkMode 
                    ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                    : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                }`}
              />
            </div>

            <div className="h-[60vh] rounded-lg overflow-hidden mb-4">
              <MapContainer
                center={userLocation}
                zoom={13}
                className="h-full w-full"
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                
                <MapController places={filteredMapPlaces} />
                
                {filteredMapPlaces.map((place) => (
                  <Marker 
                    key={place.id}
                    position={[place.latitude, place.longitude]}
                  >
                    <Popup>
                      <div className="p-2 min-w-[200px]">
                        <h3 className="font-bold text-lg">{place.title}</h3>
                        <p className="text-gray-600 text-sm mt-1">{place.description}</p>
                        
                        <div className="flex items-center justify-between mt-2">
                          <button
                            onClick={() => handleLike(place)}
                            className={`flex items-center space-x-1 ${
                              place.likes.includes(user?.uid || '') 
                                ? 'text-red-500' 
                                : 'text-gray-500 hover:text-red-500'
                            } transition-colors`}
                          >
                            <Heart className={`h-4 w-4 ${
                              place.likes.includes(user?.uid || '') ? 'fill-current' : ''
                            }`} />
                            <span className="text-xs">{place.likes.length}</span>
                          </button>
                        </div>

                        <p className="text-xs text-gray-500 mt-2">
                          {t('addedBy')} {place.userName}
                        </p>
                      </div>
                    </Popup>
                  </Marker>
                ))}

                {selectedLocation && (
                  <Marker position={selectedLocation}>
                    <Popup>New location</Popup>
                  </Marker>
                )}

                <AddMarker onLocationSelect={setSelectedLocation} />
              </MapContainer>
            </div>

            {(selectedLocation || editingPlace) && (
              <div className={`p-4 rounded-lg mb-4 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
                <div className="flex justify-between items-start mb-4">
                  <h2 className={`text-lg font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                    {editingPlace ? t('editPlace') : t('addPlace')}
                  </h2>
                  <button
                    onClick={() => {
                      setSelectedLocation(null);
                      setEditingPlace(null);
                      setTitle('');
                      setDescription('');
                    }}
                    className={`p-1 ${
                      isDarkMode ? 'hover:bg-gray-600' : 'hover:bg-gray-200'
                    } rounded-full transition-colors`}
                  >
                    <X className={`h-5 w-5 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`} />
                  </button>
                </div>
                
                <div className="space-y-4">
                  <div>
                    <input
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      placeholder={t('titlePlaceholder')}
                      maxLength={30}
                      className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                        isDarkMode 
                          ? 'bg-gray-800 border-gray-600 text-white placeholder-gray-400' 
                          : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                      }`}
                    />
                    <p className={`text-xs mt-1 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                      {title.length}/30 {t('characters')}
                    </p>
                  </div>

                  <div>
                    <textarea
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      placeholder={t('descriptionPlaceholder')}
                      maxLength={120}
                      rows={3}
                      className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none ${
                        isDarkMode 
                          ? 'bg-gray-800 border-gray-600 text-white placeholder-gray-400' 
                          : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                      }`}
                    />
                    <p className={`text-xs mt-1 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                      {description.length}/120 {t('characters')}
                    </p>
                  </div>

                  <button
                    onClick={editingPlace ? handleEditPlace : handleAddPlace}
                    disabled={submitting || !title.trim()}
                    className={`w-full flex items-center justify-center space-x-2 px-4 py-2 ${
                      isDarkMode 
                        ? 'bg-blue-600 hover:bg-blue-700' 
                        : 'bg-blue-500 hover:bg-blue-600'
                    } text-white rounded-lg disabled:opacity-50 transition-colors`}
                  >
                    {submitting ? (
                      <Loader2 className="h-5 w-5 animate-spin" />
                    ) : (
                      <>
                        {editingPlace ? (
                          <>
                            <Edit className="h-5 w-5" />
                            <span>{t('updatePlace')}</span>
                          </>
                        ) : (
                          <>
                            <Plus className="h-5 w-5" />
                            <span>{t('addPlace')}</span>
                          </>
                        )}
                      </>
                    )}
                  </button>
                </div>
              </div>
            )}

            {!selectedLocation && !editingPlace && (
              <div className={`text-center p-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                {t('clickToAdd')}
              </div>
            )}

            {showUserPlaces && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
                <div className={`w-full max-w-2xl rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-xl max-h-[90vh] overflow-y-auto`}>
                  <div className={`p-4 border-b flex justify-between items-center ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
                    <h2 className={`text-lg font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                      {t('yourPlaces')}
                    </h2>
                    <button
                      onClick={() => setShowUserPlaces(false)}
                      className={`p-2 ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'} rounded-full`}
                    >
                      <X className={`h-5 w-5 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`} />
                    </button>
                  </div>
                  
                  <div className="p-4">
                    {userPlaces.length > 0 ? (
                      <div className="space-y-4">
                        {userPlaces.map((place) => (
                          <div
                            key={place.id}
                            className={`p-4 rounded-lg ${
                              isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
                            }`}
                          >
                            <div className="flex justify-between items-start">
                              <div>
                                <h3 className={`font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                                  {place.title}
                                </h3>
                                <p className={`text-sm mt-1 ${
                                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                                }`}>
                                  {place.description}
                                </p>
                                <div className="flex items-center space-x-4 mt-2">
                                  <button
                                    onClick={() => handleLike(place)}
                                    className={`flex items-center space-x-1 ${
                                      place.likes.includes(user?.uid || '') 
                                        ? 'text-red-500' 
                                        : 'text-gray-500 hover:text-red-500'
                                    } transition-colors`}
                                  >
                                    <Heart className={`h-4 w-4 ${
                                      place.likes.includes(user?.uid || '') ? 'fill-current' : ''
                                    }`} />
                                    <span className="text-xs">{place.likes.length}</span>
                                  </button>
                                </div>
                                <p className={`text-xs mt-2 ${
                                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                                }`}>
                                  {place.timestamp.toLocaleDateString()}
                                </p>
                              </div>
                              <div className="flex space-x-2">
                                <button
                                  onClick={() => startEdit(place)}
                                  className={`p-2 rounded-full ${
                                    isDarkMode 
                                      ? 'hover:bg-gray-600 text-blue-400' 
                                      : 'hover:bg-gray-200 text-blue-500'
                                  }`}
                                >
                                  <Edit className="h-5 w-5" />
                                </button>
                                <button
                                  onClick={() => handleDeletePlace(place.id, place.userId)}
                                  className={`p-2 rounded-full ${
                                    isDarkMode 
                                      ? 'hover:bg-gray-600 text-red-400' 
                                      : 'hover:bg-gray-200 text-red-500'
                                  }`}
                                  title={isAdmin && place.userId !== user?.uid ? t('adminDelete') : t('deletePlace')}
                                >
                                  <Trash2 className="h-5 w-5" />
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="text-center py-8">
                        <MapPin className={`h-12 w-12 mx-auto mb-4 ${isDarkMode ? 'text-gray-600' : 'text-gray-400'}`} />
                        <p className={isDarkMode ? 'text-gray-400' : 'text-gray-500'}>
                          {t('noPlaces')}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </main>
    </div>
  );
}