import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { collection, doc, getDoc, getDocs, addDoc, query, orderBy, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import { useTheme } from '../contexts/ThemeContext';
import { LogOut, Loader2, ArrowLeft, Send, MessageSquare, Trash2 } from 'lucide-react';
import { auth } from '../lib/firebase';
import { formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import ReactMarkdown from 'react-markdown';
import { deleteAnyTopic, deleteAnyResponse } from '../utils/admin/communities';
import toast from 'react-hot-toast';

const translations = {
  'pt-BR': {
    back: 'Voltar',
    loading: 'Carregando...',
    errors: {
      topicNotFound: 'Tópico não encontrado',
      failedToLoad: 'Falha ao carregar tópico',
      failedToAddResponse: 'Falha ao adicionar resposta',
      failedToDelete: 'Falha ao excluir',
      noLinks: 'Links não são permitidos em respostas',
      responseTooLong: 'Resposta deve ter no máximo 120 caracteres'
    },
    responses: {
      write: 'Escreva uma resposta...',
      noResponses: 'Nenhuma resposta ainda',
      beFirst: 'Seja o primeiro a responder!',
      count: 'respostas',
      posting: 'Postando...',
      deleteConfirm: 'Tem certeza que deseja excluir esta resposta?',
      deleteSuccess: 'Resposta excluída com sucesso',
      addSuccess: 'Resposta adicionada com sucesso',
      adminDelete: 'Admin: Excluir resposta'
    },
    topic: {
      deleteConfirm: 'Tem certeza que deseja excluir este tópico? Esta ação não pode ser desfeita.',
      deleteSuccess: 'Tópico excluído com sucesso',
      adminDelete: 'Admin: Excluir tópico'
    },
    buttons: {
      backToCommunity: 'Voltar para a Comunidade'
    }
  },
  'en': {
    back: 'Back',
    loading: 'Loading...',
    errors: {
      topicNotFound: 'Topic not found',
      failedToLoad: 'Failed to load topic',
      failedToAddResponse: 'Failed to add response',
      failedToDelete: 'Failed to delete',
      noLinks: 'Links are not allowed in responses',
      responseTooLong: 'Response must be 120 characters or less'
    },
    responses: {
      write: 'Write a response...',
      noResponses: 'No responses yet',
      beFirst: 'Be the first to respond!',
      count: 'responses',
      posting: 'Posting...',
      deleteConfirm: 'Are you sure you want to delete this response?',
      deleteSuccess: 'Response deleted successfully',
      addSuccess: 'Response added successfully',
      adminDelete: 'Admin: Delete response'
    },
    topic: {
      deleteConfirm: 'Are you sure you want to delete this topic? This action cannot be undone.',
      deleteSuccess: 'Topic deleted successfully',
      adminDelete: 'Admin: Delete topic'
    },
    buttons: {
      backToCommunity: 'Back to Community'
    }
  }
};

interface Topic {
  id: string;
  title: string;
  content: string;
  authorId: string;
  authorName: string;
  authorUsername: string;
  authorPhotoURL: string;
  timestamp: Date;
  responseCount: number;
}

interface Response {
  id: string;
  content: string;
  authorId: string;
  authorName: string;
  authorUsername: string;
  authorPhotoURL: string;
  timestamp: Date;
}

export function TopicDetails() {
  const { communityId, topicId } = useParams<{ communityId: string; topicId: string }>();
  const [topic, setTopic] = useState<Topic | null>(null);
  const [responses, setResponses] = useState<Response[]>([]);
  const [newResponse, setNewResponse] = useState('');
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { user } = useAuth();
  const { isDarkMode } = useTheme();
  const [language, setLanguage] = useState('en');

  const isAdmin = user?.email?.toLowerCase() === 'afonsoalcantara@outlook.com';

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  useEffect(() => {
    const fetchTopicAndResponses = async () => {
      if (!communityId || !topicId) return;

      try {
        setLoading(true);
        const topicDoc = await getDoc(doc(db, 'communities', communityId, 'topics', topicId));
        
        if (!topicDoc.exists()) {
          toast.error(t('errors.topicNotFound'));
          return;
        }

        const topicData = {
          id: topicDoc.id,
          ...topicDoc.data(),
          timestamp: topicDoc.data().timestamp?.toDate(),
          responseCount: topicDoc.data().responseCount || 0
        } as Topic;

        setTopic(topicData);

        // Fetch responses
        const responsesRef = collection(db, 'communities', communityId, 'topics', topicId, 'responses');
        const q = query(responsesRef, orderBy('timestamp', 'asc'));
        const responsesSnapshot = await getDocs(q);

        const responsesData = responsesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate()
        })) as Response[];

        setResponses(responsesData);
      } catch (error) {
        console.error('Error fetching topic:', error);
        toast.error(t('errors.failedToLoad'));
      } finally {
        setLoading(false);
      }
    };

    fetchTopicAndResponses();
  }, [communityId, topicId, language]);

  const validateResponse = (text: string): boolean => {
    // Check for URLs in content
    const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)|([a-zA-Z0-9-]+\.(com|net|org|br|io|dev|app|me|co|uk|us|ca|eu))/gi;
    return !urlRegex.test(text);
  };

  const handleSubmitResponse = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user || !communityId || !topicId || !newResponse.trim() || submitting) return;

    const responseContent = newResponse.trim();

    // Check character limit
    if (responseContent.length > 120) {
      toast.error(t('errors.responseTooLong'));
      return;
    }

    // Check for links
    if (!validateResponse(responseContent)) {
      toast.error(t('errors.noLinks'));
      return;
    }

    setSubmitting(true);

    try {
      const topicRef = doc(db, 'communities', communityId, 'topics', topicId);
      const responseData = {
        content: responseContent,
        authorId: user.uid,
        authorName: user.displayName || '',
        authorUsername: user.displayName?.replace('@', '') || '',
        authorPhotoURL: user.photoURL,
        timestamp: serverTimestamp()
      };

      // Add response
      const responseRef = await addDoc(
        collection(db, 'communities', communityId, 'topics', topicId, 'responses'),
        responseData
      );

      // Update topic's lastResponseAt and responseCount
      await updateDoc(topicRef, {
        lastResponseAt: serverTimestamp(),
        responseCount: (topic?.responseCount || 0) + 1
      });

      // Update local state
      setResponses(prev => [...prev, {
        id: responseRef.id,
        ...responseData,
        timestamp: new Date()
      } as Response]);

      setTopic(prev => prev ? {
        ...prev,
        responseCount: (prev.responseCount || 0) + 1
      } : null);

      setNewResponse('');
      toast.success(t('responses.addSuccess'));
    } catch (error) {
      console.error('Error adding response:', error);
      toast.error(t('errors.failedToAddResponse'));
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteTopic = async () => {
    if (!isAdmin || !communityId || !topicId || isDeleting) return;

    const confirmDelete = window.confirm(t('topic.deleteConfirm'));
    if (!confirmDelete) return;

    try {
      setIsDeleting(true);
      await deleteAnyTopic(communityId, topicId, user?.email);
      window.location.href = `/communities/${communityId}`;
    } catch (error: any) {
      console.error('Error deleting topic:', error);
      toast.error(error.message || t('errors.failedToDelete'));
    } finally {
      setIsDeleting(false);
    }
  };

  const handleDeleteResponse = async (responseId: string) => {
    if (!isAdmin || !communityId || !topicId || isDeleting) return;

    const confirmDelete = window.confirm(t('responses.deleteConfirm'));
    if (!confirmDelete) return;

    try {
      setIsDeleting(true);
      await deleteAnyResponse(communityId, topicId, responseId, user?.email);
      setResponses(prev => prev.filter(response => response.id !== responseId));
      
      // Update topic response count
      if (topic) {
        setTopic(prev => prev ? {
          ...prev,
          responseCount: Math.max(0, prev.responseCount - 1)
        } : null);
      }
      
      toast.success(t('responses.deleteSuccess'));
    } catch (error: any) {
      console.error('Error deleting response:', error);
      toast.error(error.message || t('errors.failedToDelete'));
    } finally {
      setIsDeleting(false);
    }
  };

  if (loading) {
    return (
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'} flex items-center justify-center`}>
        <Loader2 className={`h-8 w-8 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
      </div>
    );
  }

  if (!topic) {
    return (
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'} flex flex-col items-center justify-center p-4`}>
        <h2 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
          {t('errors.topicNotFound')}
        </h2>
        <Link
          to={`/communities/${communityId}`}
          className={`flex items-center space-x-2 ${
            isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-500 hover:text-blue-600'
          }`}
        >
          <ArrowLeft className="h-5 w-5" />
          <span>{t('buttons.backToCommunity')}</span>
        </Link>
      </div>
    );
  }

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <header className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b`}>
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <Link
            to={`/communities/${communityId}`}
            className="flex items-center space-x-2"
          >
            <ArrowLeft className={`h-5 w-5 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`} />
            <span className={`font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>{t('back')}</span>
          </Link>
          <div className="flex items-center space-x-2">
            {isAdmin && (
              <button
                onClick={handleDeleteTopic}
                disabled={isDeleting}
                className={`p-2 text-red-500 ${
                  isDarkMode ? 'hover:bg-red-900/30' : 'hover:bg-red-50'
                } rounded-full transition-colors disabled:opacity-50`}
                title={t('topic.adminDelete')}
              >
                {isDeleting ? (
                  <Loader2 className="h-5 w-5 animate-spin" />
                ) : (
                  <Trash2 className="h-5 w-5" />
                )}
              </button>
            )}
            <button
              onClick={() => auth.signOut()}
              className={`p-2 ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'} rounded-full`}
            >
              <LogOut className={`h-5 w-5 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`} />
            </button>
          </div>
        </div>
      </header>

      <main className={`max-w-2xl mx-auto ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-x min-h-screen`}>
        <div className={`p-4 border-b ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
          <div className="flex items-start space-x-3 mb-4">
            <Link to={`/profile/${topic.authorUsername}`}>
              <img
                src={topic.authorPhotoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${topic.authorId}`}
                alt={topic.authorName}
                className="h-10 w-10 rounded-full"
              />
            </Link>
            <div>
              <Link 
                to={`/profile/${topic.authorUsername}`}
                className={`font-medium hover:underline ${isDarkMode ? 'text-white' : 'text-gray-900'}`}
              >
                {topic.authorName}
              </Link>
              <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                {formatDistanceToNow(topic.timestamp, { 
                  addSuffix: true,
                  locale: language === 'pt-BR' ? ptBR : undefined
                })}
              </p>
            </div>
          </div>

          <h1 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            {topic.title}
          </h1>
          <div className={`prose max-w-none ${isDarkMode ? 'prose-invert' : ''}`}>
            <ReactMarkdown>{topic.content}</ReactMarkdown>
          </div>

          <div className={`mt-4 flex items-center text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
            <MessageSquare className="h-4 w-4 mr-1" />
            <span>{topic.responseCount} {t('responses.count')}</span>
          </div>
        </div>

        <div className={`divide-y ${isDarkMode ? 'divide-gray-700' : 'divide-gray-200'}`}>
          {responses.map((response) => (
            <div key={response.id} className={`p-4 ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-50'}`}>
              <div className="flex items-start space-x-3">
                <Link to={`/profile/${response.authorUsername}`}>
                  <img
                    src={response.authorPhotoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${response.authorId}`}
                    alt={response.authorName}
                    className="h-8 w-8 rounded-full"
                  />
                </Link>
                <div className="flex-1 min-w-0">
                  <div className="flex items-baseline justify-between">
                    <div className="flex items-center space-x-2">
                      <Link 
                        to={`/profile/${response.authorUsername}`}
                        className={`font-medium hover:underline ${isDarkMode ? 'text-white' : 'text-gray-900'}`}
                      >
                        {response.authorName}
                      </Link>
                      <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                        {formatDistanceToNow(response.timestamp, { 
                          addSuffix: true,
                          locale: language === 'pt-BR' ? ptBR : undefined
                        })}
                      </span>
                    </div>
                    {isAdmin && (
                      <button
                        onClick={() => handleDeleteResponse(response.id)}
                        disabled={isDeleting}
                        className={`p-2 text-red-500 ${
                          isDarkMode ? 'hover:bg-red-900/30' : 'hover:bg-red-50'
                        } rounded-full transition-colors disabled:opacity-50`}
                        title={t('responses.adminDelete')}
                      >
                        {isDeleting ? (
                          <Loader2 className="h-4 w-4 animate-spin" />
                        ) : (
                          <Trash2 className="h-4 w-4" />
                        )}
                      </button>
                    )}
                  </div>
                  <p className={`mt-1 whitespace-pre-wrap break-words ${isDarkMode ? 'text-gray-300' : 'text-gray-900'}`}>
                    {response.content}
                  </p>
                </div>
              </div>
            </div>
          ))}

          {responses.length === 0 && (
            <div className={`p-8 text-center ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
              <MessageSquare className={`h-12 w-12 mx-auto mb-3 ${isDarkMode ? 'text-gray-500' : 'text-gray-400'}`} />
              <p>{t('responses.noResponses')}</p>
              <p className="text-sm mt-1">{t('responses.beFirst')}</p>
            </div>
          )}
        </div>

        {user && (
          <form onSubmit={handleSubmitResponse} className={`sticky bottom-0 p-4 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} border-t ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
            <div className="flex items-center space-x-2">
              <input
                type="text"
                value={newResponse}
                onChange={(e) => setNewResponse(e.target.value)}
                placeholder={t('responses.write')}
                className={`flex-1 px-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  isDarkMode 
                    ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                    : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                }`}
                maxLength={120}
              />
              <button
                type="submit"
                disabled={!newResponse.trim() || submitting}
                className={`p-2 ${
                  isDarkMode 
                    ? 'bg-blue-600 text-white hover:bg-blue-700' 
                    : 'bg-blue-500 text-white hover:bg-blue-600'
                } rounded-full disabled:opacity-50`}
              >
                {submitting ? (
                  <Loader2 className="h-5 w-5 animate-spin" />
                ) : (
                  <Send className="h-5 w-5" />
                )}
              </button>
            </div>
          </form>
        )}
      </main>
    </div>
  );
}