import { collection, query, where, orderBy, limit, startAfter, getDocs } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { hasBlockedOrBeenBlockedBy } from '../blocking/users';
import type { Story, StoriesResponse } from './types';

const STORIES_PER_PAGE = 24;

export async function fetchStories(currentUserId?: string, lastDoc?: any): Promise<StoriesResponse> {
  try {
    const twentyFourHoursAgo = new Date();
    twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);

    const storiesRef = collection(db, 'stories');
    let q = query(
      storiesRef,
      where('timestamp', '>', twentyFourHoursAgo),
      orderBy('timestamp', 'desc'),
      limit(STORIES_PER_PAGE)
    );

    if (lastDoc) {
      q = query(
        storiesRef,
        where('timestamp', '>', twentyFourHoursAgo),
        orderBy('timestamp', 'desc'),
        startAfter(lastDoc),
        limit(STORIES_PER_PAGE)
      );
    }

    const snapshot = await getDocs(q);
    const stories = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      timestamp: doc.data().timestamp?.toDate() || new Date()
    })) as Story[];

    // Filter out stories from blocked users if currentUserId is provided
    const filteredStories = currentUserId 
      ? stories.filter(story => !hasBlockedOrBeenBlockedBy(currentUserId, story.authorId))
      : stories;

    return {
      stories: filteredStories,
      lastDoc: snapshot.docs[snapshot.docs.length - 1] || null,
      hasMore: filteredStories.length === STORIES_PER_PAGE
    };
  } catch (error) {
    console.error('Error fetching stories:', error);
    throw error;
  }
}