import { useState, useEffect } from 'react';
import { Loader2, Copy, RefreshCw } from 'lucide-react';
import { generateInviteToken, getUserTokens } from '../utils/tokens';
import { useAuth } from './AuthProvider';
import toast from 'react-hot-toast';

interface TokenManagerProps {
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    title: 'Tokens de Convite',
    description: 'Você pode gerar até 10 tokens de convite para convidar novos usuários.',
    availableTokens: 'Tokens Disponíveis',
    noTokens: 'Nenhum token disponível',
    generate: 'Gerar Novo Token',
    generating: 'Gerando...',
    copy: 'Copiar',
    copied: 'Copiado!',
    errors: {
      generateFailed: 'Falha ao gerar token',
      maxTokens: 'Limite máximo de tokens atingido'
    }
  },
  'en': {
    title: 'Invite Tokens',
    description: 'You can generate up to 10 invite tokens to invite new users.',
    availableTokens: 'Available Tokens',
    noTokens: 'No tokens available',
    generate: 'Generate New Token',
    generating: 'Generating...',
    copy: 'Copy',
    copied: 'Copied!',
    errors: {
      generateFailed: 'Failed to generate token',
      maxTokens: 'Maximum number of tokens reached'
    }
  }
};

export function TokenManager({ isDarkMode = false, language = 'en' }: TokenManagerProps) {
  const [tokens, setTokens] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [generating, setGenerating] = useState(false);
  const { user } = useAuth();

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  useEffect(() => {
    if (!user) return;

    const loadTokens = async () => {
      try {
        setLoading(true);
        const userTokens = await getUserTokens(user.uid);
        setTokens(userTokens);
      } catch (error) {
        console.error('Error loading tokens:', error);
      } finally {
        setLoading(false);
      }
    };

    loadTokens();
  }, [user]);

  const handleGenerateToken = async () => {
    if (!user || generating) return;

    if (tokens.length >= 10) {
      toast.error(t('errors.maxTokens'));
      return;
    }

    try {
      setGenerating(true);
      const newToken = await generateInviteToken(user.uid);
      
      if (newToken) {
        setTokens(prev => [...prev, newToken]);
        toast.success('Token generated successfully');
      } else {
        toast.error(t('errors.generateFailed'));
      }
    } catch (error) {
      console.error('Error generating token:', error);
      toast.error(t('errors.generateFailed'));
    } finally {
      setGenerating(false);
    }
  };

  const copyToken = async (token: string) => {
    try {
      await navigator.clipboard.writeText(token);
      toast.success(t('copied'));
    } catch (error) {
      console.error('Error copying token:', error);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center py-4">
        <Loader2 className={`h-6 w-6 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div>
        <h2 className={`text-lg font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
          {t('title')}
        </h2>
        <p className={`text-sm mt-1 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
          {t('description')}
        </p>
      </div>

      <div className="space-y-2">
        <h3 className={`text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
          {t('availableTokens')} ({tokens.length}/10)
        </h3>
        
        {tokens.length > 0 ? (
          <div className="grid grid-cols-1 gap-2">
            {tokens.map((token) => (
              <div
                key={token}
                className={`flex items-center justify-between p-3 rounded-lg ${
                  isDarkMode 
                    ? 'bg-gray-700 text-white' 
                    : 'bg-gray-100 text-gray-900'
                }`}
              >
                <code className="font-mono">{token}</code>
                <button
                  onClick={() => copyToken(token)}
                  className={`p-1 rounded-full ${
                    isDarkMode 
                      ? 'hover:bg-gray-600' 
                      : 'hover:bg-gray-200'
                  }`}
                  title={t('copy')}
                >
                  <Copy className="h-4 w-4" />
                </button>
              </div>
            ))}
          </div>
        ) : (
          <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
            {t('noTokens')}
          </p>
        )}

        <button
          onClick={handleGenerateToken}
          disabled={generating || tokens.length >= 10}
          className={`mt-4 w-full flex items-center justify-center space-x-2 px-4 py-2 rounded-lg disabled:opacity-50 ${
            isDarkMode 
              ? 'bg-blue-600 text-white hover:bg-blue-700' 
              : 'bg-blue-500 text-white hover:bg-blue-600'
          }`}
        >
          {generating ? (
            <>
              <Loader2 className="h-5 w-5 animate-spin" />
              <span>{t('generating')}</span>
            </>
          ) : (
            <>
              <RefreshCw className="h-5 w-5" />
              <span>{t('generate')}</span>
            </>
          )}
        </button>
      </div>
    </div>
  );
}