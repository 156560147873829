import { useState, useEffect } from 'react';
import { Play, Pause, Loader2, Music, Eye, Youtube } from 'lucide-react';
import { formatTimeAgo } from '../utils/date';
import type { Story } from '../utils/stories';

interface StoryGridProps {
  stories: Story[];
  loading: boolean;
  loadingMore: boolean;
  hasMore: boolean;
  onLoadMore: () => void;
  videoPreviewRefs: React.MutableRefObject<{ [key: string]: HTMLVideoElement }>;
  previewTimersRef: React.MutableRefObject<{ [key: string]: number }>;
  onStorySelect: (index: number) => void;
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    recentStories: 'Stories Recentes',
    loading: 'Carregando...',
    noStories: 'Nenhum story ainda',
    beFirstShare: 'Seja o primeiro a compartilhar!',
    showMore: 'Mostrar Mais',
    loadingMore: 'Carregando mais...',
    watching: 'visualizações'
  },
  'en': {
    recentStories: 'Recent Stories',
    loading: 'Loading...',
    noStories: 'No stories yet',
    beFirstShare: 'Be the first to share!',
    showMore: 'Show More',
    loadingMore: 'Loading more...',
    watching: 'views'
  }
};

export function StoryGrid({
  stories,
  loading,
  loadingMore,
  hasMore,
  onLoadMore,
  videoPreviewRefs,
  previewTimersRef,
  onStorySelect,
  isDarkMode = false,
  language = 'en'
}: StoryGridProps) {
  const isAndroid = /Android/i.test(navigator.userAgent);

  const handleVideoLoad = (video: HTMLVideoElement) => {
    if (isAndroid) {
      video.currentTime = 0.1;
    }
  };

  const t = (key: string) => {
    return translations[language as keyof typeof translations][key as keyof (typeof translations)['en']];
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center p-8">
        <Loader2 className={`h-8 w-8 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
      </div>
    );
  }

  return (
    <div className="mt-6 mb-16">
      <h2 className={`text-lg font-semibold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        {t('recentStories')}
      </h2>
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
        {stories.length > 0 ? (
          stories.map((story, index) => (
            <button
              key={story.id}
              onClick={() => onStorySelect(index)}
              className="relative aspect-[9/16] rounded-lg overflow-hidden focus:outline-none group"
              style={{
                width: '100%',
                maxWidth: '270px',
                margin: '0 auto'
              }}
            >
              {/* View count badge at top center */}
              <div className="absolute top-2 left-1/2 transform -translate-x-1/2 z-10 bg-black/50 backdrop-blur-sm rounded-full px-3 py-1 flex items-center space-x-1">
                <Eye className="h-3 w-3 text-white" />
                <span className="text-xs text-white">{story.views?.length || 0}</span>
              </div>

              {story.mediaType === 'youtube' ? (
                <div className="absolute inset-0">
                  <img
                    src={story.youtubeVideo?.thumbnailUrl}
                    alt={story.youtubeVideo?.title}
                    className="w-full h-full object-cover"
                    loading="lazy"
                  />
                  <div className={`absolute inset-0 ${isDarkMode ? 'bg-gradient-to-b from-black/30 to-black/70' : 'bg-gradient-to-b from-black/20 to-black/60'} group-hover:from-black/40 group-hover:to-black/80 transition-colors`}>
                    <div className="absolute top-2 left-2">
                      <Youtube className="h-6 w-6 text-red-500" />
                    </div>
                  </div>
                </div>
              ) : story.mediaType === 'spotify' ? (
                <div className="absolute inset-0">
                  <img
                    src={story.spotifyTrack?.albumImage}
                    alt={story.authorName}
                    className="w-full h-full object-cover"
                    loading="lazy"
                  />
                  <div className={`absolute inset-0 ${isDarkMode ? 'bg-gradient-to-b from-black/30 to-black/70' : 'bg-gradient-to-b from-black/20 to-black/60'} group-hover:from-black/40 group-hover:to-black/80 transition-colors`}>
                    <div className="absolute top-2 left-2">
                      <Music className="h-6 w-6 text-green-500" />
                    </div>
                  </div>
                </div>
              ) : story.mediaType === 'video' ? (
                <div className="relative w-full h-full">
                  <video
                    ref={el => {
                      if (el) {
                        videoPreviewRefs.current[story.id] = el;
                        el.addEventListener('loadedmetadata', () => handleVideoLoad(el));
                      }
                    }}
                    src={story.mediaUrl}
                    className="absolute inset-0 w-full h-full object-cover"
                    muted
                    playsInline
                    preload="metadata"
                  />
                  <div className={`absolute inset-0 ${isDarkMode ? 'bg-gradient-to-b from-black/30 to-black/70' : 'bg-gradient-to-b from-black/20 to-black/60'} group-hover:from-black/40 group-hover:to-black/80 transition-colors`}>
                    <div className="absolute top-2 left-2">
                      <Play className="h-6 w-6 text-white" />
                    </div>
                  </div>
                </div>
              ) : (
                <img
                  src={story.mediaUrl}
                  alt={`Story by ${story.authorName}`}
                  className="absolute inset-0 w-full h-full object-cover"
                  loading="lazy"
                />
              )}
              <div className={`absolute inset-0 ${isDarkMode ? 'bg-gradient-to-b from-black/30 to-black/70' : 'bg-gradient-to-b from-black/20 to-black/60'} group-hover:from-black/40 group-hover:to-black/80 transition-colors`} />

              <div className="absolute bottom-2 left-2 right-2">
                <div className="flex items-center space-x-2">
                  <img
                    src={story.authorPhotoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${story.authorId}`}
                    alt={story.authorName}
                    className="w-6 h-6 rounded-full border border-white"
                  />
                  <div className="flex-1 min-w-0">
                    <p className="text-white text-sm font-medium truncate">
                      {story.authorName}
                    </p>
                    <p className="text-white/80 text-xs truncate">
                      {formatTimeAgo(story.timestamp, language)}
                    </p>
                  </div>
                </div>
              </div>
            </button>
          ))
        ) : (
          <div className="col-span-full text-center py-8">
            <p className={`text-lg mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              {t('noStories')}
            </p>
            <p className={`text-sm mt-2 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
              {t('beFirstShare')}
            </p>
          </div>
        )}
      </div>

      {hasMore && (
        <div className="mt-6 flex justify-center">
          <button
            onClick={onLoadMore}
            disabled={loadingMore}
            className={`px-6 py-2 rounded-full disabled:opacity-50 flex items-center space-x-2 ${
              isDarkMode 
                ? 'bg-gray-700 text-white hover:bg-gray-600' 
                : 'bg-black text-white hover:bg-gray-800'
            }`}
          >
            {loadingMore ? (
              <>
                <Loader2 className="h-4 w-4 animate-spin" />
                <span>{t('loadingMore')}</span>
              </>
            ) : (
              <span>{t('showMore')}</span>
            )}
          </button>
        </div>
      )}
    </div>
  );
}