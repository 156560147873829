import { useState } from 'react';
import { Link } from 'react-router-dom';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { Profile } from '../../types/match';
import { useAuth } from '../../components/AuthProvider';
import { Send, ChevronRight } from 'lucide-react';
import toast from 'react-hot-toast';

interface MatchCardProps {
  profile: Profile;
  onNext: () => void;
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    writeComment: 'Escreva um comentário...',
    sending: 'Enviando...',
    next: 'Próximo',
    errors: {
      commentRequired: 'Digite um comentário antes de enviar',
      commentTooLong: 'O comentário deve ter no máximo 120 caracteres',
      sendFailed: 'Falha ao enviar comentário',
      noLinks: 'Links não são permitidos em comentários'
    },
    success: {
      commentSent: 'Comentário enviado com sucesso!'
    }
  },
  'en': {
    writeComment: 'Write a comment...',
    sending: 'Sending...',
    next: 'Next',
    errors: {
      commentRequired: 'Please write a comment before sending',
      commentTooLong: 'Comment must be 120 characters or less',
      sendFailed: 'Failed to send comment',
      noLinks: 'Links are not allowed in comments'
    },
    success: {
      commentSent: 'Comment sent successfully!'
    }
  }
};

export function MatchCard({ profile, onNext, isDarkMode = false, language = 'en' }: MatchCardProps) {
  const [comment, setComment] = useState('');
  const [sending, setSending] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const { user } = useAuth();

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  const handleSendComment = async () => {
    if (!user || !comment.trim()) {
      toast.error(t('errors.commentRequired'));
      return;
    }

    if (comment.length > 120) {
      toast.error(t('errors.commentTooLong'));
      return;
    }

    // Check for links
    const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)|([a-zA-Z0-9-]+\.(com|net|org|br|io|dev|app|me|co|uk|us|ca|eu))/gi;
    if (urlRegex.test(comment)) {
      toast.error(t('errors.noLinks'));
      return;
    }

    try {
      setSending(true);

      // Create notification with comment
      await addDoc(collection(db, 'notifications'), {
        type: 'profile_comment',
        recipientId: profile.uid,
        senderId: user.uid,
        senderName: user.displayName || '',
        senderUsername: user.displayName?.replace('@', '') || '',
        senderPhotoURL: user.photoURL,
        content: comment.trim(),
        timestamp: serverTimestamp(),
        read: false
      });

      toast.success(t('success.commentSent'));
      setComment('');
      onNext();
    } catch (error) {
      console.error('Error sending comment:', error);
      toast.error(t('errors.sendFailed'));
    } finally {
      setSending(false);
    }
  };

  return (
    <div className={`w-full max-w-sm ${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-xl shadow-lg overflow-hidden`}>
      <Link to={`/profile/${profile.username}`}>
        <div className="relative w-64 h-64 mx-auto mt-6">
          {!imageLoaded && (
            <div className={`absolute inset-0 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'} animate-pulse rounded-full`} />
          )}
          
          <img
            src={profile.photoURL}
            alt={profile.name}
            className={`w-full h-full object-cover rounded-full border-4 ${
              isDarkMode ? 'border-gray-700' : 'border-gray-100'
            } transition-opacity duration-300 ${
              imageLoaded ? 'opacity-100' : 'opacity-0'
            }`}
            onLoad={() => setImageLoaded(true)}
            loading="eager"
          />
        </div>
      </Link>
      
      <div className="p-4">
        <div className="flex items-center justify-between mb-4">
          <div>
            <h2 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              {profile.name}
            </h2>
            <p className={isDarkMode ? 'text-gray-400' : 'text-gray-500'}>
              @{profile.username}
            </p>
          </div>
          <button
            onClick={onNext}
            className={`p-2 rounded-full transition-colors ${
              isDarkMode 
                ? 'bg-gray-700 hover:bg-gray-600 text-white' 
                : 'bg-gray-100 hover:bg-gray-200 text-gray-900'
            }`}
            title={t('next')}
          >
            <ChevronRight className="h-6 w-6" />
          </button>
        </div>

        <div className="flex space-x-2">
          <input
            type="text"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder={t('writeComment')}
            maxLength={120}
            className={`flex-1 px-4 py-2 rounded-full border focus:outline-none focus:ring-2 ${
              isDarkMode 
                ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400 focus:ring-blue-500' 
                : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500 focus:ring-blue-500'
            }`}
          />
          <button
            onClick={handleSendComment}
            disabled={!comment.trim() || sending}
            className={`p-2 rounded-full text-white transition-colors disabled:opacity-50 ${
              isDarkMode 
                ? 'bg-blue-600 hover:bg-blue-700' 
                : 'bg-blue-500 hover:bg-blue-600'
            }`}
          >
            <Send className="h-5 w-5" />
          </button>
        </div>
        <p className={`text-xs mt-2 ${
          comment.length > 120 
            ? 'text-red-500' 
            : isDarkMode 
              ? 'text-gray-400' 
              : 'text-gray-500'
        }`}>
          {comment.length}/120
        </p>
      </div>
    </div>
  );
}