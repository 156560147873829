import { useState, useEffect, useRef } from 'react';
import { Camera, Loader2 } from 'lucide-react';
import { useAuth } from '../components/AuthProvider';
import { usePhoto } from '../contexts/PhotoContext';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../lib/firebase';
import { ImageEditor } from './ImageEditor';
import { moderateImage } from '../utils/vision';
import toast from 'react-hot-toast';

interface ProfilePhotoUploaderProps {
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    title: 'Foto do Perfil',
    upload: 'Enviar nova foto de perfil',
    recommended: 'Recomendado: Imagem quadrada, pelo menos 400x400 pixels',
    processing: 'Processando...',
    checkingImage: 'Verificando imagem...',
    errors: {
      imageProcessing: 'Erro ao processar imagem:',
      inappropriateContent: 'Esta imagem contém conteúdo promocional ou material inadequado. Por favor, escolha outra imagem.'
    }
  },
  'en': {
    title: 'Profile Photo',
    upload: 'Upload new profile photo',
    recommended: 'Recommended: Square image, at least 400x400 pixels',
    processing: 'Processing...',
    checkingImage: 'Checking image...',
    errors: {
      imageProcessing: 'Error processing image:',
      inappropriateContent: 'This image contains promotional content or inappropriate material. Please choose another image.'
    }
  }
};

export function ProfilePhotoUploader({ isDarkMode = false, language = 'en' }: ProfilePhotoUploaderProps) {
  const [uploadingPhoto, setUploadingPhoto] = useState(false);
  const [showPhotoEditor, setShowPhotoEditor] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [moderating, setModerating] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(language);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { user } = useAuth();
  const { updateUserPhoto: updateContextPhoto } = usePhoto();

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setCurrentLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[currentLanguage as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  const handlePhotoSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result as string);
        setShowPhotoEditor(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCroppedImage = async (blob: Blob) => {
    if (!user) return;

    try {
      setModerating(true);
      
      // Convert blob to base64 for Cloud Vision
      const base64Image = await new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(blob);
      });

      // Check image with Cloud Vision
      const isImageSafe = await moderateImage(base64Image);
      if (!isImageSafe) {
        toast.error(t('errors.inappropriateContent'));
        return;
      }

      setUploadingPhoto(true);
      const loadingToast = toast.loading(t('processing'));

      const file = new File([blob], 'profile-photo.jpg', { type: 'image/jpeg' });
      const storageRef = ref(storage, `avatars/${user.uid}`);
      await uploadBytes(storageRef, file);
      const photoURL = await getDownloadURL(storageRef);

      // Update user document
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, { photoURL });

      // Update context
      updateContextPhoto(photoURL);
      
      toast.success('Photo updated successfully!');
      setShowPhotoEditor(false);
      setSelectedImage(null);
      toast.dismiss(loadingToast);
    } catch (error: any) {
      console.error('Photo update failed:', error);
      toast.error(error.message || t('errors.imageProcessing'));
    } finally {
      setUploadingPhoto(false);
      setModerating(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  return (
    <div>
      <h2 className={`text-lg font-semibold mb-6 text-center ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        {t('title')}
      </h2>
      <div className="flex justify-center">
        <div className="relative">
          <img
            src={user?.photoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${user?.uid}`}
            alt="Profile"
            className="h-32 w-32 rounded-full object-cover bg-gray-100"
          />
          <label className="absolute bottom-0 right-0 p-2 bg-white rounded-full shadow-lg cursor-pointer">
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={handlePhotoSelect}
              className="hidden"
            />
            {uploadingPhoto || moderating ? (
              <Loader2 className="h-5 w-5 animate-spin text-blue-500" />
            ) : (
              <Camera className="h-5 w-5 text-blue-500" />
            )}
          </label>
        </div>
      </div>

      {showPhotoEditor && selectedImage && (
        <ImageEditor
          image={selectedImage}
          onSave={handleCroppedImage}
          onCancel={() => {
            setShowPhotoEditor(false);
            setSelectedImage(null);
          }}
          aspectRatio={1}
          isProcessing={moderating}
        />
      )}
    </div>
  );
}