import { useState, useEffect, useRef } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Link } from 'react-router-dom';
import { X, ChevronLeft, ChevronRight, Loader2, Heart, Languages, MessageCircle, Send, Trash2, Eye, Play, Pause } from 'lucide-react';
import { doc, deleteDoc, collection, query, where, getDocs, updateDoc, arrayUnion, arrayRemove, addDoc, serverTimestamp, onSnapshot } from 'firebase/firestore';
import { ref, deleteObject } from 'firebase/storage';
import { db, storage } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import { StoryMenu } from './StoryMenu';
import { StoryComment } from './StoryComment';
import { StoryComments } from './StoryComments';
import { StoryLifetime } from './StoryLifetime';
import YouTube from 'react-youtube';
import toast from 'react-hot-toast';

const translations = {
  'pt-BR': {
    deleteConfirm: 'Tem certeza que deseja excluir este story? Esta ação não pode ser desfeita.',
    deleteSuccess: 'Story excluído com sucesso',
    deleteError: 'Falha ao excluir story',
    likeError: 'Falha ao curtir story',
    showComments: 'Ver comentários',
    hideComments: 'Ocultar comentários',
    noPreview: 'Prévia não disponível - Clique para abrir no Spotify',
    deleteStory: 'Excluir story',
    adminDelete: 'Admin: Excluir story'
  },
  'en': {
    deleteConfirm: 'Are you sure you want to delete this story? This action cannot be undone.',
    deleteSuccess: 'Story deleted successfully',
    deleteError: 'Failed to delete story',
    likeError: 'Failed to like story',
    showComments: 'Show comments',
    hideComments: 'Hide comments',
    noPreview: 'No preview available - Click to open in Spotify',
    deleteStory: 'Delete story',
    adminDelete: 'Admin: Delete story'
  }
};

interface Story {
  id: string;
  mediaUrl: string;
  mediaType: 'image' | 'video' | 'spotify' | 'youtube';
  authorId: string;
  authorName: string;
  authorUsername: string;
  authorPhotoURL: string;
  timestamp: Date;
  likes: string[];
  views: string[];
  spotifyTrack?: {
    id: string;
    name: string;
    artists: string[];
    albumName: string;
    albumImage: string;
    previewUrl?: string;
    spotifyUrl: string;
  };
  youtubeVideo?: {
    id: string;
    title: string;
    channelTitle: string;
    thumbnailUrl: string;
  };
}

interface StoryViewerProps {
  stories: Story[];
  initialIndex: number;
  onClose: () => void;
  onDelete?: (storyId: string) => void;
  isDarkMode?: boolean;
}

export function StoryViewer({ stories, initialIndex, onClose, onDelete, isDarkMode = false }: StoryViewerProps) {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [currentLikes, setCurrentLikes] = useState<string[]>([]);
  const [currentViews, setCurrentViews] = useState<string[]>([]);
  const [isLikeAnimating, setIsLikeAnimating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [optimizedImageUrl, setOptimizedImageUrl] = useState<string | null>(null);
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [language, setLanguage] = useState('en');
  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const likeButtonRef = useRef<HTMLButtonElement>(null);
  const { user } = useAuth();
  const isAdmin = user?.email?.toLowerCase() === 'afonsoalcantara@outlook.com';
  const currentStory = stories[currentIndex];

  const t = (key: string) => {
    return translations[language as keyof typeof translations][key as keyof (typeof translations)['en']];
  };

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  useEffect(() => {
    if (!currentStory) return;

    setCurrentLikes(currentStory.likes || []);
    setCurrentViews(currentStory.views || []);
    setImageLoaded(false);
    setOptimizedImageUrl(null);
    setIsVideoLoading(false);
    setShowComments(false);
    setIsPlaying(false);

    // Cleanup any existing audio
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.src = '';
      audioRef.current = null;
    }

    // Record view
    if (user && !currentStory.views?.includes(user.uid)) {
      const storyRef = doc(db, 'stories', currentStory.id);
      updateDoc(storyRef, {
        views: arrayUnion(user.uid)
      }).catch(console.error);
    }
  }, [currentStory, user]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.src = '';
        audioRef.current = null;
      }
    };
  }, []);

  const handleLike = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!user || !currentStory || !likeButtonRef.current) return;

    try {
      const storyRef = doc(db, 'stories', currentStory.id);
      const isLiked = currentLikes.includes(user.uid);

      setCurrentLikes(prev => 
        isLiked 
          ? prev.filter(id => id !== user.uid)
          : [...prev, user.uid]
      );

      if (!isLiked) {
        setIsLikeAnimating(true);
        setTimeout(() => setIsLikeAnimating(false), 1000);
      }

      await updateDoc(storyRef, {
        likes: isLiked ? arrayRemove(user.uid) : arrayUnion(user.uid)
      });

      if (!isLiked) {
        likeButtonRef.current.classList.add('animate-like');
        setTimeout(() => {
          if (likeButtonRef.current) {
            likeButtonRef.current.classList.remove('animate-like');
          }
        }, 1000);
      }
    } catch (error) {
      console.error('Error liking story:', error);
      toast.error(t('likeError'));
      setCurrentLikes(currentStory.likes || []);
    }
  };

  const handleDelete = async () => {
    if (!currentStory || !user) return;

    const isOwner = user.uid === currentStory.authorId;
    if (!isOwner && !isAdmin) return;

    const confirmDelete = window.confirm(t('deleteConfirm'));
    if (!confirmDelete) return;

    setIsDeleting(true);

    try {
      // Delete story document
      await deleteDoc(doc(db, 'stories', currentStory.id));

      // Try to delete media file if it exists
      if (currentStory.mediaType !== 'spotify' && currentStory.mediaType !== 'youtube') {
        try {
          const filename = currentStory.mediaUrl.split('/').pop()?.split('?')[0];
          if (filename) {
            const storageRef = ref(storage, `stories/${filename}`);
            await deleteObject(storageRef);
          }
        } catch (error: any) {
          // Ignore storage/object-not-found errors
          if (error.code !== 'storage/object-not-found') {
            console.error('Error deleting story media:', error);
          }
        }
      }

      if (onDelete) {
        onDelete(currentStory.id);
      }

      handleNext();
      toast.success(t('deleteSuccess'));
    } catch (error) {
      console.error('Error deleting story:', error);
      toast.error(t('deleteError'));
    } finally {
      setIsDeleting(false);
    }
  };

  const handlePrevious = () => {
    // Stop any playing audio before changing story
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.src = '';
      audioRef.current = null;
    }
    setIsPlaying(false);

    if (currentIndex > 0) {
      setCurrentIndex(prev => prev - 1);
      setImageLoaded(false);
      setIsVideoLoading(false);
      setShowComments(false);
    }
  };

  const handleNext = () => {
    // Stop any playing audio before changing story
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.src = '';
      audioRef.current = null;
    }
    setIsPlaying(false);

    if (currentIndex < stories.length - 1) {
      setCurrentIndex(prev => prev + 1);
      setImageLoaded(false);
      setIsVideoLoading(false);
      setShowComments(false);
    } else {
      // Stop audio before closing
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.src = '';
        audioRef.current = null;
      }
      onClose();
    }
  };

  const handlePlayPause = () => {
    if (!currentStory.spotifyTrack?.previewUrl) {
      window.open(currentStory.spotifyTrack?.spotifyUrl, '_blank');
      return;
    }

    if (!audioRef.current) {
      audioRef.current = new Audio(currentStory.spotifyTrack.previewUrl);
      audioRef.current.volume = 0.5;
      
      audioRef.current.onended = () => {
        setIsPlaying(false);
      };
    }

    if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      audioRef.current.currentTime = 0;
      audioRef.current.play().catch(error => {
        console.error('Error playing audio:', error);
        toast.error('Failed to play audio preview');
      });
      setIsPlaying(true);
    }
  };

  if (!currentStory) return null;

  const isLiked = currentLikes.includes(user?.uid || '');

  return (
    <div className="fixed inset-0 bg-black z-50 flex items-center justify-center">
      <div className="absolute inset-0">
        {currentStory.mediaType === 'youtube' ? (
          <div className="absolute inset-0 flex items-center justify-center">
            <YouTube
              videoId={currentStory.youtubeVideo?.id}
              opts={{
                width: '100%',
                height: '100%',
                playerVars: {
                  autoplay: 1,
                  start: 0,
                  controls: 0,
                  modestbranding: 1,
                  rel: 0,
                  showinfo: 0,
                  iv_load_policy: 3,
                  fs: 0,
                  disablekb: 1,
                }
              }}
              className="w-full h-full"
              onEnd={handleNext}
            />
          </div>
        ) : currentStory.mediaType === 'spotify' ? (
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="relative w-full h-full">
              <img
                src={currentStory.spotifyTrack?.albumImage}
                alt={currentStory.spotifyTrack?.name}
                className="w-full h-full object-contain"
                loading="eager"
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center p-4">
                <h3 className="text-3xl font-bold text-center mb-4 text-white">{currentStory.spotifyTrack?.name}</h3>
                <p className="text-xl text-center text-white mb-8">{currentStory.spotifyTrack?.artists.join(', ')}</p>
                <button
                  onClick={handlePlayPause}
                  className="p-4 bg-green-500 rounded-full hover:bg-green-600 transition-colors"
                >
                  {isPlaying ? (
                    <Pause className="h-8 w-8 text-white" />
                  ) : (
                    <Play className="h-8 w-8 text-white" />
                  )}
                </button>
                {!currentStory.spotifyTrack?.previewUrl && (
                  <p className="mt-4 text-sm text-white opacity-75">
                    {t('noPreview')}
                  </p>
                )}
              </div>
            </div>
          </div>
        ) : currentStory.mediaType === 'video' ? (
          <div className="relative w-full h-full flex items-center justify-center bg-black">
            {isVideoLoading && (
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <Loader2 className="h-12 w-12 animate-spin text-white" />
              </div>
            )}
            <video
              ref={videoRef}
              src={currentStory.mediaUrl}
              className="max-h-full max-w-full object-contain"
              playsInline
              muted={false}
              autoPlay
              onLoadStart={() => setIsVideoLoading(true)}
              onCanPlay={() => {
                setIsVideoLoading(false);
                if (videoRef.current) {
                  videoRef.current.play().catch(error => {
                    console.error('Error auto-playing video:', error);
                  });
                }
              }}
            />
          </div>
        ) : (
          <>
            {!imageLoaded && (
              <div className={`absolute inset-0 ${isDarkMode ? 'bg-gray-900' : 'bg-gray-200'} animate-pulse`} />
            )}
            
            <img
              src={optimizedImageUrl || currentStory.mediaUrl}
              alt="Story"
              className={`w-full h-full object-contain transition-opacity duration-300 ${
                imageLoaded ? 'opacity-100' : 'opacity-0'
              }`}
              onLoad={() => setImageLoaded(true)}
              loading="eager"
            />
          </>
        )}

        {isLikeAnimating && (
          <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
            <Heart className="h-32 w-32 text-red-500 fill-current animate-like-pop" />
          </div>
        )}
      </div>

      <div className="absolute top-0 left-0 right-0 p-4 z-10">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <Link to={`/profile/${currentStory.authorUsername}`}>
              <img
                src={currentStory.authorPhotoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${currentStory.authorId}`}
                alt={currentStory.authorName}
                className="w-10 h-10 rounded-full border-2 border-white"
              />
            </Link>
            <div className="text-white">
              <Link 
                to={`/profile/${currentStory.authorUsername}`}
                className="font-semibold hover:underline"
              >
                {currentStory.authorName}
              </Link>
              <p className="text-sm opacity-75">
                {formatDistanceToNow(currentStory.timestamp, { 
                  addSuffix: true,
                  locale: language === 'pt-BR' ? ptBR : undefined 
                })}
              </p>
            </div>
          </div>
          <button
            onClick={(e) => {
              e.stopPropagation();
              // Stop audio before closing
              if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.src = '';
                audioRef.current = null;
              }
              setIsPlaying(false);
              onClose();
            }}
            className="p-2 text-white hover:bg-white/10 rounded-full transition-colors"
          >
            <X className="h-6 w-6" />
          </button>
        </div>
      </div>

      <button
        onClick={(e) => {
          e.stopPropagation();
          handlePrevious();
        }}
        className="absolute left-4 top-1/2 -translate-y-1/2 p-2 text-white bg-black/50 hover:bg-black/70 rounded-full transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
        disabled={currentIndex === 0}
      >
        <ChevronLeft className="h-6 w-6" />
      </button>

      <button
        onClick={(e) => {
          e.stopPropagation();
          handleNext();
        }}
        className="absolute right-4 top-1/2 -translate-y-1/2 p-2 text-white bg-black/50 hover:bg-black/70 rounded-full transition-colors"
      >
        <ChevronRight className="h-6 w-6" />
      </button>

      <div className="absolute bottom-0 left-0 right-0 bg-black/50 backdrop-blur-sm p-4">
        <div className="flex justify-center items-center space-x-6 mb-4">
          <div className="flex items-center space-x-2">
            <button
              ref={likeButtonRef}
              onClick={handleLike}
              className="p-1.5 hover:bg-white/10 rounded-full transition-colors transform active:scale-125 flex items-center space-x-1"
            >
              <Heart 
                className={`h-4 w-4 ${isLiked ? 'text-red-500 fill-current' : 'text-white'}`}
              />
              <span className="text-white text-xs">
                {currentLikes.length}
              </span>
            </button>

            <div className="flex items-center space-x-1 text-white">
              <Eye className="h-4 w-4" />
              <span className="text-xs">{currentViews.length}</span>
            </div>

            <button
              onClick={() => setShowComments(!showComments)}
              className="flex items-center space-x-1 text-white hover:text-gray-200 transition-colors"
            >
              <MessageCircle className="h-4 w-4" />
              <span className="text-xs">{showComments ? t('hideComments') : t('showComments')}</span>
            </button>
          </div>
        </div>

        {showComments && (
          <div className={`mt-3 mb-4 max-h-32 overflow-y-auto ${
            isDarkMode ? 'bg-gray-800/80' : 'bg-white/80'
          } rounded-lg mx-auto max-w-md`}>
            <StoryComments
              storyId={currentStory.id}
              isVisible={showComments}
              isDarkMode={isDarkMode}
              language={language}
            />
          </div>
        )}

        <div className="mt-3 mb-2 max-w-md mx-auto">
          <StoryComment
            storyId={currentStory.id}
            authorId={currentStory.authorId}
            onCommentAdded={() => {}}
            isDarkMode={isDarkMode}
            language={language}
          />
        </div>

        <StoryLifetime 
          timestamp={currentStory.timestamp}
          isDarkMode={isDarkMode}
        />
      </div>

      <div className="absolute bottom-4 right-4">
        <StoryMenu
          storyId={currentStory.id}
          authorId={currentStory.authorId}
          onBlock={handleNext}
        />
      </div>

      {(user?.uid === currentStory.authorId || isAdmin) && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            handleDelete();
          }}
          disabled={isDeleting}
          className="absolute bottom-4 left-4 p-1.5 text-white hover:bg-white/10 rounded-full transition-colors disabled:opacity-50"
          title={isAdmin && user.uid !== currentStory.authorId ? t('adminDelete') : t('deleteStory')}
        >
          {isDeleting ? (
            <Loader2 className="h-5 w-5 animate-spin" />
          ) : (
            <Trash2 className="h-5 w-5" />
          )}
        </button>
      )}

      <div className="absolute top-0 left-0 right-0 h-1 bg-gray-800">
        <div className="h-full bg-white" style={{ width: `${((currentIndex + 1) / stories.length) * 100}%` }} />
      </div>
    </div>
  );
}