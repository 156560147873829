import { Eye, Link, Film, Image, Search, User, MessageSquarePlus, Trophy, BadgeCheck, Users, Flame, Newspaper, Video, Tv, Music, Radio, MapPin, Bot, Gamepad2, DollarSign } from 'lucide-react';
import { ChatBadge } from '../components/ChatBadge';
import { MenuItem } from '../types/navigation';
import type { User as FirebaseUser } from 'firebase/auth';

export function getMenuItems(user: FirebaseUser): MenuItem[] {
  return [
    { icon: Eye, path: '/', title: 'Feed World' },
    { icon: Link, path: '/allies-posts', title: 'Posts Aliados' },
    { icon: Film, path: '/stories', title: 'Stories' },
    { icon: Image, path: '/gif', title: 'GIFs' },
    { icon: Search, path: '/search', title: 'Search Users' },
    { 
      icon: User, 
      path: `/profile/${user.displayName?.replace('@', '')}`,
      title: 'Profile'
    },
    {
      component: ChatBadge,
      path: '/chat',
      title: 'Chat'
    },
    { icon: Trophy, path: '/ranking', title: 'Ranking' },
    { icon: BadgeCheck, path: '/verified', title: 'Verified Users' },
    { icon: Users, path: '/communities', title: 'Communities' },
    { icon: Flame, path: '/match', title: 'Match' },
    { icon: Newspaper, path: '/news', title: 'News' },
    { icon: Video, path: '/videos', title: 'Videos' },
    { icon: Tv, path: '/live', title: 'Live' },
    { icon: Music, path: '/music', title: 'Music' },
    { icon: Radio, path: '/radio', title: 'Radio' },
    { icon: MapPin, path: '/favorite-places', title: 'Favorite Places' },
    { icon: Bot, path: '/ai-chat', title: 'Poosting AI' },
    { icon: Gamepad2, path: '/games', title: 'Games' },
    { icon: DollarSign, path: '/ads', title: 'Poosting Ads' }
  ];
}