import { Volume2, Loader2 } from 'lucide-react';
import { useRadio } from '../../contexts/RadioContext';

interface RadioStationProps {
  station: {
    name: string;
    url: string;
    favicon: string;
    countrycode: string;
    language: string;
    bitrate: number;
    stationuuid: string;
  };
  isPlaying: boolean;
  isCurrentStation: boolean;
  onClick: () => void;
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    kbps: 'kbps',
    loading: 'Carregando...',
    errors: {
      playbackError: 'Falha ao reproduzir estação'
    }
  },
  'en': {
    kbps: 'kbps',
    loading: 'Loading...',
    errors: {
      playbackError: 'Failed to play station'
    }
  }
};

export function RadioStation({ 
  station, 
  isPlaying, 
  isCurrentStation, 
  onClick, 
  isDarkMode = false,
  language = 'en'
}: RadioStationProps) {
  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  return (
    <div
      className={`p-4 rounded-lg border transition-colors cursor-pointer ${
        isDarkMode
          ? isCurrentStation
            ? 'border-purple-500 bg-gray-700'
            : 'border-gray-700 hover:border-purple-500'
          : isCurrentStation
            ? 'border-purple-500 bg-purple-50'
            : 'border-gray-200 hover:border-purple-500'
      }`}
      onClick={onClick}
    >
      <div className="flex items-center space-x-3">
        <img
          src={station.favicon}
          alt={station.name}
          className="w-12 h-12 rounded-lg object-cover"
          loading="lazy"
          onError={(e) => {
            const target = e.target as HTMLImageElement;
            target.src = `https://api.dicebear.com/7.x/initials/svg?seed=${station.name}`;
          }}
        />
        <div className="flex-1 min-w-0">
          <h3 className={`font-medium truncate ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            {station.name}
          </h3>
          <p className={`text-sm truncate ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
            {station.countrycode} • {station.bitrate}{t('kbps')}
          </p>
        </div>
        {isCurrentStation && (isPlaying ? (
          <Volume2 className={`h-5 w-5 ${isDarkMode ? 'text-purple-400' : 'text-purple-500'} animate-pulse`} />
        ) : (
          <Loader2 className={`h-5 w-5 ${isDarkMode ? 'text-purple-400' : 'text-purple-500'} animate-spin`} />
        ))}
      </div>
    </div>
  );
}