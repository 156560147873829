import { useState, useEffect } from 'react';
import { collection, query, where, orderBy, onSnapshot, getDocs, doc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../hooks/useAuth';
import { formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Link } from 'react-router-dom';
import { Loader2, Heart, Languages, MessageCircle, Send, Trash2, X, Ban } from 'lucide-react';
import toast from 'react-hot-toast';

interface ChatMessage {
  id: string;
  userMessage: string;
  aiResponse: string;
  timestamp: Date;
  error?: boolean;
}

interface ChatListProps {
  selectedChat: ChatRoom | null;
  onChatSelect: (chat: ChatRoom) => void;
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    loading: 'Carregando...',
    noMessages: 'Nenhuma mensagem ainda',
    startChat: 'Comece uma conversa!',
    unreadMessages: 'mensagens não lidas',
    blockUser: 'Bloquear usuário',
    unblockUser: 'Desbloquear usuário',
    blockConfirm: 'Tem certeza que deseja bloquear este usuário? Esta ação é permanente e não poderá ser desfeita.',
    unblockConfirm: 'Tem certeza que deseja desbloquear este usuário?',
    success: {
      blocked: 'Usuário bloqueado com sucesso',
      unblocked: 'Usuário desbloqueado com sucesso'
    }
  },
  'en': {
    loading: 'Loading...',
    noMessages: 'No messages yet',
    startChat: 'Start a conversation!',
    unreadMessages: 'unread messages',
    blockUser: 'Block user',
    unblockUser: 'Unblock user',
    blockConfirm: 'Are you sure you want to block this user? This action is permanent and cannot be undone.',
    unblockConfirm: 'Are you sure you want to unblock this user?',
    success: {
      blocked: 'User blocked successfully',
      unblocked: 'User unblocked successfully'
    }
  }
};

interface ChatRoom {
  id: string;
  participants: string[];
  lastMessage?: string;
  lastMessageTimestamp?: Date;
  participantInfo?: { [key: string]: { name: string; username: string; photoURL: string } };
  unreadCount?: number;
  blockedBy?: string[];
}

export function ChatList({ selectedChat, onChatSelect, isDarkMode = false, language = 'en' }: ChatListProps) {
  const [chats, setChats] = useState<ChatRoom[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalUnread, setTotalUnread] = useState(0);
  const { user } = useAuth();

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  useEffect(() => {
    if (!user) return;

    const chatsRef = collection(db, 'chats');
    const q = query(
      chatsRef,
      where('participants', 'array-contains', user.uid)
    );

    const unsubscribe = onSnapshot(q, async (snapshot) => {
      try {
        const chatPromises = snapshot.docs.map(async (doc) => {
          const chatData = doc.data();
          const chatRoom = {
            id: doc.id,
            ...chatData,
            lastMessageTimestamp: chatData.lastMessageTimestamp?.toDate(),
            participantInfo: {},
            unreadCount: 0,
            blockedBy: chatData.blockedBy || []
          } as ChatRoom;

          const messagesRef = collection(db, `chats/${doc.id}/messages`);
          const messagesSnapshot = await getDocs(messagesRef);
          const unreadCount = messagesSnapshot.docs.filter(doc => 
            !doc.data().readBy?.includes(user.uid)
          ).length;
          chatRoom.unreadCount = unreadCount;

          const otherParticipantId = chatRoom.participants.find(id => id !== user.uid);
          if (otherParticipantId) {
            const userRef = collection(db, 'users');
            const userQuery = query(userRef, where('uid', '==', otherParticipantId));
            const userSnapshot = await getDocs(userQuery);
            
            if (!userSnapshot.empty) {
              const userData = userSnapshot.docs[0].data();
              chatRoom.participantInfo = {
                [otherParticipantId]: {
                  name: userData.name || 'Unknown User',
                  username: userData.username || otherParticipantId,
                  photoURL: userData.photoURL || null
                }
              };
            }
          }

          return chatRoom;
        });

        const updatedChats = await Promise.all(chatPromises);
        const total = updatedChats.reduce((sum, chat) => sum + (chat.unreadCount || 0), 0);
        setTotalUnread(total);

        updatedChats.sort((a, b) => {
          const timeA = a.lastMessageTimestamp?.getTime() || 0;
          const timeB = b.lastMessageTimestamp?.getTime() || 0;
          return timeB - timeA;
        });

        setChats(updatedChats);
        setLoading(false);

        if (selectedChat && !updatedChats.find(chat => chat.id === selectedChat.id)) {
          onChatSelect(null);
        }
      } catch (error) {
        console.error('Error processing chat updates:', error);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [user, selectedChat]);

  const handleBlockUser = async (chatId: string, otherUserId: string, isBlocked: boolean) => {
    if (!user) return;

    const confirmMessage = isBlocked ? t('unblockConfirm') : t('blockConfirm');
    const confirmed = window.confirm(confirmMessage);
    if (!confirmed) return;

    try {
      const chatRef = doc(db, 'chats', chatId);
      await updateDoc(chatRef, {
        blockedBy: isBlocked 
          ? arrayRemove(user.uid)
          : arrayUnion(user.uid)
      });

      toast.success(isBlocked ? t('success.unblocked') : t('success.blocked'));
    } catch (error) {
      console.error('Error updating block status:', error);
      toast.error('Failed to update block status');
    }
  };

  const truncateMessage = (message: string) => {
    if (!message) return '';
    return message.length > 15 ? message.substring(0, 15) + '...' : message;
  };

  if (loading) {
    return (
      <div className="w-64 border-r border-gray-200 p-4 flex justify-center">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    );
  }

  return (
    <div className="w-64 border-r border-gray-200 overflow-y-auto">
      {chats.length === 0 ? (
        <div className="p-4 text-center text-gray-500">
          <MessageCircle className="h-8 w-8 mx-auto mb-2 text-gray-400" />
          <p>{t('noMessages')}</p>
          <p className="text-sm mt-1">{t('startChat')}</p>
        </div>
      ) : (
        chats.map((chat) => {
          const otherParticipantId = chat.participants.find(id => id !== user?.uid);
          const participantInfo = otherParticipantId ? chat.participantInfo?.[otherParticipantId] : null;
          const isBlocked = chat.blockedBy?.includes(user?.uid || '');

          return (
            <div
              key={chat.id}
              className={`relative p-4 text-left hover:bg-gray-50 ${
                selectedChat?.id === chat.id ? 'bg-blue-50' : ''
              } ${chat.unreadCount ? 'bg-blue-50/50' : ''}`}
            >
              <div className="flex items-center space-x-3">
                <div className="relative">
                  <img
                    src={participantInfo?.photoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${otherParticipantId}`}
                    alt={participantInfo?.name}
                    className="w-10 h-10 rounded-full"
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.src = `https://api.dicebear.com/7.x/avataaars/svg?seed=${otherParticipantId}`;
                    }}
                  />
                  {chat.unreadCount > 0 && (
                    <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
                      {chat.unreadCount}
                    </span>
                  )}
                </div>
                <div className="flex-1 min-w-0">
                  <div className="flex items-center justify-between">
                    <button
                      onClick={() => onChatSelect(chat)}
                      className="flex-1 text-left"
                    >
                      <p className={`font-medium truncate ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                        @{participantInfo?.username || 'Unknown'}
                      </p>
                      {chat.lastMessage && (
                        <>
                          <p className={`text-sm text-gray-500 truncate ${isDarkMode ? 'text-gray-400' : ''}`}>
                            {truncateMessage(chat.lastMessage)}
                          </p>
                          {chat.lastMessageTimestamp && (
                            <p className={`text-xs ${isDarkMode ? 'text-gray-500' : 'text-gray-400'}`}>
                              {formatDistanceToNow(chat.lastMessageTimestamp, { 
                                addSuffix: true,
                                locale: language === 'pt-BR' ? ptBR : undefined
                              })}
                            </p>
                          )}
                        </>
                      )}
                    </button>
                    <button
                      onClick={() => handleBlockUser(chat.id, otherParticipantId || '', isBlocked)}
                      className={`p-1 rounded-full transition-colors ${
                        isBlocked
                          ? isDarkMode 
                            ? 'text-red-400 hover:bg-red-900/30' 
                            : 'text-red-500 hover:bg-red-50'
                          : isDarkMode
                            ? 'text-gray-400 hover:bg-gray-700'
                            : 'text-gray-500 hover:bg-gray-100'
                      }`}
                      title={isBlocked ? t('unblockUser') : t('blockUser')}
                    >
                      {isBlocked ? (
                        <X className="h-4 w-4" />
                      ) : (
                        <Ban className="h-4 w-4" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}