import { collection, query, where, getDocs, limit } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { isUserBlocked } from './blocking/users';
import type { User } from '../types/user';

export async function searchUsers(searchTerm: string, currentUserId: string) {
  try {
    const usersRef = collection(db, 'users');
    const cleanTerm = searchTerm.toLowerCase().replace('@', '');

    const [usernameSnapshot, nameSnapshot] = await Promise.all([
      getDocs(
        query(
          usersRef,
          where('username', '>=', cleanTerm),
          where('username', '<=', cleanTerm + '\uf8ff'),
          limit(10)
        )
      ),
      getDocs(
        query(
          usersRef,
          where('name', '>=', cleanTerm),
          where('name', '<=', cleanTerm + '\uf8ff'),
          limit(10)
        )
      )
    ]);

    const results = new Map<string, User>();

    [...usernameSnapshot.docs, ...nameSnapshot.docs].forEach(doc => {
      if (!results.has(doc.id) && doc.id !== currentUserId) {
        const data = doc.data();
        if (data.username && data.name) {
          // Only include users that haven't been blocked
          if (!isUserBlocked(currentUserId, doc.id)) {
            results.set(doc.id, {
              uid: doc.id,
              name: data.name,
              username: data.username,
              photoURL: data.photoURL,
              bio: data.bio || '',
              allies: data.allies || [],
              createdAt: data.createdAt?.toDate() || new Date()
            });
          }
        }
      }
    });

    return Array.from(results.values());
  } catch (error) {
    console.error('Error searching users:', error);
    throw new Error('Failed to search users');
  }
}