import { useState, useEffect } from 'react';
import { Trophy, Calendar, Loader2, Star } from 'lucide-react';
import { getDailyWinners, getMonthlyWinners } from '../../utils/ranking/winners';
import { Link } from 'react-router-dom';
import { VerificationBadge } from '../VerificationBadge';

interface DailyWinnersProps {
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    dailyTitle: 'Ranking do Mês',
    monthlyTitle: 'Melhores do Mês',
    loading: 'Carregando vencedores...',
    noWinners: 'Nenhum vencedor ainda',
    likes: 'curtidas',
    monthlyScore: 'pontos no mês',
    date: 'Data',
    position: 'Posição',
    showMonthly: 'Ver Ranking Mensal',
    showDaily: 'Ver Ranking Diário'
  },
  'en': {
    dailyTitle: 'Monthly Ranking',
    monthlyTitle: 'Monthly Best',
    loading: 'Loading winners...',
    noWinners: 'No winners yet',
    likes: 'likes',
    monthlyScore: 'monthly points',
    date: 'Date',
    position: 'Position',
    showMonthly: 'View Monthly Ranking',
    showDaily: 'View Daily Ranking'
  }
};

export function DailyWinners({ isDarkMode = false, language = 'en' }: DailyWinnersProps) {
  const [winners, setWinners] = useState<any[]>([]);
  const [monthlyWinners, setMonthlyWinners] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [showMonthly, setShowMonthly] = useState(false);

  const t = (key: string) => {
    return translations[language as keyof typeof translations][key as keyof (typeof translations)['en']];
  };

  useEffect(() => {
    const loadWinners = async () => {
      try {
        setLoading(true);
        const [dailyData, monthlyData] = await Promise.all([
          getDailyWinners(),
          getMonthlyWinners()
        ]);
        setWinners(dailyData);
        setMonthlyWinners(monthlyData);
      } catch (error) {
        console.error('Error loading winners:', error);
      } finally {
        setLoading(false);
      }
    };

    loadWinners();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <Loader2 className={`h-8 w-8 animate-spin ${isDarkMode ? 'text-yellow-400' : 'text-yellow-500'}`} />
      </div>
    );
  }

  const displayWinners = showMonthly ? monthlyWinners : winners;

  if (displayWinners.length === 0) {
    return (
      <div className="text-center py-8">
        <Trophy className={`h-12 w-12 mx-auto mb-4 ${isDarkMode ? 'text-gray-600' : 'text-gray-400'}`} />
        <p className={isDarkMode ? 'text-gray-400' : 'text-gray-500'}>
          {t('noWinners')}
        </p>
      </div>
    );
  }

  return (
    <div className={`rounded-lg overflow-hidden ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
      <div className={`p-4 ${isDarkMode ? 'bg-yellow-900/20' : 'bg-yellow-50'} border-b ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
        <div className="flex items-center justify-between">
          <h2 className={`text-lg font-semibold flex items-center ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            <Trophy className={`h-5 w-5 mr-2 ${isDarkMode ? 'text-yellow-400' : 'text-yellow-500'}`} />
            {showMonthly ? t('monthlyTitle') : t('dailyTitle')}
          </h2>
          <button
            onClick={() => setShowMonthly(!showMonthly)}
            className={`px-3 py-1 rounded-full text-sm ${
              isDarkMode 
                ? 'bg-gray-700 text-white hover:bg-gray-600' 
                : 'bg-black text-white hover:bg-gray-800'
            }`}
          >
            {showMonthly ? t('showDaily') : t('showMonthly')}
          </button>
        </div>
      </div>

      <div className={`divide-y ${isDarkMode ? 'divide-gray-700' : 'divide-gray-200'}`}>
        {displayWinners.map((winner) => (
          <div 
            key={`${winner.userId}-${winner.date?.getTime()}`}
            className={`p-4 ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-50'} transition-colors`}
          >
            <div className="flex items-center space-x-4">
              <div className={`flex items-center justify-center w-8 h-8 rounded-full ${
                winner.position <= 3 
                  ? isDarkMode 
                    ? 'bg-yellow-900/30 text-yellow-400' 
                    : 'bg-yellow-100 text-yellow-600'
                  : isDarkMode
                    ? 'bg-gray-700 text-gray-300'
                    : 'bg-gray-100 text-gray-600'
              }`}>
                <span className="text-sm font-bold">#{winner.position}</span>
              </div>

              <Link 
                to={`/profile/${winner.username}`}
                className="flex-1 min-w-0"
              >
                <div className="flex items-center space-x-2">
                  <img
                    src={winner.photoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${winner.userId}`}
                    alt={winner.name}
                    className="w-10 h-10 rounded-full"
                  />
                  <div>
                    <div className="flex items-center space-x-2">
                      <span className={`font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                        {winner.name}
                      </span>
                      <VerificationBadge alliesCount={winner.alliesCount || 0} />
                    </div>
                    <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                      @{winner.username}
                    </p>
                  </div>
                </div>
              </Link>

              <div className="text-right">
                <div className="flex items-center space-x-1 text-red-500">
                  <span className="font-bold">
                    {showMonthly ? winner.monthlyScore : winner.likesCount}
                  </span>
                  <span className="text-sm">
                    {showMonthly ? t('monthlyScore') : t('likes')}
                  </span>
                </div>
                {!showMonthly && (
                  <div className="flex items-center space-x-1 text-sm">
                    <Calendar className={`h-4 w-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`} />
                    <span className={isDarkMode ? 'text-gray-400' : 'text-gray-500'}>
                      {winner.date.toLocaleDateString()}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}