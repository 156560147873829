import { useState, useEffect } from 'react';
import { collection, query, orderBy, onSnapshot, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from './AuthProvider';
import { formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Link } from 'react-router-dom';
import { Loader2, MessageSquare, Trash2 } from 'lucide-react';
import toast from 'react-hot-toast';

interface Comment {
  id: string;
  content: string;
  authorId: string;
  authorName: string;
  authorUsername: string;
  authorPhotoURL: string;
  timestamp: Date;
}

interface StoryCommentsProps {
  storyId: string;
  isVisible: boolean;
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    loading: 'Carregando comentários...',
    noComments: 'Nenhum comentário ainda',
    beFirst: 'Seja o primeiro a comentar!',
    deleteConfirm: 'Tem certeza que deseja excluir este comentário?',
    success: {
      deleted: 'Comentário excluído com sucesso'
    },
    errors: {
      deleteFailed: 'Falha ao excluir comentário'
    }
  },
  'en': {
    loading: 'Loading comments...',
    noComments: 'No comments yet',
    beFirst: 'Be the first to comment!',
    deleteConfirm: 'Are you sure you want to delete this comment?',
    success: {
      deleted: 'Comment deleted successfully'
    },
    errors: {
      deleteFailed: 'Failed to delete comment'
    }
  }
};

export function StoryComments({ storyId, isVisible, isDarkMode = false, language = 'en' }: StoryCommentsProps) {
  const [comments, setComments] = useState<Comment[]>([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const isAdmin = user?.email?.toLowerCase() === 'afonsoalcantara@outlook.com';

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  useEffect(() => {
    if (!isVisible) return;

    const commentsRef = collection(db, 'stories', storyId, 'comments');
    const q = query(commentsRef, orderBy('timestamp', 'desc'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const commentsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate() || new Date()
      })) as Comment[];

      setComments(commentsData);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [storyId, isVisible]);

  const handleDelete = async (commentId: string, commentAuthorId: string) => {
    if (!user || (!isAdmin && user.uid !== commentAuthorId)) return;

    const confirmDelete = window.confirm(t('deleteConfirm'));
    if (!confirmDelete) return;

    try {
      await deleteDoc(doc(db, 'stories', storyId, 'comments', commentId));
      toast.success(t('success.deleted'));
    } catch (error) {
      console.error('Error deleting comment:', error);
      toast.error(t('errors.deleteFailed'));
    }
  };

  if (!isVisible) return null;

  if (loading) {
    return (
      <div className="flex justify-center py-4">
        <Loader2 className={`h-6 w-6 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
      </div>
    );
  }

  return (
    <div className={`divide-y ${isDarkMode ? 'divide-gray-700' : 'divide-gray-200'}`}>
      {comments.length > 0 ? (
        comments.map((comment) => (
          <div key={comment.id} className={`p-4 ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-50'}`}>
            <div className="flex items-start space-x-3">
              <Link to={`/profile/${comment.authorUsername}`}>
                <img
                  src={comment.authorPhotoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${comment.authorId}`}
                  alt={comment.authorName}
                  className="h-8 w-8 rounded-full"
                />
              </Link>
              <div className="flex-1 min-w-0">
                <div className="flex items-baseline justify-between">
                  <div className="flex items-center space-x-2">
                    <Link 
                      to={`/profile/${comment.authorUsername}`}
                      className={`font-medium hover:underline ${isDarkMode ? 'text-white' : 'text-gray-900'}`}
                    >
                      {comment.authorName}
                    </Link>
                    <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                      {formatDistanceToNow(comment.timestamp, { 
                        addSuffix: true,
                        locale: language === 'pt-BR' ? ptBR : undefined 
                      })}
                    </span>
                  </div>
                  {(isAdmin || user?.uid === comment.authorId) && (
                    <button
                      onClick={() => handleDelete(comment.id, comment.authorId)}
                      className={`p-2 text-red-500 ${
                        isDarkMode ? 'hover:bg-red-900/30' : 'hover:bg-red-50'
                      } rounded-full transition-colors`}
                      title="Delete comment"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  )}
                </div>
                <p className={`mt-1 whitespace-pre-wrap break-words ${isDarkMode ? 'text-gray-300' : 'text-gray-900'}`}>
                  {comment.content}
                </p>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="text-center py-8">
          <MessageSquare className={`h-12 w-12 mx-auto mb-3 ${isDarkMode ? 'text-gray-600' : 'text-gray-400'}`} />
          <p className={isDarkMode ? 'text-gray-400' : 'text-gray-500'}>
            {t('noComments')}
          </p>
          <p className="text-sm mt-1">{t('beFirst')}</p>
        </div>
      )}
    </div>
  );
}