import { useState, useEffect } from 'react';
import { collection, query, where, orderBy, limit, getDocs, Timestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { RankingItem } from '../types/ranking';

// Cache duration reduced to 1 hour since we're dealing with 24h data
const CACHE_DURATION = 1 * 60 * 60 * 1000; // 1 hour in milliseconds
const RANKINGS_LIMIT = 100; // Increased from 20 to 100

let rankingCache: RankingItem[] | null = null;
let lastFetchTime = 0;

export function useRankingData() {
  const [rankings, setRankings] = useState<RankingItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchRankings = async () => {
      try {
        setLoading(true);
        setError(null);

        const now = Date.now();
        
        // Check if we need to reset the cache (every hour)
        const lastHour = new Date();
        lastHour.setHours(lastHour.getHours() - 1);
        
        // Force cache reset at the start of each hour
        if (lastFetchTime < lastHour.getTime()) {
          rankingCache = null;
        }

        // Use cache if available and within 1-hour window
        if (rankingCache && (now - lastFetchTime) < CACHE_DURATION) {
          setRankings(rankingCache);
          setLoading(false);
          return;
        }

        // Get start of current 24-hour period
        const twentyFourHoursAgo = new Date();
        twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);

        // Query posts from last 24 hours
        const postsRef = collection(db, 'posts');
        const postsQuery = query(
          postsRef,
          where('timestamp', '>=', Timestamp.fromDate(twentyFourHoursAgo)),
          orderBy('timestamp', 'desc')
        );

        const snapshot = await getDocs(postsQuery);
        
        // Aggregate likes by user
        const userLikes = new Map<string, {
          userId: string;
          name: string;
          username: string;
          photoURL: string | null;
          alliesCount: number;
          likesCount: number;
        }>();

        snapshot.docs.forEach(doc => {
          const post = doc.data();
          const likes = post.likes?.length || 0;
          
          if (likes > 0) {
            const userData = userLikes.get(post.authorId) || {
              userId: post.authorId,
              name: post.authorName,
              username: post.authorUsername.toLowerCase().replace('@', ''),
              photoURL: post.authorPhotoURL,
              alliesCount: post.allies?.length || 0,
              likesCount: 0
            };
            
            userData.likesCount += likes;
            userLikes.set(post.authorId, userData);
          }
        });

        // Convert to array and sort by likes
        const rankingsList = Array.from(userLikes.values())
          .sort((a, b) => b.likesCount - a.likesCount)
          .slice(0, RANKINGS_LIMIT);

        // Update cache and timestamp
        rankingCache = rankingsList;
        lastFetchTime = now;

        setRankings(rankingsList);
      } catch (error) {
        console.error('Error fetching rankings:', error);
        setError('Failed to load rankings');
      } finally {
        setLoading(false);
      }
    };

    fetchRankings();

    // Set up interval to refresh rankings every hour
    const interval = setInterval(fetchRankings, CACHE_DURATION);

    // Set up daily reset at midnight
    const now = new Date();
    const tomorrow = new Date(now);
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    const timeUntilMidnight = tomorrow.getTime() - now.getTime();

    const midnightReset = setTimeout(() => {
      rankingCache = null; // Reset cache at midnight
      fetchRankings(); // Fetch new rankings
    }, timeUntilMidnight);

    return () => {
      clearInterval(interval);
      clearTimeout(midnightReset);
    };
  }, []);

  return { rankings, loading, error };
}