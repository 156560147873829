import { useState, useRef, useEffect } from 'react';
import { MoreHorizontal } from 'lucide-react';
import { useAuth } from '../components/AuthProvider';

interface ProfileMenuProps {
  profileId: string;
  currentUserId: string | undefined;
  onBlock: () => void;
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    report: 'Denunciar'
  },
  'en': {
    report: 'Report'
  }
};

export function ProfileMenu({ profileId, currentUserId, onBlock, isDarkMode = false, language = 'en' }: ProfileMenuProps) {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const { user } = useAuth();
  const [currentLanguage, setCurrentLanguage] = useState(language);

  // Detect user's language on mount
  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setCurrentLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[currentLanguage as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleReport = () => {
    window.open(`https://poosting.com.br/report/index.html?profileId=${profileId}`, '_blank');
    setIsOpen(false);
  };

  return (
    <div className="relative" ref={menuRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`p-2 ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'} rounded-full transition-colors`}
        title="More options"
      >
        <MoreHorizontal className={`h-5 w-5 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`} />
      </button>

      {isOpen && (
        <div className={`absolute right-0 mt-1 w-48 ${
          isDarkMode ? 'bg-gray-800 ring-1 ring-black ring-opacity-5' : 'bg-white ring-1 ring-black ring-opacity-5'
        } rounded-lg shadow-lg z-50`}>
          <button
            onClick={handleReport}
            className={`flex items-center w-full px-4 py-2 text-sm ${
              isDarkMode ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-700 hover:bg-gray-50'
            }`}
          >
            {t('report')}
          </button>
        </div>
      )}
    </div>
  );
}