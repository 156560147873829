import { useState, useEffect } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Link } from 'react-router-dom';
import { MoreHorizontal, Loader2, Heart, Languages, MessageCircle, Send, Trash2, X } from 'lucide-react';
import { auth, db } from '../lib/firebase';
import { doc, deleteDoc, collection, query, where, getDocs, updateDoc, arrayUnion, arrayRemove, addDoc, serverTimestamp, onSnapshot } from 'firebase/firestore';
import { useAuth } from '../components/AuthProvider';
import { isContentBlocked, isUserBlocked } from '../utils/blocking';
import { VerificationBadge } from './VerificationBadge';
import { containsBlockedWords } from '../utils/content/filter';
import toast from 'react-hot-toast';

interface CommentProps {
  id: string;
  postId: string;
  content: string;
  authorId: string;
  authorName: string;
  authorUsername: string;
  authorPhotoURL?: string;
  timestamp: Date;
  onDelete: () => void;
  allies?: string[];
  likes?: string[];
  isDarkMode?: boolean;
}

interface Reply {
  id: string;
  content: string;
  authorId: string;
  authorName: string;
  authorUsername: string;
  authorPhotoURL?: string;
  timestamp: Date;
  likes: string[];
}

const translations = {
  'pt-BR': {
    reply: 'Responder',
    replies: 'respostas',
    translate: 'Traduzir',
    translating: 'Traduzindo...',
    delete: {
      button: 'Excluir comentário',
      confirm: 'Tem certeza que deseja excluir este comentário?',
      adminButton: 'Excluir comentário (Admin)',
      success: 'Comentário excluído com sucesso'
    },
    deleteReply: {
      button: 'Excluir resposta',
      confirm: 'Tem certeza que deseja excluir esta resposta?',
      adminButton: 'Excluir resposta (Admin)', 
      success: 'Resposta excluída com sucesso'
    },
    errors: {
      deleteError: 'Falha ao excluir',
      replyError: 'Falha ao adicionar resposta',
      likeError: 'Falha ao curtir comentário',
      blockedWord: 'Sua mensagem contém uma palavra bloqueada: {word}'
    },
    writeReply: 'Escreva uma resposta...',
    posting: 'Postando...',
    show: 'Mostrar',
    hide: 'Ocultar',
    showReplies: 'Mostrar respostas',
    hideReplies: 'Ocultar respostas', 
    characters: 'caracteres',
    linksNotAllowed: 'Links não são permitidos em respostas',
    replyTooLong: 'Resposta deve ter no máximo 120 caracteres',
    pasteNotAllowed: 'Não é permitido colar texto em respostas',
    success: {
      replyAdded: 'Resposta adicionada com sucesso'
    },
    timeAgo: {
      lessThanAMinute: 'menos de um minuto atrás',
      oneMinute: '1 minuto atrás',
      minutes: 'minutos atrás',
      oneHour: '1 hora atrás',
      hours: 'horas atrás',
      oneDay: '1 dia atrás',
      days: 'dias atrás',
      oneWeek: '1 semana atrás',
      weeks: 'semanas atrás',
      oneMonth: '1 mês atrás',
      months: 'meses atrás',
      oneYear: '1 ano atrás',
      years: 'anos atrás'
    }
  },
  'en': {
    reply: 'Reply',
    replies: 'replies',
    translate: 'Translate',
    translating: 'Translating...',
    delete: {
      button: 'Delete comment',
      confirm: 'Are you sure you want to delete this comment?',
      adminButton: 'Delete comment (Admin)',
      success: 'Comment deleted successfully'
    },
    deleteReply: {
      button: 'Delete reply',
      confirm: 'Are you sure you want to delete this reply?',
      adminButton: 'Delete reply (Admin)',
      success: 'Reply deleted successfully'
    },
    errors: {
      deleteError: 'Failed to delete',
      replyError: 'Failed to add reply',
      likeError: 'Failed to like comment',
      blockedWord: 'Your message contains a blocked word: {word}'
    },
    writeReply: 'Write a reply...',
    posting: 'Posting...',
    show: 'Show',
    hide: 'Hide',
    showReplies: 'Show replies',
    hideReplies: 'Hide replies',
    characters: 'characters',
    linksNotAllowed: 'Links are not allowed in replies',
    replyTooLong: 'Reply must be 120 characters or less',
    pasteNotAllowed: 'Pasting text is not allowed in replies',
    success: {
      replyAdded: 'Reply added successfully'
    },
    timeAgo: {
      lessThanAMinute: 'less than a minute ago',
      oneMinute: '1 minute ago',
      minutes: 'minutes ago',
      oneHour: '1 hour ago',
      hours: 'hours ago',
      oneDay: '1 day ago',
      days: 'days ago',
      oneWeek: '1 week ago',
      weeks: 'weeks ago',
      oneMonth: '1 month ago',
      months: 'months ago',
      oneYear: '1 year ago',
      years: 'years ago'
    }
  }
};

export function Comment({
  id,
  postId,
  content,
  authorId,
  authorName,
  authorUsername,
  authorPhotoURL,
  timestamp,
  onDelete,
  allies = [],
  likes = [],
  isDarkMode = false
}: CommentProps) {
  const [isHidden, setIsHidden] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [isLiking, setIsLiking] = useState(false);
  const [likeCount, setLikeCount] = useState(likes.length);
  const [isLiked, setIsLiked] = useState(likes.includes(auth.currentUser?.uid || ''));
  const [showTranslation, setShowTranslation] = useState(false);
  const [translatedContent, setTranslatedContent] = useState('');
  const [translating, setTranslating] = useState(false);
  const [shouldShowTranslate, setShouldShowTranslate] = useState(false);
  const [showReplyInput, setShowReplyInput] = useState(false);
  const [replyContent, setReplyContent] = useState('');
  const [submittingReply, setSubmittingReply] = useState(false);
  const [replies, setReplies] = useState<Reply[]>([]);
  const [showReplies, setShowReplies] = useState(false);
  const [language, setLanguage] = useState('en');
  const { user: currentUser } = useAuth();

  const isAdmin = currentUser?.email?.toLowerCase() === 'afonsoalcantara@outlook.com';

  useEffect(() => {
    const userLanguage = navigator.language;
    if (userLanguage.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  const formatTimeAgo = (date: Date) => {
    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInWeeks = Math.floor(diffInDays / 7);
    const diffInMonths = Math.floor(diffInDays / 30);
    const diffInYears = Math.floor(diffInDays / 365);

    if (diffInSeconds < 60) {
      return t('timeAgo.lessThanAMinute');
    } else if (diffInMinutes === 1) {
      return t('timeAgo.oneMinute');
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} ${t('timeAgo.minutes')}`;
    } else if (diffInHours === 1) {
      return t('timeAgo.oneHour');
    } else if (diffInHours < 24) {
      return `${diffInHours} ${t('timeAgo.hours')}`;
    } else if (diffInDays === 1) {
      return t('timeAgo.oneDay');
    } else if (diffInDays < 7) {
      return `${diffInDays} ${t('timeAgo.days')}`;
    } else if (diffInWeeks === 1) {
      return t('timeAgo.oneWeek');
    } else if (diffInWeeks < 4) {
      return `${diffInWeeks} ${t('timeAgo.weeks')}`;
    } else if (diffInMonths === 1) {
      return t('timeAgo.oneMonth');
    } else if (diffInMonths < 12) {
      return `${diffInMonths} ${t('timeAgo.months')}`;
    } else if (diffInYears === 1) {
      return t('timeAgo.oneYear');
    } else {
      return `${diffInYears} ${t('timeAgo.years')}`;
    }
  };

  useEffect(() => {
    if (!currentUser) return;

    const contentBlocked = isContentBlocked(currentUser.uid, id);
    const authorBlocked = isUserBlocked(currentUser.uid, authorId);
    setIsHidden(contentBlocked || authorBlocked);
  }, [currentUser, id, authorId]);

  useEffect(() => {
    const detectLanguage = async () => {
      if (!content.trim()) return;

      try {
        const detectUrl = `https://translate.googleapis.com/translate_a/single?client=gtx&sl=auto&tl=${navigator.language.split('-')[0]}&dt=t&q=${encodeURIComponent(content)}`;
        const response = await fetch(detectUrl);
        const data = await response.json();
        const detectedLanguage = data[2];
        setShouldShowTranslate(detectedLanguage !== navigator.language.split('-')[0]);
      } catch (error) {
        console.error('Language detection error:', error);
      }
    };

    detectLanguage();
  }, [content]);

  useEffect(() => {
    if (!showReplies) return;

    const repliesRef = collection(db, 'posts', postId, 'comments', id, 'replies');
    const q = query(repliesRef, where('timestamp', '!=', null));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const repliesData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate() || new Date()
      })) as Reply[];

      repliesData.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
      setReplies(repliesData);
    });

    return () => unsubscribe();
  }, [postId, id, showReplies]);

  const handleLike = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!currentUser || !currentUser.uid || isLiking) return;

    try {
      setIsLiking(true);
      const commentRef = doc(db, 'posts', postId, 'comments', id);
      const isLiked = likes.includes(currentUser.uid);

      await updateDoc(commentRef, {
        likes: isLiked ? arrayRemove(currentUser.uid) : arrayUnion(currentUser.uid)
      });

      if (!isLiked && currentUser.uid !== authorId) {
        await addDoc(collection(db, 'notifications'), {
          type: 'comment_like',
          postId,
          commentId: id,
          recipientId: authorId,
          senderId: currentUser.uid,
          senderName: currentUser.displayName || '',
          senderUsername: currentUser.displayName?.replace('@', '') || '',
          senderPhotoURL: currentUser.photoURL,
          content,
          timestamp: serverTimestamp(),
          read: false
        });
      }

      setLikeCount(prev => isLiked ? prev - 1 : prev + 1);
      setIsLiked(!isLiked);
    } catch (error) {
      console.error('Error liking comment:', error);
      toast.error(t('errors.likeError'));
    } finally {
      setIsLiking(false);
    }
  };

  const handleDelete = async () => {
    if (!currentUser || (!isAdmin && currentUser.uid !== authorId) || deleting) return;

    const confirmDelete = window.confirm(t('delete.confirm'));
    if (!confirmDelete) return;

    setDeleting(true);

    try {
      const repliesRef = collection(db, 'posts', postId, 'comments', id, 'replies');
      const repliesSnapshot = await getDocs(repliesRef);
      await Promise.all(repliesSnapshot.docs.map(doc => deleteDoc(doc.ref)));

      await deleteDoc(doc(db, 'posts', postId, 'comments', id));
      onDelete();
      setShowMenu(false);
      toast.success(t('delete.success'));
    } catch (error) {
      console.error('Error deleting comment:', error);
      toast.error(t('errors.deleteError'));
    } finally {
      setDeleting(false);
    }
  };

  const handleDeleteReply = async (replyId: string, replyAuthorId: string) => {
    if (!currentUser || (!isAdmin && currentUser.uid !== replyAuthorId)) return;

    const confirmDelete = window.confirm(t('deleteReply.confirm'));
    if (!confirmDelete) return;

    try {
      await deleteDoc(doc(db, 'posts', postId, 'comments', id, 'replies', replyId));
      
      const notificationsRef = collection(db, 'notifications');
      const q = query(
        notificationsRef,
        where('type', '==', 'comment_reply'),
        where('replyId', '==', replyId)
      );
      
      const notificationDocs = await getDocs(q);
      await Promise.all(
        notificationDocs.docs.map(doc => deleteDoc(doc.ref))
      );

      toast.success(t('deleteReply.success'));
    } catch (error) {
      console.error('Error deleting reply:', error);
      toast.error(t('errors.deleteError'));
    }
  };

  const handleSubmitReply = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentUser || !replyContent.trim() || submittingReply) return;

    if (replyContent.length > 120) {
      toast.error(t('replyTooLong'));
      return;
    }

    const { isBlocked, blockedWord } = containsBlockedWords(replyContent);
    if (isBlocked) {
      toast.error(t('errors.blockedWord').replace('{word}', blockedWord || ''));
      return;
    }

    const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)|([a-zA-Z0-9-]+\.(com|net|org|br|io|dev|app|me|co|uk|us|ca|eu))/gi;
    if (urlRegex.test(replyContent)) {
      toast.error(t('linksNotAllowed'));
      return;
    }

    try {
      setSubmittingReply(true);

      const replyRef = await addDoc(collection(db, 'posts', postId, 'comments', id, 'replies'), {
        content: replyContent.trim(),
        authorId: currentUser.uid,
        authorName: currentUser.displayName || '',
        authorUsername: currentUser.displayName?.replace('@', '') || '',
        authorPhotoURL: currentUser.photoURL,
        timestamp: serverTimestamp(),
        likes: []
      });

      await addDoc(collection(db, 'notifications'), {
        type: 'comment_reply',
        postId,
        commentId: id,
        replyId: replyRef.id,
        recipientId: authorId,
        senderId: currentUser.uid,
        senderName: currentUser.displayName || '',
        senderUsername: currentUser.displayName?.replace('@', '') || '',
        senderPhotoURL: currentUser.photoURL,
        content: replyContent.trim(),
        timestamp: serverTimestamp(),
        read: false
      });

      setReplyContent('');
      setShowReplyInput(false);
      setShowReplies(true);
      toast.success(t('success.replyAdded'));
    } catch (error) {
      console.error('Error adding reply:', error);
      toast.error(t('errors.replyError'));
    } finally {
      setSubmittingReply(false);
    }
  };

  const handleTranslate = async () => {
    if (!content.trim()) return;

    try {
      setTranslating(true);
      const googleTranslateUrl = `https://translate.googleapis.com/translate_a/single?client=gtx&sl=auto&tl=${navigator.language.split('-')[0]}&dt=t&q=${encodeURIComponent(content)}`;
      const response = await fetch(googleTranslateUrl);
      const data = await response.json();
      const translatedText = data[0]
        .map((item: any[]) => item[0])
        .join(' ');
      
      setTranslatedContent(translatedText);
      setShowTranslation(true);
    } catch (error) {
      console.error('Translation error:', error);
    } finally {
      setTranslating(false);
    }
  };

  if (isHidden) {
    return null;
  }

  return (
    <div className={`px-4 py-3 ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-50'} transition-colors`}>
      <div className="flex items-start space-x-3">
        <Link to={`/profile/${authorUsername}`}>
          <img
            src={authorPhotoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${authorId}`}
            alt={authorName}
            className="h-8 w-8 rounded-full"
          />
        </Link>
        <div className="flex-1 min-w-0">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <Link 
                to={`/profile/${authorUsername}`}
                className={`font-medium hover:underline ${isDarkMode ? 'text-white' : 'text-gray-900'}`}
              >
                {authorName}
              </Link>
              <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                {formatTimeAgo(timestamp)}
              </span>
            </div>
            {(currentUser?.uid === authorId || isAdmin) && (
              <div className="relative">
                <button
                  onClick={() => setShowMenu(!showMenu)}
                  className={`p-1 ${isDarkMode ? 'hover:bg-gray-600' : 'hover:bg-gray-100'} rounded-full transition-colors`}
                  disabled={deleting}
                >
                  {deleting ? (
                    <Loader2 className={`h-4 w-4 animate-spin ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`} />
                  ) : (
                    <MoreHorizontal className={`h-4 w-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`} />
                  )}
                </button>
                {showMenu && (
                  <div className={`absolute bottom-full right-0 mb-2 w-48 rounded-md shadow-lg ${
                    isDarkMode 
                      ? 'bg-gray-800 ring-1 ring-black ring-opacity-5' 
                      : 'bg-white ring-1 ring-black ring-opacity-5'
                  } z-10`}>
                    <button
                      onClick={handleDelete}
                      disabled={deleting}
                      className={`block w-full text-left px-4 py-2 text-sm ${
                        isDarkMode 
                          ? 'text-red-400 hover:bg-gray-700' 
                          : 'text-red-600 hover:bg-gray-50'
                      } disabled:opacity-50`}
                    >
                      {isAdmin && currentUser.uid !== authorId ? t('delete.adminButton') : t('delete.button')}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
          
          <p className={`text-sm break-words whitespace-pre-wrap ${isDarkMode ? 'text-gray-300' : 'text-gray-900'}`}>
            {content}
          </p>

          <div className="flex items-center space-x-3 mt-1">
            <button
              onClick={handleLike}
              disabled={isLiking}
              className={`flex items-center space-x-1 ${
                isLiked 
                  ? 'text-red-500' 
                  : isDarkMode 
                    ? 'text-gray-400 hover:text-red-400' 
                    : 'text-gray-500 hover:text-red-500'
              } transition-colors disabled:opacity-50`}
            >
              <Heart 
                className={`h-4 w-4 ${isLiked ? 'fill-current' : ''}`} 
              />
              {likeCount > 0 && (
                <span className="text-xs">{likeCount}</span>
              )}
            </button>

            <button
              onClick={() => setShowReplyInput(!showReplyInput)}
              className={`flex items-center space-x-1 ${
                isDarkMode 
                  ? 'text-gray-400 hover:text-gray-300' 
                  : 'text-gray-500 hover:text-gray-600'
              }`}
            >
              <MessageCircle className="h-4 w-4" />
              <span className="text-xs">{t('reply')}</span>
              {replies.length > 0 && (
                <span className={`text-xs ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  ({replies.length})
                </span>
              )}
            </button>

            {shouldShowTranslate && (
              <button
                onClick={handleTranslate}
                disabled={translating}
                className={`inline-flex items-center space-x-1 px-2 py-1 rounded-full text-xs ${
                  isDarkMode 
                    ? 'bg-gray-700 text-gray-300 hover:bg-gray-600' 
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                } transition-colors disabled:opacity-50`}
              >
                <Languages className="h-3 w-3" />
                <span>{translating ? t('translating') : t('translate')}</span>
              </button>
            )}
          </div>

          {showTranslation && translatedContent && (
            <div className={`mt-2 p-2 rounded-lg text-sm ${
              isDarkMode ? 'bg-gray-700/50 text-gray-300' : 'bg-gray-50 text-gray-700'
            }`}>
              <p className="whitespace-pre-wrap break-words">{translatedContent}</p>
            </div>
          )}

          {showReplyInput && (
            <form 
              onSubmit={handleSubmitReply} 
              className="mt-4"
            >
              <div className="flex items-center space-x-2">
                <input
                  type="text"
                  value={replyContent}
                  onChange={(e) => setReplyContent(e.target.value)}
                  placeholder={t('writeReply')}
                  maxLength={120}
                  className={`flex-1 px-3 py-1.5 text-sm border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                    isDarkMode 
                      ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                      : 'border-gray-300 placeholder-gray-500'
                  }`}
                />
                <button
                  type="submit"
                  disabled={!replyContent.trim() || submittingReply || replyContent.length > 120}
                  className={`p-2 ${
                    isDarkMode 
                      ? 'bg-blue-600 text-white hover:bg-blue-700' 
                      : 'bg-blue-500 text-white hover:bg-blue-600'
                  } rounded-full disabled:opacity-50`}
                >
                  {submittingReply ? (
                    <Loader2 className="h-4 w-4 animate-spin" />
                  ) : (
                    <Send className="h-4 w-4" />
                  )}
                </button>
              </div>
              <div className={`text-xs mt-1 ${
                replyContent.length > 120 
                  ? 'text-red-500' 
                  : isDarkMode 
                    ? 'text-gray-400' 
                    : 'text-gray-500'
              }`}>
                {replyContent.length}/120 {t('characters')}
              </div>
            </form>
          )}

          {replies.length > 0 && (
            <div className="mt-2">
              <button
                onClick={() => setShowReplies(!showReplies)}
                className={`text-sm ${
                  isDarkMode 
                    ? 'text-blue-400 hover:text-blue-300' 
                    : 'text-blue-500 hover:text-blue-600'
                }`}
              >
                {showReplies ? t('hideReplies') : t('showReplies')} ({replies.length} {t('replies')})
              </button>

              {showReplies && (
                <div className={`mt-2 space-y-3 pl-4 border-l-2 ${
                  isDarkMode ? 'border-gray-600' : 'border-gray-200'
                }`}>
                  {replies.map((reply) => (
                    <div key={reply.id} className="flex items-start space-x-2">
                      <Link to={`/profile/${reply.authorUsername}`}>
                        <img
                          src={reply.authorPhotoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${reply.authorId}`}
                          alt={reply.authorName}
                          className="h-6 w-6 rounded-full"
                        />
                      </Link>
                      <div className="flex-1 min-w-0">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center space-x-2">
                            <Link 
                              to={`/profile/${reply.authorUsername}`}
                              className={`text-sm font-medium hover:underline ${
                                isDarkMode ? 'text-white' : 'text-gray-900'
                              }`}
                            >
                              {reply.authorName}
                            </Link>
                            <span className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                              {formatTimeAgo(reply.timestamp)}
                            </span>
                          </div>
                          {(isAdmin || currentUser?.uid === reply.authorId) && (
                            <button
                              onClick={() => handleDeleteReply(reply.id, reply.authorId)}
                              className={`p-1 rounded-full ${
                                isDarkMode 
                                  ? 'text-red-400 hover:bg-red-900/30' 
                                  : 'text-red-500 hover:bg-red-50'
                              } transition-colors`}
                              title={isAdmin && currentUser?.uid !== reply.authorId ? t('deleteReply.adminButton') : t('deleteReply.button')}
                            >
                              <Trash2 className="h-4 w-4" />
                            </button>
                          )}
                        </div>
                        <p className={`text-sm break-words whitespace-pre-wrap ${
                          isDarkMode ? 'text-gray-300' : 'text-gray-900'
                        }`}>
                          {reply.content}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}