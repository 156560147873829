import { PostVotes } from './PostVotes';
import { PostMenu } from './PostMenu';
import { useState } from 'react';
import { GiftGallery } from './GiftGallery';

interface PostFooterProps {
  id: string;
  authorId: string;
  likes: string[];
  stars: string[];
  trash: string[];
  matches: string[];
  onBlock: () => void;
  isDarkMode?: boolean;
  language?: string;
}

export function PostFooter({ id, authorId, likes, stars, trash, matches, onBlock, isDarkMode, language }: PostFooterProps) {
  const [showGiftGallery, setShowGiftGallery] = useState(false);

  return (
    <div className="flex items-center justify-between">
      <PostVotes
        postId={id}
        authorId={authorId}
        initialVotes={{ likes, stars, trash, matches }}
        onGiftClick={() => setShowGiftGallery(true)}
        language={language}
      />
      <PostMenu postId={id} authorId={authorId} onBlock={onBlock} />

      {showGiftGallery && (
        <GiftGallery
          postId={id}
          authorId={authorId}
          onClose={() => setShowGiftGallery(false)}
          isDarkMode={isDarkMode}
          language={language}
        />
      )}
    </div>
  );
}