// Helper function to chunk array into smaller arrays
export function chunkArray<T>(array: T[], size: number): T[][] {
  const chunks: T[][] = [];
  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size));
  }
  return chunks;
}

export function mergeNewPosts(existingPosts: any[], newPosts: any[]): any[] {
  // Create a map of existing posts by ID for quick lookup
  const existingPostsMap = new Map(existingPosts.map(post => [post.id, post]));
  
  // Filter out any new posts that we already have
  const uniqueNewPosts = newPosts.filter(post => !existingPostsMap.has(post.id));
  
  // Combine existing and unique new posts
  const allPosts = [...existingPosts, ...uniqueNewPosts];
  
  // Sort by timestamp descending (newest first)
  return allPosts.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
}

export function ensureUniqueKey(post: any): any {
  if (!post._key) {
    return {
      ...post,
      _key: `post-${post.id}-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`
    };
  }
  return post;
}