import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Gamepad2, ChevronRight, Wand2 as Snake, Square, Ghost, Grid, Hash, Compass, Layout, Target, Search, Rows as Gallows, Tangent as Tank, Car, Crown } from 'lucide-react';
import { useTheme } from '../contexts/ThemeContext';
import { MainMenu } from '../components/MainMenu';
import { SnakeGame } from '../components/SnakeGame';
import { TetrisGame } from '../components/TetrisGame';
import { PacmanGame } from '../components/PacmanGame';
import { MinesweeperGame } from '../components/MinesweeperGame';
import { MemoryGame } from '../components/MemoryGame';
import { TicTacToeGame } from '../components/TicTacToeGame';
import { MazeGame } from '../components/MazeGame';
import { UnoGame } from '../components/UnoGame';
import { RouletteGame } from '../components/RouletteGame';
import { WordSearchGame } from '../components/WordSearchGame';
import { HangmanGame } from '../components/HangmanGame';
import { BattleCityGame } from '../components/BattleCityGame';
import { RoadFighterGame } from '../components/RoadFighterGame';
import { CheckersGame } from '../components/CheckersGame';

const translations = {
  'pt-BR': {
    title: 'Poosting Games',
    availableGames: 'Jogos Disponíveis',
    startXbox: 'Iniciar Xbox',
    games: {
      checkers: {
        title: 'Jogo da Dama',
        description: 'Clássico jogo de damas'
      },
      battleCity: {
        title: 'Battle City',
        description: 'Clássico jogo de tanque de guerra'
      },
      roadFighter: {
        title: 'Road Fighter',
        description: 'Corrida clássica com obstáculos'
      },
      snake: {
        title: 'Snake',
        description: 'Clássico jogo da cobrinha'
      },
      tetris: {
        title: 'Tetris',
        description: 'O famoso jogo de blocos russo'
      },
      pacman: {
        title: 'Pac-Man',
        description: 'O famoso come-come'
      },
      minesweeper: {
        title: 'Campo Minado',
        description: 'O clássico jogo de encontrar minas'
      },
      memory: {
        title: 'Jogo da Memória',
        description: 'Encontre os pares de emojis'
      },
      tictactoe: {
        title: 'Jogo da Velha',
        description: 'O clássico jogo de X e O'
      },
      maze: {
        title: 'Labirinto',
        description: 'Encontre o caminho até a saída'
      },
      uno: {
        title: 'UNO',
        description: 'O famoso jogo de cartas'
      },
      roulette: {
        title: 'Roleta',
        description: 'Roleta de cassino'
      },
      wordSearch: {
        title: 'Caça Palavras',
        description: 'Encontre palavras escondidas'
      },
      hangman: {
        title: 'Jogo da Forca',
        description: 'Adivinhe a palavra antes que o boneco seja enforcado'
      }
    }
  },
  'en': {
    title: 'Poosting Games',
    availableGames: 'Available Games',
    startXbox: 'Start Xbox',
    games: {
      checkers: {
        title: 'Checkers',
        description: 'Classic checkers game'
      },
      battleCity: {
        title: 'Battle City',
        description: 'Classic tank battle game'
      },
      roadFighter: {
        title: 'Road Fighter',
        description: 'Classic racing game with obstacles'
      },
      snake: {
        title: 'Snake',
        description: 'Classic snake game'
      },
      tetris: {
        title: 'Tetris',
        description: 'The famous Russian block game'
      },
      pacman: {
        title: 'Pac-Man',
        description: 'The famous dot-eating maze game'
      },
      minesweeper: {
        title: 'Minesweeper',
        description: 'The classic mine finding game'
      },
      memory: {
        title: 'Memory Game',
        description: 'Find matching pairs of emojis'
      },
      tictactoe: {
        title: 'Tic Tac Toe',
        description: 'The classic X and O game'
      },
      maze: {
        title: 'Maze',
        description: 'Find your way to the exit'
      },
      uno: {
        title: 'UNO',
        description: 'The famous card game'
      },
      roulette: {
        title: 'Roulette',
        description: 'Casino roulette'
      },
      wordSearch: {
        title: 'Word Search',
        description: 'Find hidden words'
      },
      hangman: {
        title: 'Hangman',
        description: 'Guess the word before the man is hanged'
      }
    }
  }
};

type GameType = 'checkers' | 'battleCity' | 'roadFighter' | 'snake' | 'tetris' | 'pacman' | 'minesweeper' | 'memory' | 'tictactoe' | 'maze' | 'uno' | 'roulette' | 'wordSearch' | 'hangman';

const gameIcons = {
  checkers: Crown,
  battleCity: Tank,
  roadFighter: Car,
  snake: Snake,
  tetris: Square,
  pacman: Ghost,
  minesweeper: Grid,
  memory: Grid,
  tictactoe: Hash,
  maze: Compass,
  uno: Layout,
  roulette: Target,
  wordSearch: Search,
  hangman: Gallows
};

export function Games() {
  const [selectedGame, setSelectedGame] = useState<GameType | null>(null);
  const { isDarkMode } = useTheme();
  const [language, setLanguage] = useState(() => {
    return navigator.language.startsWith('pt') ? 'pt-BR' : 'en';
  });

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  const renderGame = () => {
    switch (selectedGame) {
      case 'checkers':
        return <CheckersGame isDarkMode={isDarkMode} />;
      case 'battleCity':
        return <BattleCityGame isDarkMode={isDarkMode} />;
      case 'roadFighter':
        return <RoadFighterGame isDarkMode={isDarkMode} />;
      case 'snake':
        return <SnakeGame isDarkMode={isDarkMode} />;
      case 'tetris':
        return <TetrisGame isDarkMode={isDarkMode} />;
      case 'pacman':
        return <PacmanGame isDarkMode={isDarkMode} />;
      case 'minesweeper':
        return <MinesweeperGame isDarkMode={isDarkMode} />;
      case 'memory':
        return <MemoryGame isDarkMode={isDarkMode} />;
      case 'tictactoe':
        return <TicTacToeGame isDarkMode={isDarkMode} />;
      case 'maze':
        return <MazeGame isDarkMode={isDarkMode} />;
      case 'uno':
        return <UnoGame isDarkMode={isDarkMode} />;
      case 'roulette':
        return <RouletteGame isDarkMode={isDarkMode} />;
      case 'wordSearch':
        return <WordSearchGame isDarkMode={isDarkMode} />;
      case 'hangman':
        return <HangmanGame isDarkMode={isDarkMode} />;
      default:
        return null;
    }
  };

  const games: GameType[] = [
    'checkers',
    'battleCity',
    'roadFighter',
    'snake',
    'tetris',
    'pacman',
    'minesweeper',
    'memory',
    'tictactoe',
    'maze',
    'uno',
    'roulette',
    'wordSearch',
    'hangman'
  ];

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <header className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b`}>
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <Gamepad2 className={`h-6 w-6 ${isDarkMode ? 'text-purple-400' : 'text-purple-500'}`} />
            <h1 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-black'} text-left`}>
              {selectedGame ? t(`games.${selectedGame}.title`) : t('title')}
            </h1>
          </div>
          {selectedGame && (
            <button
              onClick={() => setSelectedGame(null)}
              className={`px-4 py-2 rounded-full text-sm ${
                isDarkMode 
                  ? 'bg-gray-700 text-white hover:bg-gray-600' 
                  : 'bg-black text-white hover:bg-gray-800'
              }`}
            >
              ←
            </button>
          )}
        </div>
        <MainMenu />
      </header>

      <main className={`max-w-2xl mx-auto ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-x min-h-screen`}>
        {selectedGame ? (
          <div className="p-4">
            {renderGame()}
          </div>
        ) : (
          <div className="p-4">
            <div className="mb-6">
              <a
                href="https://www.xbox.com/pt-BR/play"
                target="_blank"
                rel="noopener noreferrer"
                className="w-full flex items-center justify-center px-6 py-3 bg-green-600 hover:bg-green-700 text-white font-medium rounded-lg transition-colors"
              >
                {t('startXbox')}
              </a>
            </div>

            <h2 className={`text-lg font-semibold mb-4 text-left ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              {t('availableGames')}
            </h2>
            <div className="space-y-4">
              {games.map((game) => {
                const Icon = gameIcons[game];
                return (
                  <button
                    key={game}
                    onClick={() => setSelectedGame(game)}
                    className={`w-full p-4 rounded-lg flex items-center justify-between ${
                      isDarkMode 
                        ? 'bg-gray-700 hover:bg-gray-600' 
                        : 'bg-gray-50 hover:bg-gray-100'
                    } transition-colors`}
                  >
                    <div className="flex items-center space-x-4">
                      <Icon className={`h-6 w-6 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`} />
                      <div className="text-left">
                        <h3 className={`text-lg font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                          {t(`games.${game}.title`)}
                        </h3>
                        <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                          {t(`games.${game}.description`)}
                        </p>
                      </div>
                    </div>
                    <ChevronRight className={`h-5 w-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`} />
                  </button>
                );
              })}
            </div>
          </div>
        )}
      </main>
    </div>
  );
}