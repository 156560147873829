import { useState, useEffect } from 'react';
import { Radio as RadioIcon, Loader2, ChevronLeft, ChevronRight, Search } from 'lucide-react';
import { auth } from '../lib/firebase';
import { MainMenu } from '../components/MainMenu';
import { RadioStation } from '../components/radio/RadioStation';
import { fetchRadioStations } from '../utils/radio/api';
import { useTheme } from '../contexts/ThemeContext';
import { useRadio } from '../contexts/RadioContext';
import toast from 'react-hot-toast';

const translations = {
  'pt-BR': {
    title: 'Poosting Live',
    searchPlaceholder: 'Buscar estações de rádio...',
    loading: 'Carregando...',
    noStations: 'Nenhuma estação encontrada',
    noResults: 'Nenhuma estação encontrada para "{query}"',
    searchRadio: 'Buscar Rádios',
    enterSearch: 'Digite algo para buscar estações',
    errors: {
      loadFailed: 'Falha ao carregar estações',
      searchFailed: 'Falha ao buscar estações'
    }
  },
  'en': {
    title: 'Poosting Live',
    searchPlaceholder: 'Search radio stations...',
    loading: 'Loading...',
    noStations: 'No stations found',
    noResults: 'No stations found for "{query}"',
    searchRadio: 'Search Radio',
    enterSearch: 'Enter something to search stations',
    errors: {
      loadFailed: 'Failed to load stations',
      searchFailed: 'Failed to search stations'
    }
  }
};

interface Station {
  name: string;
  url: string;
  favicon: string;
  countrycode: string;
  language: string;
  stationuuid: string;
}

export function Radio() {
  const [stations, setStations] = useState<Station[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredStations, setFilteredStations] = useState<Station[]>([]);
  const { isDarkMode } = useTheme();
  const { currentStation, isPlaying, playStation } = useRadio();
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string, params?: Record<string, string>) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    let text = value as string;
    if (params) {
      Object.entries(params).forEach(([param, replacement]) => {
        text = text.replace(`{${param}}`, replacement);
      });
    }
    
    return text;
  };

  useEffect(() => {
    loadStations();
  }, []);

  useEffect(() => {
    if (searchQuery) {
      const normalizedQuery = searchQuery.toLowerCase();
      const filtered = stations.filter(station => 
        (station.name || '').toLowerCase().includes(normalizedQuery) ||
        (station.countrycode || '').toLowerCase().includes(normalizedQuery) ||
        (station.language || '').toLowerCase().includes(normalizedQuery)
      );
      setFilteredStations(filtered);
    } else {
      setFilteredStations([]);
    }
  }, [searchQuery, stations]);

  const loadStations = async () => {
    try {
      setLoading(true);
      const data = await fetchRadioStations();
      setStations(data);
    } catch (error) {
      console.error('Error loading radio stations:', error);
      toast.error(t('errors.loadFailed'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <header className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b`}>
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <RadioIcon className={`h-6 w-6 ${isDarkMode ? 'text-purple-400' : 'text-purple-500'}`} />
            <h1 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-black'}`}>
              {t('title')}
            </h1>
          </div>
        </div>
        <div className="max-w-2xl mx-auto px-4 py-3">
          <div className="relative">
            <Search className={`absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-400'}`} />
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder={t('searchPlaceholder')}
              className={`w-full pl-10 pr-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-purple-500 ${
                isDarkMode 
                  ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                  : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
              }`}
            />
          </div>
        </div>
        <MainMenu />
      </header>

      <main className={`max-w-2xl mx-auto ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-x min-h-screen`}>
        {loading ? (
          <div className="flex justify-center items-center p-8">
            <Loader2 className={`h-8 w-8 animate-spin ${isDarkMode ? 'text-purple-400' : 'text-purple-500'}`} />
          </div>
        ) : (searchQuery ? filteredStations : stations).length > 0 ? (
          <div className="p-4 space-y-4">
            {(searchQuery ? filteredStations : stations).map((station) => (
              <RadioStation
                key={station.stationuuid}
                station={station}
                isPlaying={isPlaying}
                isCurrentStation={currentStation?.url === station.url}
                onClick={() => playStation(station)}
                isDarkMode={isDarkMode}
                language={language}
              />
            ))}
          </div>
        ) : (
          <div className="text-center py-8">
            <RadioIcon className={`h-12 w-12 mx-auto mb-4 ${isDarkMode ? 'text-gray-600' : 'text-gray-400'}`} />
            <p className={`text-lg font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              {searchQuery ? t('noResults', { query: searchQuery }) : t('noStations')}
            </p>
            {!searchQuery && (
              <p className={`mt-2 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                {t('enterSearch')}
              </p>
            )}
          </div>
        )}
      </main>
    </div>
  );
}