import { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import { Profile } from '../types/match';
import toast from 'react-hot-toast';

const translations = {
  'pt-BR': {
    loading: 'Estamos selecionando os perfis para você, aguarde...',
    errors: {
      searchFailed: 'Falha ao buscar perfis',
      dailyLimit: 'Você atingiu o limite diário de 50 perfis. Volte em {hours} horas para mais matches!',
      noValidProfiles: 'Nenhum perfil disponível no momento. Tente novamente mais tarde.'
    }
  },
  'en': {
    loading: 'We are selecting profiles for you, please wait...',
    errors: {
      searchFailed: 'Failed to fetch profiles',
      dailyLimit: 'Daily limit of 50 profiles reached. Come back in {hours} hours for more matches!',
      noValidProfiles: 'No profiles available at the moment. Please try again later.'
    }
  }
};

const PROFILES_LIMIT = 50;
const LIMIT_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
const MATCH_LIMIT_KEY = 'poosting_match_limit';

interface MatchLimit {
  count: number;
  timestamp: number;
  lastReset: number;
}

function getMatchLimit(): MatchLimit {
  try {
    const limit = localStorage.getItem(MATCH_LIMIT_KEY);
    if (limit) {
      const data = JSON.parse(limit);
      // Validate stored data to prevent tampering
      if (typeof data.count === 'number' && 
          typeof data.timestamp === 'number' &&
          typeof data.lastReset === 'number') {
        return data;
      }
    }
  } catch (error) {
    console.error('Error reading match limit:', error);
  }
  const now = Date.now();
  return { count: 0, timestamp: now, lastReset: now };
}

function updateMatchLimit() {
  const now = Date.now();
  let limit = getMatchLimit();

  // Check if 24 hours have passed since last reset
  if (now - limit.lastReset >= LIMIT_DURATION) {
    // Reset counter and update last reset time
    limit = { count: 1, timestamp: now, lastReset: now };
  } else {
    // Increment counter but maintain original reset time
    limit.count += 1;
    limit.timestamp = now;
  }

  localStorage.setItem(MATCH_LIMIT_KEY, JSON.stringify(limit));
  return limit;
}

function canViewMoreProfiles(): { allowed: boolean; timeLeft: number } {
  const limit = getMatchLimit();
  const now = Date.now();

  // If 24 hours have passed since last reset
  if (now - limit.lastReset >= LIMIT_DURATION) {
    return { allowed: true, timeLeft: 0 };
  }

  // If under limit, allow
  if (limit.count < PROFILES_LIMIT) {
    return { allowed: true, timeLeft: 0 };
  }

  // Calculate time left until reset
  const timeLeft = LIMIT_DURATION - (now - limit.lastReset);
  return { allowed: false, timeLeft };
}

export function useMatchProfiles() {
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string, params?: Record<string, string>) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }

    let text = value as string;
    if (params) {
      Object.entries(params).forEach(([param, replacement]) => {
        text = text.replace(`{${param}}`, replacement);
      });
    }
    
    return text;
  };

  useEffect(() => {
    if (!user) return;

    const fetchProfiles = async () => {
      try {
        setLoading(true);

        // Check daily limit
        const { allowed, timeLeft } = canViewMoreProfiles();
        if (!allowed) {
          const hoursLeft = Math.ceil(timeLeft / (1000 * 60 * 60));
          toast.error(t('errors.dailyLimit', { hours: hoursLeft.toString() }));
          setProfiles([]);
          setLoading(false);
          return;
        }

        const usersRef = collection(db, 'users');
        const q = query(
          usersRef,
          where('uid', '!=', user.uid)
        );
        
        const snapshot = await getDocs(q);
        const fetchedProfiles = snapshot.docs
          .map(doc => ({
            uid: doc.id,
            ...doc.data()
          } as Profile))
          .filter(profile => {
            if (!profile.name || !profile.username) return false;
            if (!profile.photoURL) return false;
            if (profile.photoURL.includes('dicebear.com')) return false;
            if (!profile.photoURL.includes('firebasestorage.googleapis.com')) return false;
            try {
              const url = new URL(profile.photoURL);
              return url.protocol === 'https:' && url.hostname.includes('firebasestorage.googleapis.com');
            } catch {
              return false;
            }
          });

        if (fetchedProfiles.length === 0) {
          toast.error(t('errors.noValidProfiles'));
          setLoading(false);
          return;
        }

        // Update limit counter
        updateMatchLimit();

        const shuffled = fetchedProfiles
          .sort(() => Math.random() - 0.5)
          .slice(0, PROFILES_LIMIT);

        setProfiles(shuffled);
      } catch (error) {
        console.error('Error fetching profiles:', error);
        toast.error(t('errors.searchFailed'));
      } finally {
        setLoading(false);
      }
    };

    fetchProfiles();
  }, [user, language]);

  return {
    profiles,
    loading,
    currentIndex,
    setCurrentIndex,
    loadingMessage: t('loading')
  };
}