import { createContext, useContext, useEffect, useState } from 'react';
import { auth, db } from '../lib/firebase';
import { User } from 'firebase/auth';
import { analyticsEvents } from '../lib/firebase';
import { isUserBlocked, checkForceLogout } from '../utils/admin/users';
import { doc, onSnapshot } from 'firebase/firestore';
import toast from 'react-hot-toast';

interface AuthContextType {
  user: User | null;
  loading: boolean;
}

export const AuthContext = createContext<AuthContextType>({ user: null, loading: true });

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

const translations = {
  'pt-BR': {
    blocked: 'Sua conta foi bloqueada. Entre em contato com o suporte para mais informações.',
    forcedLogout: 'Sua sessão foi encerrada por um administrador.'
  },
  'en': {
    blocked: 'Your account has been blocked. Please contact support for more information.',
    forcedLogout: 'Your session has been terminated by an administrator.'
  }
};

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    return translations[language as keyof typeof translations][key as keyof (typeof translations)['en']];
  };

  useEffect(() => {
    let userStatusUnsubscribe: (() => void) | null = null;

    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          // Initial check for blocked status
          const blocked = await isUserBlocked(user.uid);
          if (blocked) {
            await auth.signOut();
            toast.error(t('blocked'));
            setUser(null);
            setLoading(false);
            return;
          }

          // Set up real-time listener for user status
          const userRef = doc(db, 'users', user.uid);
          userStatusUnsubscribe = onSnapshot(userRef, async (doc) => {
            if (doc.exists()) {
              const userData = doc.data();
              if (userData.blocked || userData.forceLogout) {
                await auth.signOut();
                toast.error(userData.blocked ? t('blocked') : t('forcedLogout'));
                setUser(null);
              }
            }
          }, (error) => {
            console.error('Error in user status listener:', error);
          });

          analyticsEvents.login();
          setUser(user);
        } catch (error) {
          console.error('Error checking user status:', error);
          setUser(null);
        }
      } else {
        setUser(null);
        if (userStatusUnsubscribe) {
          userStatusUnsubscribe();
          userStatusUnsubscribe = null;
        }
      }
      setLoading(false);
    });

    return () => {
      unsubscribe();
      if (userStatusUnsubscribe) {
        userStatusUnsubscribe();
      }
    };
  }, [language]);

  const value = {
    user,
    loading
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}