import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Pause, Play, Loader2, Heart, Languages, MessageCircle, Send, Trash2, Eye, X, BadgeCheck, Star, Crown, Gift, DollarSign, RefreshCw, MousePointer } from 'lucide-react';
import { PostBoostModal } from './PostBoostModal';
import toast from 'react-hot-toast';

interface AdminBoostManagerProps {
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    title: 'Gerenciar Posts Impulsionados',
    loading: 'Carregando...',
    noBoosts: 'Nenhum post impulsionado encontrado',
    current: 'Atual',
    next: 'Próximo',
    views: 'visualizações',
    clicks: 'cliques',
    campaign: {
      start: 'Início:',
      end: 'Término:',
      paused: 'Pausado'
    }
  },
  'en': {
    title: 'Manage Boosted Posts',
    loading: 'Loading...',
    noBoosts: 'No boosted posts found',
    current: 'Current',
    next: 'Next',
    views: 'views',
    clicks: 'clicks',
    campaign: {
      start: 'Start:',
      end: 'End:',
      paused: 'Paused'
    }
  }
};

export function AdminBoostManager({ isDarkMode = false, language = 'en' }: AdminBoostManagerProps) {
  const [posts, setPosts] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [showBoostModal, setShowBoostModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState<any>(null);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  useEffect(() => {
    loadBoostedPosts();
  }, []);

  const loadBoostedPosts = async () => {
    try {
      setLoading(true);
      const postsRef = collection(db, 'posts');
      const q = query(
        postsRef,
        where('boosted', '==', true)
      );

      const snapshot = await getDocs(q);
      const postsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        boostConfig: {
          ...doc.data().boostConfig,
          startTime: doc.data().boostConfig?.startTime?.toDate() || new Date(),
          endTime: doc.data().boostConfig?.endTime?.toDate() || new Date()
        },
        views: doc.data().views || 0,
        clicks: doc.data().clicks || 0
      }));

      setPosts(postsData);
    } catch (error) {
      console.error('Error loading boosted posts:', error);
      toast.error('Failed to load boosted posts');
    } finally {
      setLoading(false);
    }
  };

  const handlePauseResume = async (postId: string, isPaused: boolean) => {
    try {
      const postRef = doc(db, 'posts', postId);
      await updateDoc(postRef, {
        'boostConfig.paused': !isPaused
      });

      setPosts(prev => prev.map(post => 
        post.id === postId 
          ? { ...post, boostConfig: { ...post.boostConfig, paused: !isPaused } }
          : post
      ));

      toast.success(isPaused ? 'Boost resumed' : 'Boost paused');
    } catch (error) {
      console.error('Error updating boost status:', error);
      toast.error('Failed to update status');
    }
  };

  const handleDelete = async (postId: string) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this boost? This action cannot be undone.');
    if (!confirmDelete) return;

    try {
      const postRef = doc(db, 'posts', postId);
      await updateDoc(postRef, {
        boosted: false,
        boostConfig: null
      });

      setPosts(prev => prev.filter(post => post.id !== postId));
      toast.success('Boost deleted successfully');
    } catch (error) {
      console.error('Error deleting boost:', error);
      toast.error('Failed to delete boost');
    }
  };

  const handleReconfigure = (post: any) => {
    setSelectedPost(post);
    setShowBoostModal(true);
  };

  const formatDateTime = (date: Date) => {
    return new Intl.DateTimeFormat(language, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };

  if (loading) {
    return (
      <div className="flex justify-center py-4">
        <Loader2 className={`h-8 w-8 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
      </div>
    );
  }

  return (
    <div className={`mt-12 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-lg overflow-hidden`}>
      <div className={`px-6 py-4 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'} border-b ${isDarkMode ? 'border-gray-600' : 'border-gray-200'}`}>
        <h2 className={`text-lg font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
          {t('title')}
        </h2>
      </div>

      <div className="divide-y divide-gray-200">
        {posts.map((post) => (
          <div 
            key={post.id} 
            className={`p-6 ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-50'} transition-colors`}
          >
            <div className="flex justify-between items-start">
              <div className="flex-1">
                <div className="flex items-center space-x-4 mb-4">
                  {post.imageUrl && (
                    <div className="flex-shrink-0">
                      <img
                        src={post.imageUrl}
                        alt="Post content"
                        className="w-24 h-24 object-cover rounded-lg"
                        loading="lazy"
                      />
                    </div>
                  )}
                  <div className="flex-1">
                    <h3 className={`font-medium mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                      {post.content}
                    </h3>
                    <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                      by {post.authorName}
                    </p>

                    {/* Campaign Dates */}
                    <div className={`mt-2 text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                      <p>
                        {t('campaign.start')} {formatDateTime(post.boostConfig.startTime)}
                      </p>
                      <p>
                        {t('campaign.end')} {formatDateTime(post.boostConfig.endTime)}
                      </p>
                      {post.boostConfig.paused && (
                        <p className="text-yellow-500 font-medium mt-1">
                          {t('campaign.paused')}
                        </p>
                      )}
                    </div>

                    {/* Metrics */}
                    <div className="mt-4 flex items-center space-x-6">
                      <div className="flex items-center space-x-1">
                        <Eye className={`h-4 w-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`} />
                        <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                          {post.views} {t('views')}
                        </span>
                      </div>
                      <div className="flex items-center space-x-1">
                        <MousePointer className={`h-4 w-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`} />
                        <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                          {post.clicks} {t('clicks')}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex space-x-2">
                <button
                  onClick={() => handleReconfigure(post)}
                  className={`p-2 rounded-full transition-colors ${
                    isDarkMode 
                      ? 'hover:bg-gray-600' 
                      : 'hover:bg-gray-100'
                  }`}
                  title="Reconfigure boost"
                >
                  <RefreshCw className={`h-5 w-5 ${
                    post.boosted 
                      ? 'text-green-500' 
                      : isDarkMode 
                        ? 'text-gray-400' 
                        : 'text-gray-600'
                  }`} />
                </button>

                <button
                  onClick={() => handlePauseResume(post.id, post.boostConfig.paused || false)}
                  className={`p-2 rounded-full ${
                    post.boostConfig.paused
                      ? isDarkMode 
                        ? 'bg-green-600 text-white hover:bg-green-700' 
                        : 'bg-green-500 text-white hover:bg-green-600'
                      : isDarkMode
                        ? 'bg-yellow-600 text-white hover:bg-yellow-700'
                        : 'bg-yellow-500 text-white hover:bg-yellow-600'
                  }`}
                  title={post.boostConfig.paused ? 'Resume boost' : 'Pause boost'}
                >
                  {post.boostConfig.paused ? (
                    <Play className="h-5 w-5" />
                  ) : (
                    <Pause className="h-5 w-5" />
                  )}
                </button>

                <button
                  onClick={() => handleDelete(post.id)}
                  className={`p-2 ${
                    isDarkMode 
                      ? 'text-red-400 hover:bg-red-900/30' 
                      : 'text-red-500 hover:bg-red-50'
                  } rounded-full transition-colors`}
                  title="Delete boost"
                >
                  <Trash2 className="h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
        ))}

        {posts.length === 0 && (
          <div className="p-8 text-center">
            <p className={isDarkMode ? 'text-gray-400' : 'text-gray-500'}>
              {t('noBoosts')}
            </p>
          </div>
        )}
      </div>

      {showBoostModal && selectedPost && (
        <PostBoostModal
          postId={selectedPost.id}
          onClose={() => {
            setShowBoostModal(false);
            setSelectedPost(null);
            loadBoostedPosts(); // Reload posts after reconfiguration
          }}
          isDarkMode={isDarkMode}
        />
      )}
    </div>
  );
}