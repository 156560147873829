import { useState } from 'react';
import { X, Loader2 } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import toast from 'react-hot-toast';

interface CreateTopicProps {
  onClose: () => void;
  onCreate: (title: string, content: string) => Promise<void>;
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    title: 'Criar Novo Tópico',
    form: {
      title: {
        label: 'Título do Tópico',
        placeholder: 'Digite um título claro e descritivo',
        required: 'Título é obrigatório',
        tooLong: 'Título deve ter no máximo 100 caracteres'
      },
      content: {
        label: 'Conteúdo do Tópico',
        placeholder: 'Escreva o conteúdo do seu tópico aqui. Você pode usar Markdown para formatação.',
        required: 'Conteúdo é obrigatório',
        tooLong: 'Conteúdo deve ter no máximo 120 caracteres'
      },
      preview: 'Visualizar',
      edit: 'Editar',
      characters: 'caracteres',
      supportsMarkdown: 'Suporta Markdown'
    },
    buttons: {
      cancel: 'Cancelar',
      create: 'Criar Tópico',
      creating: 'Criando...'
    },
    errors: {
      titleRequired: 'Por favor, insira um título',
      contentRequired: 'Por favor, insira o conteúdo',
      titleTooLong: 'Título deve ter no máximo 100 caracteres',
      contentTooLong: 'Conteúdo deve ter no máximo 120 caracteres',
      failedToCreate: 'Falha ao criar tópico',
      noLinks: 'Links não são permitidos no conteúdo'
    }
  },
  'en': {
    title: 'Create New Topic',
    form: {
      title: {
        label: 'Topic Title',
        placeholder: 'Enter a clear, descriptive title',
        required: 'Title is required',
        tooLong: 'Title must be 100 characters or less'
      },
      content: {
        label: 'Topic Content',
        placeholder: 'Write your topic content here. You can use Markdown for formatting.',
        required: 'Content is required',
        tooLong: 'Content must be 120 characters or less'
      },
      preview: 'Preview',
      edit: 'Edit',
      characters: 'characters',
      supportsMarkdown: 'Supports Markdown'
    },
    buttons: {
      cancel: 'Cancel',
      create: 'Create Topic',
      creating: 'Creating...'
    },
    errors: {
      titleRequired: 'Please enter a title',
      contentRequired: 'Please enter content',
      titleTooLong: 'Title must be 100 characters or less',
      contentTooLong: 'Content must be 120 characters or less',
      failedToCreate: 'Failed to create topic',
      noLinks: 'Links are not allowed in content'
    }
  }
};

export function CreateTopic({ onClose, onCreate, isDarkMode = false, language = 'en' }: CreateTopicProps) {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  const validateContent = (text: string): boolean => {
    // Check for URLs in content
    const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)|([a-zA-Z0-9-]+\.(com|net|org|br|io|dev|app|me|co|uk|us|ca|eu))/gi;
    return !urlRegex.test(text);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!title.trim() || !content.trim() || submitting) return;

    if (content.length > 120) {
      toast.error(t('errors.contentTooLong'));
      return;
    }

    if (!validateContent(content)) {
      toast.error(t('errors.noLinks'));
      return;
    }

    try {
      setSubmitting(true);
      await onCreate(title, content);
      onClose();
    } catch (error) {
      console.error('Error creating topic:', error);
      toast.error(t('errors.failedToCreate'));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className={`w-full max-w-2xl rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'} max-h-[90vh] overflow-y-auto`}>
        <div className={`flex justify-between items-center p-4 border-b sticky top-0 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'}`}>
          <h2 className={`text-xl font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            {t('title')}
          </h2>
          <button
            onClick={onClose}
            className={`p-2 ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'} rounded-full`}
          >
            <X className={`h-5 w-5 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`} />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-4 space-y-4">
          <div>
            <label htmlFor="title" className={`block text-sm font-medium mb-1 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
              {t('form.title.label')}
            </label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder={t('form.title.placeholder')}
              className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                isDarkMode 
                  ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                  : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
              }`}
              required
              maxLength={100}
            />
            <p className={`text-xs mt-1 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
              {title.length}/100 {t('form.characters')}
            </p>
          </div>

          <div>
            <div className="flex justify-between items-center mb-1">
              <label htmlFor="content" className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                {t('form.content.label')}
              </label>
              <button
                type="button"
                onClick={() => setShowPreview(!showPreview)}
                className={`text-sm ${isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-500 hover:text-blue-600'}`}
              >
                {showPreview ? t('form.edit') : t('form.preview')}
              </button>
            </div>
            
            {showPreview ? (
              <div className={`prose max-w-none p-3 border rounded-lg ${
                isDarkMode ? 'bg-gray-700 border-gray-600 prose-invert' : 'bg-gray-50 border-gray-200'
              } min-h-[120px]`}>
                <ReactMarkdown>{content}</ReactMarkdown>
              </div>
            ) : (
              <textarea
                id="content"
                value={content}
                onChange={(e) => setContent(e.target.value)}
                placeholder={t('form.content.placeholder')}
                className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none ${
                  isDarkMode 
                    ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                    : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                }`}
                rows={6}
                required
                maxLength={120}
              />
            )}
            <p className={`text-xs mt-1 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
              {content.length}/120 {t('form.characters')} • {t('form.supportsMarkdown')}
            </p>
          </div>

          <div className="flex justify-end space-x-3 pt-4">
            <button
              type="button"
              onClick={onClose}
              className={`px-4 py-2 ${
                isDarkMode 
                  ? 'text-gray-300 hover:bg-gray-700' 
                  : 'text-gray-700 hover:bg-gray-100'
              } rounded-lg`}
            >
              {t('buttons.cancel')}
            </button>
            <button
              type="submit"
              disabled={!title.trim() || !content.trim() || submitting}
              className={`px-4 py-2 text-white rounded-lg disabled:opacity-50 flex items-center space-x-2 ${
                isDarkMode 
                  ? 'bg-blue-600 hover:bg-blue-700' 
                  : 'bg-blue-500 hover:bg-blue-600'
              }`}
            >
              {submitting ? (
                <>
                  <Loader2 className="h-5 w-5 animate-spin" />
                  <span>{t('buttons.creating')}</span>
                </>
              ) : (
                <span>{t('buttons.create')}</span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}