import { useState, useEffect } from 'react';
import { Music as MusicIcon, Loader2, Search } from 'lucide-react';
import { auth } from '../lib/firebase';
import { MainMenu } from '../components/MainMenu';
import { useTheme } from '../contexts/ThemeContext';
import { spotify } from '../lib/spotify';
import { TrackList } from '../components/TrackList';
import { SpotifyTopTracks } from '../components/spotify/SpotifyTopTracks';
import { MusicTrendingVideos } from '../components/youtube/MusicTrendingVideos';
import toast from 'react-hot-toast';

const translations = {
  'pt-BR': {
    title: 'Poosting Música',
    searchPlaceholder: 'Buscar músicas...',
    loading: 'Carregando...',
    noResults: 'Nenhuma música encontrada',
    noResultsFor: 'Nenhuma música encontrada para',
    searchMusic: 'Buscar Música',
    enterSong: 'Digite o nome de uma música para buscar',
    errors: {
      spotifyError: 'Falha ao conectar ao Spotify',
      searchError: 'Falha ao buscar músicas',
      playbackError: 'Falha ao reproduzir prévia'
    }
  },
  'en': {
    title: 'Poosting Music',
    searchPlaceholder: 'Search for songs...',
    loading: 'Loading...',
    noResults: 'No songs found',
    noResultsFor: 'No songs found for',
    searchMusic: 'Search Music',
    enterSong: 'Enter a song name to search',
    errors: {
      spotifyError: 'Failed to connect to Spotify',
      searchError: 'Failed to search tracks',
      playbackError: 'Failed to play preview'
    }
  }
};

interface Track {
  id: string;
  name: string;
  artists: string[];
  album: {
    name: string;
    images: { url: string }[];
  };
  external_urls: {
    spotify: string;
  };
  preview_url?: string;
}

export function Music() {
  const [searchQuery, setSearchQuery] = useState('');
  const [tracks, setTracks] = useState<Track[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentlyPlayingId, setCurrentlyPlayingId] = useState<string | null>(null);
  const [audio] = useState(new Audio());
  const { isDarkMode } = useTheme();
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  useEffect(() => {
    spotify.authenticate().catch(error => {
      console.error('Spotify authentication error:', error);
      toast.error(t('errors.spotifyError'));
    });

    return () => {
      audio.pause();
      audio.src = '';
    };
  }, []);

  useEffect(() => {
    if (!searchQuery) {
      setTracks([]);
      return;
    }

    const searchTimer = setTimeout(async () => {
      try {
        setLoading(true);
        
        await spotify.authenticate();
        
        const response = await spotify.search(searchQuery, ['track'], undefined, 20);
        const searchResults = response.tracks.items
          .filter(track => track.preview_url);

        setTracks(searchResults);
      } catch (error) {
        console.error('Error searching tracks:', error);
        toast.error(t('errors.searchError'));
      } finally {
        setLoading(false);
      }
    }, 500);

    return () => clearTimeout(searchTimer);
  }, [searchQuery, language]);

  const handlePlayPause = (track: Track) => {
    if (currentlyPlayingId === track.id) {
      audio.pause();
      setCurrentlyPlayingId(null);
    } else {
      if (!track.preview_url) {
        window.open(track.external_urls.spotify, '_blank');
        return;
      }

      audio.src = track.preview_url;
      audio.play().catch(error => {
        console.error('Error playing preview:', error);
        toast.error(t('errors.playbackError'));
      });
      setCurrentlyPlayingId(track.id);
    }
  };

  audio.onended = () => setCurrentlyPlayingId(null);

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <header className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b`}>
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <MusicIcon className="h-6 w-6 text-green-500" />
            <h1 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-black'}`}>
              {t('title')}
            </h1>
          </div>
        </div>
        <div className="max-w-2xl mx-auto px-4 py-3">
          <div className="relative">
            <Search className={`absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-400'}`} />
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder={t('searchPlaceholder')}
              className={`w-full pl-10 pr-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-green-500 ${
                isDarkMode 
                  ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                  : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
              }`}
            />
          </div>
        </div>
        <MainMenu />
      </header>

      <main className={`max-w-2xl mx-auto ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-x min-h-screen`}>
        {/* Search Results */}
        {loading ? (
          <div className="flex justify-center py-8">
            <Loader2 className={`h-8 w-8 animate-spin ${isDarkMode ? 'text-green-400' : 'text-green-500'}`} />
          </div>
        ) : tracks.length > 0 ? (
          <div className="p-4">
            <h2 className={`text-lg font-semibold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              Search Results
            </h2>
            <TrackList
              tracks={tracks}
              currentlyPlayingId={currentlyPlayingId}
              onPlayPause={handlePlayPause}
              isDarkMode={isDarkMode}
              language={language}
            />
          </div>
        ) : searchQuery ? (
          <div className={`p-8 text-center ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
            {t('noResultsFor')} "{searchQuery}"
          </div>
        ) : null}

        {/* Billboard Hot 50 */}
        <SpotifyTopTracks />

        {/* Trending Music Videos */}
        <MusicTrendingVideos />
      </main>
    </div>
  );
}