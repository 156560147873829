import { useState, useEffect, useCallback } from 'react';
import { RotateCw, ChevronUp, ChevronDown, ChevronLeft, ChevronRight } from 'lucide-react';

interface Props {
  isDarkMode?: boolean;
}

const translations = {
  'pt-BR': {
    title: 'Tetris',
    score: 'Pontuação',
    highScore: 'Recorde',
    gameOver: 'Fim de Jogo!',
    newGame: 'Novo Jogo',
    paused: 'Pausado',
    resume: 'Continuar',
    restart: 'Reiniciar',
    controls: {
      title: 'Controles:',
      move: 'Mover: Setas',
      rotate: 'Girar: Seta para cima',
      drop: 'Soltar: Seta para baixo',
      pause: 'Pausar: P',
      tip: 'Complete linhas para marcar pontos!'
    }
  },
  'en': {
    title: 'Tetris',
    score: 'Score',
    highScore: 'High Score',
    gameOver: 'Game Over!',
    newGame: 'New Game',
    paused: 'Paused',
    resume: 'Resume',
    restart: 'Restart',
    controls: {
      title: 'Controls:',
      move: 'Move: Arrows',
      rotate: 'Rotate: Up Arrow',
      drop: 'Drop: Down Arrow',
      pause: 'Pause: P',
      tip: 'Complete lines to score points!'
    }
  }
};

const BOARD_WIDTH = 10;
const BOARD_HEIGHT = 20;
const CELL_SIZE = 25;
const HIGH_SCORE_KEY = 'tetris_high_score';

const TETROMINOS = {
  I: {
    shape: [[1, 1, 1, 1]],
    color: 'bg-cyan-500'
  },
  J: {
    shape: [
      [1, 0, 0],
      [1, 1, 1]
    ],
    color: 'bg-blue-500'
  },
  L: {
    shape: [
      [0, 0, 1],
      [1, 1, 1]
    ],
    color: 'bg-orange-500'
  },
  O: {
    shape: [
      [1, 1],
      [1, 1]
    ],
    color: 'bg-yellow-500'
  },
  S: {
    shape: [
      [0, 1, 1],
      [1, 1, 0]
    ],
    color: 'bg-green-500'
  },
  T: {
    shape: [
      [0, 1, 0],
      [1, 1, 1]
    ],
    color: 'bg-purple-500'
  },
  Z: {
    shape: [
      [1, 1, 0],
      [0, 1, 1]
    ],
    color: 'bg-red-500'
  }
};

type TetrominoType = keyof typeof TETROMINOS;

interface Piece {
  type: TetrominoType;
  position: { x: number; y: number };
  shape: number[][];
}

export function TetrisGame({ isDarkMode = false }: Props) {
  const [board, setBoard] = useState<(string | null)[][]>(
    Array(BOARD_HEIGHT).fill(null).map(() => Array(BOARD_WIDTH).fill(null))
  );
  const [currentPiece, setCurrentPiece] = useState<Piece | null>(null);
  const [score, setScore] = useState(0);
  const [highScore, setHighScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  // Load high score from localStorage
  useEffect(() => {
    const savedHighScore = localStorage.getItem(HIGH_SCORE_KEY);
    if (savedHighScore) {
      setHighScore(parseInt(savedHighScore));
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  const initializeGame = useCallback(() => {
    setBoard(Array(BOARD_HEIGHT).fill(null).map(() => Array(BOARD_WIDTH).fill(null)));
    setCurrentPiece(null);
    setScore(0);
    setGameOver(false);
    setIsPaused(false);
  }, []);

  useEffect(() => {
    initializeGame();
  }, [initializeGame]);

  const createNewPiece = useCallback(() => {
    const types = Object.keys(TETROMINOS) as TetrominoType[];
    const type = types[Math.floor(Math.random() * types.length)];
    return {
      type,
      position: { x: Math.floor(BOARD_WIDTH / 2) - 1, y: 0 },
      shape: TETROMINOS[type].shape
    };
  }, []);

  const isValidMove = useCallback((piece: Piece, offsetX: number, offsetY: number) => {
    return piece.shape.every((row, y) =>
      row.every((value, x) => {
        if (!value) return true;
        const newX = piece.position.x + x + offsetX;
        const newY = piece.position.y + y + offsetY;
        return (
          newX >= 0 &&
          newX < BOARD_WIDTH &&
          newY < BOARD_HEIGHT &&
          (newY < 0 || !board[newY][newX])
        );
      })
    );
  }, [board]);

  const rotatePiece = useCallback((piece: Piece) => {
    const rotatedShape = piece.shape[0].map((_, i) =>
      piece.shape.map(row => row[i]).reverse()
    );
    const newPiece = { ...piece, shape: rotatedShape };
    if (isValidMove(newPiece, 0, 0)) {
      setCurrentPiece(newPiece);
    }
  }, [isValidMove]);

  const mergePieceToBoard = useCallback((piece: Piece) => {
    const newBoard = board.map(row => [...row]);
    piece.shape.forEach((row, y) => {
      row.forEach((value, x) => {
        if (value) {
          const boardY = piece.position.y + y;
          if (boardY >= 0) {
            newBoard[boardY][piece.position.x + x] = TETROMINOS[piece.type].color;
          }
        }
      });
    });
    return newBoard;
  }, [board]);

  const clearLines = useCallback((board: (string | null)[][]) => {
    let linesCleared = 0;
    const newBoard = board.filter(row => {
      const isLineFull = row.every(cell => cell !== null);
      if (isLineFull) linesCleared++;
      return !isLineFull;
    });

    while (newBoard.length < BOARD_HEIGHT) {
      newBoard.unshift(Array(BOARD_WIDTH).fill(null));
    }

    if (linesCleared > 0) {
      const points = [0, 100, 300, 500, 800][linesCleared];
      const newScore = score + points;
      setScore(newScore);
      
      // Update high score if current score is higher
      if (newScore > highScore) {
        setHighScore(newScore);
        localStorage.setItem(HIGH_SCORE_KEY, newScore.toString());
      }
    }

    return newBoard;
  }, [score, highScore]);

  const moveDown = useCallback(() => {
    if (!currentPiece || gameOver || isPaused) return;

    if (isValidMove(currentPiece, 0, 1)) {
      setCurrentPiece({
        ...currentPiece,
        position: {
          ...currentPiece.position,
          y: currentPiece.position.y + 1
        }
      });
    } else {
      const newBoard = mergePieceToBoard(currentPiece);
      setBoard(clearLines(newBoard));

      const newPiece = createNewPiece();
      if (!isValidMove(newPiece, 0, 0)) {
        setGameOver(true);
      } else {
        setCurrentPiece(newPiece);
      }
    }
  }, [currentPiece, gameOver, isPaused, isValidMove, mergePieceToBoard, clearLines, createNewPiece]);

  const movePiece = useCallback((direction: 'left' | 'right') => {
    if (!currentPiece || gameOver || isPaused) return;
    const offset = direction === 'left' ? -1 : 1;
    if (isValidMove(currentPiece, offset, 0)) {
      setCurrentPiece({
        ...currentPiece,
        position: {
          ...currentPiece.position,
          x: currentPiece.position.x + offset
        }
      });
    }
  }, [currentPiece, gameOver, isPaused, isValidMove]);

  const dropPiece = useCallback(() => {
    if (!currentPiece || gameOver || isPaused) return;
    let dropDistance = 0;
    while (isValidMove(currentPiece, 0, dropDistance + 1)) {
      dropDistance++;
    }
    setCurrentPiece({
      ...currentPiece,
      position: {
        ...currentPiece.position,
        y: currentPiece.position.y + dropDistance
      }
    });
  }, [currentPiece, gameOver, isPaused, isValidMove]);

  useEffect(() => {
    if (!currentPiece && !gameOver) {
      setCurrentPiece(createNewPiece());
    }
  }, [currentPiece, gameOver, createNewPiece]);

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (gameOver) return;
      
      switch (e.key) {
        case 'ArrowLeft':
          movePiece('left');
          break;
        case 'ArrowRight':
          movePiece('right');
          break;
        case 'ArrowDown':
          moveDown();
          break;
        case 'ArrowUp':
          if (currentPiece) rotatePiece(currentPiece);
          break;
        case ' ':
          dropPiece();
          break;
        case 'p':
          setIsPaused(!isPaused);
          break;
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [currentPiece, gameOver, isPaused, moveDown, movePiece, rotatePiece, dropPiece]);

  useEffect(() => {
    if (gameOver || isPaused) return;
    
    const interval = setInterval(moveDown, 1000);
    return () => clearInterval(interval);
  }, [moveDown, gameOver, isPaused]);

  const resetGame = () => {
    setBoard(Array(BOARD_HEIGHT).fill(null).map(() => Array(BOARD_WIDTH).fill(null)));
    setCurrentPiece(null);
    setScore(0);
    setGameOver(false);
    setIsPaused(false);
  };

  return (
    <div className="flex flex-col items-center justify-center p-4">
      <div className={`mb-4 text-lg font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        <div className="flex justify-between items-center gap-8">
          <div>{t('score')}: {score}</div>
          <div>{t('highScore')}: {highScore}</div>
        </div>
        {gameOver && <div className="text-center mt-2">{t('gameOver')}</div>}
      </div>

      <div 
        className={`grid gap-0 ${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'}`}
        style={{
          gridTemplateColumns: `repeat(${BOARD_WIDTH}, ${CELL_SIZE}px)`,
          padding: '2px',
          borderRadius: '8px'
        }}
      >
        {board.map((row, y) =>
          row.map((cell, x) => {
            const isPieceCell = currentPiece?.shape.some((row, pieceY) =>
              row.some((value, pieceX) =>
                value === 1 &&
                pieceY + currentPiece.position.y === y &&
                pieceX + currentPiece.position.x === x
              )
            );

            return (
              <div
                key={`${x}-${y}`}
                className={`${
                  cell || (isPieceCell ? TETROMINOS[currentPiece.type].color : '')
                } ${
                  !cell && !isPieceCell
                    ? isDarkMode ? 'bg-gray-900' : 'bg-white'
                    : ''
                } border ${
                  isDarkMode ? 'border-gray-700' : 'border-gray-300'
                }`}
                style={{
                  width: CELL_SIZE,
                  height: CELL_SIZE
                }}
              />
            );
          })
        )}
      </div>

      {/* Mobile Controls */}
      <div className="mt-6 grid grid-cols-3 gap-4">
        <div />
        <button
          onClick={() => currentPiece && rotatePiece(currentPiece)}
          className={`p-3 rounded-full ${
            isDarkMode 
              ? 'bg-gray-700 text-white hover:bg-gray-600' 
              : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
          }`}
        >
          <ChevronUp className="h-6 w-6" />
        </button>
        <div />
        <button
          onClick={() => movePiece('left')}
          className={`p-3 rounded-full ${
            isDarkMode 
              ? 'bg-gray-700 text-white hover:bg-gray-600' 
              : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
          }`}
        >
          <ChevronLeft className="h-6 w-6" />
        </button>
        <button
          onClick={dropPiece}
          className={`p-3 rounded-full ${
            isDarkMode 
              ? 'bg-gray-700 text-white hover:bg-gray-600' 
              : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
          }`}
        >
          <ChevronDown className="h-6 w-6" />
        </button>
        <button
          onClick={() => movePiece('right')}
          className={`p-3 rounded-full ${
            isDarkMode 
              ? 'bg-gray-700 text-white hover:bg-gray-600' 
              : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
          }`}
        >
          <ChevronRight className="h-6 w-6" />
        </button>
      </div>

      {(gameOver || isPaused) && (
        <div 
          className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center"
          onClick={gameOver ? resetGame : () => setIsPaused(false)}
        >
          <div className="text-white text-center">
            <p className="text-xl font-bold mb-2">
              {gameOver ? t('gameOver') : t('paused')}
            </p>
            <p className="text-sm">
              {gameOver ? t('restart') : t('resume')}
            </p>
          </div>
        </div>
      )}

      <div className={`mt-4 text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
        <p className="font-medium">{t('controls.title')}</p>
        <p>{t('controls.move')}</p>
        <p>{t('controls.rotate')}</p>
        <p>{t('controls.drop')}</p>
        <p>{t('controls.pause')}</p>
        <p className="mt-2 italic">{t('controls.tip')}</p>
      </div>
    </div>
  );
}