import { useState, useEffect, useRef } from 'react';
import { X, Gift, Loader2 } from 'lucide-react';
import { collection, addDoc, getDocs, query, where, serverTimestamp, doc, getDoc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from './AuthProvider';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';

interface GiftGalleryProps {
  postId: string;
  authorId: string;
  onClose: () => void;
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    title: 'Enviar Presente',
    sending: 'Enviando presente...',
    success: 'Presente enviado com sucesso!',
    error: 'Falha ao enviar presente',
    alreadySent: 'Você já enviou um presente para esta postagem',
    giftSent: 'Presente enviado!',
    giftFailed: 'Falha ao enviar presente',
    confirmSend: 'Enviar presente para',
    sendGift: 'Enviar Presente'
  },
  'en': {
    title: 'Gift',
    sending: 'gift...',
    success: 'Gift Ok!',
    error: 'Error gift',
    alreadySent: '1 Gift Poost Ok!',
    giftSent: 'Gift Ok!',
    giftFailed: 'Error gift',
    confirmSend: 'Gift',
    sendGift: 'Gift'
  }
};

// Pre-define gift emojis array outside component to prevent recreation on each render
const GIFT_EMOJIS = [
  { id: 'cake', emoji: '🎂', name: 'Birthday Cake' },
  { id: 'gift', emoji: '🎁', name: 'Wrapped Gift' },
  { id: 'balloon', emoji: '🎈', name: 'Balloon' },
  { id: 'party', emoji: '🎉', name: 'Party Popper' },
  { id: 'confetti', emoji: '🎊', name: 'Confetti Ball' },
  { id: 'trophy', emoji: '🏆', name: 'Trophy' },
  { id: 'medal', emoji: '🏅', name: 'Medal' },
  { id: 'crown', emoji: '👑', name: 'Crown' },
  { id: 'champagne', emoji: '🍾', name: 'Champagne' },
  { id: 'fireworks', emoji: '🎆', name: 'Fireworks' },
  { id: 'red-heart', emoji: '❤️', name: 'Red Heart' },
  { id: 'pink-heart', emoji: '💗', name: 'Pink Heart' },
  { id: 'purple-heart', emoji: '💜', name: 'Purple Heart' },
  { id: 'yellow-heart', emoji: '💛', name: 'Yellow Heart' },
  { id: 'green-heart', emoji: '💚', name: 'Green Heart' },
  { id: 'blue-heart', emoji: '💙', name: 'Blue Heart' },
  { id: 'orange-heart', emoji: '🧡', name: 'Orange Heart' },
  { id: 'heart-sparkles', emoji: '💖', name: 'Sparkling Heart' },
  { id: 'heart-ribbon', emoji: '💝', name: 'Heart with Ribbon' },
  { id: 'rose', emoji: '🌹', name: 'Rose' },
  { id: 'sunflower', emoji: '🌻', name: 'Sunflower' },
  { id: 'cherry-blossom', emoji: '🌸', name: 'Cherry Blossom' },
  { id: 'tulip', emoji: '🌷', name: 'Tulip' },
  { id: 'bouquet', emoji: '💐', name: 'Bouquet' },
  { id: 'hibiscus', emoji: '🌺', name: 'Hibiscus' },
  { id: 'rainbow', emoji: '🌈', name: 'Rainbow' },
  { id: 'butterfly', emoji: '🦋', name: 'Butterfly' },
  { id: 'four-leaf', emoji: '🍀', name: 'Four Leaf Clover' },
  { id: 'sparkles', emoji: '✨', name: 'Sparkles' },
  { id: 'pizza', emoji: '🍕', name: 'Pizza' },
  { id: 'burger', emoji: '🍔', name: 'Hamburger' },
  { id: 'ice-cream', emoji: '🍦', name: 'Ice Cream' },
  { id: 'cake-slice', emoji: '🍰', name: 'Shortcake' },
  { id: 'donut', emoji: '🍩', name: 'Donut' },
  { id: 'coffee', emoji: '☕', name: 'Coffee' },
  { id: 'candy', emoji: '🍬', name: 'Candy' },
  { id: 'lollipop', emoji: '🍭', name: 'Lollipop' },
  { id: 'chocolate', emoji: '🍫', name: 'Chocolate Bar' },
  { id: 'popcorn', emoji: '🍿', name: 'Popcorn' },
  { id: 'sushi', emoji: '🍣', name: 'Sushi' },
  { id: 'ramen', emoji: '🍜', name: 'Ramen' },
  { id: 'taco', emoji: '🌮', name: 'Taco' },
  { id: 'wine', emoji: '🍷', name: 'Wine Glass' },
  { id: 'beer', emoji: '🍺', name: 'Beer Mug' },
  { id: 'cocktail', emoji: '🍸', name: 'Cocktail Glass' },
  { id: 'tropical-drink', emoji: '🍹', name: 'Tropical Drink' },
  { id: 'tea', emoji: '🫖', name: 'Teapot' },
  { id: 'croissant', emoji: '🥐', name: 'Croissant' },
  { id: 'cupcake', emoji: '🧁', name: 'Cupcake' },
  { id: 'sun', emoji: '☀️', name: 'Sun' },
  { id: 'moon', emoji: '🌙', name: 'Crescent Moon' },
  { id: 'star', emoji: '⭐', name: 'Star' },
  { id: 'maple-leaf', emoji: '🍁', name: 'Maple Leaf' },
  { id: 'palm-tree', emoji: '🌴', name: 'Palm Tree' },
  { id: 'snowflake', emoji: '❄️', name: 'Snowflake' },
  { id: 'cloud', emoji: '☁️', name: 'Cloud' },
  { id: 'lightning', emoji: '⚡', name: 'Lightning' },
  { id: 'umbrella', emoji: '☔', name: 'Umbrella with Rain' },
  { id: 'rainbow-sky', emoji: '🌤️', name: 'Sun Behind Cloud' },
  { id: 'unicorn', emoji: '🦄', name: 'Unicorn' },
  { id: 'cat', emoji: '🐱', name: 'Cat Face' },
  { id: 'dog', emoji: '🐶', name: 'Dog Face' },
  { id: 'panda', emoji: '🐼', name: 'Panda Face' },
  { id: 'penguin', emoji: '🐧', name: 'Penguin' },
  { id: 'koala', emoji: '🐨', name: 'Koala' },
  { id: 'dolphin', emoji: '🐬', name: 'Dolphin' },
  { id: 'owl', emoji: '🦉', name: 'Owl' },
  { id: 'hamster', emoji: '🐹', name: 'Hamster' },
  { id: 'rabbit', emoji: '🐰', name: 'Rabbit Face' },
  { id: 'musical-note', emoji: '🎵', name: 'Musical Note' },
  { id: 'musical-notes', emoji: '🎶', name: 'Musical Notes' },
  { id: 'microphone', emoji: '🎤', name: 'Microphone' },
  { id: 'headphones', emoji: '🎧', name: 'Headphones' },
  { id: 'guitar', emoji: '🎸', name: 'Guitar' },
  { id: 'piano', emoji: '🎹', name: 'Piano' },
  { id: 'drum', emoji: '🥁', name: 'Drum' },
  { id: 'saxophone', emoji: '🎷', name: 'Saxophone' },
  { id: 'violin', emoji: '🎻', name: 'Violin' },
  { id: 'trumpet', emoji: '🎺', name: 'Trumpet' },
  { id: 'soccer', emoji: '⚽', name: 'Soccer Ball' },
  { id: 'basketball', emoji: '🏀', name: 'Basketball' },
  { id: 'football', emoji: '🏈', name: 'American Football' },
  { id: 'tennis', emoji: '🎾', name: 'Tennis Ball' },
  { id: 'volleyball', emoji: '🏐', name: 'Volleyball' },
  { id: 'baseball', emoji: '⚾', name: 'Baseball' },
  { id: 'bowling', emoji: '🎳', name: 'Bowling' },
  { id: 'ping-pong', emoji: '🏓', name: 'Ping Pong' },
  { id: 'skateboard', emoji: '🛹', name: 'Skateboard' },
  { id: 'surfboard', emoji: '🏄', name: 'Surfing' },
  { id: 'game-controller', emoji: '🎮', name: 'Game Controller' },
  { id: 'video-game', emoji: '🎲', name: 'Game Die' },
  { id: 'smartphone', emoji: '📱', name: 'Smartphone' },
  { id: 'laptop', emoji: '💻', name: 'Laptop' },
  { id: 'camera', emoji: '📷', name: 'Camera' },
  { id: 'tv', emoji: '📺', name: 'Television' },
  { id: 'joystick', emoji: '🕹️', name: 'Joystick' },
  { id: 'keyboard', emoji: '⌨️', name: 'Keyboard' },
  { id: 'mouse', emoji: '🖱️', name: 'Computer Mouse' },
  { id: 'battery', emoji: '🔋', name: 'Battery' },
  { id: 'airplane', emoji: '✈️', name: 'Airplane' },
  { id: 'car', emoji: '🚗', name: 'Car' },
  { id: 'train', emoji: '🚂', name: 'Train' },
  { id: 'ship', emoji: '🚢', name: 'Ship' },
  { id: 'motorcycle', emoji: '🏍️', name: 'Motorcycle' },
  { id: 'bicycle', emoji: '🚲', name: 'Bicycle' },
  { id: 'rocket', emoji: '🚀', name: 'Rocket' },
  { id: 'helicopter', emoji: '🚁', name: 'Helicopter' },
  { id: 'compass', emoji: '🧭', name: 'Compass' },
  { id: 'crystal-ball', emoji: '🔮', name: 'Crystal Ball' },
  { id: 'genie-lamp', emoji: '🪔', name: 'Diya Lamp' },
  { id: 'dragon', emoji: '🐉', name: 'Dragon' },
  { id: 'fairy', emoji: '🧚', name: 'Fairy' },
  { id: 'mermaid', emoji: '🧜‍♀️', name: 'Mermaid' },
  { id: 'wizard', emoji: '🧙‍♂️', name: 'Wizard' },
  { id: 'castle', emoji: '🏰', name: 'Castle' },
  { id: 'shooting-star', emoji: '🌠', name: 'Shooting Star' },
  { id: 'magic-hat', emoji: '🎩', name: 'Top Hat' },
  { id: 'gem-stone', emoji: '💎', name: 'Gem Stone' },
  { id: 'ring', emoji: '💍', name: 'Ring' },
  { id: 'necklace', emoji: '📿', name: 'Prayer Beads' },
  { id: 'watch', emoji: '⌚', name: 'Watch' },
  { id: 'money-bag', emoji: '💰', name: 'Money Bag' },
  { id: 'credit-card', emoji: '💳', name: 'Credit Card' },
  { id: 'diamond', emoji: '💠', name: 'Diamond Shape' },
  { id: 'crown-gold', emoji: '👑', name: 'Gold Crown' },
  { id: 'high-heel', emoji: '👠', name: 'High-Heeled Shoe' },
  { id: 'handbag', emoji: '👜', name: 'Handbag' },
  { id: 'peace', emoji: '☮️', name: 'Peace Symbol' },
  { id: 'infinity', emoji: '♾️', name: 'Infinity' },
  { id: 'yin-yang', emoji: '☯️', name: 'Yin Yang' },
  { id: 'lucky-star', emoji: '🌟', name: 'Glowing Star' },
  { id: 'four-leaf-clover', emoji: '🍀', name: 'Four Leaf Clover' },
  { id: 'horseshoe', emoji: '🧲', name: 'Magnet' },
  { id: 'rainbow-flag', emoji: '🏳️‍🌈', name: 'Rainbow Flag' },
  { id: 'dove', emoji: '🕊️', name: 'Dove' },
  { id: 'angel', emoji: '👼', name: 'Baby Angel' },
  { id: 'christmas-tree', emoji: '🎄', name: 'Christmas Tree' },
  { id: 'santa', emoji: '🎅', name: 'Santa Claus' },
  { id: 'snowman', emoji: '⛄', name: 'Snowman' },
  { id: 'jack-o-lantern', emoji: '🎃', name: 'Jack-o-Lantern' },
  { id: 'ghost', emoji: '👻', name: 'Ghost' },
  { id: 'alien', emoji: '👽', name: 'Alien' },
  { id: 'robot', emoji: '🤖', name: 'Robot' },
  { id: 'astronaut', emoji: '👨‍🚀', name: 'Astronaut' },
  { id: 'scientist', emoji: '👨‍🔬', name: 'Scientist' },
  { id: 'artist', emoji: '👨‍🎨', name: 'Artist' },
  { id: 'farmer', emoji: '👨‍🌾', name: 'Farmer' },
  { id: 'chef', emoji: '👨‍🍳', name: 'Chef' },
  { id: 'teacher', emoji: '👨‍🏫', name: 'Teacher' },
  { id: 'mechanic', emoji: '👨‍🔧', name: 'Mechanic' },
  { id: 'firefighter', emoji: '👨‍🚒', name: 'Firefighter' },
  { id: 'police-officer', emoji: '👮', name: 'Police Officer' },
  { id: 'detective', emoji: '🕵️', name: 'Detective' },
  { id: 'doctor', emoji: '👨‍⚕️', name: 'Doctor' },
  { id: 'judge', emoji: '👨‍⚖️', name: 'Judge' },
  { id: 'pilot', emoji: '👨‍✈️', name: 'Pilot' },
  { id: 'sheep', emoji: '🐑', name: 'Sheep' }
];

interface AuthorProfile {
  name: string;
  username: string;
  photoURL: string;
  allies: string[];
}

export function GiftGallery({ postId, authorId, onClose, isDarkMode = false, language = 'en' }: GiftGalleryProps) {
  const [selectedGift, setSelectedGift] = useState<{ id: string; emoji: string; name: string } | null>(null);
  const [sending, setSending] = useState(false);
  const [authorProfile, setAuthorProfile] = useState<AuthorProfile | null>(null);
  const { user } = useAuth();

  // Memoize translations to prevent unnecessary re-renders
  const t = (key: string) => {
    return translations[language as keyof typeof translations][key as keyof (typeof translations)['en']];
  };

  // Load author profile only once on mount
  useEffect(() => {
    const fetchAuthorProfile = async () => {
      try {
        const userRef = doc(db, 'users', authorId);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const data = userDoc.data();
          setAuthorProfile({
            name: data.name,
            username: data.username,
            photoURL: data.photoURL,
            allies: data.allies || []
          });
        }
      } catch (error) {
        console.error('Error fetching author profile:', error);
      }
    };

    fetchAuthorProfile();
  }, [authorId]);

  const handleGiftSelect = (gift: { id: string; emoji: string; name: string }) => {
    setSelectedGift(gift);
  };

  const handleSendGift = async () => {
    if (!user || !selectedGift) return;

    try {
      setSending(true);

      // Check if user has already sent a gift to this post
      const giftsRef = collection(db, 'posts', postId, 'gifts');
      const q = query(giftsRef, where('senderId', '==', user.uid));
      const snapshot = await getDocs(q);

      if (!snapshot.empty) {
        toast.error(t('alreadySent'));
        return;
      }

      // Add gift to post's gifts collection
      await addDoc(giftsRef, {
        giftId: selectedGift.id,
        emoji: selectedGift.emoji,
        name: selectedGift.name,
        senderId: user.uid,
        senderName: user.displayName || '',
        senderUsername: user.displayName?.replace('@', '') || '',
        senderPhotoURL: user.photoURL,
        timestamp: serverTimestamp()
      });

      // Create notification for post author
      if (user.uid !== authorId) {
        await addDoc(collection(db, 'notifications'), {
          type: 'post_gift',
          postId,
          recipientId: authorId,
          senderId: user.uid,
          senderName: user.displayName || '',
          senderUsername: user.displayName?.replace('@', '') || '',
          senderPhotoURL: user.photoURL,
          giftEmoji: selectedGift.emoji,
          giftName: selectedGift.name,
          timestamp: serverTimestamp(),
          read: false
        });
      }

      toast.success(t('success'));
      onClose();
    } catch (error) {
      console.error('Error sending gift:', error);
      toast.error(t('error'));
    } finally {
      setSending(false);
    }
  };

  // Use CSS Grid for better performance with many items
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className={`w-full max-w-md rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'} max-h-[90vh] flex flex-col`}>
        <div className={`sticky top-0 z-10 p-4 border-b ${isDarkMode ? 'border-gray-700 bg-gray-800' : 'border-gray-200 bg-white'} flex justify-between items-center rounded-t-lg`}>
          <div className="flex items-center space-x-2">
            <Gift className={`h-5 w-5 ${isDarkMode ? 'text-pink-400' : 'text-pink-500'}`} />
            <h2 className={`text-lg font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              {t('title')}
            </h2>
          </div>
          <button
            onClick={onClose}
            className={`p-2 ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'} rounded-full`}
          >
            <X className={`h-5 w-5 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`} />
          </button>
        </div>

        <div className="flex-1 overflow-y-auto overflow-x-hidden">
          {selectedGift ? (
            <div className="p-4">
              {authorProfile && (
                <div className={`p-4 mb-4 rounded-lg ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
                  <div className="flex items-center space-x-4">
                    <Link to={`/profile/${authorProfile.username}`}>
                      <img
                        src={authorProfile.photoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${authorId}`}
                        alt={authorProfile.name}
                        className="w-12 h-12 rounded-full"
                      />
                    </Link>
                    <div className="flex-1 min-w-0">
                      <Link 
                        to={`/profile/${authorProfile.username}`}
                        className={`font-medium hover:underline ${isDarkMode ? 'text-white' : 'text-gray-900'}`}
                      >
                        {authorProfile.name}
                      </Link>
                      <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                        @{authorProfile.username}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div className="flex justify-center mb-4">
                <div className={`p-8 rounded-full ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
                  <span className="text-4xl">{selectedGift.emoji}</span>
                </div>
              </div>
              <button
                onClick={handleSendGift}
                disabled={sending}
                className={`w-full py-2 rounded-lg ${
                  isDarkMode 
                    ? 'bg-pink-600 text-white hover:bg-pink-700' 
                    : 'bg-pink-500 text-white hover:bg-pink-600'
                } disabled:opacity-50`}
              >
                {sending ? (
                  <Loader2 className="h-5 w-5 animate-spin mx-auto" />
                ) : (
                  t('sendGift')
                )}
              </button>
            </div>
          ) : (
            <div className="p-4">
              <div className="grid grid-cols-4 sm:grid-cols-6 gap-4">
                {GIFT_EMOJIS.map((gift) => (
                  <button
                    key={gift.id}
                    onClick={() => handleGiftSelect(gift)}
                    className={`aspect-square rounded-full ${
                      isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'
                    } flex items-center justify-center transition-transform hover:scale-105 group relative`}
                    style={{
                      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06)',
                      transform: 'translateZ(0)'
                    }}
                  >
                    <span className="text-3xl transform group-hover:scale-110 transition-transform">
                      {gift.emoji}
                    </span>
                    <span className={`absolute -bottom-6 left-1/2 -translate-x-1/2 text-xs text-center py-1 px-2 rounded-full ${
                      isDarkMode ? 'text-gray-400 bg-gray-700' : 'text-gray-600 bg-gray-100'
                    } opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap`}>
                      {gift.name}
                    </span>
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}