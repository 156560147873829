import { useState, useEffect } from 'react';
import { Video, Loader2, Play, X } from 'lucide-react';
import axios from 'axios';
import YouTube from 'react-youtube';
import { useLocalization } from '../../contexts/LocalizationContext';

const translations = {
  'pt-BR': {
    title: 'Trending Music Video',
    loading: 'Carregando vídeos musicais em alta...',
    backToVideos: 'Voltar aos vídeos',
    noResults: 'Nenhum vídeo encontrado',
    watchNow: 'Assistir agora'
  },
  'en': {
    title: 'Trending Music Videos',
    loading: 'Loading trending music videos...',
    backToVideos: 'Back to videos',
    noResults: 'No videos found',
    watchNow: 'Watch now'
  }
};

export function MusicTrendingVideos() {
  const [videos, setVideos] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);
  const { country } = useLocalization();
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    return translations[language as keyof typeof translations][key as keyof (typeof translations)['en']];
  };

  useEffect(() => {
    const fetchTrendingVideos = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          'https://www.googleapis.com/youtube/v3/search',
          {
            params: {
              part: 'snippet',
              type: 'video',
              videoCategoryId: '10', // Music category
              maxResults: 20,
              chart: 'mostPopular',
              regionCode: country,
              key: 'AIzaSyBuJT7El4mSrV6GjcknUnKvHbjrsSXuyf8'
            }
          }
        );

        const musicVideos = response.data.items
          .filter((item: any) => 
            item?.id?.videoId &&
            item?.snippet?.title &&
            item?.snippet?.channelTitle &&
            item?.snippet?.thumbnails?.high?.url
          );

        setVideos(musicVideos);
      } catch (error) {
        console.error('Error fetching trending music videos:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTrendingVideos();
  }, [country]);

  return (
    <div className="bg-black text-white p-6">
      <div className="max-w-2xl mx-auto">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl font-bold flex items-center">
            <Video className="h-6 w-6 text-red-500 mr-2" />
            {t('title')}
          </h2>
        </div>

        {loading ? (
          <div className="flex flex-col items-center justify-center py-12 space-y-4">
            <Loader2 className="h-8 w-8 animate-spin text-red-500" />
            <p className="text-gray-400">{t('loading')}</p>
          </div>
        ) : selectedVideo ? (
          <div className="mb-6">
            <button
              onClick={() => setSelectedVideo(null)}
              className="mb-4 flex items-center space-x-2 text-blue-400 hover:text-blue-300"
            >
              <span>← {t('backToVideos')}</span>
            </button>
            <div className="aspect-video mb-4">
              <YouTube
                videoId={selectedVideo}
                opts={{
                  width: '100%',
                  height: '100%',
                  playerVars: {
                    autoplay: 1,
                    modestbranding: 1,
                    rel: 0
                  },
                }}
                className="w-full h-full"
              />
            </div>
            {videos.find(v => v.id.videoId === selectedVideo)?.snippet && (
              <div className="space-y-2">
                <h3 className="text-xl font-bold">
                  {videos.find(v => v.id.videoId === selectedVideo)?.snippet.title}
                </h3>
                <p className="text-gray-400">
                  {videos.find(v => v.id.videoId === selectedVideo)?.snippet.channelTitle}
                </p>
              </div>
            )}
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-4">
            {videos.map((video) => (
              <div
                key={video.id.videoId}
                onClick={() => setSelectedVideo(video.id.videoId)}
                className="cursor-pointer group"
              >
                <div className="relative aspect-video rounded-lg overflow-hidden">
                  <img
                    src={video.snippet.thumbnails.high.url}
                    alt={video.snippet.title}
                    className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                    loading="lazy"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                    <div className="flex flex-col items-center space-y-2">
                      <Play className="h-12 w-12 text-white opacity-0 group-hover:opacity-100 transform group-hover:scale-110 transition-all duration-300" />
                      <span className="text-xs font-medium text-white bg-black/50 px-2 py-1 rounded-full">
                        {t('watchNow')}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mt-2 px-1">
                  <h3 className="text-sm font-medium line-clamp-2 group-hover:text-red-500 transition-colors">
                    {video.snippet.title}
                  </h3>
                  <p className="text-xs text-gray-400 mt-1">
                    {video.snippet.channelTitle}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}