import { useState, useEffect, useCallback } from 'react';
import { RotateCw, ChevronUp, ChevronDown, ChevronLeft, ChevronRight } from 'lucide-react';

interface Props {
  isDarkMode?: boolean;
}

const BOARD_SIZE = 8;
const CELL_SIZE = 50;

type Piece = {
  isKing: boolean;
  isRed: boolean;
} | null;

type Position = {
  row: number;
  col: number;
};

type Move = {
  from: Position;
  to: Position;
  jumped?: Position;
};

const translations = {
  'pt-BR': {
    title: 'Jogo da Dama',
    yourTurn: 'Sua vez',
    aiTurn: 'Vez da IA',
    youWin: 'Você Venceu! 🎉',
    aiWins: 'IA Venceu!',
    newGame: 'Novo Jogo',
    select: 'Selecionar',
    move: 'Mover',
    thinking: 'IA pensando...'
  },
  'en': {
    title: 'Checkers',
    yourTurn: 'Your Turn',
    aiTurn: 'AI\'s Turn',
    youWin: 'You Win! 🎉',
    aiWins: 'AI Wins!',
    newGame: 'New Game',
    select: 'Select',
    move: 'Move',
    thinking: 'AI thinking...'
  }
};

export function CheckersGame({ isDarkMode = false }: Props) {
  const [board, setBoard] = useState<(Piece | null)[][]>([]);
  const [selectedPiece, setSelectedPiece] = useState<Position | null>(null);
  const [isRedTurn, setIsRedTurn] = useState(true); // Player is red
  const [validMoves, setValidMoves] = useState<Position[]>([]);
  const [gameOver, setGameOver] = useState(false);
  const [language, setLanguage] = useState('en');
  const [cursorPosition, setCursorPosition] = useState<Position>({ row: 0, col: 0 });
  const [isAiThinking, setIsAiThinking] = useState(false);

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  const initializeBoard = useCallback(() => {
    const newBoard: (Piece | null)[][] = Array(BOARD_SIZE).fill(null)
      .map(() => Array(BOARD_SIZE).fill(null));

    // Place red pieces (player)
    for (let row = 0; row < 3; row++) {
      for (let col = 0; col < BOARD_SIZE; col++) {
        if ((row + col) % 2 === 1) {
          newBoard[row][col] = { isKing: false, isRed: true };
        }
      }
    }

    // Place black pieces (AI)
    for (let row = BOARD_SIZE - 3; row < BOARD_SIZE; row++) {
      for (let col = 0; col < BOARD_SIZE; col++) {
        if ((row + col) % 2 === 1) {
          newBoard[row][col] = { isKing: false, isRed: false };
        }
      }
    }

    return newBoard;
  }, []);

  const initializeGame = () => {
    setBoard(initializeBoard());
    setSelectedPiece(null);
    setValidMoves([]);
    setIsRedTurn(true);
    setGameOver(false);
    setCursorPosition({ row: 0, col: 0 });
    setIsAiThinking(false);
  };

  useEffect(() => {
    initializeGame();
  }, []);

  const getAllValidMoves = (piece: Position, currentBoard: (Piece | null)[][]): Move[] => {
    const moves: Move[] = [];
    const currentPiece = currentBoard[piece.row][piece.col];
    if (!currentPiece) return moves;

    const directions = currentPiece.isKing ? [-1, 1] : currentPiece.isRed ? [1] : [-1];

    // Check for jump moves first
    directions.forEach(dRow => {
      [-1, 1].forEach(dCol => {
        const jumpRow = piece.row + dRow * 2;
        const jumpCol = piece.col + dCol * 2;
        const midRow = piece.row + dRow;
        const midCol = piece.col + dCol;

        if (
          isValidPosition(jumpRow, jumpCol) &&
          !currentBoard[jumpRow][jumpCol] &&
          currentBoard[midRow][midCol] &&
          currentBoard[midRow][midCol]?.isRed !== currentPiece.isRed
        ) {
          moves.push({
            from: piece,
            to: { row: jumpRow, col: jumpCol },
            jumped: { row: midRow, col: midCol }
          });
        }
      });
    });

    // If no jumps are available, check for regular moves
    if (moves.length === 0) {
      directions.forEach(dRow => {
        [-1, 1].forEach(dCol => {
          const newRow = piece.row + dRow;
          const newCol = piece.col + dCol;
          
          if (isValidPosition(newRow, newCol) && !currentBoard[newRow][newCol]) {
            moves.push({
              from: piece,
              to: { row: newRow, col: newCol }
            });
          }
        });
      });
    }

    return moves;
  };

  const getAllPieceMoves = (isRedPieces: boolean, currentBoard: (Piece | null)[][]): Move[] => {
    const allMoves: Move[] = [];
    
    // First, look for any available jumps
    for (let row = 0; row < BOARD_SIZE; row++) {
      for (let col = 0; col < BOARD_SIZE; col++) {
        const piece = currentBoard[row][col];
        if (piece && piece.isRed === isRedPieces) {
          const moves = getAllValidMoves({ row, col }, currentBoard);
          const jumpMoves = moves.filter(move => move.jumped);
          if (jumpMoves.length > 0) {
            allMoves.push(...jumpMoves);
          }
        }
      }
    }

    // If no jumps are available, look for regular moves
    if (allMoves.length === 0) {
      for (let row = 0; row < BOARD_SIZE; row++) {
        for (let col = 0; col < BOARD_SIZE; col++) {
          const piece = currentBoard[row][col];
          if (piece && piece.isRed === isRedPieces) {
            const moves = getAllValidMoves({ row, col }, currentBoard);
            allMoves.push(...moves);
          }
        }
      }
    }

    return allMoves;
  };

  const evaluateBoard = (currentBoard: (Piece | null)[][]): number => {
    let score = 0;
    
    for (let row = 0; row < BOARD_SIZE; row++) {
      for (let col = 0; col < BOARD_SIZE; col++) {
        const piece = currentBoard[row][col];
        if (piece) {
          // Base piece values
          let value = piece.isKing ? 3 : 1;
          
          // Position-based bonuses
          if (!piece.isKing) {
            // Encourage pieces to advance
            const advancement = piece.isRed ? row : (BOARD_SIZE - 1 - row);
            value += advancement * 0.1;
          }
          
          // Edge protection bonus
          if (col === 0 || col === BOARD_SIZE - 1) {
            value += 0.2;
          }

          // Center control bonus
          if (col >= 2 && col <= 5 && row >= 2 && row <= 5) {
            value += 0.3;
          }

          score += piece.isRed ? value : -value;
        }
      }
    }

    return score;
  };

  const minimax = (
    currentBoard: (Piece | null)[][],
    depth: number,
    alpha: number,
    beta: number,
    isMaximizing: boolean
  ): { score: number; move?: Move } => {
    if (depth === 0) {
      return { score: evaluateBoard(currentBoard) };
    }

    const moves = getAllPieceMoves(!isMaximizing, currentBoard);
    if (moves.length === 0) {
      return { score: isMaximizing ? -1000 : 1000 };
    }

    let bestScore = isMaximizing ? -Infinity : Infinity;
    let bestMove: Move | undefined;

    for (const move of moves) {
      const newBoard = makeMove(currentBoard, move);
      const evaluation = minimax(newBoard, depth - 1, alpha, beta, !isMaximizing);

      if (isMaximizing) {
        if (evaluation.score > bestScore) {
          bestScore = evaluation.score;
          bestMove = move;
        }
        alpha = Math.max(alpha, bestScore);
      } else {
        if (evaluation.score < bestScore) {
          bestScore = evaluation.score;
          bestMove = move;
        }
        beta = Math.min(beta, bestScore);
      }

      if (beta <= alpha) {
        break;
      }
    }

    return { score: bestScore, move: bestMove };
  };

  const makeMove = (currentBoard: (Piece | null)[][], move: Move): (Piece | null)[][] => {
    const newBoard = currentBoard.map(row => [...row]);
    const piece = newBoard[move.from.row][move.from.col];
    
    // Move piece
    newBoard[move.to.row][move.to.col] = piece;
    newBoard[move.from.row][move.from.col] = null;

    // Handle jumps
    if (move.jumped) {
      newBoard[move.jumped.row][move.jumped.col] = null;
    }

    // King promotion
    if (piece && !piece.isKing) {
      if ((piece.isRed && move.to.row === BOARD_SIZE - 1) || (!piece.isRed && move.to.row === 0)) {
        piece.isKing = true;
      }
    }

    return newBoard;
  };

  const makeAiMove = async () => {
    setIsAiThinking(true);
    
    // Add a small delay to make AI feel more natural
    await new Promise(resolve => setTimeout(resolve, 500));

    const { move } = minimax(board, 4, -Infinity, Infinity, true);
    
    if (move) {
      const newBoard = makeMove(board, move);
      setBoard(newBoard);
      
      // Check for game over
      const hasRedPieces = newBoard.some(row => 
        row.some(cell => cell?.isRed)
      );
      const hasBlackPieces = newBoard.some(row => 
        row.some(cell => cell && !cell.isRed)
      );

      if (!hasRedPieces || !hasBlackPieces) {
        setGameOver(true);
      } else {
        setIsRedTurn(true);
      }
    }

    setIsAiThinking(false);
  };

  useEffect(() => {
    if (!isRedTurn && !gameOver && !isAiThinking) {
      makeAiMove();
    }
  }, [isRedTurn, gameOver]);

  const isValidPosition = (row: number, col: number): boolean => {
    return row >= 0 && row < BOARD_SIZE && col >= 0 && col < BOARD_SIZE;
  };

  const moveCursor = (direction: 'up' | 'down' | 'left' | 'right') => {
    setCursorPosition(prev => {
      let newRow = prev.row;
      let newCol = prev.col;

      switch (direction) {
        case 'up':
          newRow = Math.max(0, prev.row - 1);
          break;
        case 'down':
          newRow = Math.min(BOARD_SIZE - 1, prev.row + 1);
          break;
        case 'left':
          newCol = Math.max(0, prev.col - 1);
          break;
        case 'right':
          newCol = Math.min(BOARD_SIZE - 1, prev.col + 1);
          break;
      }

      return { row: newRow, col: newCol };
    });
  };

  const selectOrMovePiece = () => {
    if (gameOver || !isRedTurn || isAiThinking) return;

    const { row, col } = cursorPosition;
    const piece = board[row][col];

    // Selecting a piece
    if (piece && piece.isRed === isRedTurn) {
      setSelectedPiece({ row, col });
      setValidMoves(getAllValidMoves({ row, col }, board).map(move => move.to));
      return;
    }

    // Moving a piece
    if (selectedPiece && validMoves.some(move => move.row === row && move.col === col)) {
      const moves = getAllValidMoves(selectedPiece, board);
      const selectedMove = moves.find(move => move.to.row === row && move.to.col === col);
      
      if (selectedMove) {
        const newBoard = makeMove(board, selectedMove);
        setBoard(newBoard);
        setSelectedPiece(null);
        setValidMoves([]);
        
        // Check for game over
        const hasRedPieces = newBoard.some(row => 
          row.some(cell => cell?.isRed)
        );
        const hasBlackPieces = newBoard.some(row => 
          row.some(cell => cell && !cell.isRed)
        );

        if (!hasRedPieces || !hasBlackPieces) {
          setGameOver(true);
        } else {
          setIsRedTurn(false);
        }
      }
    }
  };

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (!isAiThinking) {
        switch (e.key) {
          case 'ArrowUp':
            moveCursor('up');
            break;
          case 'ArrowDown':
            moveCursor('down');
            break;
          case 'ArrowLeft':
            moveCursor('left');
            break;
          case 'ArrowRight':
            moveCursor('right');
            break;
          case ' ':
          case 'Enter':
            selectOrMovePiece();
            break;
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [cursorPosition, board, selectedPiece, validMoves, isRedTurn, gameOver, isAiThinking]);

  return (
    <div className="flex flex-col items-center justify-center p-4">
      <div className={`mb-4 text-lg font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        {gameOver ? (
          board.some(row => row.some(cell => cell?.isRed)) ? t('youWin') : t('aiWins')
        ) : isAiThinking ? (
          t('thinking')
        ) : (
          isRedTurn ? t('yourTurn') : t('aiTurn')
        )}
      </div>

      <div 
        className={`grid gap-0.5 p-2 rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'}`}
        style={{
          gridTemplateColumns: `repeat(${BOARD_SIZE}, ${CELL_SIZE}px)`,
          gridTemplateRows: `repeat(${BOARD_SIZE}, ${CELL_SIZE}px)`
        }}
      >
        {board.map((row, rowIndex) => 
          row.map((piece, colIndex) => {
            const isBlackSquare = (rowIndex + colIndex) % 2 === 1;
            const isValidMove = validMoves.some(
              move => move.row === rowIndex && move.col === colIndex
            );
            const isSelected = selectedPiece?.row === rowIndex && selectedPiece?.col === colIndex;
            const isCursor = cursorPosition.row === rowIndex && cursorPosition.col === colIndex;

            return (
              <div
                key={`${rowIndex}-${colIndex}`}
                className={`relative flex items-center justify-center cursor-pointer ${
                  isBlackSquare
                    ? isDarkMode 
                      ? 'bg-gray-700' 
                      : 'bg-gray-600'
                    : isDarkMode
                      ? 'bg-gray-900'
                      : 'bg-gray-300'
                } ${
                  isValidMove
                    ? 'ring-2 ring-yellow-400'
                    : ''
                } ${
                  isSelected
                    ? 'ring-2 ring-blue-400'
                    : ''
                } ${
                  isCursor && isRedTurn && !isAiThinking
                    ? 'ring-2 ring-green-400'
                    : ''
                }`}
              >
                {piece && (
                  <div className={`w-4/5 h-4/5 rounded-full ${
                    piece.isRed
                      ? 'bg-red-500'
                      : 'bg-black'
                  } ${
                    piece.isKing
                      ? 'ring-2 ring-yellow-400'
                      : ''
                  } shadow-lg transform transition-transform hover:scale-105`} />
                )}
              </div>
            );
          })
        )}
      </div>

      {/* Controls - only enabled during player's turn */}
      <div className="mt-6 grid grid-cols-3 gap-4">
        <div />
        <button
          onClick={() => moveCursor('up')}
          disabled={!isRedTurn || isAiThinking}
          className={`p-3 rounded-full ${
            isDarkMode 
              ? 'bg-gray-700 text-white hover:bg-gray-600' 
              : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
          } disabled:opacity-50`}
        >
          <ChevronUp className="h-6 w-6" />
        </button>
        <div />
        <button
          onClick={() => moveCursor('left')}
          disabled={!isRedTurn || isAiThinking}
          className={`p-3 rounded-full ${
            isDarkMode 
              ? 'bg-gray-700 text-white hover:bg-gray-600' 
              : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
          } disabled:opacity-50`}
        >
          <ChevronLeft className="h-6 w-6" />
        </button>
        <button
          onClick={selectOrMovePiece}
          disabled={!isRedTurn || isAiThinking}
          className={`p-3 rounded-full ${
            isDarkMode 
              ? 'bg-blue-600 text-white hover:bg-blue-700' 
              : 'bg-blue-500 text-white hover:bg-blue-600'
          } disabled:opacity-50`}
        >
          {selectedPiece ? t('move') : t('select')}
        </button>
        <button
          onClick={() => moveCursor('right')}
          disabled={!isRedTurn || isAiThinking}
          className={`p-3 rounded-full ${
            isDarkMode 
              ? 'bg-gray-700 text-white hover:bg-gray-600' 
              : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
          } disabled:opacity-50`}
        >
          <ChevronRight className="h-6 w-6" />
        </button>
        <div />
        <button
          onClick={() => moveCursor('down')}
          disabled={!isRedTurn || isAiThinking}
          className={`p-3 rounded-full ${
            isDarkMode 
              ? 'bg-gray-700 text-white hover:bg-gray-600' 
              : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
          } disabled:opacity-50`}
        >
          <ChevronDown className="h-6 w-6" />
        </button>
        <div />
      </div>

      <button
        onClick={initializeGame}
        className={`mt-6 px-4 py-2 rounded-lg flex items-center space-x-2 ${
          isDarkMode 
            ? 'bg-blue-600 text-white hover:bg-blue-700' 
            : 'bg-blue-500 text-white hover:bg-blue-600'
        }`}
      >
        <RotateCw className="h-5 w-5" />
        <span>{t('newGame')}</span>
      </button>
    </div>
  );
}