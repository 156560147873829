import { collection, query, where, getDocs, addDoc, serverTimestamp, doc, updateDoc, arrayUnion, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { generateToken } from './tokenGenerator';

// Generate a new invite token for a user
export async function generateInviteToken(userId: string): Promise<string | null> {
  try {
    // Check if user has less than 10 available tokens
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    const availableTokens = userDoc.data()?.availableTokens || [];
    
    if (availableTokens.length >= 10) {
      throw new Error('Maximum number of tokens reached');
    }

    // Generate unique token
    const token = generateToken();

    // Save token to database
    await addDoc(collection(db, 'inviteTokens'), {
      token,
      ownerId: userId,
      createdAt: serverTimestamp(),
      used: false,
      usedBy: null,
      usedAt: null
    });

    // Add token to user's available tokens
    await updateDoc(userRef, {
      availableTokens: arrayUnion(token)
    });

    return token;
  } catch (error) {
    console.error('Error generating invite token:', error);
    return null;
  }
}

// Get user's available tokens
export async function getUserTokens(userId: string): Promise<string[]> {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    
    if (!userDoc.exists()) {
      return [];
    }
    
    return userDoc.data()?.availableTokens || [];
  } catch (error) {
    console.error('Error getting user tokens:', error);
    return [];
  }
}

// Validate an invite token
export async function validateInviteToken(token: string): Promise<boolean> {
  try {
    const tokensRef = collection(db, 'inviteTokens');
    const tokenQuery = query(
      tokensRef, 
      where('token', '==', token),
      where('used', '==', false)
    );
    
    const snapshot = await getDocs(tokenQuery);
    return !snapshot.empty;
  } catch (error) {
    console.error('Error validating token:', error);
    return false;
  }
}

// Mark a token as used
export async function consumeInviteToken(token: string, userId: string): Promise<void> {
  try {
    const tokensRef = collection(db, 'inviteTokens');
    const tokenQuery = query(tokensRef, where('token', '==', token));
    const snapshot = await getDocs(tokenQuery);

    if (!snapshot.empty) {
      const tokenDoc = snapshot.docs[0];
      const tokenData = tokenDoc.data();

      // Update token document
      await updateDoc(doc(db, 'inviteTokens', tokenDoc.id), {
        used: true,
        usedBy: userId,
        usedAt: serverTimestamp()
      });

      // Remove token from owner's available tokens
      const ownerRef = doc(db, 'users', tokenData.ownerId);
      await updateDoc(ownerRef, {
        availableTokens: arrayUnion(token)
      });
    }
  } catch (error) {
    console.error('Error consuming token:', error);
    throw error;
  }
}