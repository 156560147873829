import { useState, useEffect } from 'react';
import { RotateCw, Loader2, ChevronUp, ChevronDown, ChevronLeft, ChevronRight } from 'lucide-react';

interface Props {
  isDarkMode?: boolean;
}

// Updated grid size to 8x8
const GRID_SIZE = 8;
const CELL_SIZE = 40;

type Cell = {
  letter: string;
  isSelected: boolean;
  isFound: boolean;
};

type Position = {
  row: number;
  col: number;
};

const translations = {
  'pt-BR': {
    title: 'Caça Palavras',
    newGame: 'Novo Jogo',
    youWin: 'Você venceu! 🎉',
    categories: {
      animals: ['GATO', 'LEAO', 'URSO', 'LOBO'],
      fruits: ['UVA', 'PERA', 'MACA', 'KIWI'],
      colors: ['AZUL', 'ROSA', 'ROXO', 'BEGE']
    },
    foundWords: 'Palavras Encontradas',
    remainingWords: 'Palavras Restantes',
    loading: 'Carregando...',
    select: '*',
    confirm: 'Confirmar'
  },
  'en': {
    title: 'Word Search',
    newGame: 'New Game',
    youWin: 'You win! 🎉',
    categories: {
      animals: ['CAT', 'DOG', 'LION', 'WOLF'],
      fruits: ['KIWI', 'PEAR', 'PLUM', 'LIME'],
      colors: ['BLUE', 'PINK', 'TEAL', 'GOLD']
    },
    foundWords: 'Found Words',
    remainingWords: 'Remaining Words',
    loading: 'Loading...',
    select: 'Select',
    confirm: 'Confirm'
  }
};

const DIRECTIONS = [
  [0, 1],   // right
  [1, 0],   // down
  [1, 1],   // diagonal down-right
  [-1, 1],  // diagonal up-right
];

export function WordSearchGame({ isDarkMode = false }: Props) {
  const [grid, setGrid] = useState<Cell[][]>([]);
  const [words, setWords] = useState<string[]>([]);
  const [foundWords, setFoundWords] = useState<string[]>([]);
  const [selectedCells, setSelectedCells] = useState<Position[]>([]);
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState('en');
  const [cursorPosition, setCursorPosition] = useState<Position>({ row: 0, col: 0 });

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value;
  };

  const generateGrid = (words: string[]) => {
    // Initialize empty grid
    const grid: Cell[][] = Array(GRID_SIZE).fill(null).map(() => 
      Array(GRID_SIZE).fill(null).map(() => ({
        letter: '',
        isSelected: false,
        isFound: false
      }))
    );

    // Place each word
    words.forEach(word => {
      let placed = false;
      let attempts = 0;
      const maxAttempts = 100;

      while (!placed && attempts < maxAttempts) {
        attempts++;
        // Random starting position
        const row = Math.floor(Math.random() * GRID_SIZE);
        const col = Math.floor(Math.random() * GRID_SIZE);
        
        // Random direction
        const direction = DIRECTIONS[Math.floor(Math.random() * DIRECTIONS.length)];
        
        // Check if word fits
        if (canPlaceWord(grid, word, row, col, direction)) {
          placeWord(grid, word, row, col, direction);
          placed = true;
        }
      }
    });

    // Fill empty spaces with random letters
    for (let i = 0; i < GRID_SIZE; i++) {
      for (let j = 0; j < GRID_SIZE; j++) {
        if (grid[i][j].letter === '') {
          grid[i][j].letter = String.fromCharCode(65 + Math.floor(Math.random() * 26));
        }
      }
    }

    return grid;
  };

  const canPlaceWord = (
    grid: Cell[][],
    word: string,
    row: number,
    col: number,
    [dy, dx]: number[]
  ): boolean => {
    if (
      row + dy * (word.length - 1) < 0 ||
      row + dy * (word.length - 1) >= GRID_SIZE ||
      col + dx * (word.length - 1) < 0 ||
      col + dx * (word.length - 1) >= GRID_SIZE
    ) {
      return false;
    }

    for (let i = 0; i < word.length; i++) {
      const currentCell = grid[row + dy * i][col + dx * i];
      if (currentCell.letter !== '' && currentCell.letter !== word[i]) {
        return false;
      }
    }

    return true;
  };

  const placeWord = (
    grid: Cell[][],
    word: string,
    row: number,
    col: number,
    [dy, dx]: number[]
  ) => {
    for (let i = 0; i < word.length; i++) {
      grid[row + dy * i][col + dx * i].letter = word[i];
    }
  };

  const initializeGame = () => {
    setLoading(true);
    const categories = t('categories') as Record<string, string[]>;
    const category = Object.keys(categories)[Math.floor(Math.random() * Object.keys(categories).length)];
    const words = categories[category];
    
    setWords(words);
    setFoundWords([]);
    setGrid(generateGrid(words));
    setSelectedCells([]);
    setCursorPosition({ row: 0, col: 0 });
    setLoading(false);
  };

  useEffect(() => {
    initializeGame();
  }, [language]);

  const moveCursor = (direction: 'up' | 'down' | 'left' | 'right') => {
    setCursorPosition(prev => {
      let newRow = prev.row;
      let newCol = prev.col;

      switch (direction) {
        case 'up':
          newRow = Math.max(0, prev.row - 1);
          break;
        case 'down':
          newRow = Math.min(GRID_SIZE - 1, prev.row + 1);
          break;
        case 'left':
          newCol = Math.max(0, prev.col - 1);
          break;
        case 'right':
          newCol = Math.min(GRID_SIZE - 1, prev.col + 1);
          break;
      }

      return { row: newRow, col: newCol };
    });
  };

  const selectCell = () => {
    // If cell is already found, ignore selection
    if (grid[cursorPosition.row][cursorPosition.col].isFound) return;

    const newSelectedCells = [...selectedCells];
    const cellIndex = selectedCells.findIndex(
      cell => cell.row === cursorPosition.row && cell.col === cursorPosition.col
    );

    if (cellIndex === -1) {
      // Add cell to selection
      newSelectedCells.push(cursorPosition);
    } else {
      // Remove cell and all subsequent cells from selection
      newSelectedCells.splice(cellIndex);
    }

    // Update grid with new selections
    const newGrid = grid.map(row => 
      row.map(cell => ({ ...cell, isSelected: false }))
    );
    newSelectedCells.forEach(cell => {
      newGrid[cell.row][cell.col].isSelected = true;
    });
    setGrid(newGrid);
    setSelectedCells(newSelectedCells);

    // Check if selected cells form a word
    if (newSelectedCells.length > 1) {
      const selectedWord = newSelectedCells
        .map(cell => grid[cell.row][cell.col].letter)
        .join('');

      // Check if word exists and hasn't been found yet
      if (words.includes(selectedWord) && !foundWords.includes(selectedWord)) {
        // Mark cells as found
        newSelectedCells.forEach(cell => {
          newGrid[cell.row][cell.col].isFound = true;
          newGrid[cell.row][cell.col].isSelected = false;
        });
        setGrid(newGrid);
        setFoundWords(prev => [...prev, selectedWord]);
        setSelectedCells([]);
      }
    }
  };

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowUp':
          moveCursor('up');
          break;
        case 'ArrowDown':
          moveCursor('down');
          break;
        case 'ArrowLeft':
          moveCursor('left');
          break;
        case 'ArrowRight':
          moveCursor('right');
          break;
        case ' ':
        case 'Enter':
          selectCell();
          break;
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [cursorPosition, grid, selectedCells]);

  if (loading) {
    return (
      <div className="flex justify-center items-center p-8">
        <Loader2 className={`h-8 w-8 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
      </div>
    );
  }

  const remainingWords = words.filter(word => !foundWords.includes(word));
  const isGameWon = remainingWords.length === 0;

  return (
    <div className="flex flex-col items-center justify-center p-4">
      <div className={`mb-4 text-lg font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        {isGameWon ? t('youWin') : t('title')}
      </div>

      <div className="grid gap-2 mb-8" style={{ gridTemplateColumns: `repeat(${GRID_SIZE}, minmax(0, 1fr))` }}>
        {grid.map((row, rowIndex) => (
          row.map((cell, colIndex) => (
            <div
              key={`${rowIndex}-${colIndex}`}
              className={`w-12 h-12 flex items-center justify-center font-bold text-2xl rounded-lg relative ${
                cursorPosition.row === rowIndex && cursorPosition.col === colIndex
                  ? 'ring-4 ring-yellow-400'
                  : ''
              } ${
                cell.isSelected
                  ? isDarkMode
                    ? 'bg-blue-600 text-white'
                    : 'bg-blue-500 text-white'
                  : cell.isFound
                    ? isDarkMode
                      ? 'bg-green-600 text-white'
                      : 'bg-green-500 text-white'
                    : isDarkMode
                      ? 'bg-gray-700 text-white'
                      : 'bg-gray-100'
              } transition-colors shadow-md`}
            >
              {cell.letter}
            </div>
          ))
        ))}
      </div>

      {/* Controls */}
      <div className="mb-8">
        <div className="grid grid-cols-3 gap-4 w-48 mb-4">
          <div />
          <button
            onClick={() => moveCursor('up')}
            className={`p-3 rounded-full ${
              isDarkMode 
                ? 'bg-gray-700 text-white hover:bg-gray-600' 
                : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
            }`}
          >
            <ChevronUp className="h-6 w-6" />
          </button>
          <div />
          <button
            onClick={() => moveCursor('left')}
            className={`p-3 rounded-full ${
              isDarkMode 
                ? 'bg-gray-700 text-white hover:bg-gray-600' 
                : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
            }`}
          >
            <ChevronLeft className="h-6 w-6" />
          </button>
          <button
            onClick={selectCell}
            className={`p-3 rounded-full ${
              isDarkMode 
                ? 'bg-blue-600 text-white hover:bg-blue-700' 
                : 'bg-blue-500 text-white hover:bg-blue-600'
            }`}
          >
            {t('select')}
          </button>
          <button
            onClick={() => moveCursor('right')}
            className={`p-3 rounded-full ${
              isDarkMode 
                ? 'bg-gray-700 text-white hover:bg-gray-600' 
                : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
            }`}
          >
            <ChevronRight className="h-6 w-6" />
          </button>
          <div />
          <button
            onClick={() => moveCursor('down')}
            className={`p-3 rounded-full ${
              isDarkMode 
                ? 'bg-gray-700 text-white hover:bg-gray-600' 
                : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
            }`}
          >
            <ChevronDown className="h-6 w-6" />
          </button>
          <div />
        </div>
      </div>

      <div className="flex justify-between w-full max-w-md mb-4">
        <div>
          <h3 className={`font-bold mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            {t('foundWords')} ({foundWords.length})
          </h3>
          <ul className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>
            {foundWords.map(word => (
              <li key={word} className="line-through">{word}</li>
            ))}
          </ul>
        </div>
        <div>
          <h3 className={`font-bold mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            {t('remainingWords')} ({remainingWords.length})
          </h3>
          <ul className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>
            {remainingWords.map(word => (
              <li key={word}>{word}</li>
            ))}
          </ul>
        </div>
      </div>

      <button
        onClick={initializeGame}
        className={`px-4 py-2 rounded-lg flex items-center space-x-2 ${
          isDarkMode 
            ? 'bg-blue-600 text-white hover:bg-blue-700' 
            : 'bg-blue-500 text-white hover:bg-blue-600'
        }`}
      >
        <RotateCw className="h-5 w-5" />
        <span>{t('newGame')}</span>
      </button>
    </div>
  );
}