import { useState, useEffect, useCallback } from 'react';
import { RotateCw, ChevronUp, ChevronDown, ChevronLeft, ChevronRight } from 'lucide-react';

interface Props {
  isDarkMode?: boolean;
}

const translations = {
  'pt-BR': {
    title: 'Jogo da Velha',
    yourTurn: 'Sua vez',
    aiTurn: 'Vez da IA',
    youWin: 'Você venceu! 🎉',
    aiWins: 'IA venceu!',
    newGame: 'Novo Jogo',
    select: 'Selecionar',
    move: 'Mover',
    thinking: 'IA pensando...'
  },
  'en': {
    title: 'Tic Tac Toe',
    yourTurn: 'Your turn',
    aiTurn: 'AI\'s turn',
    youWin: 'You win! 🎉',
    aiWins: 'AI wins!',
    newGame: 'New Game',
    select: 'Select',
    move: 'Move',
    thinking: 'AI thinking...'
  }
};

type Cell = 'X' | 'O' | null;
type Board = Cell[];

const WINNING_COMBINATIONS = [
  [0, 1, 2], // Top row
  [3, 4, 5], // Middle row
  [6, 7, 8], // Bottom row
  [0, 3, 6], // Left column
  [1, 4, 7], // Middle column
  [2, 5, 8], // Right column
  [0, 4, 8], // Diagonal
  [2, 4, 6]  // Diagonal
];

export function TicTacToeGame({ isDarkMode = false }: Props) {
  const [board, setBoard] = useState<Board>(Array(9).fill(null));
  const [currentPlayer, setCurrentPlayer] = useState<'X' | 'O'>('X');
  const [winner, setWinner] = useState<'X' | 'O' | 'draw' | null>(null);
  const [isThinking, setIsThinking] = useState(false);
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  const checkWinner = (boardState: Board): { winner: Cell; cells: number[] } => {
    // Check for winner
    for (const combination of WINNING_COMBINATIONS) {
      const [a, b, c] = combination;
      if (
        boardState[a] &&
        boardState[a] === boardState[b] &&
        boardState[a] === boardState[c]
      ) {
        return { winner: boardState[a], cells: combination };
      }
    }

    // Check for draw
    if (boardState.every(cell => cell !== null)) {
      return { winner: null, cells: [] };
    }

    return { winner: null, cells: [] };
  };

  // Minimax algorithm for AI
  const minimax = (boardState: Board, depth: number, isMaximizing: boolean): number => {
    const result = checkWinner(boardState);

    if (result.winner === 'O') return 10 - depth;
    if (result.winner === 'X') return depth - 10;
    if (result.winner === null && result.cells.length === 0 && boardState.every(cell => cell !== null)) return 0;

    if (isMaximizing) {
      let bestScore = -Infinity;
      for (let i = 0; i < 9; i++) {
        if (!boardState[i]) {
          boardState[i] = 'O';
          const score = minimax(boardState, depth + 1, false);
          boardState[i] = null;
          bestScore = Math.max(score, bestScore);
        }
      }
      return bestScore;
    } else {
      let bestScore = Infinity;
      for (let i = 0; i < 9; i++) {
        if (!boardState[i]) {
          boardState[i] = 'X';
          const score = minimax(boardState, depth + 1, true);
          boardState[i] = null;
          bestScore = Math.min(score, bestScore);
        }
      }
      return bestScore;
    }
  };

  const makeAIMove = async () => {
    setIsThinking(true);
    
    // Add a small delay to make AI feel more natural
    await new Promise(resolve => setTimeout(resolve, 500));

    let bestScore = -Infinity;
    let bestMove = -1;

    for (let i = 0; i < 9; i++) {
      if (!board[i]) {
        const newBoard = [...board];
        newBoard[i] = 'O';
        const score = minimax(newBoard, 0, false);
        if (score > bestScore) {
          bestScore = score;
          bestMove = i;
        }
      }
    }

    if (bestMove !== -1) {
      const newBoard = [...board];
      newBoard[bestMove] = 'O';
      setBoard(newBoard);

      const { winner } = checkWinner(newBoard);
      if (winner) {
        setWinner(winner);
      } else if (newBoard.every(cell => cell !== null)) {
        setWinner('draw');
      } else {
        setCurrentPlayer('X');
      }
    }

    setIsThinking(false);
  };

  useEffect(() => {
    if (currentPlayer === 'O' && !winner && !isThinking) {
      makeAIMove();
    }
  }, [currentPlayer, winner]);

  const handleCellClick = (index: number) => {
    if (board[index] || winner || currentPlayer === 'O' || isThinking) return;

    const newBoard = [...board];
    newBoard[index] = 'X';
    setBoard(newBoard);

    const { winner: newWinner } = checkWinner(newBoard);
    if (newWinner) {
      setWinner(newWinner);
    } else if (newBoard.every(cell => cell !== null)) {
      setWinner('draw');
    } else {
      setCurrentPlayer('O');
    }
  };

  const resetGame = () => {
    setBoard(Array(9).fill(null));
    setCurrentPlayer('X');
    setWinner(null);
    setIsThinking(false);
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className={`mb-4 text-lg font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        {winner 
          ? winner === 'draw' 
            ? "It's a draw!" 
            : winner === 'X' 
              ? t('youWin') 
              : t('aiWins')
          : isThinking 
            ? t('thinking')
            : currentPlayer === 'X' 
              ? t('yourTurn') 
              : t('aiTurn')}
      </div>

      <div className={`grid grid-cols-3 gap-2 mb-8 ${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'} p-2 rounded-lg`}>
        {board.map((cell, index) => (
          <button
            key={index}
            onClick={() => handleCellClick(index)}
            disabled={!!cell || !!winner || currentPlayer === 'O' || isThinking}
            className={`w-20 h-20 flex items-center justify-center text-3xl font-bold rounded-lg transition-colors ${
              isDarkMode 
                ? 'bg-gray-700 hover:bg-gray-600' 
                : 'bg-white hover:bg-gray-50'
            } ${
              cell === 'X' 
                ? isDarkMode ? 'text-blue-400' : 'text-blue-500'
                : cell === 'O'
                  ? isDarkMode ? 'text-red-400' : 'text-red-500'
                  : ''
            }`}
          >
            {cell}
          </button>
        ))}
      </div>

      <button
        onClick={resetGame}
        className={`px-4 py-2 rounded-lg flex items-center space-x-2 ${
          isDarkMode 
            ? 'bg-blue-600 text-white hover:bg-blue-700' 
            : 'bg-blue-500 text-white hover:bg-blue-600'
        }`}
      >
        <RotateCw className="h-5 w-5" />
        <span>{t('newGame')}</span>
      </button>

      <div className={`mt-4 text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
        
      </div>
    </div>
  );
}