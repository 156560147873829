import { useState } from 'react';
import { Send, Loader2, MessageSquare } from 'lucide-react';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from './AuthProvider';
import toast from 'react-hot-toast';

interface StoryCommentProps {
  storyId: string;
  authorId: string;
  onCommentAdded: () => void;
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    writeComment: 'Escreva um comentário...',
    posting: 'Enviando...',
    showComments: 'Ver comentários',
    errors: {
      commentRequired: 'Digite um comentário antes de enviar',
      commentTooLong: 'Comentário deve ter no máximo 120 caracteres',
      noLinks: 'Links não são permitidos em comentários',
      sendFailed: 'Falha ao enviar comentário'
    },
    success: {
      commentAdded: 'Comentário adicionado com sucesso'
    }
  },
  'en': {
    writeComment: 'Write a comment...',
    posting: 'Posting...',
    showComments: 'View comments',
    errors: {
      commentRequired: 'Please write a comment before sending',
      commentTooLong: 'Comment must be 120 characters or less',
      noLinks: 'Links are not allowed in comments',
      sendFailed: 'Failed to send comment'
    },
    success: {
      commentAdded: 'Comment added successfully'
    }
  }
};

export function StoryComment({ storyId, authorId, onCommentAdded, isDarkMode = false, language = 'en' }: StoryCommentProps) {
  const [comment, setComment] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const { user } = useAuth();

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user || !comment.trim() || submitting) {
      toast.error(t('errors.commentRequired'));
      return;
    }

    if (comment.length > 120) {
      toast.error(t('errors.commentTooLong'));
      return;
    }

    // Check for URLs in content
    const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)|([a-zA-Z0-9-]+\.(com|net|org|br|io|dev|app|me|co|uk|us|ca|eu))/gi;
    if (urlRegex.test(comment)) {
      toast.error(t('errors.noLinks'));
      return;
    }

    try {
      setSubmitting(true);
      
      // Add comment
      await addDoc(collection(db, 'stories', storyId, 'comments'), {
        content: comment.trim(),
        authorId: user.uid,
        authorName: user.displayName || '',
        authorUsername: user.displayName?.replace('@', '') || '',
        authorPhotoURL: user.photoURL,
        timestamp: serverTimestamp()
      });

      // Create notification for story author
      if (user.uid !== authorId) {
        await addDoc(collection(db, 'notifications'), {
          type: 'story_comment',
          storyId,
          recipientId: authorId,
          senderId: user.uid,
          senderName: user.displayName || '',
          senderUsername: user.displayName?.replace('@', '') || '',
          senderPhotoURL: user.photoURL,
          content: comment.trim(),
          timestamp: serverTimestamp(),
          read: false
        });
      }

      setComment('');
      onCommentAdded();
      toast.success(t('success.commentAdded'));
    } catch (error) {
      console.error('Error adding comment:', error);
      toast.error(t('errors.sendFailed'));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mt-4">
      <div className="flex items-center space-x-2">
        <input
          type="text"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder={t('writeComment')}
          maxLength={120}
          className={`flex-1 px-4 py-2 rounded-full border focus:outline-none focus:ring-2 focus:ring-blue-500 ${
            isDarkMode 
              ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
              : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
          }`}
        />
        <button
          type="submit"
          disabled={!comment.trim() || submitting}
          className={`p-2 ${
            isDarkMode 
              ? 'bg-blue-600 text-white hover:bg-blue-700' 
              : 'bg-blue-500 text-white hover:bg-blue-600'
          } rounded-full disabled:opacity-50`}
        >
          {submitting ? (
            <Loader2 className="h-5 w-5 animate-spin" />
          ) : (
            <Send className="h-5 w-5" />
          )}
        </button>
      </div>
      <p className={`text-xs mt-1 ${
        comment.length > 120 
          ? 'text-red-500' 
          : isDarkMode 
            ? 'text-gray-400' 
            : 'text-gray-500'
      }`}>
        {comment.length}/120
      </p>
    </form>
  );
}