import { useState, useEffect } from 'react';
import { Flag, Bomb } from 'lucide-react';

interface Props {
  isDarkMode?: boolean;
}

const translations = {
  'pt-BR': {
    title: 'Campo Minado',
    position: 'Posição',
    mines: 'Minas',
    gameOver: 'Fim de Jogo!',
    youWin: 'Você venceu! 🎉',
    newGame: 'Novo Jogo',
    controls: {
      title: 'Controles:',
      leftClick: 'Clique: Revelar célula',
      rightClick: 'Clique direito: Marcar mina',
      tip: 'Dica: Os números indicam quantas minas estão ao redor!'
    }
  },
  'en': {
    title: 'Minesweeper',
    position: 'Position',
    mines: 'Mines',
    gameOver: 'Game Over!',
    youWin: 'You win! 🎉',
    newGame: 'New Game',
    controls: {
      title: 'Controls:',
      leftClick: 'Click: Reveal cell',
      rightClick: 'Right click: Flag mine',
      tip: 'Tip: Numbers show how many mines are nearby!'
    }
  }
};

const BOARD_SIZE = 10;
const MINES_COUNT = 15;

type Cell = {
  isMine: boolean;
  isRevealed: boolean;
  isFlagged: boolean;
  neighborMines: number;
};

export function MinesweeperGame({ isDarkMode = false }: Props) {
  const [board, setBoard] = useState<Cell[][]>([]);
  const [gameOver, setGameOver] = useState(false);
  const [gameWon, setGameWon] = useState(false);
  const [minesLeft, setMinesLeft] = useState(MINES_COUNT);
  const [firstClick, setFirstClick] = useState(true);
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  // Initialize board
  const initializeBoard = () => {
    const newBoard: Cell[][] = Array(BOARD_SIZE).fill(null).map(() =>
      Array(BOARD_SIZE).fill(null).map(() => ({
        isMine: false,
        isRevealed: false,
        isFlagged: false,
        neighborMines: 0
      }))
    );
    return newBoard;
  };

  // Place mines after first click
  const placeMines = (board: Cell[][], firstX: number, firstY: number) => {
    let minesPlaced = 0;
    const newBoard = [...board];

    while (minesPlaced < MINES_COUNT) {
      const x = Math.floor(Math.random() * BOARD_SIZE);
      const y = Math.floor(Math.random() * BOARD_SIZE);

      // Don't place mine on first click or where there's already a mine
      if (!newBoard[y][x].isMine && !(x === firstX && y === firstY)) {
        newBoard[y][x].isMine = true;
        minesPlaced++;
      }
    }

    // Calculate neighbor mines
    for (let y = 0; y < BOARD_SIZE; y++) {
      for (let x = 0; x < BOARD_SIZE; x++) {
        if (!newBoard[y][x].isMine) {
          let neighbors = 0;
          for (let dy = -1; dy <= 1; dy++) {
            for (let dx = -1; dx <= 1; dx++) {
              if (y + dy >= 0 && y + dy < BOARD_SIZE && x + dx >= 0 && x + dx < BOARD_SIZE) {
                if (newBoard[y + dy][x + dx].isMine) neighbors++;
              }
            }
          }
          newBoard[y][x].neighborMines = neighbors;
        }
      }
    }

    return newBoard;
  };

  // Reset game
  const resetGame = () => {
    setBoard(initializeBoard());
    setGameOver(false);
    setGameWon(false);
    setMinesLeft(MINES_COUNT);
    setFirstClick(true);
  };

  useEffect(() => {
    resetGame();
  }, []);

  // Reveal cell and its neighbors if it's empty
  const revealCell = (board: Cell[][], y: number, x: number): Cell[][] => {
    if (y < 0 || y >= BOARD_SIZE || x < 0 || x >= BOARD_SIZE || 
        board[y][x].isRevealed || board[y][x].isFlagged) {
      return board;
    }

    // Create a new copy of the board
    const newBoard = board.map(row => [...row]);
    newBoard[y][x] = { ...newBoard[y][x], isRevealed: true };

    // If cell has no neighboring mines, reveal neighbors recursively
    if (newBoard[y][x].neighborMines === 0) {
      for (let dy = -1; dy <= 1; dy++) {
        for (let dx = -1; dx <= 1; dx++) {
          if (dy === 0 && dx === 0) continue;
          const revealedBoard = revealCell(newBoard, y + dy, x + dx);
          // Copy the revealed cells to our board
          revealedBoard.forEach((row, i) => {
            row.forEach((cell, j) => {
              newBoard[i][j] = { ...cell };
            });
          });
        }
      }
    }

    return newBoard;
  };

  // Handle cell click
  const handleCellClick = (y: number, x: number) => {
    if (gameOver || gameWon || board[y][x].isFlagged) return;

    let newBoard = [...board];

    if (firstClick) {
      newBoard = placeMines(newBoard, x, y);
      setFirstClick(false);
    }

    if (newBoard[y][x].isMine) {
      // Reveal all mines
      newBoard = newBoard.map(row => row.map(cell => ({
        ...cell,
        isRevealed: cell.isMine ? true : cell.isRevealed
      })));
      setGameOver(true);
    } else {
      newBoard = revealCell(newBoard, y, x);
      
      // Check for win
      const unrevealedSafeCells = newBoard.flat().filter(
        cell => !cell.isRevealed && !cell.isMine
      ).length;
      
      if (unrevealedSafeCells === 0) {
        setGameWon(true);
        // Flag all mines
        newBoard = newBoard.map(row => row.map(cell => ({
          ...cell,
          isFlagged: cell.isMine ? true : cell.isFlagged
        })));
      }
    }

    setBoard(newBoard);
  };

  // Handle right click (flag)
  const handleRightClick = (e: React.MouseEvent, y: number, x: number) => {
    e.preventDefault();
    if (gameOver || gameWon || board[y][x].isRevealed) return;

    const newBoard = [...board];
    const cell = newBoard[y][x];
    
    newBoard[y][x] = {
      ...cell,
      isFlagged: !cell.isFlagged
    };
    
    setBoard(newBoard);
    setMinesLeft(prev => prev + (cell.isFlagged ? 1 : -1));
  };

  // Handle long press for mobile (flag)
  const handleLongPress = (y: number, x: number) => {
    if (gameOver || gameWon || board[y][x].isRevealed) return;

    const newBoard = [...board];
    const cell = newBoard[y][x];
    
    newBoard[y][x] = {
      ...cell,
      isFlagged: !cell.isFlagged
    };
    
    setBoard(newBoard);
    setMinesLeft(prev => prev + (cell.isFlagged ? 1 : -1));
  };

  const getCellColor = (cell: Cell) => {
    if (cell.isRevealed) {
      if (cell.isMine) {
        return isDarkMode ? 'bg-red-900' : 'bg-red-500';
      }
      return isDarkMode ? 'bg-gray-700' : 'bg-gray-200';
    }
    return isDarkMode ? 'bg-gray-600 hover:bg-gray-500' : 'bg-gray-300 hover:bg-gray-200';
  };

  const getNumberColor = (num: number) => {
    const colors = [
      'text-blue-500',
      'text-green-500',
      'text-red-500',
      'text-purple-500',
      'text-yellow-500',
      'text-cyan-500',
      'text-pink-500',
      'text-orange-500'
    ];
    return colors[num - 1] || colors[0];
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className={`mb-4 flex items-center justify-between w-full max-w-[400px] ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        <div className="flex items-center">
          <Flag className="h-5 w-5 mr-2" />
          <span className="font-bold">{minesLeft}</span>
        </div>
        <button
          onClick={resetGame}
          className={`px-4 py-2 rounded-lg ${
            isDarkMode 
              ? 'bg-gray-700 hover:bg-gray-600' 
              : 'bg-gray-200 hover:bg-gray-300'
          }`}
        >
          {t('newGame')}
        </button>
      </div>

      <div className={`grid gap-1 p-2 rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'}`}
        style={{ gridTemplateColumns: `repeat(${BOARD_SIZE}, 1fr)` }}
      >
        {board.map((row, y) => row.map((cell, x) => (
          <button
            key={`${y}-${x}`}
            onClick={() => handleCellClick(y, x)}
            onContextMenu={(e) => handleRightClick(e, y, x)}
            onTouchStart={() => {
              let timer = setTimeout(() => handleLongPress(y, x), 500);
              return () => clearTimeout(timer);
            }}
            className={`w-8 h-8 flex items-center justify-center font-bold text-sm 
              ${getCellColor(cell)} transition-colors duration-200`}
          >
            {cell.isFlagged ? (
              <Flag className="h-4 w-4" />
            ) : cell.isRevealed ? (
              cell.isMine ? (
                <Bomb className="h-4 w-4" />
              ) : cell.neighborMines > 0 ? (
                <span className={getNumberColor(cell.neighborMines)}>
                  {cell.neighborMines}
                </span>
              ) : null
            ) : null}
          </button>
        )))}
      </div>

      {(gameOver || gameWon) && (
        <div className={`mt-4 text-lg font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
          {gameWon ? t('youWin') : t('gameOver')}
        </div>
      )}

      <div className={`mt-4 text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
        <p className="font-medium">{t('controls.title')}</p>
        <p>{t('controls.leftClick')}</p>
        <p>{t('controls.rightClick')}</p>
        <p className="mt-2 italic">{t('controls.tip')}</p>
      </div>
    </div>
  );
}