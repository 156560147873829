import { useState, useEffect, useRef } from 'react';
import { collection, query, where, orderBy, onSnapshot, addDoc, serverTimestamp, doc, updateDoc, arrayUnion, arrayRemove, getDocs, deleteDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Send, Plus, Menu, Trash2, MessageSquare } from 'lucide-react';
import { auth } from '../lib/firebase';
import { Link } from 'react-router-dom';
import { ChatList } from '../components/ChatList';
import { ChatInvite } from '../components/ChatInvite';
import { MainMenu } from '../components/MainMenu';
import { useTheme } from '../contexts/ThemeContext';
import { useAuth } from '../hooks/useAuth';
import toast from 'react-hot-toast';

const translations = {
  'pt-BR': {
    title: 'Mensagens',
    newChat: 'Chat',
    chat: 'Chat',
    yourMessages: 'Suas Mensagens',
    sendPrivate: 'Envie mensagens privadas para outros usuários',
    typeMessage: 'Digite uma mensagem...',
    deleteChat: 'Excluir conversa',
    confirmDelete: 'Tem certeza que deseja excluir esta conversa? Esta ação não pode ser desfeita.',
    blockedMessage: 'Você bloqueou este usuário. Não é possível enviar mensagens.',
    youAreBlocked: 'Você foi bloqueado por este usuário.',
    errors: {
      linksNotAllowed: 'Links não são permitidos em mensagens',
      failedToSend: 'Falha ao enviar mensagem',
      failedToDelete: 'Falha ao excluir conversa',
      chatLimit: 'Limite de chat atingido ({count}/{max}). Tente novamente em {hours} horas.'
    },
    success: {
      chatDeleted: 'Conversa excluída com sucesso'
    }
  },
  'en': {
    title: 'Messages',
    newChat: 'New Chat',
    chat: 'Chat',
    yourMessages: 'Your Messages',
    sendPrivate: 'Send private messages to other users',
    typeMessage: 'Type a message...',
    deleteChat: 'Delete chat',
    confirmDelete: 'Are you sure you want to delete this chat? This action cannot be undone.',
    blockedMessage: 'You have blocked this user. Messages cannot be sent.',
    youAreBlocked: 'You have been blocked by this user.',
    errors: {
      linksNotAllowed: 'Links are not allowed in messages',
      failedToSend: 'Failed to send message',
      failedToDelete: 'Failed to delete chat',
      chatLimit: 'Chat limit reached ({count}/{max}). Try again in {hours} hours.'
    },
    success: {
      chatDeleted: 'Chat deleted successfully'
    }
  }
};

interface Message {
  id: string;
  content: string;
  senderId: string;
  senderName: string;
  timestamp: Date;
  readBy: string[];
}

interface ChatRoom {
  id: string;
  participants: string[];
  lastMessageTimestamp?: Date;
  blockedBy?: string[];
}

const CHAT_LIMIT_KEY = 'poosting_chat_limit';
const MAX_NEW_CHATS = 5;
const CHAT_LIMIT_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

interface ChatLimit {
  count: number;
  timestamp: number;
}

function getChatLimit(): ChatLimit {
  try {
    const limit = localStorage.getItem(CHAT_LIMIT_KEY);
    if (limit) {
      return JSON.parse(limit);
    }
  } catch (error) {
    console.error('Error reading chat limit:', error);
  }
  return { count: 0, timestamp: Date.now() };
}

function updateChatLimit() {
  const now = Date.now();
  let limit = getChatLimit();

  if (now - limit.timestamp > CHAT_LIMIT_DURATION) {
    limit = { count: 1, timestamp: now };
  } else {
    limit.count += 1;
  }

  localStorage.setItem(CHAT_LIMIT_KEY, JSON.stringify(limit));
  return limit;
}

function canStartNewChat(): boolean {
  const limit = getChatLimit();
  const now = Date.now();

  if (now - limit.timestamp > CHAT_LIMIT_DURATION) {
    return true;
  }

  return limit.count < MAX_NEW_CHATS;
}

function hasLinks(text: string): boolean {
  const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)|([a-zA-Z0-9-]+\.(com|net|org|br|io|dev|app|me|co|uk|us|ca|eu))/gi;
  return urlRegex.test(text);
}

export function Chat() {
  const [selectedChat, setSelectedChat] = useState<ChatRoom | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [sending, setSending] = useState(false);
  const [showInvite, setShowInvite] = useState(false);
  const [showChatList, setShowChatList] = useState(window.innerWidth >= 768);
  const [selectedUsername, setSelectedUsername] = useState<string | null>(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const { user } = useAuth();
  const { isDarkMode } = useTheme();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string, params?: Record<string, string>) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }

    let text = value as string;
    if (params) {
      Object.entries(params).forEach(([param, replacement]) => {
        text = text.replace(`{${param}}`, replacement);
      });
    }
    
    return text;
  };

  useEffect(() => {
    if (!selectedChat || !user) return;

    const messagesRef = collection(db, `chats/${selectedChat.id}/messages`);
    const q = query(messagesRef, orderBy('timestamp', 'asc'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const messageData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate() || new Date()
      })) as Message[];

      setMessages(messageData);

      snapshot.docs.forEach(async (doc) => {
        const messageData = doc.data();
        if (!messageData.readBy?.includes(user.uid)) {
          await updateDoc(doc.ref, {
            readBy: arrayUnion(user.uid)
          });
        }
      });
    });

    return () => unsubscribe();
  }, [selectedChat, user]);

  useEffect(() => {
    if (messageContainerRef.current) {
      const { scrollHeight, clientHeight } = messageContainerRef.current;
      messageContainerRef.current.scrollTop = scrollHeight - clientHeight;
    }
  }, [messages]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newMessage.trim() || !selectedChat || !user || sending) return;

    // Check if user is blocked
    if (selectedChat.blockedBy?.includes(user.uid)) {
      toast.error(t('blockedMessage'));
      return;
    }

    // Check if user has been blocked
    if (selectedChat.blockedBy?.some(id => id !== user.uid)) {
      toast.error(t('youAreBlocked'));
      return;
    }

    if (hasLinks(newMessage)) {
      toast.error(t('errors.linksNotAllowed'));
      return;
    }

    const messageContent = newMessage.trim();
    setNewMessage('');
    setSending(true);

    try {
      await addDoc(collection(db, `chats/${selectedChat.id}/messages`), {
        content: messageContent,
        senderId: user.uid,
        senderName: user.displayName || '',
        timestamp: serverTimestamp(),
        readBy: [user.uid]
      });

      await updateDoc(doc(db, 'chats', selectedChat.id), {
        lastMessage: messageContent,
        lastMessageTimestamp: serverTimestamp(),
        unreadBy: selectedChat.participants.filter(id => id !== user.uid)
      });
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error(t('errors.failedToSend'));
      setNewMessage(messageContent);
    } finally {
      setSending(false);
    }
  };

  const handleStartNewChat = async (userId: string) => {
    if (!user) return;

    if (!canStartNewChat()) {
      const limit = getChatLimit();
      const timeLeft = CHAT_LIMIT_DURATION - (Date.now() - limit.timestamp);
      const hoursLeft = Math.ceil(timeLeft / (1000 * 60 * 60));
      toast.error(t('errors.chatLimit', {
        count: limit.count.toString(),
        max: MAX_NEW_CHATS.toString(),
        hours: hoursLeft.toString()
      }));
      return;
    }

    try {
      updateChatLimit();

      const chatDoc = await addDoc(collection(db, 'chats'), {
        participants: [user.uid, userId],
        createdAt: serverTimestamp(),
        lastMessageTimestamp: serverTimestamp(),
        blockedBy: [] // Initialize empty blockedBy array
      });

      setSelectedChat({ id: chatDoc.id, participants: [user.uid, userId], blockedBy: [] });
      setShowInvite(false);
      if (window.innerWidth < 768) {
        setShowChatList(false);
      }
    } catch (error) {
      console.error('Error creating chat:', error);
      toast.error(t('errors.failedToCreate'));
    }
  };

  const handleDeleteChat = async () => {
    if (!selectedChat || !user) return;

    const confirmDelete = window.confirm(t('confirmDelete'));
    if (!confirmDelete) return;

    try {
      const messagesRef = collection(db, `chats/${selectedChat.id}/messages`);
      const messagesSnapshot = await getDocs(messagesRef);
      await Promise.all(messagesSnapshot.docs.map(doc => deleteDoc(doc.ref)));

      await deleteDoc(doc(db, 'chats', selectedChat.id));

      setSelectedChat(null);
      setSelectedUsername(null);
      toast.success(t('success.chatDeleted'));
    } catch (error) {
      console.error('Error deleting chat:', error);
      toast.error(t('errors.failedToDelete'));
    }
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <header className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b`}>
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-4">
            {selectedChat && window.innerWidth < 768 && (
              <button
                onClick={() => {
                  setSelectedChat(null);
                  setShowChatList(true);
                }}
                className={`p-2 ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'} rounded-full`}
              >
                <Menu className={`h-5 w-5 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`} />
              </button>
            )}
            {!selectedChat && (
              <button
                onClick={() => setShowChatList(!showChatList)}
                className={`md:hidden flex items-center space-x-2 px-3 py-2 rounded-full ${
                  isDarkMode 
                    ? 'bg-gray-700 text-white hover:bg-gray-600' 
                    : 'bg-black text-white hover:bg-gray-800'
                }`}
                title={showChatList ? 'Hide chat list' : 'Show chat list'}
              >
                <Menu className="h-5 w-5" />
                <span className="text-sm">{t('title')}</span>
                {unreadCount > 0 && (
                  <span className="bg-red-500 text-white text-xs rounded-full px-2 py-0.5 min-w-[1.25rem]">
                    {unreadCount}
                  </span>
                )}
              </button>
            )}
            <h1 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-black'}`}>
              {selectedUsername ? `@${selectedUsername}` : t('title')}
            </h1>
          </div>
          <div className="flex items-center space-x-2">
            <button
              onClick={() => setShowInvite(true)}
              className={`flex items-center space-x-1 px-3 py-1.5 rounded-full text-xs ${
                isDarkMode 
                  ? 'bg-gray-700 text-white hover:bg-gray-600' 
                  : 'bg-black text-white hover:bg-gray-800'
              }`}
            >
              <Plus className="h-4 w-4" />
              <span>{t('newChat')}</span>
            </button>
            {selectedChat && (
              <button
                onClick={handleDeleteChat}
                className={`p-2 rounded-full text-red-500 ${
                  isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'
                }`}
                title={t('deleteChat')}
              >
                <Trash2 className="h-5 w-5" />
              </button>
            )}
          </div>
        </div>
        <MainMenu />
      </header>

      <main className={`max-w-2xl mx-auto ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-x min-h-screen flex`}>
        <div className={`${
          showChatList ? 'block' : 'hidden'
        } md:block ${selectedChat ? 'hidden md:block' : 'w-full md:w-80'} ${
          isDarkMode ? 'bg-gray-800' : 'bg-white'
        } z-10 h-[calc(100vh-64px)] border-r ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
          <ChatList
            selectedChat={selectedChat}
            onChatSelect={(chat) => {
              setSelectedChat(chat);
              if (window.innerWidth < 768) {
                setShowChatList(false);
              }
            }}
            isDarkMode={isDarkMode}
            language={language}
          />
        </div>

        {selectedChat ? (
          <div className="flex-1 flex flex-col h-[calc(100vh-64px)]">
            <div className={`fixed top-[113px] left-0 right-0 md:left-[320px] md:right-auto md:w-[calc(100%-320px)] max-w-2xl ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b p-4 z-20`}>
              <form onSubmit={handleSubmit}>
                <div className="flex items-center space-x-2">
                  <input
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder={
                      selectedChat.blockedBy?.includes(user?.uid || '')
                        ? t('blockedMessage')
                        : selectedChat.blockedBy?.some(id => id !== user?.uid)
                        ? t('youAreBlocked')
                        : t('typeMessage')
                    }
                    disabled={
                      selectedChat.blockedBy?.includes(user?.uid || '') ||
                      selectedChat.blockedBy?.some(id => id !== user?.uid)
                    }
                    className={`flex-1 px-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                      isDarkMode 
                        ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                        : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                    } disabled:opacity-50 disabled:cursor-not-allowed`}
                  />

                  <button
                    type="submit"
                    disabled={
                      !newMessage.trim() || 
                      sending || 
                      selectedChat.blockedBy?.includes(user?.uid || '') ||
                      selectedChat.blockedBy?.some(id => id !== user?.uid)
                    }
                    className={`p-2 ${
                      isDarkMode 
                        ? 'text-blue-400 hover:bg-gray-700' 
                        : 'text-blue-500 hover:bg-gray-100'
                    } rounded-full disabled:opacity-50`}
                  >
                    <Send className="h-5 w-5" />
                  </button>
                </div>
              </form>
            </div>

            <div 
              ref={messageContainerRef}
              className="flex-1 overflow-y-auto p-4 space-y-4 mt-[76px]"
            >
              {messages.map((msg) => (
                <div
                  key={msg.id}
                  className={`flex ${
                    msg.senderId === user?.uid ? 'justify-end' : 'justify-start'
                  }`}
                >
                  <div
                    className={`max-w-[80%] md:max-w-[70%] rounded-lg ${
                      msg.senderId === user?.uid
                        ? isDarkMode 
                          ? 'bg-blue-600 text-white' 
                          : 'bg-black text-white'
                        : isDarkMode 
                          ? 'bg-gray-700 text-white' 
                          : 'bg-gray-100 text-gray-900'
                    } p-3`}
                  >
                    <p className="break-words whitespace-pre-wrap">{msg.content}</p>
                  </div>
                </div>
              ))}
              <div ref={messagesEndRef} />
            </div>
          </div>
        ) : (
          <div className="flex-1 flex flex-col items-center justify-center p-4">
            <div className="text-center space-y-4">
              <MessageSquare className={`h-12 w-12 mx-auto ${isDarkMode ? 'text-gray-600' : 'text-gray-400'}`} />
              <h2 className={`text-xl font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                {t('yourMessages')}
              </h2>
              <p className={isDarkMode ? 'text-gray-400' : 'text-gray-500'}>
                {t('sendPrivate')}
              </p>
            </div>
          </div>
        )}
      </main>

      {showInvite && (
        <ChatInvite
          onClose={() => setShowInvite(false)}
          onInviteAccepted={handleStartNewChat}
          isDarkMode={isDarkMode}
          language={language}
        />
      )}
    </div>
  );
}