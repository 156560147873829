import { MAX_AD_IMAGE_SIZE, ACCEPTED_AD_IMAGE_TYPES } from './constants';

export async function validateAdImage(file: File): Promise<{ valid: boolean; error?: string }> {
  // Validate file size
  if (file.size > MAX_AD_IMAGE_SIZE) {
    return { 
      valid: false, 
      error: 'Image must be less than 5MB' 
    };
  }

  // Validate file type
  if (!ACCEPTED_AD_IMAGE_TYPES.includes(file.type)) {
    return { 
      valid: false, 
      error: 'Please select a valid image file (JPEG, PNG, or WebP)' 
    };
  }

  return { valid: true };
}