import { useState, useEffect } from 'react';
import { RotateCw, Loader2 } from 'lucide-react';

interface Props {
  isDarkMode?: boolean;
}

type CardColor = 'red' | 'blue' | 'green' | 'yellow';
type CardValue = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | 'skip' | 'reverse' | '+2';

interface Card {
  id: string;
  color: CardColor;
  value: CardValue;
}

const translations = {
  'pt-BR': {
    yourTurn: 'Sua vez!',
    computerTurn: 'Vez do computador',
    youWin: 'Você venceu! 🎉',
    computerWins: 'Computador venceu!',
    yourTurnAgain: 'Sua vez novamente!',
    computerPlaysAgain: 'Computador joga novamente!',
    computerDraws2: 'Computador compra 2! Sua vez novamente!',
    youDraw2: 'Você compra 2! Computador joga novamente!',
    computerCards: 'Cartas do computador',
    currentCard: 'Carta atual',
    yourCards: 'Suas cartas',
    drawCard: 'Comprar Carta',
    newGame: 'Novo Jogo',
    howToPlay: 'Como jogar:',
    matchCard: '• Combine a cor ou número da carta atual',
    specialCards: '• Cartas especiais:',
    skipCard: '  - Pular: Pula a vez do oponente',
    reverseCard: '  - Reverter: Age como Pular em jogo de 2 jogadores',
    draw2Card: '  - +2: Oponente compra 2 cartas',
    winCondition: '• Primeiro a jogar todas as cartas vence!'
  },
  'en': {
    yourTurn: 'Your turn!',
    computerTurn: 'Computer\'s turn',
    youWin: 'You win! 🎉',
    computerWins: 'Computer wins!',
    yourTurnAgain: 'Your turn again!',
    computerPlaysAgain: 'Computer plays again!',
    computerDraws2: 'Computer draws 2! Your turn again!',
    youDraw2: 'You draw 2! Computer plays again!',
    computerCards: 'Computer\'s cards',
    currentCard: 'Current card',
    yourCards: 'Your cards',
    drawCard: 'Draw Card',
    newGame: 'New Game',
    howToPlay: 'How to play:',
    matchCard: '• Match the color or number of the current card',
    specialCards: '• Special cards:',
    skipCard: '  - Skip: Skip opponent\'s turn',
    reverseCard: '  - Reverse: Acts like Skip in 2-player game',
    draw2Card: '  - +2: Opponent draws 2 cards',
    winCondition: '• First to play all cards wins!'
  }
};

const COLORS: CardColor[] = ['red', 'blue', 'green', 'yellow'];
const NUMBERS: CardValue[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
const ACTIONS: CardValue[] = ['skip', 'reverse', '+2'];

export function UnoGame({ isDarkMode = false }: Props) {
  const [deck, setDeck] = useState<Card[]>([]);
  const [playerHand, setPlayerHand] = useState<Card[]>([]);
  const [computerHand, setComputerHand] = useState<Card[]>([]);
  const [discardPile, setDiscardPile] = useState<Card[]>([]);
  const [currentPlayer, setCurrentPlayer] = useState<'player' | 'computer'>('player');
  const [gameOver, setGameOver] = useState(false);
  const [winner, setWinner] = useState<'player' | 'computer' | null>(null);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    return translations[language as keyof typeof translations][key as keyof (typeof translations)['en']];
  };

  useEffect(() => {
    initializeGame();
  }, []);

  const createDeck = (): Card[] => {
    const deck: Card[] = [];
    
    COLORS.forEach(color => {
      NUMBERS.forEach(value => {
        if (value === '0') {
          deck.push({ id: `${color}-${value}-1`, color, value });
        } else {
          deck.push({ id: `${color}-${value}-1`, color, value });
          deck.push({ id: `${color}-${value}-2`, color, value });
        }
      });
    });

    COLORS.forEach(color => {
      ACTIONS.forEach(value => {
        deck.push({ id: `${color}-${value}-1`, color, value });
        deck.push({ id: `${color}-${value}-2`, color, value });
      });
    });

    return shuffleDeck(deck);
  };

  const shuffleDeck = (deck: Card[]): Card[] => {
    return [...deck].sort(() => Math.random() - 0.5);
  };

  const dealCards = (deck: Card[]): {
    remainingDeck: Card[];
    playerHand: Card[];
    computerHand: Card[];
    firstCard: Card;
  } => {
    const playerHand = deck.slice(0, 7);
    const computerHand = deck.slice(7, 14);
    const firstCard = deck[14];
    const remainingDeck = deck.slice(15);

    return {
      remainingDeck,
      playerHand,
      computerHand,
      firstCard
    };
  };

  const initializeGame = () => {
    setLoading(true);
    const newDeck = createDeck();
    const { remainingDeck, playerHand, computerHand, firstCard } = dealCards(newDeck);

    setDeck(remainingDeck);
    setPlayerHand(playerHand);
    setComputerHand(computerHand);
    setDiscardPile([firstCard]);
    setCurrentPlayer('player');
    setGameOver(false);
    setWinner(null);
    setMessage(t('yourTurn'));
    setLoading(false);
  };

  const isValidPlay = (card: Card): boolean => {
    const topCard = discardPile[discardPile.length - 1];
    return card.color === topCard.color || card.value === topCard.value;
  };

  const playCard = (card: Card, isPlayer: boolean = true) => {
    if (isPlayer) {
      setPlayerHand(prev => prev.filter(c => c.id !== card.id));
    } else {
      setComputerHand(prev => prev.filter(c => c.id !== card.id));
    }

    setDiscardPile(prev => [...prev, card]);

    if (isPlayer && playerHand.length === 1) {
      setGameOver(true);
      setWinner('player');
      setMessage(t('youWin'));
      return;
    } else if (!isPlayer && computerHand.length === 1) {
      setGameOver(true);
      setWinner('computer');
      setMessage(t('computerWins'));
      return;
    }

    handleSpecialCard(card, isPlayer);
  };

  const handleSpecialCard = (card: Card, isPlayer: boolean) => {
    switch (card.value) {
      case 'skip':
        setMessage(isPlayer ? t('yourTurnAgain') : t('computerPlaysAgain'));
        break;
      case 'reverse':
        setMessage(isPlayer ? t('yourTurnAgain') : t('computerPlaysAgain'));
        break;
      case '+2':
        const newCards = deck.slice(0, 2);
        const remainingDeck = deck.slice(2);
        setDeck(remainingDeck);
        
        if (isPlayer) {
          setComputerHand(prev => [...prev, ...newCards]);
          setCurrentPlayer('player');
          setMessage(t('computerDraws2'));
        } else {
          setPlayerHand(prev => [...prev, ...newCards]);
          setCurrentPlayer('computer');
          setMessage(t('youDraw2'));
        }
        return;
      default:
        setCurrentPlayer(isPlayer ? 'computer' : 'player');
        setMessage(isPlayer ? t('computerTurn') : t('yourTurn'));
    }
  };

  const drawCard = (isPlayer: boolean = true) => {
    if (deck.length === 0) {
      const topCard = discardPile[discardPile.length - 1];
      const newDeck = shuffleDeck(discardPile.slice(0, -1));
      setDeck(newDeck);
      setDiscardPile([topCard]);
    }

    const newCard = deck[0];
    const remainingDeck = deck.slice(1);
    setDeck(remainingDeck);

    if (isPlayer) {
      setPlayerHand(prev => [...prev, newCard]);
      setCurrentPlayer('computer');
      setMessage(t('computerTurn'));
    } else {
      setComputerHand(prev => [...prev, newCard]);
      setCurrentPlayer('player');
      setMessage(t('yourTurn'));
    }
  };

  const computerPlay = () => {
    const validCards = computerHand.filter(isValidPlay);

    if (validCards.length > 0) {
      setTimeout(() => {
        playCard(validCards[0], false);
      }, 1000);
    } else {
      setTimeout(() => {
        drawCard(false);
      }, 1000);
    }
  };

  useEffect(() => {
    if (currentPlayer === 'computer' && !gameOver) {
      computerPlay();
    }
  }, [currentPlayer, gameOver]);

  if (loading) {
    return (
      <div className="flex justify-center items-center p-8">
        <Loader2 className={`h-8 w-8 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center p-4">
      <div className={`mb-4 text-lg font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        {message}
      </div>

      <div className="mb-8">
        <div className={`text-sm mb-2 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
          {t('computerCards')}: {computerHand.length}
        </div>
        <div className="flex gap-2 flex-wrap justify-center">
          {computerHand.map((_, index) => (
            <div
              key={index}
              className={`w-16 h-24 rounded-lg ${
                isDarkMode ? 'bg-gray-700' : 'bg-gray-200'
              } flex items-center justify-center`}
            >
              <div className={`text-2xl ${isDarkMode ? 'text-gray-500' : 'text-gray-400'}`}>
                ?
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="mb-8">
        <div className={`text-sm mb-2 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
          {t('currentCard')}
        </div>
        {discardPile.length > 0 && (
          <div
            className={`w-16 h-24 rounded-lg bg-${discardPile[discardPile.length - 1].color}-500 flex items-center justify-center text-white font-bold text-xl`}
          >
            {discardPile[discardPile.length - 1].value}
          </div>
        )}
      </div>

      <div>
        <div className={`text-sm mb-2 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
          {t('yourCards')}
        </div>
        <div className="flex gap-2 flex-wrap justify-center">
          {playerHand.map((card) => (
            <button
              key={card.id}
              onClick={() => isValidPlay(card) && playCard(card)}
              disabled={currentPlayer !== 'player' || !isValidPlay(card)}
              className={`w-16 h-24 rounded-lg bg-${card.color}-500 flex items-center justify-center text-white font-bold text-xl transition-transform hover:scale-105 ${
                isValidPlay(card) && currentPlayer === 'player'
                  ? 'cursor-pointer hover:-translate-y-2'
                  : 'opacity-70 cursor-not-allowed'
              }`}
            >
              {card.value}
            </button>
          ))}
        </div>
      </div>

      <div className="mt-8 flex gap-4">
        <button
          onClick={() => currentPlayer === 'player' && drawCard()}
          disabled={currentPlayer !== 'player'}
          className={`px-4 py-2 rounded-lg ${
            isDarkMode 
              ? 'bg-blue-600 text-white hover:bg-blue-700' 
              : 'bg-blue-500 text-white hover:bg-blue-600'
          } disabled:opacity-50`}
        >
          {t('drawCard')}
        </button>
        <button
          onClick={initializeGame}
          className={`px-4 py-2 rounded-lg flex items-center space-x-2 ${
            isDarkMode 
              ? 'bg-purple-600 text-white hover:bg-purple-700' 
              : 'bg-purple-500 text-white hover:bg-purple-600'
          }`}
        >
          <RotateCw className="h-5 w-5" />
          <span>{t('newGame')}</span>
        </button>
      </div>

      <div className={`mt-8 text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
        <p>{t('howToPlay')}</p>
        <p>{t('matchCard')}</p>
        <p>{t('specialCards')}</p>
        <p>{t('skipCard')}</p>
        <p>{t('reverseCard')}</p>
        <p>{t('draw2Card')}</p>
        <p>{t('winCondition')}</p>
      </div>
    </div>
  );
}