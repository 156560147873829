import { useEffect, useRef } from 'react';
import { loadGoogleMaps } from '../utils/maps';
import toast from 'react-hot-toast';

interface MapProps {
  center: { lat: number; lng: number };
  places: google.maps.places.PlaceResult[];
  onPlaceSelect: (place: google.maps.places.PlaceResult) => void;
}

export function Map({ center, places, onPlaceSelect }: MapProps) {
  const mapRef = useRef<HTMLDivElement>(null);
  const googleMapRef = useRef<google.maps.Map | null>(null);
  const markersRef = useRef<google.maps.Marker[]>([]);
  const markerClustererRef = useRef<any>(null);

  useEffect(() => {
    const initializeMap = async () => {
      try {
        const google = await loadGoogleMaps();
        
        if (!mapRef.current) return;

        const map = new google.maps.Map(mapRef.current, {
          center,
          zoom: 14,
          styles: [
            {
              featureType: 'poi',
              elementType: 'labels',
              stylers: [{ visibility: 'off' }]
            }
          ],
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false
        });

        googleMapRef.current = map;

        // Add user location marker
        new google.maps.Marker({
          position: center,
          map,
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 10,
            fillColor: '#4F46E5',
            fillOpacity: 0.4,
            strokeColor: '#4F46E5',
            strokeWeight: 2,
          },
          title: 'Your Location'
        });

      } catch (error) {
        console.error('Error initializing map:', error);
        toast.error('Failed to load map');
      }
    };

    initializeMap();

    return () => {
      markersRef.current.forEach(marker => marker.setMap(null));
      markersRef.current = [];
      if (markerClustererRef.current) {
        markerClustererRef.current.clearMarkers();
      }
    };
  }, [center]);

  useEffect(() => {
    if (!googleMapRef.current) return;

    // Clear existing markers
    markersRef.current.forEach(marker => marker.setMap(null));
    markersRef.current = [];

    // Add new markers
    places.forEach((place, index) => {
      if (!place.geometry?.location) return;

      const marker = new google.maps.Marker({
        position: place.geometry.location,
        map: googleMapRef.current!,
        title: place.name,
        animation: google.maps.Animation.DROP,
        label: {
          text: (index + 1).toString(),
          color: 'white',
          fontSize: '14px'
        }
      });

      marker.addListener('click', () => {
        onPlaceSelect(place);
      });

      markersRef.current.push(marker);
    });

    // Fit bounds if there are places
    if (places.length > 0) {
      const bounds = new google.maps.LatLngBounds();
      bounds.extend(center); // Include user location
      places.forEach(place => {
        if (place.geometry?.location) {
          bounds.extend(place.geometry.location);
        }
      });
      googleMapRef.current.fitBounds(bounds);
    }
  }, [places, center, onPlaceSelect]);

  return (
    <div ref={mapRef} className="w-full h-[400px] rounded-lg shadow-md" />
  );
}