import { useState, useEffect } from 'react';
import { Flame } from 'lucide-react';
import { useAuth } from '../components/AuthProvider';
import { useTheme } from '../contexts/ThemeContext';
import { MatchCard } from '../components/match/MatchCard';
import { EmptyMatchState } from '../components/match/EmptyMatchState';
import { useMatchProfiles } from '../hooks/useMatchProfiles';
import { MainMenu } from '../components/MainMenu';

const MATCH_LIMIT_KEY = 'poosting_match_limit';
const LIMIT_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

export function Match() {
  const { profiles, loading, currentIndex, setCurrentIndex, loadingMessage } = useMatchProfiles();
  const { isDarkMode } = useTheme();
  const [timeLeft, setTimeLeft] = useState<number | null>(null);
  const currentProfile = profiles[currentIndex];
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  useEffect(() => {
    const checkTimeLeft = () => {
      const limitData = localStorage.getItem(MATCH_LIMIT_KEY);
      if (limitData) {
        const { timestamp } = JSON.parse(limitData);
        const now = Date.now();
        const remaining = LIMIT_DURATION - (now - timestamp);
        
        if (remaining > 0) {
          setTimeLeft(remaining);
        } else {
          setTimeLeft(null);
          localStorage.removeItem(MATCH_LIMIT_KEY);
        }
      }
    };

    checkTimeLeft();
    const interval = setInterval(checkTimeLeft, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleNext = () => {
    setCurrentIndex(prev => {
      const nextIndex = prev + 1;
      if (nextIndex >= profiles.length) {
        // Show empty state when we reach the end
        return profiles.length;
      }
      return nextIndex;
    });
  };

  if (loading) {
    return (
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'} flex items-center justify-center`}>
        <div className="text-center p-4">
          <Flame className={`h-12 w-12 mx-auto mb-4 ${isDarkMode ? 'text-orange-400' : 'text-orange-500'} animate-pulse`} />
          <p className={`text-lg ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            {loadingMessage}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <header className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b`}>
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <Flame className={`h-6 w-6 ${isDarkMode ? 'text-orange-400' : 'text-orange-500'}`} />
            <h1 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-black'}`}>
              Poosting Match
            </h1>
          </div>
        </div>
        <MainMenu />
      </header>

      <main className={`max-w-2xl mx-auto ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-x min-h-screen`}>
        <div className="p-4 mt-8">
          {currentProfile ? (
            <div className="relative h-[60vh] flex items-center justify-center">
              <MatchCard 
                profile={currentProfile} 
                onNext={handleNext}
                isDarkMode={isDarkMode}
                language={language}
              />
            </div>
          ) : (
            <EmptyMatchState 
              isDarkMode={isDarkMode}
              timeLeft={timeLeft || undefined}
              language={language}
            />
          )}
        </div>
      </main>
    </div>
  );
}