import { formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export function formatTimeAgo(date: Date, language: string = 'en'): string {
  try {
    return formatDistanceToNow(date, { 
      addSuffix: true,
      locale: language === 'pt-BR' ? ptBR : undefined
    });
  } catch (error) {
    console.error('Error formatting time:', error);
    return 'agora';
  }
}

export function formatTopicDate(date: Date | null | undefined): string {
  if (!date || !(date instanceof Date) || isNaN(date.getTime())) {
    return 'Just now';
  }

  try {
    return formatDistanceToNow(date, { addSuffix: true });
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Just now';
  }
}