import { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useTheme } from '../contexts/ThemeContext';
import { MainMenu } from '../components/MainMenu';
import { Link } from 'react-router-dom';
import { BadgeCheck, Star, Loader2 } from 'lucide-react';
import { VerificationBadge } from '../components/VerificationBadge';
import toast from 'react-hot-toast';

interface VerifiedUser {
  uid: string;
  name: string;
  username: string;
  photoURL: string;
  allies: string[];
  bio?: string;
}

const translations = {
  'pt-BR': {
    title: 'Verificados',
    loading: 'Atualizando a lista de usuários...',
    noUsers: 'Nenhum usuário verificado encontrado',
    levels: {
      star: 'Status Estrela',
      elite: 'Status Elite',
      legend: 'Status Lenda',
      champion: 'Status Campeão'
    },
    requirements: {
      star: '1,000,000+ aliados',
      elite: '100,000+ aliados',
      legend: '10,000+ aliados',
      champion: '1,000+ aliados'
    }
  },
  'en': {
    title: 'Verified',
    loading: 'Updating users list...',
    noUsers: 'No verified users found',
    levels: {
      star: 'Star Status',
      elite: 'Elite Status',
      legend: 'Legend Status',
      champion: 'Champion Status'
    },
    requirements: {
      star: '1,000,000+ allies',
      elite: '100,000+ allies',
      legend: '10,000+ allies',
      champion: '1,000+ allies'
    }
  }
};

export function Verified() {
  const [users, setUsers] = useState<VerifiedUser[]>([]);
  const [loading, setLoading] = useState(true);
  const { isDarkMode } = useTheme();
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  useEffect(() => {
    const fetchVerifiedUsers = async () => {
      try {
        setLoading(true);
        const usersRef = collection(db, 'users');
        const usersSnapshot = await getDocs(usersRef);
        
        const verifiedUsers = usersSnapshot.docs
          .map(doc => ({
            uid: doc.id,
            ...doc.data()
          } as VerifiedUser))
          .filter(user => (user.allies?.length || 0) >= 1000)
          .sort((a, b) => (b.allies?.length || 0) - (a.allies?.length || 0));

        setUsers(verifiedUsers);
      } catch (error) {
        console.error('Error fetching verified users:', error);
        toast.error('Failed to load verified users');
      } finally {
        setLoading(false);
      }
    };

    fetchVerifiedUsers();
  }, []);

  const groupUsersByLevel = () => {
    const groups = {
      star: users.filter(user => (user.allies?.length || 0) >= 1000000),
      elite: users.filter(user => (user.allies?.length || 0) >= 100000 && (user.allies?.length || 0) < 1000000),
      legend: users.filter(user => (user.allies?.length || 0) >= 10000 && (user.allies?.length || 0) < 100000),
      champion: users.filter(user => (user.allies?.length || 0) >= 1000 && (user.allies?.length || 0) < 10000)
    };
    return groups;
  };

  const userGroups = groupUsersByLevel();

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <header className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b`}>
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <BadgeCheck className={`h-6 w-6 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
            <h1 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-black'}`}>
              {t('title')}
            </h1>
          </div>
        </div>
        <MainMenu />
      </header>

      <main className={`max-w-2xl mx-auto ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-x min-h-screen`}>
        {loading ? (
          <div className="flex flex-col items-center justify-center p-8 space-y-4">
            <Loader2 className={`h-8 w-8 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
            <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
              {t('loading')}
            </p>
          </div>
        ) : users.length > 0 ? (
          <div className="divide-y divide-gray-200">
            {/* Star Status */}
            {userGroups.star.length > 0 && (
              <div className={`p-4 ${isDarkMode ? 'bg-yellow-900/20' : 'bg-yellow-50'}`}>
                <div className="flex items-center space-x-2 mb-4">
                  <Star className="h-5 w-5 text-yellow-500" />
                  <h2 className={`text-lg font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                    {t('levels.star')}
                  </h2>
                  <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                    ({t('requirements.star')})
                  </span>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  {userGroups.star.map(user => (
                    <UserCard key={user.uid} user={user} isDarkMode={isDarkMode} />
                  ))}
                </div>
              </div>
            )}

            {/* Elite Status */}
            {userGroups.elite.length > 0 && (
              <div className={`p-4 ${isDarkMode ? 'bg-gray-800' : 'bg-gray-50'}`}>
                <div className="flex items-center space-x-2 mb-4">
                  <BadgeCheck className="h-5 w-5 text-black" />
                  <h2 className={`text-lg font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                    {t('levels.elite')}
                  </h2>
                  <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                    ({t('requirements.elite')})
                  </span>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  {userGroups.elite.map(user => (
                    <UserCard key={user.uid} user={user} isDarkMode={isDarkMode} />
                  ))}
                </div>
              </div>
            )}

            {/* Legend Status */}
            {userGroups.legend.length > 0 && (
              <div className={`p-4 ${isDarkMode ? 'bg-red-900/20' : 'bg-red-50'}`}>
                <div className="flex items-center space-x-2 mb-4">
                  <BadgeCheck className="h-5 w-5 text-red-500" />
                  <h2 className={`text-lg font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                    {t('levels.legend')}
                  </h2>
                  <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                    ({t('requirements.legend')})
                  </span>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  {userGroups.legend.map(user => (
                    <UserCard key={user.uid} user={user} isDarkMode={isDarkMode} />
                  ))}
                </div>
              </div>
            )}

            {/* Champion Status */}
            {userGroups.champion.length > 0 && (
              <div className={`p-4 ${isDarkMode ? 'bg-blue-900/20' : 'bg-blue-50'}`}>
                <div className="flex items-center space-x-2 mb-4">
                  <BadgeCheck className="h-5 w-5 text-blue-500" />
                  <h2 className={`text-lg font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                    {t('levels.champion')}
                  </h2>
                  <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                    ({t('requirements.champion')})
                  </span>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  {userGroups.champion.map(user => (
                    <UserCard key={user.uid} user={user} isDarkMode={isDarkMode} />
                  ))}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center p-8">
            <BadgeCheck className={`h-12 w-12 ${isDarkMode ? 'text-gray-600' : 'text-gray-400'} mb-4`} />
            <p className={`text-lg ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
              {t('noUsers')}
            </p>
          </div>
        )}
      </main>
    </div>
  );
}

function UserCard({ user, isDarkMode }: { user: VerifiedUser; isDarkMode: boolean }) {
  return (
    <Link
      to={`/profile/${user.username}`}
      className={`flex items-center space-x-3 p-3 rounded-lg ${
        isDarkMode ? 'bg-gray-700 hover:bg-gray-600' : 'bg-white hover:bg-gray-50'
      } transition-colors shadow-sm`}
    >
      <img
        src={user.photoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${user.uid}`}
        alt={user.name}
        className="w-12 h-12 rounded-full"
      />
      <div className="flex-1 min-w-0">
        <div className="flex items-center space-x-2">
          <h3 className={`font-medium truncate ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            {user.name}
          </h3>
          <VerificationBadge alliesCount={user.allies?.length || 0} />
        </div>
        <p className={`text-sm truncate ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
          @{user.username}
        </p>
        {user.bio && (
          <p className={`text-xs truncate mt-1 ${isDarkMode ? 'text-gray-500' : 'text-gray-400'}`}>
            {user.bio}
          </p>
        )}
      </div>
      <div className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
        {(user.allies?.length || 0).toLocaleString()}
      </div>
    </Link>
  );
}