import { useState, useEffect, useRef } from 'react';
import { ChevronUp, ChevronDown, ChevronLeft, ChevronRight } from 'lucide-react';

interface Props {
  isDarkMode?: boolean;
}

const GRID_SIZE = 20;
const CELL_SIZE = 20;
const PACMAN_SPEED = 200;

type Direction = 'up' | 'down' | 'left' | 'right';
type Cell = 'empty' | 'wall' | 'dot' | 'powerPellet' | 'ghost' | 'pacman';

export function PacmanGame({ isDarkMode = false }: Props) {
  const [grid, setGrid] = useState<Cell[][]>([]);
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [direction, setDirection] = useState<Direction>('right');
  const [pacmanPosition, setPacmanPosition] = useState({ x: 1, y: 1 });
  const [ghosts, setGhosts] = useState<{ x: number; y: number; direction: Direction }[]>([]);
  const [powerMode, setPowerMode] = useState(false);
  const moveIntervalRef = useRef<number>();

  // Initialize game grid
  useEffect(() => {
    const initialGrid: Cell[][] = Array(GRID_SIZE).fill('empty').map(() => 
      Array(GRID_SIZE).fill('dot')
    );

    // Add walls
    for (let i = 0; i < GRID_SIZE; i++) {
      initialGrid[0][i] = 'wall';
      initialGrid[GRID_SIZE - 1][i] = 'wall';
      initialGrid[i][0] = 'wall';
      initialGrid[i][GRID_SIZE - 1] = 'wall';
    }

    // Add power pellets
    initialGrid[1][1] = 'powerPellet';
    initialGrid[1][GRID_SIZE - 2] = 'powerPellet';
    initialGrid[GRID_SIZE - 2][1] = 'powerPellet';
    initialGrid[GRID_SIZE - 2][GRID_SIZE - 2] = 'powerPellet';

    // Add some walls for maze-like structure
    for (let i = 3; i < GRID_SIZE - 3; i += 2) {
      for (let j = 3; j < GRID_SIZE - 3; j += 2) {
        initialGrid[i][j] = 'wall';
      }
    }

    setGrid(initialGrid);

    // Initialize ghosts
    setGhosts([
      { x: GRID_SIZE - 2, y: GRID_SIZE - 2, direction: 'left' },
      { x: GRID_SIZE - 2, y: 1, direction: 'right' },
      { x: 1, y: GRID_SIZE - 2, direction: 'up' },
      { x: Math.floor(GRID_SIZE / 2), y: Math.floor(GRID_SIZE / 2), direction: 'down' }
    ]);
  }, []);

  const moveGhosts = () => {
    setGhosts(prevGhosts => prevGhosts.map(ghost => {
      const possibleDirections: Direction[] = ['up', 'down', 'left', 'right'];
      const validDirections = possibleDirections.filter(dir => {
        let newX = ghost.x;
        let newY = ghost.y;
        
        switch (dir) {
          case 'up': newY--; break;
          case 'down': newY++; break;
          case 'left': newX--; break;
          case 'right': newX++; break;
        }

        return grid[newY]?.[newX] !== 'wall';
      });

      if (validDirections.length === 0) return ghost;

      const newDirection = validDirections[Math.floor(Math.random() * validDirections.length)];
      let newX = ghost.x;
      let newY = ghost.y;

      switch (newDirection) {
        case 'up': newY--; break;
        case 'down': newY++; break;
        case 'left': newX--; break;
        case 'right': newX++; break;
      }

      return { x: newX, y: newY, direction: newDirection };
    }));
  };

  const checkCollision = () => {
    const collision = ghosts.some(ghost => 
      ghost.x === pacmanPosition.x && ghost.y === pacmanPosition.y
    );

    if (collision) {
      if (powerMode) {
        setScore(prev => prev + 200);
        setGhosts(prevGhosts => prevGhosts.filter(ghost => 
          ghost.x !== pacmanPosition.x || ghost.y !== pacmanPosition.y
        ));
      } else {
        setGameOver(true);
      }
    }
  };

  const movePacman = (newDirection: Direction) => {
    if (gameOver || isPaused) return;

    let newX = pacmanPosition.x;
    let newY = pacmanPosition.y;

    switch (newDirection) {
      case 'up': newY--; break;
      case 'down': newY++; break;
      case 'left': newX--; break;
      case 'right': newX++; break;
    }

    if (grid[newY]?.[newX] !== 'wall') {
      setPacmanPosition({ x: newX, y: newY });
      setDirection(newDirection);

      // Collect dots and power pellets
      if (grid[newY][newX] === 'dot') {
        setScore(prev => prev + 10);
        const newGrid = [...grid];
        newGrid[newY][newX] = 'empty';
        setGrid(newGrid);
      } else if (grid[newY][newX] === 'powerPellet') {
        setScore(prev => prev + 50);
        const newGrid = [...grid];
        newGrid[newY][newX] = 'empty';
        setGrid(newGrid);
        setPowerMode(true);
        setTimeout(() => setPowerMode(false), 10000);
      }

      checkCollision();
    }
  };

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowUp': movePacman('up'); break;
        case 'ArrowDown': movePacman('down'); break;
        case 'ArrowLeft': movePacman('left'); break;
        case 'ArrowRight': movePacman('right'); break;
        case 'p': setIsPaused(prev => !prev); break;
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [pacmanPosition, grid, gameOver, isPaused]);

  useEffect(() => {
    if (gameOver || isPaused) {
      clearInterval(moveIntervalRef.current);
      return;
    }

    moveIntervalRef.current = window.setInterval(() => {
      moveGhosts();
      checkCollision();
    }, PACMAN_SPEED);

    return () => clearInterval(moveIntervalRef.current);
  }, [gameOver, isPaused, pacmanPosition]);

  const resetGame = () => {
    setGameOver(false);
    setIsPaused(false);
    setScore(0);
    setPacmanPosition({ x: 1, y: 1 });
    setDirection('right');
    setPowerMode(false);
    
    // Reset grid and ghosts
    const initialGrid: Cell[][] = Array(GRID_SIZE).fill('empty').map(() => 
      Array(GRID_SIZE).fill('dot')
    );

    for (let i = 0; i < GRID_SIZE; i++) {
      initialGrid[0][i] = 'wall';
      initialGrid[GRID_SIZE - 1][i] = 'wall';
      initialGrid[i][0] = 'wall';
      initialGrid[i][GRID_SIZE - 1] = 'wall';
    }

    initialGrid[1][1] = 'powerPellet';
    initialGrid[1][GRID_SIZE - 2] = 'powerPellet';
    initialGrid[GRID_SIZE - 2][1] = 'powerPellet';
    initialGrid[GRID_SIZE - 2][GRID_SIZE - 2] = 'powerPellet';

    for (let i = 3; i < GRID_SIZE - 3; i += 2) {
      for (let j = 3; j < GRID_SIZE - 3; j += 2) {
        initialGrid[i][j] = 'wall';
      }
    }

    setGrid(initialGrid);
    setGhosts([
      { x: GRID_SIZE - 2, y: GRID_SIZE - 2, direction: 'left' },
      { x: GRID_SIZE - 2, y: 1, direction: 'right' },
      { x: 1, y: GRID_SIZE - 2, direction: 'up' },
      { x: Math.floor(GRID_SIZE / 2), y: Math.floor(GRID_SIZE / 2), direction: 'down' }
    ]);
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className={`mb-4 text-lg font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        Score: {score}
      </div>

      <div 
        className={`grid gap-0 ${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'}`}
        style={{
          gridTemplateColumns: `repeat(${GRID_SIZE}, ${CELL_SIZE}px)`,
          padding: '2px',
          borderRadius: '8px'
        }}
      >
        {grid.map((row, y) => row.map((cell, x) => {
          const isPacman = pacmanPosition.x === x && pacmanPosition.y === y;
          const isGhost = ghosts.some(ghost => ghost.x === x && ghost.y === y);

          let cellContent = null;
          if (isPacman) {
            cellContent = '😮';
          } else if (isGhost) {
            cellContent = powerMode ? '👻' : '👾';
          } else if (cell === 'dot') {
            cellContent = '·';
          } else if (cell === 'powerPellet') {
            cellContent = '⭐';
          }

          return (
            <div
              key={`${y}-${x}`}
              className={`flex items-center justify-center ${
                cell === 'wall' 
                  ? isDarkMode ? 'bg-blue-900' : 'bg-blue-800'
                  : isDarkMode ? 'bg-gray-900' : 'bg-white'
              }`}
              style={{
                width: CELL_SIZE,
                height: CELL_SIZE,
                fontSize: cell === 'dot' ? '20px' : '16px'
              }}
            >
              {cellContent}
            </div>
          );
        }))}
      </div>

      {/* Mobile Controls */}
      <div className="mt-6 grid grid-cols-3 gap-4">
        <div />
        <button
          onTouchStart={() => movePacman('up')}
          onClick={() => movePacman('up')}
          className={`p-3 rounded-full ${
            isDarkMode 
              ? 'bg-gray-700 text-white hover:bg-gray-600' 
              : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
          }`}
        >
          <ChevronUp className="h-6 w-6" />
        </button>
        <div />
        <button
          onTouchStart={() => movePacman('left')}
          onClick={() => movePacman('left')}
          className={`p-3 rounded-full ${
            isDarkMode 
              ? 'bg-gray-700 text-white hover:bg-gray-600' 
              : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
          }`}
        >
          <ChevronLeft className="h-6 w-6" />
        </button>
        <div />
        <button
          onTouchStart={() => movePacman('right')}
          onClick={() => movePacman('right')}
          className={`p-3 rounded-full ${
            isDarkMode 
              ? 'bg-gray-700 text-white hover:bg-gray-600' 
              : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
          }`}
        >
          <ChevronRight className="h-6 w-6" />
        </button>
        <div />
        <button
          onTouchStart={() => movePacman('down')}
          onClick={() => movePacman('down')}
          className={`p-3 rounded-full ${
            isDarkMode 
              ? 'bg-gray-700 text-white hover:bg-gray-600' 
              : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
          }`}
        >
          <ChevronDown className="h-6 w-6" />
        </button>
        <div />
      </div>

      {/* Game Over/Pause Overlay */}
      {(gameOver || isPaused) && (
        <div 
          className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center"
          onClick={gameOver ? resetGame : () => setIsPaused(false)}
        >
          <div className="text-white text-center">
            <p className="text-xl font-bold mb-2">
              {gameOver ? 'Game Over!' : 'Paused'}
            </p>
            <p className="text-sm">
              {gameOver ? 'Click to restart' : 'Click to resume'}
            </p>
          </div>
        </div>
      )}

      <div className={`mt-4 text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
      
      </div>
    </div>
  );
}