import { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Link as LinkIcon, Loader2 } from 'lucide-react';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import { useTheme } from '../contexts/ThemeContext';
import { MainMenu } from '../components/MainMenu';
import { getAllyUsers } from '../utils/allies';
import { chunkArray } from '../utils/posts/helpers';
import { UserPosts } from '../components/UserPosts';
import { SnakeGame } from '../components/SnakeGame';
import { formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import toast from 'react-hot-toast';

const translations = {
  'pt-BR': {
    title: 'Poosts Aliados',
    loading: 'Selecionando poosts dos seus aliados...',
    loadingMore: 'Carregando mais...',
    showMore: 'Mostrar Mais',
    noAllies: 'Você ainda não tem aliados',
    followUsers: 'Siga outros usuários para ver seus posts aqui',
    viewPosts: 'Ver posts',
    noPosts: 'Nenhum post nas últimas 24 horas',
    playWhileLoading: 'Jogue enquanto carregamos os posts!',
    errors: {
      loadFailed: 'Falha ao carregar posts'
    }
  },
  'en': {
    title: 'Poosts Allies',
    loading: 'Selecting posts from your allies...',
    loadingMore: 'Loading more...',
    showMore: 'Show More',
    noAllies: 'You don\'t have any allies yet',
    followUsers: 'Follow other users to see their posts here',
    viewPosts: 'View posts',
    noPosts: 'No posts in the last 24 hours',
    playWhileLoading: 'Play while we load the posts!',
    errors: {
      loadFailed: 'Failed to load posts'
    }
  }
};

interface AllyUser {
  uid: string;
  username: string;
  name: string;
  photoURL: string;
  lastPostTime: Date;
  allies: string[];
}

const CHUNK_SIZE = 30; // Maximum size for 'IN' clause
const INITIAL_DISPLAY_COUNT = 10;

export function AlliesPosts() {
  const [allAllyUsers, setAllAllyUsers] = useState<AllyUser[]>([]);
  const [displayedUsers, setDisplayedUsers] = useState<AllyUser[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [selectedUsername, setSelectedUsername] = useState<string | null>(null);
  const { user } = useAuth();
  const { isDarkMode } = useTheme();
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = useCallback((key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  }, [language]);

  useEffect(() => {
    loadAllyPosts();
  }, [user]);

  const loadAllyPosts = async () => {
    if (!user) return;

    try {
      setLoading(true);
      
      // Get user's allies with their data
      const allies = await getAllyUsers(user.uid);
      
      if (allies.length === 0) {
        setLoading(false);
        return;
      }

      // Get posts from last 24 hours
      const twentyFourHoursAgo = new Date();
      twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);

      // Get ally IDs that are mutual (they follow back)
      const mutualAllyIds = allies
        .filter(ally => ally.allies?.includes(user.uid))
        .map(ally => ally.uid);

      if (mutualAllyIds.length === 0) {
        setLoading(false);
        return;
      }

      // Split ally IDs into chunks of 30 (Firestore IN clause limit)
      const allyIdChunks = chunkArray(mutualAllyIds, CHUNK_SIZE);
      const allPosts: any[] = [];

      // Fetch posts for each chunk of allies
      for (const chunk of allyIdChunks) {
        const postsRef = collection(db, 'posts');
        const q = query(
          postsRef,
          where('authorId', 'in', chunk),
          where('timestamp', '>=', twentyFourHoursAgo),
          orderBy('timestamp', 'desc')
        );

        const snapshot = await getDocs(q);
        const chunkPosts = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate() || new Date()
        }));

        allPosts.push(...chunkPosts);
      }

      // Create a map of users with their latest post time
      const userMap = new Map<string, AllyUser>();
      allPosts.forEach(post => {
        // Only include posts from mutual allies
        if (mutualAllyIds.includes(post.authorId)) {
          if (!userMap.has(post.authorId) || post.timestamp > userMap.get(post.authorId)!.lastPostTime) {
            const ally = allies.find(a => a.uid === post.authorId);
            if (ally) {
              userMap.set(post.authorId, {
                uid: post.authorId,
                username: post.authorUsername,
                name: post.authorName,
                photoURL: post.authorPhotoURL,
                lastPostTime: post.timestamp,
                allies: ally.allies || []
              });
            }
          }
        }
      });

      // Convert map to array and sort by latest post time
      const sortedUsers = Array.from(userMap.values())
        .sort((a, b) => b.lastPostTime.getTime() - a.lastPostTime.getTime());

      setAllAllyUsers(sortedUsers);
      setDisplayedUsers(sortedUsers.slice(0, INITIAL_DISPLAY_COUNT));
    } catch (error) {
      console.error('Error loading posts:', error);
      toast.error(t('errors.loadFailed'));
    } finally {
      setLoading(false);
    }
  };

  const loadMore = () => {
    setLoadingMore(true);
    const currentLength = displayedUsers.length;
    const nextUsers = allAllyUsers.slice(currentLength, currentLength + INITIAL_DISPLAY_COUNT);
    setDisplayedUsers(prev => [...prev, ...nextUsers]);
    setLoadingMore(false);
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <header className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b`}>
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <LinkIcon className={`h-6 w-6 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
            <h1 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-black'}`}>
              {t('title')}
            </h1>
          </div>
        </div>
        <MainMenu />
      </header>

      <main className={`max-w-2xl mx-auto ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-x min-h-screen`}>
        {loading ? (
          <div className="flex flex-col items-center py-8">
            <div className="text-center mb-8">
              <Loader2 className={`h-8 w-8 animate-spin mx-auto mb-4 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
              <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                {t('loading')}
              </p>
              <p className={`mt-2 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                {t('playWhileLoading')}
              </p>
            </div>
            
            <div className="w-full max-w-md mx-auto">
              <SnakeGame isDarkMode={isDarkMode} />
            </div>
          </div>
        ) : allAllyUsers.length > 0 ? (
          <div className={`p-4 ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
            <div className="space-y-4">
              {displayedUsers.map((allyUser) => (
                <div 
                  key={allyUser.uid}
                  className={`flex items-center justify-between p-4 rounded-lg ${
                    isDarkMode ? 'bg-gray-700' : 'bg-gray-50'
                  }`}
                >
                  <div className="flex items-center space-x-3">
                    <Link to={`/profile/${allyUser.username}`}>
                      <img
                        src={allyUser.photoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${allyUser.uid}`}
                        alt={allyUser.name}
                        className="w-10 h-10 rounded-full"
                      />
                    </Link>
                    <div>
                      <Link 
                        to={`/profile/${allyUser.username}`}
                        className={`font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'} hover:underline`}
                      >
                        @{allyUser.username}
                      </Link>
                      <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                        {formatDistanceToNow(allyUser.lastPostTime, {
                          addSuffix: true,
                          locale: language === 'pt-BR' ? ptBR : undefined
                        })}
                      </p>
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      setSelectedUserId(allyUser.uid);
                      setSelectedUsername(allyUser.username);
                    }}
                    className={`px-4 py-2 rounded-full text-sm ${
                      isDarkMode 
                        ? 'bg-blue-600 text-white hover:bg-blue-700' 
                        : 'bg-black text-white hover:bg-gray-800'
                    }`}
                  >
                    {t('viewPosts')}
                  </button>
                </div>
              ))}

              {displayedUsers.length < allAllyUsers.length && (
                <div className="flex justify-center mt-6">
                  <button
                    onClick={loadMore}
                    disabled={loadingMore}
                    className={`px-6 py-2 rounded-full disabled:opacity-50 flex items-center space-x-2 ${
                      isDarkMode 
                        ? 'bg-gray-700 text-white hover:bg-gray-600' 
                        : 'bg-black text-white hover:bg-gray-800'
                    }`}
                  >
                    {loadingMore ? (
                      <>
                        <Loader2 className="h-4 w-4 animate-spin" />
                        <span>{t('loadingMore')}</span>
                      </>
                    ) : (
                      <span>{t('showMore')}</span>
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="text-center py-8">
            <LinkIcon className={`h-12 w-12 mx-auto mb-4 ${isDarkMode ? 'text-gray-600' : 'text-gray-400'}`} />
            <p className={`text-lg font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              {user?.uid ? t('noPosts') : t('noAllies')}
            </p>
            {!user?.uid && (
              <p className={`mt-2 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                {t('followUsers')}
              </p>
            )}
          </div>
        )}

        {selectedUserId && selectedUsername && (
          <UserPosts
            userId={selectedUserId}
            username={selectedUsername}
            onClose={() => {
              setSelectedUserId(null);
              setSelectedUsername(null);
            }}
            isDarkMode={isDarkMode}
          />
        )}
      </main>
    </div>
  );
}