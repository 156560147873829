import { useState, useEffect, useRef } from 'react';
import { collection, query, where, onSnapshot, doc, updateDoc, increment } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { X, Loader2, Heart, Languages, MessageCircle, Send, Trash2, Eye, EyeOff, BadgeCheck, Star, Crown, Gift, DollarSign, RefreshCw, MousePointer, ChevronRight, ChevronLeft } from 'lucide-react';
import { Link } from 'react-router-dom';
import { VerificationBadge } from './VerificationBadge';
import { ProfileProgress } from './ProfileProgress';
import toast from 'react-hot-toast';

interface BoostedPostsSliderProps {
  postCount: number;
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    title: 'Poosting Ads',
    loading: 'Carregando...',
    noResults: 'Nenhum anúncio encontrado',
    learnMore: 'Saiba Mais',
    views: 'visualizações',
    clicks: 'cliques',
    previous: 'Anterior',
    next: 'Próximo'
  },
  'en': {
    title: 'Poosting Ads',
    loading: 'Loading...',
    noResults: 'No ads found',
    learnMore: 'Learn More',
    views: 'views',
    clicks: 'clicks',
    previous: 'Previous',
    next: 'Next'
  }
};

interface BoostedPost {
  id: string;
  content: string;
  authorId: string;
  authorName: string;
  authorUsername: string;
  authorPhotoURL: string;
  imageUrl?: string;
  boostConfig: {
    frequency: number;
    startTime: Date;
    endTime: Date;
    externalLink?: string | null;
    paused?: boolean;
  };
  views: number;
  clicks: number;
}

const POST_INTERVAL = 30; // Show boosted post every 30 posts
const SLIDE_DURATION = 30000; // 30 seconds per slide

export function BoostedPostsSlider({ postCount, isDarkMode = false, language = 'en' }: BoostedPostsSliderProps) {
  const [boostedPosts, setBoostedPosts] = useState<BoostedPost[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [imagesLoaded, setImagesLoaded] = useState<{ [key: string]: boolean }>({});
  const timerRef = useRef<number>();
  const viewedRef = useRef<Set<string>>(new Set());
  const lastShownIndexRef = useRef<number>(-1);
  const preloadedImagesRef = useRef<{ [key: string]: HTMLImageElement }>({});
  const transitioningRef = useRef(false);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  // Function to get a random index different from the last shown
  const getRandomIndex = (max: number) => {
    if (max === 0) return 0;
    if (max === 1) return 0;
    
    let newIndex;
    do {
      newIndex = Math.floor(Math.random() * max);
    } while (newIndex === lastShownIndexRef.current);
    
    lastShownIndexRef.current = newIndex;
    return newIndex;
  };

  // Preload images function with error handling
  const preloadImages = (posts: BoostedPost[]) => {
    posts.forEach(post => {
      if (post.imageUrl && !preloadedImagesRef.current[post.imageUrl]) {
        const img = new Image();
        
        img.onload = () => {
          setImagesLoaded(prev => ({ ...prev, [post.imageUrl!]: true }));
        };
        
        img.onerror = () => {
          console.error(`Failed to load image: ${post.imageUrl}`);
          setImagesLoaded(prev => ({ ...prev, [post.imageUrl!]: false }));
        };
        
        img.src = post.imageUrl;
        preloadedImagesRef.current[post.imageUrl] = img;
      }
    });
  };

  useEffect(() => {
    if (postCount === 0 || postCount % POST_INTERVAL !== 0) {
      return;
    }

    const now = new Date();
    const postsRef = collection(db, 'posts');
    const q = query(
      postsRef,
      where('boosted', '==', true)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const activePosts = snapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data(),
          boostConfig: {
            ...doc.data().boostConfig,
            startTime: doc.data().boostConfig?.startTime?.toDate(),
            endTime: doc.data().boostConfig?.endTime?.toDate()
          },
          views: doc.data().views || 0,
          clicks: doc.data().clicks || 0
        }))
        .filter(post => {
          if (!post.boostConfig?.startTime || !post.boostConfig?.endTime) return false;
          if (post.boostConfig.paused) return false;
          
          const startTime = post.boostConfig.startTime.getTime();
          const endTime = post.boostConfig.endTime.getTime();
          const currentTime = now.getTime();

          return currentTime >= startTime && currentTime <= endTime;
        });

      // Shuffle posts array for random initial order
      const shuffledPosts = [...activePosts].sort(() => Math.random() - 0.5);
      setBoostedPosts(shuffledPosts);

      // Preload all images
      preloadImages(shuffledPosts);

      // Set random initial index
      if (!transitioningRef.current) {
        const randomIndex = getRandomIndex(shuffledPosts.length);
        setCurrentIndex(randomIndex);

        // Update views for the first shown post if not already viewed
        if (shuffledPosts.length > 0 && !viewedRef.current.has(shuffledPosts[randomIndex].id)) {
          const postRef = doc(db, 'posts', shuffledPosts[randomIndex].id);
          updateDoc(postRef, {
            views: increment(1)
          }).catch(console.error);
          viewedRef.current.add(shuffledPosts[randomIndex].id);
        }
      }

      setLoading(false);
    });

    return () => {
      unsubscribe();
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [postCount]);

  // Set up rotation timer
  useEffect(() => {
    if (boostedPosts.length <= 1) return;

    timerRef.current = window.setInterval(() => {
      transitioningRef.current = true;
      setCurrentIndex(prev => {
        const nextIndex = (prev + 1) % boostedPosts.length;
        
        // Update views for the next post if not already viewed
        const nextPostId = boostedPosts[nextIndex].id;
        if (!viewedRef.current.has(nextPostId)) {
          const postRef = doc(db, 'posts', nextPostId);
          updateDoc(postRef, {
            views: increment(1)
          }).catch(console.error);
          viewedRef.current.add(nextPostId);
        }

        return nextIndex;
      });
      
      // Reset transitioning flag after a short delay
      setTimeout(() => {
        transitioningRef.current = false;
      }, 500);
    }, SLIDE_DURATION);

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [boostedPosts.length]);

  const handlePrevious = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    
    transitioningRef.current = true;
    setCurrentIndex(prev => {
      const nextIndex = prev === 0 ? boostedPosts.length - 1 : prev - 1;
      
      // Update views for the next post if not already viewed
      const nextPostId = boostedPosts[nextIndex].id;
      if (!viewedRef.current.has(nextPostId)) {
        const postRef = doc(db, 'posts', nextPostId);
        updateDoc(postRef, {
          views: increment(1)
        }).catch(console.error);
        viewedRef.current.add(nextPostId);
      }

      return nextIndex;
    });
    
    // Reset transitioning flag after a short delay
    setTimeout(() => {
      transitioningRef.current = false;
    }, 500);
  };

  const handleNext = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    
    transitioningRef.current = true;
    setCurrentIndex(prev => {
      const nextIndex = (prev + 1) % boostedPosts.length;
      
      // Update views for the next post if not already viewed
      const nextPostId = boostedPosts[nextIndex].id;
      if (!viewedRef.current.has(nextPostId)) {
        const postRef = doc(db, 'posts', nextPostId);
        updateDoc(postRef, {
          views: increment(1)
        }).catch(console.error);
        viewedRef.current.add(nextPostId);
      }

      return nextIndex;
    });
    
    // Reset transitioning flag after a short delay
    setTimeout(() => {
      transitioningRef.current = false;
    }, 500);
  };

  const incrementClicks = async (postId: string) => {
    try {
      const postRef = doc(db, 'posts', postId);
      await updateDoc(postRef, {
        clicks: increment(1)
      });
    } catch (error) {
      console.error('Error incrementing clicks:', error);
    }
  };

  const handleExternalLink = (url: string, postId: string) => {
    // Increment clicks
    incrementClicks(postId);

    // Check if running on iOS
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    
    if (isIOS) {
      // Use window.open with _system target for iOS Safari
      window.open(url, '_system');
    } else {
      // Regular external link handling for other platforms
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  if (!shouldShowBoostedPost(postCount) || boostedPosts.length === 0) {
    return null;
  }

  if (loading) {
    return (
      <div className="flex justify-center py-4">
        <Loader2 className={`h-6 w-6 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
      </div>
    );
  }

  const currentPost = boostedPosts[currentIndex];
  if (!currentPost) return null;

  return (
    <div className={`border-b ${isDarkMode ? 'border-gray-700' : 'border-gray-200'} py-4`}>
      <div className="relative w-full max-w-2xl mx-auto">
        {/* Navigation Arrows */}
        {boostedPosts.length > 1 && (
          <>
            <button
              onClick={handlePrevious}
              className={`absolute left-4 top-1/2 transform -translate-y-1/2 z-10 p-2 ${
                isDarkMode ? 'bg-gray-800/80' : 'bg-white/80'
              } rounded-full shadow-lg hover:bg-opacity-100 transition-opacity`}
              title={t('previous')}
            >
              <ChevronLeft className={`h-6 w-6 ${isDarkMode ? 'text-white' : 'text-gray-800'}`} />
            </button>
            <button
              onClick={handleNext}
              className={`absolute right-4 top-1/2 transform -translate-y-1/2 z-10 p-2 ${
                isDarkMode ? 'bg-gray-800/80' : 'bg-white/80'
              } rounded-full shadow-lg hover:bg-opacity-100 transition-opacity`}
              title={t('next')}
            >
              <ChevronRight className={`h-6 w-6 ${isDarkMode ? 'text-white' : 'text-gray-800'}`} />
            </button>
          </>
        )}

        {/* Square aspect ratio container */}
        <div className="aspect-square w-full overflow-hidden rounded-lg">
          {currentPost.imageUrl ? (
            <>
              {/* Loading placeholder */}
              {!imagesLoaded[currentPost.imageUrl] && (
                <div className={`w-full h-full flex items-center justify-center ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
                  <Loader2 className={`h-8 w-8 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
                </div>
              )}
              {/* Actual image */}
              <img
                src={currentPost.imageUrl}
                alt={currentPost.content}
                className={`w-full h-full object-cover transition-opacity duration-300 ${
                  imagesLoaded[currentPost.imageUrl] ? 'opacity-100' : 'opacity-0'
                }`}
                style={{ display: imagesLoaded[currentPost.imageUrl] ? 'block' : 'none' }}
              />
            </>
          ) : (
            <div className={`w-full h-full flex items-center justify-center p-6 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'}`}>
              <p className={`text-lg text-center ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                {currentPost.content}
              </p>
            </div>
          )}
        </div>

        <div className={`mt-4 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'} rounded-lg p-4`}>
          <Link 
            to={`/profile/${currentPost.authorUsername}`}
            className="flex items-center space-x-4"
          >
            <img
              src={currentPost.authorPhotoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${currentPost.authorId}`}
              alt={currentPost.authorName}
              className="w-8 h-8 rounded-full"
            />
            <span className={`font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              {currentPost.authorName}
            </span>
          </Link>

          {/* Post description */}
          <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
            {currentPost.content}
          </p>

          {/* Metrics */}
          <div className="flex items-center space-x-4 mb-4">
            <div className="flex items-center space-x-1">
              <Eye className={`h-4 w-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`} />
              <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                {currentPost.views} {t('views')}
              </span>
            </div>
            <div className="flex items-center space-x-1">
              <MousePointer className={`h-4 w-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`} />
              <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                {currentPost.clicks} {t('clicks')}
              </span>
            </div>
          </div>

          {currentPost.boostConfig.externalLink && (
            <button
              onClick={() => handleExternalLink(currentPost.boostConfig.externalLink!, currentPost.id)}
              className={`mt-4 w-full flex items-center justify-center space-x-2 px-4 py-2 ${
                isDarkMode 
                  ? 'bg-blue-600 hover:bg-blue-700' 
                  : 'bg-blue-500 hover:bg-blue-600'
              } text-white rounded-lg transition-colors`}
            >
              <span>{t('learnMore')}</span>
              <ChevronRight className="h-4 w-4" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

function shouldShowBoostedPost(postCount: number): boolean {
  return postCount > 0 && postCount % POST_INTERVAL === 0;
}