import { useState, useEffect, useRef } from 'react';
import { auth, storage, db, analyticsEvents } from '../lib/firebase';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  fetchSignInMethodsForEmail,
  sendPasswordResetEmail,
  AuthError
} from 'firebase/auth';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, query, where, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { Loader2, Moon, Sun } from 'lucide-react';
import toast from 'react-hot-toast';
import { debounce } from 'lodash';
import { ImageUpload } from '../components/ImageUpload';
import { AuthInput } from '../components/AuthInput';
import { isValidEmail, isValidUsername, isValidPassword, isValidName } from '../utils/validation';
import { addDeviceRegistration } from '../utils/deviceLimit';
import { useTheme } from '../contexts/ThemeContext';
import ReCAPTCHA from 'react-google-recaptcha';

const RECAPTCHA_SITE_KEY = '6LclLdgqAAAAAEd7zwlPmxB0aT1btgNL21mDKCNa';

// Translations object
const translations = {
  'pt-BR': {
    title: 'Poosting',
    subtitle: 'Pense diferente! Poste para o mundo.',
    signIn: {
      title: 'Entrar',
      emailPlaceholder: 'Endereço de e-mail',
      passwordPlaceholder: 'Senha',
      submitButton: 'Entrar',
      forgotPassword: 'Esqueceu sua senha?',
      sendingReset: 'Enviando...',
      resetSuccess: 'E-mail de redefinição enviado! Verifique sua caixa de entrada',
      resetError: 'Falha ao enviar e-mail de redefinição',
      noAccount: 'Não tem uma conta? Cadastre-se'
    },
    signUp: {
      title: 'Criar Conta',
      photoLabel: 'Foto do Perfil',
      namePlaceholder: 'Nome completo',
      usernamePlaceholder: '@usuário',
      emailPlaceholder: 'Endereço de e-mail',
      passwordPlaceholder: 'Senha',
      termsCheckbox: 'Aceito os termos de uso e política de cookies',
      ageCheckbox: 'Confirmo que tenho 18 anos ou mais',
      submitButton: 'Criar Conta',
      uploadPhoto: 'Enviar Foto',
      photoRequired: 'Foto do perfil é obrigatória',
      processing: 'Processando...',
      creating: 'Criando...'
    },
    errors: {
      emailRequired: 'Por favor, insira seu e-mail',
      invalidEmail: 'Por favor, insira um e-mail válido',
      noAccount: 'Nenhuma conta encontrada com este e-mail',
      nameRequired: 'Por favor, insira seu nome',
      invalidName: 'Nome pode conter apenas letras e espaços',
      usernameInvalid: 'Nome de usuário deve ter entre 3-20 caracteres e conter apenas letras, números e underscores',
      usernameUnavailable: 'Nome de usuário não está disponível',
      termsRequired: 'Você deve aceitar os termos de uso e política de cookies',
      ageRequired: 'Você deve confirmar que tem 18 anos ou mais',
      photoRequired: 'Por favor, envie uma foto de perfil',
      captchaRequired: 'Por favor, complete a verificação reCAPTCHA',
      deviceLimit: 'Limite de dispositivos atingido. Máximo 3 contas por dispositivo.'
    }
  },
  'en': {
    title: 'Poosting',
    subtitle: 'Think different! Post to the world.',
    signIn: {
      title: 'Sign In',
      emailPlaceholder: 'Email address',
      passwordPlaceholder: 'Password',
      submitButton: 'Sign in',
      forgotPassword: 'Forgot your password?',
      sendingReset: 'Sending...',
      resetSuccess: 'Password reset email sent! Please check your inbox',
      resetError: 'Failed to send reset email',
      noAccount: "Don't have an account? Sign up"
    },
    signUp: {
      title: 'Create Account',
      photoLabel: 'Profile Photo',
      namePlaceholder: 'Full name',
      usernamePlaceholder: '@username',
      emailPlaceholder: 'Email address',
      passwordPlaceholder: 'Password',
      termsCheckbox: 'I accept the terms of use and cookie policy',
      ageCheckbox: 'I am 18 years or older',
      submitButton: 'Create Account',
      uploadPhoto: 'Upload Photo',
      photoRequired: 'Profile photo is required',
      processing: 'Processing...',
      creating: 'Creating...'
    },
    errors: {
      emailRequired: 'Please enter your email',
      invalidEmail: 'Please enter a valid email address',
      noAccount: 'No account found with this email address',
      nameRequired: 'Please enter your name',
      invalidName: 'Name can only contain letters and spaces',
      usernameInvalid: 'Username must be 3-20 characters and contain only letters, numbers, and underscores',
      usernameUnavailable: 'Username is not available',
      termsRequired: 'You must accept the terms of use and cookie policy',
      ageRequired: 'You must confirm that you are 18 years or older',
      photoRequired: 'Please upload a profile photo',
      captchaRequired: 'Please complete the reCAPTCHA verification',
      deviceLimit: 'Device limit reached. Maximum 3 accounts per device.'
    }
  }
};

export function Auth() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [username, setUsername] = useState('@');
  const [loading, setLoading] = useState(false);
  const [profileImage, setProfileImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [isCheckingUsername, setIsCheckingUsername] = useState(false);
  const [isUsernameAvailable, setIsUsernameAvailable] = useState<boolean | null>(null);
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);
  const [isEmailAvailable, setIsEmailAvailable] = useState<boolean | null>(null);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isAdult, setIsAdult] = useState(false);
  const [sendingResetEmail, setSendingResetEmail] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [language, setLanguage] = useState('en');
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const navigate = useNavigate();
  const { isDarkMode, toggleTheme } = useTheme();

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  const handleUsernameChange = (value: string) => {
    if (!value.startsWith('@')) {
      setUsername('@' + value);
    } else {
      setUsername(value);
    }
  };

  const handleImageSelect = (file: File) => {
    setProfileImage(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const checkUsername = async (username: string) => {
    if (!username || !isValidUsername(username.slice(1))) {
      setIsUsernameAvailable(null);
      return;
    }

    setIsCheckingUsername(true);
    try {
      const cleanUsername = username.slice(1).toLowerCase();
      const q = query(
        collection(db, 'users'),
        where('username', '==', cleanUsername)
      );
      const snapshot = await getDocs(q);
      setIsUsernameAvailable(snapshot.empty);
    } catch (error) {
      console.error('Error checking username:', error);
      setIsUsernameAvailable(false);
    } finally {
      setIsCheckingUsername(false);
    }
  };

  const checkEmail = async (email: string) => {
    if (!email || !isValidEmail(email)) {
      setIsEmailAvailable(null);
      return;
    }

    setIsCheckingEmail(true);
    try {
      const methods = await fetchSignInMethodsForEmail(auth, email);
      setIsEmailAvailable(methods.length === 0);
      
      if (methods.length > 0) {
        toast.error(t('errors.emailInUse'));
      }
    } catch (error) {
      console.error('Error checking email:', error);
      setIsEmailAvailable(false);
    } finally {
      setIsCheckingEmail(false);
    }
  };

  const debouncedCheckUsername = debounce(checkUsername, 500);
  const debouncedCheckEmail = debounce(checkEmail, 500);

  useEffect(() => {
    if (username.length > 1) {
      debouncedCheckUsername(username);
    }
    return () => debouncedCheckUsername.cancel();
  }, [username]);

  useEffect(() => {
    if (email) {
      debouncedCheckEmail(email);
    }
    return () => debouncedCheckEmail.cancel();
  }, [email]);

  const handleForgotPassword = async () => {
    if (!email) {
      toast.error(t('errors.emailRequired'));
      return;
    }

    if (!isValidEmail(email)) {
      toast.error(t('errors.invalidEmail'));
      return;
    }

    if (!captchaToken) {
      toast.error(t('errors.captchaRequired'));
      return;
    }

    try {
      setSendingResetEmail(true);
      await sendPasswordResetEmail(auth, email);
      toast.success(t('signIn.resetSuccess'));
    } catch (error: any) {
      console.error('Error sending reset email:', error);
      if (error.code === 'auth/user-not-found') {
        toast.error(t('errors.noAccount'));
      } else {
        toast.error(t('signIn.resetError'));
      }
    } finally {
      setSendingResetEmail(false);
      recaptchaRef.current?.reset();
      setCaptchaToken(null);
    }
  };

  const validateSignUpForm = () => {
    if (!name.trim()) {
      toast.error(t('errors.nameRequired'));
      return false;
    }

    if (!isValidName(name)) {
      toast.error(t('errors.invalidName'));
      return false;
    }

    if (!isValidUsername(username.slice(1))) {
      toast.error(t('errors.usernameInvalid'));
      return false;
    }

    if (!isUsernameAvailable) {
      toast.error(t('errors.usernameUnavailable'));
      return false;
    }

    if (!termsAccepted) {
      toast.error(t('errors.termsRequired'));
      return false;
    }

    if (!isAdult) {
      toast.error(t('errors.ageRequired'));
      return false;
    }

    if (!profileImage) {
      toast.error(t('errors.photoRequired'));
      return false;
    }

    if (!isValidEmail(email)) {
      toast.error(t('errors.invalidEmail'));
      return false;
    }

    if (!isEmailAvailable) {
      toast.error(t('errors.emailInUse'));
      return false;
    }

    if (!isValidPassword(password)) {
      toast.error(t('errors.invalidPassword'));
      return false;
    }

    return true;
  };

  const handleSignIn = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isValidEmail(email) || !password || loading) return;

    if (!captchaToken) {
      toast.error(t('errors.captchaRequired'));
      return;
    }

    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      analyticsEvents.login();
      toast.success('Você Entrou! Welcome back.');
      navigate('/');
    } catch (error: any) {
      console.error('Sign in error:', error);
      if (error.code === 'auth/invalid-credential') {
        toast.error('Email or password are wrong. Please try again.');
      } else {
        toast.error('Authentication failed. Please try again.');
      }
    } finally {
      setLoading(false);
      recaptchaRef.current?.reset();
      setCaptchaToken(null);
    }
  };

  const handleSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateSignUpForm() || loading) return;

    if (!captchaToken) {
      toast.error(t('errors.captchaRequired'));
      return;
    }

    if (!addDeviceRegistration(email)) {
      toast.error(t('errors.deviceLimit'));
      return;
    }

    setLoading(true);
    try {
      const { user } = await createUserWithEmailAndPassword(auth, email, password);
      
      let photoURL = '';
      
      if (profileImage) {
        const storageRef = ref(storage, `avatars/${user.uid}`);
        await uploadBytes(storageRef, profileImage);
        photoURL = await getDownloadURL(storageRef);
      }

      const cleanUsername = username.slice(1).toLowerCase();

      await addDoc(collection(db, 'users'), {
        uid: user.uid,
        name: name.trim(),
        username: cleanUsername,
        displayName: cleanUsername,
        photoURL,
        createdAt: serverTimestamp(),
        allies: [],
        likes: [],
        stars: [],
        trash: [],
        coins: 10,
        previousAlliesCount: 0
      });

      await updateProfile(user, {
        displayName: username,
        photoURL,
      });

      analyticsEvents.signup();
      toast.success(t('signUp.success'));
      navigate('/');
    } catch (error: any) {
      console.error('Sign up error:', error);
      toast.error(error.message || t('signUp.error'));
    } finally {
      setLoading(false);
      recaptchaRef.current?.reset();
      setCaptchaToken(null);
    }
  };

  return (
    <div className={`min-h-screen py-12 px-4 sm:px-6 lg:px-8 ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <div className="max-w-md w-full mx-auto space-y-8">
        {/* Centered logo and theme toggle */}
        <div className="text-center relative">
          <button
            onClick={toggleTheme}
            className={`absolute right-0 top-0 p-2 rounded-full ${
              isDarkMode 
                ? 'bg-gray-800 text-yellow-400 hover:bg-gray-700' 
                : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
            }`}
            title={isDarkMode ? 'Switch to light mode' : 'Switch to dark mode'}
          >
            {isDarkMode ? <Sun className="h-5 w-5" /> : <Moon className="h-5 w-5" />}
          </button>
          
          <h1 className={`text-5xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-black'}`}>
            {t('title')}
          </h1>
          <p className={`text-lg ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
            {t('subtitle')}
          </p>
        </div>

        {/* Sign In Form */}
        <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} p-8 rounded-lg shadow-md`}>
          <h2 className={`text-2xl font-bold text-center mb-6 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            {t('signIn.title')}
          </h2>
          <form onSubmit={handleSignIn} className="space-y-4">
            <AuthInput
              id="signin-email"
              type="email"
              label="Email"
              value={email}
              onChange={setEmail}
              placeholder={t('signIn.emailPlaceholder')}
              isDarkMode={isDarkMode}
            />
            <AuthInput
              id="signin-password"
              type="password"
              label="Password"
              value={password}
              onChange={setPassword}
              placeholder={t('signIn.passwordPlaceholder')}
              isDarkMode={isDarkMode}
            />

            {/* Add reCAPTCHA */}
            <div className="mt-4 flex justify-center">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={RECAPTCHA_SITE_KEY}
                onChange={(token) => setCaptchaToken(token)}
                theme={isDarkMode ? 'dark' : 'light'}
              />
            </div>

            <button
              type="submit"
              disabled={loading || !captchaToken}
              className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                isDarkMode 
                  ? 'bg-blue-600 hover:bg-blue-700' 
                  : 'bg-black hover:bg-gray-800'
              } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:opacity-50`}
            >
              {loading ? <Loader2 className="h-5 w-5 animate-spin" /> : t('signIn.submitButton')}
            </button>
            <div className="text-center mt-4">
              <button
                type="button"
                onClick={handleForgotPassword}
                disabled={sendingResetEmail}
                className={`text-sm ${
                  isDarkMode 
                    ? 'text-blue-400 hover:text-blue-300' 
                    : 'text-blue-600 hover:text-blue-500'
                }`}
              >
                {sendingResetEmail ? t('signIn.sendingReset') : t('signIn.forgotPassword')}
              </button>
            </div>
          </form>
        </div>

        {/* Sign Up Form */}
        <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} p-8 rounded-lg shadow-md`}>
          <h2 className={`text-2xl font-bold text-center mb-6 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            {t('signUp.title')}
          </h2>
          <form onSubmit={handleSignUp} className="space-y-6">
            <div className="text-center mb-6">
              <p className={`text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'} mb-2`}>
                {t('signUp.photoLabel')}
              </p>
              <ImageUpload
                imagePreview={imagePreview}
                onImageSelect={handleImageSelect}
                isDarkMode={isDarkMode}
              />
            </div>

            <AuthInput
              id="name"
              type="text"
              label="Name"
              value={name}
              onChange={setName}
              placeholder={t('signUp.namePlaceholder')}
              maxLength={30}
              isDarkMode={isDarkMode}
            />

            <div>
              <p className={`text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'} mb-2`}>Username</p>
              <AuthInput
                id="username"
                type="text"
                label="Username"
                value={username}
                onChange={handleUsernameChange}
                placeholder={t('signUp.usernamePlaceholder')}
                isChecking={isCheckingUsername}
                isValid={isUsernameAvailable}
                maxLength={20}
                isDarkMode={isDarkMode}
              />
            </div>

            <AuthInput
              id="signup-email"
              type="email"
              label="Email"
              value={email}
              onChange={setEmail}
              placeholder={t('signUp.emailPlaceholder')}
              isChecking={isCheckingEmail}
              isValid={isEmailAvailable}
              isDarkMode={isDarkMode}
            />

            <AuthInput
              id="signup-password"
              type="password"
              label="Password"
              value={password}
              onChange={setPassword}
              placeholder={t('signUp.passwordPlaceholder')}
              isDarkMode={isDarkMode}
            />

            <div className="space-y-4">
              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="age-verification"
                    name="age-verification"
                    type="checkbox"
                    checked={isAdult}
                    onChange={(e) => setIsAdult(e.target.checked)}
                    className={`h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 ${
                      !isAdult ? 'ring-2 ring-red-500' : ''
                    }`}
                    required
                  />
                </div>
                <div className="ml-3">
                  <label htmlFor="age-verification" className={`text-sm ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  } ${!isAdult ? 'text-red-500' : ''}`}>
                    {t('signUp.ageCheckbox')} *
                  </label>
                </div>
              </div>

              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="terms"
                    name="terms"
                    type="checkbox"
                    checked={termsAccepted}
                    onChange={(e) => setTermsAccepted(e.target.checked)}
                    className={`h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 ${
                      !termsAccepted ? 'ring-2 ring-red-500' : ''
                    }`}
                    required
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="terms" className={`${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  } ${!termsAccepted ? 'text-red-500' : ''}`}>
                    {t('signUp.termsCheckbox')} *
                  </label>
                </div>
              </div>
            </div>

            {/* Add reCAPTCHA before the sign up button */}
            <div className="mt-4 flex justify-center">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={RECAPTCHA_SITE_KEY}
                onChange={(token) => setCaptchaToken(token)}
                theme={isDarkMode ? 'dark' : 'light'}
              />
            </div>

            <button
              type="submit"
              disabled={loading || !captchaToken || !isAdult || !termsAccepted}
              className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                isDarkMode 
                  ? 'bg-blue-600 hover:bg-blue-700' 
                  : 'bg-black hover:bg-gray-800'
              } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:opacity-50`}
            >
              {loading ? <Loader2 className="h-5 w-5 animate-spin" /> : t('signUp.submitButton')}
            </button>
          </form>
        </div>

        <div className="text-center space-y-2">
          <p className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
            Created by CEO - Afonso Alcântara
          </p>
          <a
            href="https://poosting.com.br/doc/en/privacy-policy.html"
            target="_blank"
            rel="noopener noreferrer"
            className="block text-xs text-blue-600 hover:text-blue-500"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
}