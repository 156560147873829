import { useState, useEffect, useCallback } from 'react';
import { RotateCw, ChevronUp, ChevronDown, ChevronLeft, ChevronRight } from 'lucide-react';

interface Props {
  isDarkMode?: boolean;
}

const BOARD_SIZE = 8; // Reduced from 15 to 8 for better mobile display
const CELL_SIZE = 25; // Reduced from 30 to 25 for better mobile display

type Cell = {
  top: boolean;
  right: boolean;
  bottom: boolean;
  left: boolean;
  visited: boolean;
  isPath: boolean;
  isPlayer: boolean;
  isEnd: boolean;
};

type Position = {
  row: number;
  col: number;
};

const translations = {
  'pt-BR': {
    title: 'Labirinto',
    moves: 'Movimentos',
    youWin: 'Você venceu! 🎉',
    newGame: 'Novo Jogo',
    select: 'Selecionar',
    move: 'Mover',
    controls: {
      title: 'Controles:',
      move: 'Mover: Setas',
      tip: 'Dica: Encontre o caminho até a estrela!'
    }
  },
  'en': {
    title: 'Maze',
    moves: 'Moves',
    youWin: 'You win! 🎉',
    newGame: 'New Game',
    select: 'Select',
    move: 'Move',
    controls: {
      title: 'Controls:',
      move: 'Move: Arrows',
      tip: 'Tip: Find your way to the star!'
    }
  }
};

export function MazeGame({ isDarkMode = false }: Props) {
  const [board, setBoard] = useState<Cell[][]>([]);
  const [playerPos, setPlayerPos] = useState({ x: 0, y: 0 });
  const [gameWon, setGameWon] = useState(false);
  const [moves, setMoves] = useState(0);
  const [language, setLanguage] = useState('en');
  const [cursorPosition, setCursorPosition] = useState<Position>({ row: 0, col: 0 });

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  const initializeBoard = useCallback(() => {
    const newBoard: Cell[][] = Array(BOARD_SIZE).fill(null)
      .map(() => Array(BOARD_SIZE).fill(null).map(() => ({
        top: true,
        right: true,
        bottom: true,
        left: true,
        visited: false,
        isPath: false,
        isPlayer: false,
        isEnd: false
      })));

    // Generate maze using depth-first search
    const stack: [number, number][] = [];
    const startX = 0;
    const startY = 0;
    newBoard[startY][startX].visited = true;
    stack.push([startX, startY]);

    while (stack.length > 0) {
      const [currentX, currentY] = stack[stack.length - 1];
      const neighbors: [number, number, string][] = [];

      // Check neighbors
      if (currentY > 0 && !newBoard[currentY - 1][currentX].visited)
        neighbors.push([currentX, currentY - 1, 'top']);
      if (currentX < BOARD_SIZE - 1 && !newBoard[currentY][currentX + 1].visited)
        neighbors.push([currentX + 1, currentY, 'right']);
      if (currentY < BOARD_SIZE - 1 && !newBoard[currentY + 1][currentX].visited)
        neighbors.push([currentX, currentY + 1, 'bottom']);
      if (currentX > 0 && !newBoard[currentY][currentX - 1].visited)
        neighbors.push([currentX - 1, currentY, 'left']);

      if (neighbors.length > 0) {
        const [nextX, nextY, direction] = neighbors[Math.floor(Math.random() * neighbors.length)];
        
        // Remove walls between current and next cell
        switch (direction) {
          case 'top':
            newBoard[currentY][currentX].top = false;
            newBoard[nextY][nextX].bottom = false;
            break;
          case 'right':
            newBoard[currentY][currentX].right = false;
            newBoard[nextY][nextX].left = false;
            break;
          case 'bottom':
            newBoard[currentY][currentX].bottom = false;
            newBoard[nextY][nextX].top = false;
            break;
          case 'left':
            newBoard[currentY][currentX].left = false;
            newBoard[nextY][nextX].right = false;
            break;
        }

        newBoard[nextY][nextX].visited = true;
        stack.push([nextX, nextY]);
      } else {
        stack.pop();
      }
    }

    // Set start and end points
    newBoard[0][0].isPlayer = true;
    newBoard[BOARD_SIZE - 1][BOARD_SIZE - 1].isEnd = true;

    // Reset visited property
    for (let y = 0; y < BOARD_SIZE; y++) {
      for (let x = 0; x < BOARD_SIZE; x++) {
        newBoard[y][x].visited = false;
      }
    }

    setBoard(newBoard);
    setPlayerPos({ x: 0, y: 0 });
    setGameWon(false);
    setMoves(0);
  }, []);

  useEffect(() => {
    initializeBoard();
  }, [initializeBoard]);

  const movePlayer = (dx: number, dy: number) => {
    if (gameWon) return;

    const newX = playerPos.x + dx;
    const newY = playerPos.y + dy;

    // Check if move is valid
    if (
      newX >= 0 && newX < BOARD_SIZE &&
      newY >= 0 && newY < BOARD_SIZE
    ) {
      const currentCell = board[playerPos.y][playerPos.x];
      
      // Check walls
      if (
        (dx === 1 && !currentCell.right) ||
        (dx === -1 && !currentCell.left) ||
        (dy === 1 && !currentCell.bottom) ||
        (dy === -1 && !currentCell.top)
      ) {
        const newBoard = [...board];
        newBoard[playerPos.y][playerPos.x].isPlayer = false;
        newBoard[newY][newX].isPlayer = true;
        
        setBoard(newBoard);
        setPlayerPos({ x: newX, y: newY });
        setMoves(moves + 1);

        // Check if player reached the end
        if (newX === BOARD_SIZE - 1 && newY === BOARD_SIZE - 1) {
          setGameWon(true);
        }
      }
    }
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowUp':
          movePlayer(0, -1);
          break;
        case 'ArrowDown':
          movePlayer(0, 1);
          break;
        case 'ArrowLeft':
          movePlayer(-1, 0);
          break;
        case 'ArrowRight':
          movePlayer(1, 0);
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [playerPos, board, gameWon]);

  return (
    <div className="flex flex-col items-center justify-center">
      <div className={`mb-4 text-lg font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        {gameWon ? (
          <div className="text-center">
            <p>{t('youWin')}</p>
            <p className="text-sm">{t('moves')}: {moves}</p>
          </div>
        ) : (
          <div>{t('moves')}: {moves}</div>
        )}
      </div>

      <div 
        className={`grid gap-0 p-2 rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'}`}
        style={{
          gridTemplateColumns: `repeat(${BOARD_SIZE}, ${CELL_SIZE}px)`,
          gridTemplateRows: `repeat(${BOARD_SIZE}, ${CELL_SIZE}px)`
        }}
      >
        {board.map((row, y) =>
          row.map((cell, x) => (
            <div
              key={`${x}-${y}`}
              className={`relative ${isDarkMode ? 'bg-gray-700' : 'bg-white'}`}
              style={{
                borderTop: cell.top ? '2px solid' : 'none',
                borderRight: cell.right ? '2px solid' : 'none',
                borderBottom: cell.bottom ? '2px solid' : 'none',
                borderLeft: cell.left ? '2px solid' : 'none',
                borderColor: isDarkMode ? '#4B5563' : '#D1D5DB'
              }}
            >
              {cell.isPlayer && (
                <div className={`absolute inset-0 flex items-center justify-center ${
                  isDarkMode ? 'text-blue-400' : 'text-blue-500'
                }`}>
                  ●
                </div>
              )}
              {cell.isEnd && (
                <div className={`absolute inset-0 flex items-center justify-center ${
                  isDarkMode ? 'text-green-400' : 'text-green-500'
                }`}>
                  ⭐
                </div>
              )}
            </div>
          ))
        )}
      </div>

      {/* Mobile Controls */}
      <div className="mt-6 grid grid-cols-3 gap-4">
        <div />
        <button
          onClick={() => movePlayer(0, -1)}
          className={`p-3 rounded-full ${
            isDarkMode 
              ? 'bg-gray-700 text-white hover:bg-gray-600' 
              : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
          }`}
        >
          <ChevronUp className="h-6 w-6" />
        </button>
        <div />
        <button
          onClick={() => movePlayer(-1, 0)}
          className={`p-3 rounded-full ${
            isDarkMode 
              ? 'bg-gray-700 text-white hover:bg-gray-600' 
              : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
          }`}
        >
          <ChevronLeft className="h-6 w-6" />
        </button>
        <button
          onClick={initializeBoard}
          className={`p-3 rounded-full ${
            isDarkMode 
              ? 'bg-blue-600 text-white hover:bg-blue-700' 
              : 'bg-blue-500 text-white hover:bg-blue-600'
          }`}
        >
          <RotateCw className="h-6 w-6" />
        </button>
        <button
          onClick={() => movePlayer(1, 0)}
          className={`p-3 rounded-full ${
            isDarkMode 
              ? 'bg-gray-700 text-white hover:bg-gray-600' 
              : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
          }`}
        >
          <ChevronRight className="h-6 w-6" />
        </button>
        <div />
        <button
          onClick={() => movePlayer(0, 1)}
          className={`p-3 rounded-full ${
            isDarkMode 
              ? 'bg-gray-700 text-white hover:bg-gray-600' 
              : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
          }`}
        >
          <ChevronDown className="h-6 w-6" />
        </button>
        <div />
      </div>

      <div className={`mt-4 text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
        <p className="font-medium">{t('controls.title')}</p>
        <p>{t('controls.move')}</p>
        <p className="mt-2 italic">{t('controls.tip')}</p>
      </div>
    </div>
  );
}