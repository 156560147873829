import { useState, useEffect, useRef } from 'react';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Gift, Loader2, ChevronLeft, ChevronRight, X } from 'lucide-react';
import { Link } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';

interface Gift {
  id: string;
  emoji: string;
  name: string;
  senderId: string;
  senderName: string;
  senderUsername: string;
  senderPhotoURL: string;
  timestamp: Date;
}

interface PostGiftsProps {
  postId: string;
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    title: 'Presentes',
    loading: 'Carregando presentes...',
    noGifts: 'Nenhum presente ainda',
    beFirst: 'Seja o primeiro a enviar um presente!',
    from: 'de',
    sent: 'enviou'
  },
  'en': {
    title: 'Gifts',
    loading: 'Loading gifts...',
    noGifts: 'No gifts yet',
    beFirst: 'Be the first to send a gift!',
    from: 'from',
    sent: 'sent'
  }
};

export function PostGifts({ postId, isDarkMode = false, language = 'en' }: PostGiftsProps) {
  const [gifts, setGifts] = useState<Gift[]>([]);
  const [loading, setLoading] = useState(true);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [selectedGift, setSelectedGift] = useState<Gift | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const t = (key: string) => {
    return translations[language as keyof typeof translations][key as keyof (typeof translations)['en']];
  };

  useEffect(() => {
    const commentsRef = collection(db, 'posts', postId, 'gifts');
    const q = query(commentsRef, orderBy('timestamp', 'desc'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const giftsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate() || new Date()
      })) as Gift[];

      setGifts(giftsData);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [postId]);

  const checkScrollButtons = () => {
    if (!containerRef.current) return;
    
    const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
    setShowLeftArrow(scrollLeft > 0);
    setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
  };

  useEffect(() => {
    checkScrollButtons();
    window.addEventListener('resize', checkScrollButtons);
    return () => window.removeEventListener('resize', checkScrollButtons);
  }, [gifts]);

  const scroll = (direction: 'left' | 'right') => {
    if (!containerRef.current) return;

    const scrollAmount = 200;
    const container = containerRef.current;
    const newScrollLeft = direction === 'left' 
      ? container.scrollLeft - scrollAmount 
      : container.scrollLeft + scrollAmount;

    container.scrollTo({
      left: newScrollLeft,
      behavior: 'smooth'
    });
  };

  if (loading) {
    return (
      <div className="flex justify-center py-4">
        <Loader2 className={`h-6 w-6 animate-spin ${isDarkMode ? 'text-pink-400' : 'text-pink-500'}`} />
      </div>
    );
  }

  if (gifts.length === 0) {
    return null;
  }

  return (
    <div className="py-4 mb-2">
      <h3 className={`text-sm font-medium mb-4 flex items-center ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        <Gift className={`h-4 w-4 mr-2 ${isDarkMode ? 'text-pink-400' : 'text-pink-500'}`} />
        {t('title')} ({gifts.length})
      </h3>

      <div className="relative">
        {showLeftArrow && (
          <button
            onClick={() => scroll('left')}
            className={`absolute left-0 top-1/2 transform -translate-y-1/2 z-10 p-1 ${
              isDarkMode 
                ? 'bg-gray-700 text-white hover:bg-gray-600' 
                : 'bg-white text-gray-600 hover:bg-gray-100'
            } rounded-full shadow-lg transition-colors`}
          >
            <ChevronLeft className="h-5 w-5" />
          </button>
        )}

        <div 
          ref={containerRef}
          className="flex space-x-2 overflow-x-auto scrollbar-hide scroll-smooth px-6 pb-2"
          onScroll={checkScrollButtons}
        >
          {gifts.map((gift) => (
            <div
              key={gift.id}
              className="relative group flex-none cursor-pointer mb-1"
              onClick={() => setSelectedGift(gift)}
            >
              <div 
                className={`w-10 h-10 flex items-center justify-center rounded-full ${
                  isDarkMode ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-100 hover:bg-gray-200'
                } text-xl transition-all duration-200 transform hover:scale-110`}
                style={{
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06)',
                  transform: 'translateZ(0)'
                }}
              >
                {gift.emoji}
              </div>
            </div>
          ))}
        </div>

        {showRightArrow && (
          <button
            onClick={() => scroll('right')}
            className={`absolute right-0 top-1/2 transform -translate-y-1/2 z-10 p-1 ${
              isDarkMode 
                ? 'bg-gray-700 text-white hover:bg-gray-600' 
                : 'bg-white text-gray-600 hover:bg-gray-100'
            } rounded-full shadow-lg transition-colors`}
          >
            <ChevronRight className="h-5 w-5" />
          </button>
        )}
      </div>

      {selectedGift && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={() => setSelectedGift(null)}
        >
          <div 
            className={`w-full max-w-sm mx-4 rounded-lg ${
              isDarkMode ? 'bg-gray-800' : 'bg-white'
            } shadow-xl overflow-hidden`}
            onClick={e => e.stopPropagation()}
          >
            <div className={`p-4 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'} flex justify-between items-center`}>
              <div className="flex items-center space-x-4">
                <Link to={`/profile/${selectedGift.senderUsername}`}>
                  <img
                    src={selectedGift.senderPhotoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${selectedGift.senderId}`}
                    alt={selectedGift.senderName}
                    className="w-12 h-12 rounded-full"
                  />
                </Link>
                <div>
                  <Link 
                    to={`/profile/${selectedGift.senderUsername}`}
                    className={`font-medium hover:underline ${isDarkMode ? 'text-white' : 'text-gray-900'}`}
                  >
                    {selectedGift.senderName}
                  </Link>
                  <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                    @{selectedGift.senderUsername}
                  </p>
                </div>
              </div>
              <button
                onClick={() => setSelectedGift(null)}
                className={`p-2 ${isDarkMode ? 'hover:bg-gray-600' : 'hover:bg-gray-200'} rounded-full transition-colors`}
              >
                <X className={`h-5 w-5 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`} />
              </button>
            </div>

            <div className="p-6 flex flex-col items-center">
              <div className={`p-8 rounded-full mb-4 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
                <span className="text-4xl">{selectedGift.emoji}</span>
              </div>
              <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                {formatDistanceToNow(selectedGift.timestamp, {
                  addSuffix: true,
                  locale: language === 'pt-BR' ? ptBR : undefined
                })}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}