// Utility for processing items in batches with delays
export async function processBatch<T, R>(
  items: T[],
  batchSize: number,
  processItem: (item: T) => Promise<R>,
  delayMs: number = 1000
): Promise<R[]> {
  const results: R[] = [];
  
  for (let i = 0; i < items.length; i += batchSize) {
    const batch = items.slice(i, i + batchSize);
    
    // Process batch items concurrently
    const batchResults = await Promise.all(
      batch.map(item => processItem(item).catch(error => {
        console.error('Error processing batch item:', error);
        return null;
      }))
    );
    
    results.push(...batchResults.filter((r): r is R => r !== null));
    
    // Add delay between batches if not the last batch
    if (i + batchSize < items.length) {
      await new Promise(resolve => setTimeout(resolve, delayMs));
    }
  }
  
  return results;
}