import { useState, useEffect, useRef } from 'react';
import { Send, Bot, Loader2, AlertCircle, MessageSquare } from 'lucide-react';
import { useAuth } from '../components/AuthProvider';
import { useTheme } from '../contexts/ThemeContext';
import { MainMenu } from '../components/MainMenu';
import { getChatCompletion } from '../lib/openai';
import { analyticsEvents } from '../lib/firebase';
import toast from 'react-hot-toast';

interface ChatMessage {
  id: string;
  userMessage: string;
  aiResponse: string;
  timestamp: Date;
  error?: boolean;
}

const translations = {
  'pt-BR': {
    title: 'Poosting IA',
    placeholder: 'Faça uma pergunta...',
    sending: 'Enviando...',
    welcome: 'Eu sou sua IA pessoal da Poosting, me pergunte qualquer coisa em qualquer idioma',
    errors: {
      messageRequired: 'Por favor, digite uma mensagem',
      apiError: 'Erro ao conectar com a IA. Tente novamente.',
      noLinks: 'Links não são permitidos em mensagens'
    }
  },
  'en': {
    title: 'Poosting AI',
    placeholder: 'Ask a question...',
    sending: 'Sending...',
    welcome: "I'm your personal AI Poosting, ask me anything in any language",
    errors: {
      messageRequired: 'Please enter a message',
      apiError: 'Failed to connect to AI. Please try again.',
      noLinks: 'Links are not allowed in messages'
    }
  }
};

export function AIChat() {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
  const { user } = useAuth();
  const { isDarkMode } = useTheme();
  const chatEndRef = useRef<HTMLDivElement>(null);
  const [error, setError] = useState<string | null>(null);
  const [language, setLanguage] = useState('en');

  // Scroll to top on mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatHistory]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!message.trim() || !user || loading) return;

    // Check for links
    const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)|([a-zA-Z0-9-]+\.(com|net|org|br|io|dev|app|me|co|uk|us|ca|eu))/gi;
    if (urlRegex.test(message)) {
      toast.error(t('errors.noLinks'));
      return;
    }

    const userMessage = message.trim();
    setMessage('');
    setLoading(true);
    setError(null);

    // Add user message immediately for better UX
    const newMessage = {
      id: Date.now().toString(),
      userMessage,
      aiResponse: '',
      timestamp: new Date()
    };
    setChatHistory(prev => [...prev, newMessage]);

    try {
      const aiResponse = await getChatCompletion(userMessage);

      // Update the message with AI response
      setChatHistory(prev => prev.map(msg => 
        msg.id === newMessage.id 
          ? { ...msg, aiResponse } 
          : msg
      ));

      // Log analytics event
      analyticsEvents.aiMessage();
    } catch (error: any) {
      console.error('Error sending message:', error);
      const errorMessage = error.message || t('errors.apiError');
      setError(errorMessage);
      toast.error(errorMessage);

      // Update message with error
      setChatHistory(prev => prev.map(msg => 
        msg.id === newMessage.id 
          ? { ...msg, aiResponse: errorMessage, error: true } 
          : msg
      ));
    } finally {
      setLoading(false);
    }
  };

  // Log page view on mount
  useEffect(() => {
    analyticsEvents.aiChat();
  }, []);

  if (!user) {
    return (
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'} flex items-center justify-center`}>
        <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
          Please sign in to use AI Chat
        </p>
      </div>
    );
  }

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <header className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b`}>
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <Bot className={`h-6 w-6 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
            <h1 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-black'}`}>
              {t('title')}
            </h1>
          </div>
        </div>
        <MainMenu />
      </header>

      <main className={`max-w-2xl mx-auto ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-x min-h-screen`}>
        <div className="p-4">
          <div className={`${isDarkMode ? 'bg-blue-900/20' : 'bg-blue-50'} rounded-lg p-4 mb-4 mt-5`}>
            <p className={isDarkMode ? 'text-blue-300' : 'text-blue-800'}>
              {t('welcome')}
            </p>
          </div>

          {error && (
            <div className={`${isDarkMode ? 'bg-red-900/20' : 'bg-red-50'} rounded-lg p-4 mb-4 flex items-center`}>
              <AlertCircle className={`h-5 w-5 mr-2 flex-shrink-0 ${isDarkMode ? 'text-red-400' : 'text-red-500'}`} />
              <p className={isDarkMode ? 'text-red-400' : 'text-red-800'}>{error}</p>
            </div>
          )}

          <div className="space-y-4 mb-4 max-h-[60vh] overflow-y-auto">
            {chatHistory.map((chat) => (
              <div key={chat.id} className="space-y-4">
                <div className="flex justify-end">
                  <div className={`bg-blue-500 text-white rounded-lg px-4 py-2 max-w-[80%] ${isDarkMode ? 'bg-blue-600' : ''}`}>
                    <p className="whitespace-pre-wrap break-words">{chat.userMessage}</p>
                  </div>
                </div>
                {chat.aiResponse && (
                  <div className="flex justify-start">
                    <div className={`${
                      chat.error 
                        ? isDarkMode ? 'bg-red-900/20 text-red-300' : 'bg-red-50 text-red-800'
                        : isDarkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-900'
                    } rounded-lg px-4 py-2 max-w-[80%]`}>
                      <p className="whitespace-pre-wrap break-words">{chat.aiResponse}</p>
                    </div>
                  </div>
                )}
              </div>
            ))}
            <div ref={chatEndRef} />
          </div>

          <form onSubmit={handleSubmit} className={`sticky bottom-0 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} pt-4`}>
            <div className="relative">
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder={t('placeholder')}
                className={`w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none ${
                  isDarkMode 
                    ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                    : 'border-gray-300 placeholder-gray-500'
                }`}
                rows={3}
                disabled={loading}
              />
              <button
                type="submit"
                disabled={!message.trim() || loading}
                className={`absolute bottom-3 right-3 p-2 ${
                  isDarkMode 
                    ? 'bg-blue-600 text-white hover:bg-blue-700' 
                    : 'bg-blue-500 text-white hover:bg-blue-600'
                } rounded-full disabled:opacity-50 disabled:cursor-not-allowed`}
              >
                {loading ? (
                  <Loader2 className="h-5 w-5 animate-spin" />
                ) : (
                  <Send className="h-5 w-5" />
                )}
              </button>
            </div>
          </form>
        </div>
      </main>
    </div>
  );
}