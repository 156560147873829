import { useState, useEffect, useRef } from 'react';
import { ImagePlus, Loader2, DollarSign } from 'lucide-react';
import { auth, db, storage } from '../lib/firebase';
import { MainMenu } from '../components/MainMenu';
import { useTheme } from '../contexts/ThemeContext';
import { validateAdImage } from '../utils/ads/validation';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { ImageEditor } from '../components/ImageEditor';
import { AdminBoostManager } from '../components/AdminBoostManager';
import toast from 'react-hot-toast';

interface AdDuration {
  days: number;
  url: string;
}

const AD_DURATIONS: AdDuration[] = [
  {
    days: 1,
    url: 'https://buy.stripe.com/9AQbJ64im7lBal228a'
  },
  {
    days: 7,
    url: 'https://buy.stripe.com/eVa28w4imgWb2SA6or'
  },
  {
    days: 15,
    url: 'https://buy.stripe.com/9AQ4gE02649p2SAdQU'
  },
  {
    days: 30,
    url: 'https://buy.stripe.com/6oE5kI7uy9tJfFm28d'
  }
];

const translations = {
  'pt-BR': {
    title: 'Poosting Ads',
    description: 'Agora você pode anunciar seu negócio na Poosting! Alcance milhares de pessoas anunciando no Feed Principal. Envie sua mídia e anuncie agora.',
    uploadImage: 'Enviar Imagem',
    imageRequired: 'Imagem é obrigatória',
    adText: {
      label: 'Texto do Anúncio',
      placeholder: 'Digite o texto do seu anúncio...',
      maxLength: 'caracteres'
    },
    adLink: {
      label: 'Para onde quer apontar seu anúncio?',
      placeholder: 'Informe o link do seu anúncio...'
    },
    processing: 'Processando...',
    adCreated: 'Anúncio criado com sucesso!',
    selectDuration: 'Selecione a duração do anúncio:',
    days: 'dias',
    day: 'dia',
    payNow: 'Pagar Agora',
    errors: {
      imageRequired: 'Por favor, selecione uma imagem',
      textRequired: 'Por favor, digite o texto do anúncio',
      invalidLink: 'Por favor, insira um link válido',
      uploadFailed: 'Falha ao enviar imagem',
      paymentFailed: 'Falha no pagamento',
      invalidImage: 'Imagem inválida. Use JPG, PNG ou WebP até 5MB',
      durationRequired: 'Por favor, selecione a duração do anúncio'
    }
  },
  'en': {
    title: 'Poosting Ads',
    description: 'Now you can advertise your business on Poosting! Reach thousands of people by advertising in the Main Feed. Upload your media and advertise now.',
    uploadImage: 'Upload Image',
    imageRequired: 'Image is required',
    adText: {
      label: 'Ad Text',
      placeholder: 'Enter your ad text...',
      maxLength: 'characters'
    },
    adLink: {
      label: 'Where do you want your ad to point to?',
      placeholder: 'Enter your ad link...'
    },
    processing: 'Processing...',
    adCreated: 'Ad created successfully!',
    selectDuration: 'Select ad duration:',
    days: 'days',
    day: 'day',
    payNow: 'Pay Now',
    errors: {
      imageRequired: 'Please select an image',
      textRequired: 'Please enter ad text',
      invalidLink: 'Please enter a valid link',
      uploadFailed: 'Failed to upload image',
      paymentFailed: 'Payment failed',
      invalidImage: 'Invalid image. Use JPG, PNG or WebP up to 5MB',
      durationRequired: 'Please select ad duration'
    }
  }
};

export function Ads() {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [finalImage, setFinalImage] = useState<File | null>(null);
  const [adText, setAdText] = useState('');
  const [adLink, setAdLink] = useState('');
  const [processing, setProcessing] = useState(false);
  const [showImageEditor, setShowImageEditor] = useState(false);
  const [selectedDuration, setSelectedDuration] = useState<AdDuration | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { isDarkMode } = useTheme();
  const [language, setLanguage] = useState(() => {
    return navigator.language.startsWith('pt') ? 'pt-BR' : 'en';
  });

  const isAdmin = auth.currentUser?.email?.toLowerCase() === 'afonsoalcantara@outlook.com';

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  const handleImageSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result as string);
        setShowImageEditor(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const validateLink = (link: string): boolean => {
    if (!link) return false;
    try {
      new URL(link);
      return true;
    } catch {
      return false;
    }
  };

  const handleCroppedImage = async (blob: Blob) => {
    if (!auth.currentUser) return;

    try {
      const file = new File([blob], `ad-${Date.now()}.jpg`, { type: 'image/jpeg' });

      // Validate image
      const validation = await validateAdImage(file);
      if (!validation.valid) {
        toast.error(validation.error || t('errors.invalidImage'));
        return;
      }

      // Generate unique filename
      const timestamp = Date.now();
      const filename = `ads/${auth.currentUser.uid}/${timestamp}.jpg`;
      
      // Upload to Firebase Storage
      const storageRef = ref(storage, filename);
      await uploadBytes(storageRef, file);
      const imageUrl = await getDownloadURL(storageRef);

      setFinalImage(file);
      setShowImageEditor(false);
      toast.success(t('adCreated'));
    } catch (error: any) {
      console.error('Error processing image:', error);
      toast.error(error.message || t('errors.uploadFailed'));
      resetFileInputs();
    }
  };

  const handlePayment = async () => {
    if (!finalImage || !selectedDuration) {
      toast.error(t('errors.imageRequired'));
      return;
    }

    if (!adText.trim()) {
      toast.error(t('errors.textRequired'));
      return;
    }

    if (!validateLink(adLink)) {
      toast.error(t('errors.invalidLink'));
      return;
    }

    try {
      setProcessing(true);

      // Create ad document in Firestore with email
      await addDoc(collection(db, 'ads'), {
        userId: auth.currentUser?.uid,
        username: auth.currentUser?.displayName?.replace('@', '') || 'unknown',
        email: auth.currentUser?.email,
        imageUrl: finalImage ? URL.createObjectURL(finalImage) : null,
        adText: adText.trim(),
        adLink: adLink.trim(),
        timestamp: serverTimestamp(),
        status: 'pending',
        amount: selectedDuration?.days === 1 ? 10000 : // R$100.00
                selectedDuration?.days === 7 ? 50000 : // R$500.00
                selectedDuration?.days === 15 ? 90000 : // R$900.00
                150000 // R$1500.00 for 30 days
      });

      // Redirect to Stripe Checkout URL
      window.location.href = selectedDuration.url;
    } catch (error: any) {
      console.error('Error creating ad:', error);
      toast.error(t('errors.uploadFailed'));
      setProcessing(false);
    }
  };

  const resetFileInputs = () => {
    if (fileInputRef.current) fileInputRef.current.value = '';
    setSelectedImage(null);
    setFinalImage(null);
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <header className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b`}>
        <div className="max-w-2xl mx-auto px-4 py-3">
          <div className="flex items-center space-x-2">
            <DollarSign className={`h-6 w-6 ${isDarkMode ? 'text-green-400' : 'text-green-500'}`} />
            <h1 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-black'}`}>
              {t('title')}
            </h1>
          </div>
        </div>
        <MainMenu />
      </header>

      <main className={`max-w-2xl mx-auto ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-x min-h-screen p-4`}>
        <div className="space-y-6">
          <div className={`text-center mb-8 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            <p className="text-sm">{t('description')}</p>
          </div>

          <div>
            <div className="flex justify-center mb-4">
              <div className="relative">
                <div 
                  onClick={() => !processing && fileInputRef.current?.click()}
                  className={`w-full aspect-square max-w-md rounded-lg ${
                    finalImage ? '' : 'border-2 border-dashed'
                  } ${
                    isDarkMode 
                      ? 'border-gray-600 bg-gray-700' 
                      : 'border-gray-300 bg-gray-50'
                  } flex items-center justify-center cursor-pointer overflow-hidden group ${
                    processing ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                >
                  {processing ? (
                    <div className="text-center">
                      <Loader2 className={`h-12 w-12 animate-spin mx-auto mb-4 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
                      <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                        {t('processing')}
                      </p>
                    </div>
                  ) : finalImage ? (
                    <>
                      <img
                        src={URL.createObjectURL(finalImage)}
                        alt="Ad preview"
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                        <ImagePlus className="h-12 w-12 text-white" />
                      </div>
                    </>
                  ) : (
                    <div className="text-center">
                      <ImagePlus className={`h-12 w-12 mx-auto mb-4 ${isDarkMode ? 'text-gray-500' : 'text-gray-400'}`} />
                      <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                        {t('uploadImage')}
                      </p>
                    </div>
                  )}
                </div>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="image/jpeg,image/png,image/webp"
                  onChange={handleImageSelect}
                  disabled={processing}
                  className="hidden"
                />
              </div>
            </div>

            <div className="space-y-4 mb-8">
              {/* Ad Text Field */}
              <div>
                <label className={`block text-sm font-medium mb-2 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                  {t('adText.label')}
                </label>
                <textarea
                  value={adText}
                  onChange={(e) => setAdText(e.target.value)}
                  maxLength={120}
                  placeholder={t('adText.placeholder')}
                  className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                    isDarkMode 
                      ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                      : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                  }`}
                  rows={3}
                />
                <p className={`text-xs mt-1 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                  {adText.length}/120 {t('adText.maxLength')}
                </p>
              </div>

              {/* Ad Link Field */}
              <div>
                <label className={`block text-sm font-medium mb-2 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                  {t('adLink.label')}
                </label>
                <div className="relative">
                  <input
                    type="url"
                    value={adLink}
                    onChange={(e) => setAdLink(e.target.value)}
                    placeholder={t('adLink.placeholder')}
                    className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                      isDarkMode 
                        ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                        : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                    }`}
                  />
                </div>
              </div>
            </div>

            <div className={`mb-8 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              <h3 className="text-lg font-semibold mb-4 text-center">
                {t('selectDuration')}
              </h3>
              <div className="grid grid-cols-2 gap-4">
                {AD_DURATIONS.map((duration) => (
                  <button
                    key={duration.days}
                    onClick={() => setSelectedDuration(duration)}
                    className={`p-4 rounded-lg border-2 transition-colors ${
                      selectedDuration?.days === duration.days
                        ? isDarkMode
                          ? 'border-green-500 bg-green-500/20'
                          : 'border-green-500 bg-green-50'
                        : isDarkMode
                          ? 'border-gray-600 hover:border-green-500/50'
                          : 'border-gray-200 hover:border-green-500/50'
                    }`}
                  >
                    <div className="text-lg font-bold">
                      {duration.days} {duration.days === 1 ? t('day') : t('days')}
                    </div>
                  </button>
                ))}
              </div>
            </div>

            <button
              onClick={handlePayment}
              disabled={!finalImage || !selectedDuration || !adText.trim() || !adLink.trim() || processing}
              className={`w-full flex items-center justify-center space-x-2 px-4 py-3 ${
                isDarkMode 
                  ? 'bg-green-600 text-white hover:bg-green-700' 
                  : 'bg-green-500 text-white hover:bg-green-600'
              } rounded-lg disabled:opacity-50 transition-colors`}
            >
              {processing ? (
                <>
                  <Loader2 className="h-5 w-5 animate-spin" />
                  <span>{t('processing')}</span>
                </>
              ) : (
                <>
                  <DollarSign className="h-5 w-5" />
                  <span>{t('payNow')}</span>
                </>
              )}
            </button>
          </div>
        </div>

        {/* Admin Boost Manager */}
        {isAdmin && (
          <AdminBoostManager isDarkMode={isDarkMode} />
        )}
      </main>

      {showImageEditor && selectedImage && (
        <ImageEditor
          image={selectedImage}
          onSave={handleCroppedImage}
          onCancel={() => {
            setShowImageEditor(false);
            setSelectedImage(null);
          }}
          aspectRatio={1}
        />
      )}
    </div>
  );
}